import React, { useEffect, useState } from 'react';

const CustomProgressBar = ({progressBarKey, currentPage, pageTitles, isFFT, survey }) => {

  const [pageEntries, setPageEntries] = useState([]);
  const [indexedPage, setIndexedPage] = useState();

  useEffect(() => {
    if (!isFFT) {
      const pages = {
        "pt.1": "Health Profile",
        "pt.2": "Symptoms",
        "pt.3": "Exposures",
        "pt.4": "TTP",
      };
      const entries = Object.values(pages);
      setPageEntries(entries);

      for (const [key, value] of Object.entries(pages)) {
        if (survey.currentPage.jsonObj.name.includes(key)) {
          setIndexedPage(Object.keys(pages).indexOf(key));
          currentPage = Object.keys(pages).indexOf(key);
          break;
        }
      }
      
    }
  }, [isFFT, progressBarKey]);

  const ttpMouseOver = () => {
        document.getElementById("navigationToolTip").style.display = "block";
    }
    const ttpMouseOut = () => {
        document.getElementById("navigationToolTip").style.display = "none";
    }
  if (isFFT) {
    console.log("currentPage", currentPage);
    console.log("pageTitles", pageTitles);
    return (
      <div className="flex justify-between">
        {pageTitles?.map((step, i) => (
          <div
            key={i}
            className={`step-item ${currentPage === i && "activeStep"} ${
              i < currentPage + 1 && "complete"
            } `}
          >
            <p className="page-title">{step === pageTitles[currentPage] ? step : <></>}</p>
            <div className="step"></div>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <>
        <div className="flex justify-between">
          {pageEntries.map((step, i) => (
            <div
              key={i}
              className={`step-item ${indexedPage === i && "activeStep"} ${
                i < indexedPage + 1 && "complete"
              } `}
            >
                {indexedPage === 3 && step === "TTP" ? <div className='speech-bubble' id="navigationToolTip">Testing, Treatment, Prevention</div> : <></>}
                <p className={`page-title ${step === "TTP" ? "ttp-underline" : ""}`}
                onMouseOver={step === "TTP" ? ttpMouseOver : undefined}
                onMouseOut={step === "TTP" ? ttpMouseOut : undefined}>
                    {step === pageEntries[indexedPage] ? step : <></>}
                </p>
                <div className="step"></div>
            </div>
          ))}
        </div>
        
      </>
    );
  }
};

export default CustomProgressBar;