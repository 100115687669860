import React, { useEffect, useState, useRef } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { DetailSubHeader } from "./DetailSubHeader";
import { DetailSubsection } from "./DetailSubsection";
import { Dropdown } from "./Dropdown";
import { MinuteCard } from "./MinuteCard";
import fftIcon from "../../../images/fft-tree-dopage.svg"
import boundaryIcon from "../../../images/boundary-dopage.svg"
import dreamIcon from "../../../images/dream-dopage.svg"
import gratitudeIcon from "../../../images/gratitude-dopage.svg"
import infectiousIcon from "../../../images/infectious-dopage.svg"
import ttpIcon from "../../../images/ttp-dopage.svg"

function DoComponent({ disease,
  headerTitle,
  minuteCardOneHeader,
  minuteCardOneText,
  minuteCardTwoHeader,
  minuteCardTwoText,
  setDisease,
  setPage,
  children,
  tokenPayload,
  communityHealthInfo,
  countyLabel,
  stateLabel,
  countryLabel, 
  savedFFTData,
}) {

  const [openTwoMin, setOpenTwoMin] = useState(false);
    const location = useLocation()
    const navigate = useNavigate()
    const wrapperRef = useRef(null);

    const [comingSoonList, setComingSoonList] = useState({
        BoundaryBuilder: false,
        DreamJournal: false,
        GratitudeJournal: false,
        TTP:false
    })

    const ComingSoonClick = (name)=>{
        const NewList = {
        BoundaryBuilder: false,
        DreamJournal: false,
        GratitudeJournal: false,
        TTP:false
    }
    setComingSoonList({...NewList, [`${name}`]: true})

    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setComingSoonList({
                    BoundaryBuilder: false,
                    DreamJournal: false,
                    GratitudeJournal: false,
                    TTP: false
                })
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {

            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);



  useEffect(() => {
    setOpenTwoMin(false);
  }, [disease]);


    const options = {
        community: ["Location"],
        emotional: ["Today", "This Week", "This Month", "Select Date/Range"],
    }

    const comingSoonOptions = {
        community: ["Disease", "Transmission Type", "Demographics", "Advanced"],
        emotional: ["Thoughts", "Feelings", "Body Sensations", "Actions"],
    };

    const firstLinkImg = () =>{
        if (location.pathname === "/emotionalhealth"){
            return <img src={fftIcon} alt={"tree/infectious icon"} style={{height: "148px", width: "148px"}}/>
        }
        if (location.pathname === "/learnmore"){
            return <img src={infectiousIcon} alt={"tree/infectious icon"} style={{height: "100px", width: "100px"}}/>
        }
    }

    const minuteCardLoc = () =>{
        if (location.pathname === "/emotionalhealth") {
            return "/emotionalhealth/fft"
        }
        if (location.pathname === "/learnmore"){
            return "/learnmore"
        }
    }


  return (
      <div className={location.pathname === "/emotionalhealth" ? "Do-container": "Do-container Do-container-id"}>
          <div className={"Do-component"}>
              <div  ref={wrapperRef} onClick={()=> {
                  if (location.pathname === "/emotionalhealth") {
                      navigate('/emotionalhealth/fft', {
                          state: {
                              headerTitle: "Emotional Health",
                              options: options.emotional,
                              comingSoonOptions: comingSoonOptions.emotional,
                              minuteCardOneHeader: "Feelings Flow Tracker",
                              minuteCardOneText: "Identify, understand, and process your emotions",
                              minuteCardTwoHeader: "Boundary Builder",
                              minuteCardTwoText: "Understand your personal boundaries",
                              sayComponentText:
                                  "In addition to Reading, Writing and Arithmetic, should schools teach Relationships (how we relate to ourselves, others and situations)?",
                              saySectionLabel: "VIEW ACTIVITY AS",
                              tokenPayload:tokenPayload,
                              countyLabel,
                              stateLabel,
                              countryLabel,

                          }
                      })
                  }
                  if (location.pathname === "/learnmore") {
                      navigate('/learnmore/survey', {
                          state: {
                              headerTitle: "Emotional Health",
                              options: options.emotional,
                              comingSoonOptions: comingSoonOptions.emotional,
                              minuteCardOneHeader: "Feelings Flow Tracker",
                              minuteCardOneText: "Identify, understand, and process your emotions",
                              minuteCardTwoHeader: "Boundary Builder",
                              minuteCardTwoText: "Understand your personal boundaries",
                              sayComponentText:
                                  "In addition to Reading, Writing and Arithmetic, should schools teach Relationships (how we relate to ourselves, others and situations)?",
                              saySectionLabel: "VIEW ACTIVITY AS",
                              tokenPayload:tokenPayload
                          }
                      })
                  }


              }}>
                  {location.pathname === "/emotionalhealth" ?
                      <img src={fftIcon} alt={"tree/infectious icon"} style={{height: "148px", width: "148px"}}/> :
                      <img src={infectiousIcon} alt={"tree/infectious icon"} style={{height: "85px", width: "85px"}}/>
                  }
              </div>
              {location.pathname === "/emotionalhealth" ?
                  <span>Feelings Flow</span> :
                  <span style={{fontSize:"12px"}}>Screen for <br/>Infectious Disease</span>
              }

          </div>
          <div className={"Do-component"}>
              <div onClick={() => {
                  if (location.pathname === "/emotionalhealth") {
                      ComingSoonClick("BoundaryBuilder")
                  }
                  if (location.pathname === "/learnmore") {
                      ComingSoonClick("TTP")

                  }
              }}>
                  {location.pathname === "/emotionalhealth" ?
                      <img src={boundaryIcon} alt={"boundary icon"}/> :
                      <img src={ttpIcon} alt={"tree/infectious icon"} style={{height: "100px", width: "100px"}}/>
                  }
              </div>

                  {location.pathname === "/emotionalhealth" ?
                      <span>Boundaries Builder <br/>
                          {comingSoonList.BoundaryBuilder &&
                              <small>Coming Soon</small>
                          }
                      </span> :
                      <span style={{fontSize: "12px"}}>Find Testing,<br/>Treatment or <br/>Prevention (TTP) <br/>
                          {comingSoonList.TTP &&
                              <small>Coming Soon</small>
                          }
                      </span>
                  }

          </div>
          {location.pathname === "/emotionalhealth" &&
              <React.Fragment>
                  <div className={"Do-component"}>
                      <div onClick={()=>ComingSoonClick("DreamJournal")}>
                          <img src={dreamIcon} alt={"dream icon"} style={{height: "148px", width: "148px"}}/>
                      </div>
                      <span>Dream Journal <br/>
                          {comingSoonList.DreamJournal &&
                              <small>Coming Soon</small>
                          }
                      </span>

                  </div>
                  <div className={"Do-component"}>
                      <div onClick={()=>ComingSoonClick("GratitudeJournal")}>
                          <img src={gratitudeIcon} alt={"gratitude icon"} style={{height: "95px", width: "95px"}}/>
                      </div>
                      <span>Gratitude Journal <br/>
                          {comingSoonList.GratitudeJournal &&
                              <small>Coming Soon</small>
                          }

                      </span>
                  </div>
              </React.Fragment>


          }

</div>
)
    ;
}

export default DoComponent;