import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import emailIcon from '../../images/email-icon.svg';
import './RegistrationLoginFlow.css';

const ConfirmationPage = () => {
  const { state } = useLocation(); // Access the passed state
  const { formData } = state || {}; // Extract formData (if available)
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const email = formData?.email;

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    //TODO: remove password for signing up
    try {
      await Auth.confirmSignUp(email, confirmationCode);

      // Sign in the user after confirming sign-up
      await Auth.signIn(email, formData.password)

      // Redirect the user back to the main page after successful sign-in
      navigate('/success', {state:{loggedIn: false}}); // Change '/' to the path where your main app resides
    } catch (error) {
      setError(error.message || 'An error occurred.');
      console.error('Error confirming sign up:', error);
    }
  };

  const handleResendCode = async () => {
    try {
      await Auth.resendSignUp(email);
      console.log('Confirmation code resent', email);
    } catch (error) {
      setError(error.message || 'An error occurred while resending code.');
      console.error('Error resending confirmation code:', error);
    }
  };

  return (
    <div className='confirmationRoot' >
      <img src={emailIcon} alt="email icon" className='emailIcon' />
      <div className='confirmationWrapper'>
        <div className='confirmationHeader'>
          <h2>Check Your Email</h2>
          <p>Please enter your verification code</p>
        </div>
        <OtpInput
          value={confirmationCode}
          onChange={setConfirmationCode}
          numInputs={6}
          renderInput={(props) => <input {...props} />}
          containerStyle="confirmationInputContainer"
          inputStyle="confirmationInput"
        />
        {/* <input
          type="text"
          placeholder="Enter Confirmation Code"
          value={confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
          style={{ margin: '10px' }}
        />
        */}
        <br />
          <div className='signUpConfirmationButtonWrapper'>
              {/* <button className='signUpConfirmationButtons' onClick={handleResendCode}>Resend Code</button> */}
              <button className='signUpConfirmationButtons' onClick={handleSubmit}>Confirm Account</button>
          </div>
        {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
      </div>
      <div className='checkSpamText'>
        <p>Didn't recieve the code?</p>
        <p>Check your spam folder.</p>
      </div>
      <div className='resendText'>
        <p>OR</p>
        <button className='resendCodeButton' onClick={handleResendCode}>Resend Code</button>
      </div>

    </div>
  );
};

export default ConfirmationPage;