export const Powerless = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 2363 2363\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-6372 -3401.96)\">\n" +
    "        <ellipse\n" +
    "          cx=\"3430.13\"\n" +
    "          cy=\"3550.95\"\n" +
    "          fill=\"#000002\"\n" +
    "          rx=\"1444.45\"\n" +
    "          ry=\"1384.27\"\n" +
    "          transform=\"matrix(.81768 0 0 .85323 4748.35 1553.28)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(7566.7 4601.17) scale(24.4536) translate(-34.5 -35)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H69V70H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.5 68.004c18.502 0 33.5-14.999 33.5-33.502S53.002 1 34.5 1C15.999 1 1 15.999 1 34.502s14.999 33.502 33.5 33.502z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#00ADEB\"\n" +
    "              d=\"M55.449 34.502s-6.939 11.522 0 11.522c6.94 0 0-11.522 0-11.522z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M30.229 11.061s1.256 5.791-3.853 9.129c0 0-4.295 2.788-8.817-.885 0 0 8.698 1.591 12.67-8.244zM38.771 11.061s-1.256 5.791 3.853 9.129c0 0 4.295 2.788 8.818-.885 0 0-8.699 1.591-12.671-8.244z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M49.778 53.216c-1.113-7.251-7.49-12.839-15.279-12.839s-14.154 5.576-15.278 12.815c6.58-2.788 17.874-5.504 30.545.024h.012z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M15.764 26.236s8.494-1.603 14.225 5.732c0 0-4.355-.994-11.246 3.864\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M15.764 26.236s8.494-1.603 14.225 5.732c0 0-4.355-.994-11.246 3.864\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M53.235 26.236s-8.494-1.603-14.225 5.732c0 0 4.355-.994 11.246 3.864\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M53.235 26.236s-8.494-1.603-14.225 5.732c0 0 4.355-.994 11.246 3.864\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"