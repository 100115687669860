import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ChevronLeft from "../../images/chevron-left-solid.svg";
import "./profilePage.css";
import CloseIcon from "../../images/bubbly-close.svg";
import NextButton from "../../images/right-arrow-button.svg";
import { Auth } from 'aws-amplify';
import { Slider, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import OtpInput from 'react-otp-input';
import DropdownButton from "../Shared/DropdownButton.js";
import { getZipcodeDetails } from "../Shared/ZipcodeSearch";
import { MdEdit } from "react-icons/md"
import axios from 'axios';

//privacy slider component with customized styling
const CustomSlider = withStyles({
    thumb: {
        height: 12,
        width: 12,
        backgroundColor: '#fff',
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        display: 'none',
    },
    rail: {
        height: 4,
        borderRadius: 4,
        background: 'linear-gradient(89.97deg, #F22568 4.75%, #E2396B 17.64%, #FA3B3D 30.84%, #F15D2A 45.55%, #F27444 61.78%, #FFC843 84.22%)',
        opacity: 1,
    },
    mark: {
        display: 'none',
    },
    markLabel: {
        color: '#fff',
    },
})(Slider);

export function ProfileSettingsPage(props) {
    const location = useLocation();
    const {parentPath, tokenPayload, communityHealthInfo,
        options,
        countyData,
        countyLabel,
        stateData,
        stateLabel,
        countryData,
        countryLabel,
        comingSoonOptions,
        headerTitle,
        minuteCardOneHeader,
        minuteCardOneText,
        minuteCardTwoHeader,
        minuteCardTwoText,
        sayComponentText,
        saySectionLabel,
        newNavPage,
        dropdownOpen} = location.state || {};
    const [hoveredSettings, setHoveredSettings] = useState([]);
    const [visibleInputs, setVisibleInputs] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [userInfo, setUserInfo] = useState();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [newResetPassword, setNewResetPassword] = useState('');
    const [confirmNewResetPassword, setConfirmNewResetPassword] = useState('');
    const confirmSettingRef = useRef(null);
    const forgotPasswordRef = useRef(null);
    const [selectedLocationString, setSelectedLocationString] = useState('');
    const [selectedLocation, setSelectedLocation] = useState({});
    const [locationBool, setLocationBool] = useState(true);
    const [zipResults, setZipResults] = useState([]);
    const [zipResultStrings, setZipResultStrings] = useState([]);
    const [confirmError, setConfirmError] = useState('');
    const [forgotOtpError, setForgotOtpError] = useState('');
    const [forgotPassError, setForgotPassError] = useState('');
    const [verifyEmailError, setVerifyEmailError] = useState('');
    const [sliderValue, setSliderValue] = useState(3);
    const [registeredZipcode, setRegisteredZipcode] = useState('');
    const [registeredCountry, setRegisteredCountry] = useState('');
    const [registeredCity, SetRegisteredCity] = useState('');
    const [registeredLocation, setRegisteredLocation] = useState('');
    const [zipExists, setZipExists] = useState(true);
    const inputRef = useRef(null);
    const confirmEmailRef = useRef(null);
    const [emailOtp, setEmailOtp] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);
    const emailVerifiedRef = useRef(emailVerified);
    const deleteAccountDialogRef = useRef(null);
    const [registeredPrivacyLevel, setRegisteredPrivacyLevel] = useState(3);
    const [privacyLevelChanged, setPrivacyLevelChanged] = useState(false);

    //marks for the privacy slider
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserInfo(false);
    }, [])

    const fetchUserInfo = async (forceFetch) => {
        try {
            let user;  
            if (forceFetch) { // Fetch up to date user information bypassing the cache
                user = await Auth.currentAuthenticatedUser({bypassCache: true});
            }
            else {
                user = await Auth.currentAuthenticatedUser();
            }
            setUserInfo(user);
            setRegisteredZipcode(user.attributes['custom:Zipcode']);
            setRegisteredCountry(user.attributes['custom:Country']);
            SetRegisteredCity(user.attributes['custom:City']);
            if (user.attributes['custom:privacyLevel']) {
                setRegisteredPrivacyLevel(user.attributes['custom:privacyLevel']);
            }
            console.log('registered city:' + user.attributes['custom:City']); // Log directly from the user object
            console.log('registered email:' + user.attributes.email); // Log directly from the user object
        } catch (error) {
            console.error('Error fetching user information', error);
        }
    };

    useEffect(() => {
        setSliderValue(registeredPrivacyLevel);
    }, [registeredPrivacyLevel]);

    useEffect(() => {
        getRegisteredLocation();
    }, [registeredZipcode, registeredCountry, registeredCity])

    useEffect(() => {
        console.log(locationBool);
    }, [locationBool])

    const settings = [
        {
            settingK: "username",
            settingLabel: "Username",
            settingName: userInfo?.attributes.preferred_username,
            settingType: "text",
            settingInputPlaceholder: "Username"
        },
        {
            settingK: "email",
            settingLabel: "Email",
            settingName: userInfo?.attributes.email,
            settingType: "email",
            settingInputPlaceholder: "Email"
        },
        {
            settingK: "registeredLocation",
            settingLabel: "Registered Location",
            country: {
                settingK: "country",
                settingLabel: "Country",
                settingName: userInfo?.attributes['custom:Country'],
                settingType: "text",
                settingInputPlaceholder: "Select Country",
                options: ["United States", "Other countries coming soon!"]
            },
            zipcode: {
                settingK: "zipcode",
                settingLabel: "Zipcode",
                settingName: registeredLocation ? registeredLocation : 'Loading...',
                settingType: "text",
                settingInputPlaceholder: "Zip Code"
            },
        },
        /*
        {
            settingK: "oldPassword",
            settingLabel: "Password",
            settingName: "*".repeat(8),
            settingType: "password",
            settingInputPlaceholder: "Old Password"
        },
        */
    ]

    const removeErrors = () => {
        setConfirmError('');
        setForgotOtpError('');
        setForgotPassError('');
        setVerifyEmailError('');
    };

    const toggleDialog = (dialogRef) => {
        if (!dialogRef.current) {
            console.log('dialogRef is null');
            return;
        }
        if (dialogRef.current.hasAttribute('open')) {
            console.log('closing dialog')   
            dialogRef.current.close() 
            
        } else {
            console.log('opening dialog')
            dialogRef.current.showModal();
        }
        removeErrors();
    };

    const swapDialogs = () => {
        if (confirmSettingRef.current.hasAttribute('open')) 
        {
            confirmSettingRef.current.close();
            forgotPasswordRef.current.showModal();
        }
        else if (forgotPasswordRef.current.hasAttribute('open'))
        {
            forgotPasswordRef.current.close();
            confirmSettingRef.current.showModal();
        }
        else if (confirmEmailRef.current.hasAttribute('open'))
        {
            confirmEmailRef.current.close();
            confirmSettingRef.current.showModal();
        }
    };

    const closeDialogs = () => {
        confirmSettingRef.current.close();
        forgotPasswordRef.current.close();
        confirmEmailRef.current.close();
    };

    const handleSettingButtonHover = (settingKey) => {
        setHoveredSettings((prevHoveredSettings) => [...new Set([...prevHoveredSettings, settingKey])]);
    };

    const handleSettingButtonLeave = (settingKey) => {
        setHoveredSettings((prevHoveredSettings) => prevHoveredSettings.filter((key) => key !== settingKey));
    };

    const handleShowInputButtonClick = (settingKey) => {
        setVisibleInputs((prevVisibleInputs) => [...new Set([...prevVisibleInputs, settingKey])]);
    };

    const handleInputChange = (settingKey, e) => {
        setInputValues((prevInputValues) => ({ ...prevInputValues, [settingKey]: e.target.value }));
    };

    const handleSliderChange = (e, newValue) => {
        setSliderValue(newValue);
    };

    useEffect(() => {
        console.log('sliderValue:', sliderValue);
        console.log('registeredPrivacyLevel:', registeredPrivacyLevel);
        if (sliderValue !== registeredPrivacyLevel) {
            setPrivacyLevelChanged(true);
        } 
    }, [sliderValue]);

    const handleDeleteAccountClick = async () => {
        try {
            await Auth.currentAuthenticatedUser()
                .then(user => Auth.deleteUser(user))
                .then(() => {
                    console.log('User deleted successfully');
                    // Redirect to the sign-out page or homepage after successful deletion
                    window.location.href = '/';
                });
        } catch (error) {
            console.error('Error deleting user account:', error);
        }
    };
    const handleConfirmPassword = async () => {
        try {
            await Auth.signIn(userInfo.username, confirmPassword);
            console.log('Password is correct');
            handleInputSubmit();
        }
        catch (error) {
            console.error('Error with validating password:', error);
            if (error.code === 'NotAuthorizedException') {
                setConfirmError('Error: incorrect password');
            } else {
                setConfirmError('Error: unable to validate password. Please try again later.');
            }
        }
    };
    const handleConfirmEmail = async () => {
        try {
            console.log('Attempting to verify email');
            await Auth.verifyCurrentUserAttributeSubmit('email', emailOtp);
            console.log('Email verified successfully');
            console.log('User information updated successfully');
            setVisibleInputs([]);
            setInputValues({});
            await fetchUserInfo(true);
            closeDialogs();
        } catch (error) {
            console.error('Error verifying email:', error);
            if (error.code === 'CodeMismatchException') {
                setVerifyEmailError('Error: incorrect code. PLease try again');
            } else if (error.code === 'ExpiredCodeException') {
                setVerifyEmailError('The verification code has expired. Please request a new code.');
            } else {
                setVerifyEmailError('Error verifying email. Please try again later.');
            }
        }
    };
    const handleResendVerificationCode = async () => {
        try {
            await Auth.verifyCurrentUserAttribute('email');
            console.log('Verification code resent to email');
        } catch (error) {
            console.error('Error resending verification code:', error);
        }
    };
    useEffect(() => {
        emailVerifiedRef.current = emailVerified;
    }, [emailVerified]);

    const handleInputSubmit = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log(user);
            // if (inputValues.email) {
            //     await Auth.updateUserAttributes(user, {
            //         email: inputValues.email,
            //     });
            //     const code = prompt('Enter the verification code sent to your email');
            //     await Auth.verifyCurrentUserAttributeSubmit('email', code);
            // }
            if (inputValues.oldPassword && inputValues.newPassword) {
                await Auth.changePassword(user, inputValues.oldPassword, inputValues.newPassword);
            }
            if (inputValues.zipcode) {
                await Auth.updateUserAttributes(user, {
                    'custom:Zipcode': inputValues.zipcode,
                });
            }
            if (inputValues.country) {
                await Auth.updateUserAttributes(user, {
                    'custom:Country': inputValues.country,
                });
                console.log('Country updated successfully');
            }
            if (inputValues.city) {
                await Auth.updateUserAttributes(user, {
                    'custom:City': inputValues.city,
                });
                console.log('City updated successfully');
            }
            if (inputValues.username) {
                await Auth.updateUserAttributes(user, {
                    preferred_username: inputValues.username,
                });
            }
            if (privacyLevelChanged) {
                await Auth.updateUserAttributes(user, {
                    'custom:privacyLevel': sliderValue.toString(),
                });
            }
            if (inputValues.email) {
                console.log('Updating email:', inputValues.email);
                try {
                    await Auth.updateUserAttributes(user, { email: inputValues.email });
                    console.log('Email update process finished!');
                } catch (error) {
                    console.error('Error updating email:', error);
                }                
                console.log('Updated email:', user.attributes.email);
                toggleDialog(confirmSettingRef);
                toggleDialog(confirmEmailRef); //opens dialog where it calls the verification when user submits code
            } else {
                console.log('User information updated successfully');
                setVisibleInputs([]);
                setInputValues({});
                setPrivacyLevelChanged(false);
                await fetchUserInfo(true);
                //fetch user info bypassing the cache
                closeDialogs();
            }            
        } catch (error) {
            console.error('Error updating user information', error);
        }
    
    };
    const handleInputCancel = () => {
        setVisibleInputs([]);
        setInputValues({});
        setSliderValue(registeredPrivacyLevel);
        setPrivacyLevelChanged(false);
    };

    const handleSendResetCode = async () => {
        try {
            await Auth.forgotPassword(userInfo?.attributes.email);
            console.log('Reset code sent to email');
            swapDialogs();
        } catch (error) {
            console.error('Error sending reset code:', error);
            setForgotOtpError(error.message || 'Error with sending reset code.');
        }
    };

    const handleResetPassword = async () => {
        if (newResetPassword !== confirmNewResetPassword) {
            console.error('Passwords do not match');
            
            return;
        }
        else {
            try {
                await Auth.forgotPasswordSubmit(userInfo?.attributes.email, otp, newResetPassword);
                console.log('Password reset successfully');
                closeDialogs();
            } catch (error) {
                console.error('Error resetting password:', error);
                if (error.code === 'CodeMismatchException') {
                    setForgotOtpError('Error: verification code is incorrect');
                } else if (error.code === 'InvalidPasswordException') {
                    setForgotPassError(error.message);
                } else if (error.code === 'InvalidParameterException') {
                    setForgotPassError('Error: password must be at least 8 characters long');
                } else if (error.code === 'LimitExceededException') {
                    setForgotPassError('Limit exceeded. Please try again later.');
                } else {
                    console.log(error);
                }
            }
        }
    };

    const getRegisteredLocation = async () => {
        try {
            const res = await axios.get(`https://ehomoi5b95.execute-api.us-east-1.amazonaws.com/dev/zipcode/getPlaceByCityNameAndCountryCodeAndZipcode`, {
                params: { cityName: registeredCity, zipcode: registeredZipcode, countryCode: registeredCountry },
            });
            console.log(res.data);
            const location = res.data.place;
            setRegisteredLocation(`${registeredZipcode}, ${location.pn ? location.pn + ',' : ''}${location.adm3 ? location.adm3 + ',' : ''} ${location.adm2 ? location.adm2 + ',' : ''} ${location.adm1 ? location.adm1 + ',' : ''} ${registeredCountry.toUpperCase()}`);
        }
        catch (error) {
            console.error(error);
        }
    };

    const controllerRef = useRef(null);
    //connects to zip code api to get location details
    const getLocations = async (zipcode) => {
        if(controllerRef.current) {
            controllerRef.current.abort()
            console.log('aborting previous request')
        }
        controllerRef.current = new AbortController()
        try {
            const res = await axios.get(`https://ehomoi5b95.execute-api.us-east-1.amazonaws.com/dev/zipcode/getAllPlacesByZipcodeStartWith`, {
                params: { zipcodeStartWith: zipcode },
            });
            console.log(res.data);
            // const locations = res.data.places;
            // setZipResults(locations);
            const zipcodes = res.data.places;
            
            let locationArray = [];
            for (const zip of zipcodes) {
                for (const place of zip.places) {
                    const location = {...place, zipcode: zip.zipcode};
                    locationArray.push(location);
                }
            };
            console.log(locationArray);
            setZipExists(true);
            setZipResults(locationArray);
        }
        catch (error) {
            console.error(error);
            setZipExists(false);
            setZipResultStrings(['Please enter a valid zipcode']);
        }
    };

    useEffect(() => {
        //TODO: insert sorting logic here
        console.log("console"+JSON.stringify(zipResults));
        const sortedResults = [...zipResults].sort((a, b) => {
            if (a.zipcode < b.zipcode) return -1;
            if (a.zipcode > b.zipcode) return 1;
    
            if (a.pn < b.pn) return -1;
            if (a.pn > b.pn) return 1;
    
            if (a.adm3 < b.adm3) return -1;
            if (a.adm3 > b.adm3) return 1;
    
            if (a.adm2 < b.adm2) return -1;
            if (a.adm2 > b.adm2) return 1;
    
            if (a.adm1 < b.adm1) return -1;
            if (a.adm1 > b.adm1) return 1;
    
            if (a.cc < b.cc) return -1;
            if (a.cc > b.cc) return 1;
    
            return 0;
        });
        console.log("sorted: "+JSON.stringify(sortedResults));
        let locationArray = [];
        for (const location of sortedResults) {
            const country = location.cc;
            //formatting the location details from smallest to largest
            locationArray.push
                (`${location.zipcode},
                ${location.pn? location.pn + ',' : ''} 
                ${location.adm3?  location.adm3 + ',' : ''}
                ${location.adm2? location.adm2 + ',' : ''}
                ${location.adm1? location.adm1 + ',' : ''} 
                ${country}`);
        };
        setZipResultStrings(locationArray);
    }, [zipResults]);

    //gets the location object from the zipResults array via the index from the button value
    const handleLocationSelect = async (index) => {
        setSelectedLocation(zipResults[index]);
        setSelectedLocationString(zipResultStrings[index]);
    };

    useEffect(() => {
        console.log(selectedLocationString);
        const zipcode = selectedLocationString.split(',')[0].trim();
        const country = selectedLocation.cc;
        const city = selectedLocation.pn;
        setInputValues((prevInputValues) => ({ ...prevInputValues, 'zipcode': zipcode, 'country': country, 'city': city }));
    }, [selectedLocation, selectedLocationString]);

    //marks for the privacy slider
    const marks = [
        { value: 1, label: '1', info: 'ads based on psychographics, demographics and location'},
        { value: 2, label: '2', info: 'ads based on demographics and location'},
        { value: 3, label: '3', info: 'ads based on location only'},
        { value: 4, label: '4', info: 'ads only of products/brands that you specify'},
        { value: 5, label: '5', info: 'no ads - you pay monthly subscription fee'},
    ];

    //privacy slider tooltip component
    function ValueLabelComponent(props) {
        const { children, value, marks } = props;
      
        // Find the corresponding mark for the current value
        const currentMark = marks.find(mark => mark.value === value);
        const label = currentMark ? currentMark.label : value; // Fallback to value if no label is found
        const info = currentMark ? currentMark.info : ''; // Fallback to empty string if no info is found
      
        const tooltipText = `${info ? `${info}` : ''}`;
      
        return (
          <Tooltip enterTouchDelay={0} placement="top" title={tooltipText}>
            {children}
          </Tooltip>
        );
    }
      

    return (
        <div className="profileSettingsPage">
            <dialog className='deleteAccountDialog' ref={deleteAccountDialogRef}>
                <div id="closeDialog">
                    <button className="closeDialogButton" id='closeConfirmation' onClick={() => {toggleDialog(deleteAccountDialogRef)}}>
                        <p>-</p>
                    </button>
                </div>
                <p className='confirmSettingText'>Are you sure you want to delete your account?</p>
                <p className='deleteSubText'>You will not be able to recover your information.</p>
                <button className='confirmSettingButton' onClick={handleDeleteAccountClick}>
                    <img src={NextButton} alt="next" className='nextButton'/>
                </button>
            </dialog>
            <dialog ref={confirmEmailRef} className='confirmEmailDialog'>
                <div className="goBackContainer">
                    <button className="goBackButton" onClick={() => {swapDialogs();}}>
                        <img src={ChevronLeft} alt="chevron" id='backChevron'/>
                    </button>
                    <button className="closeDialogButton" id='closeConfirmation' onClick={() => {toggleDialog(confirmEmailRef);}}>
                        <p>-</p>
                    </button>
                </div>
                <p className='forgotPassText'>Check your new email — enter the verification code to confirm your email</p>
                <OtpInput
                    value={emailOtp}
                    onChange={setEmailOtp}
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                    containerStyle="otpInputContainer"
                    inputStyle="otpInput"
                />
                {verifyEmailError && <div style={{ color: 'red', marginTop: '10px' }}>{verifyEmailError}</div>}
                <button className='confirmSettingButton' disabled={!emailOtp} onClick={handleConfirmEmail}>
                    <img src={NextButton} alt="next" className='nextButton'/>
                </button>
                {/* <p className='linkText' onClick={handleResendVerificationCode}>Resend Code</p> */}
            </dialog>
            <dialog ref={forgotPasswordRef} className='forgotPasswordDialog'>
                <div className="goBackContainer">
                    <button className="goBackButton" onClick={() => {swapDialogs();}}>
                        <img src={ChevronLeft} alt="chevron" id='backChevron'/>
                    </button>
                    <button className="closeDialogButton" id='closeConfirmation' onClick={() => {toggleDialog(forgotPasswordRef);}}>
                        <p>-</p>
                    </button>
                </div>
                <p className='forgotPassText'>Check your email — enter your verification code and new password</p>
                <div className='forgotPassInputContainer'>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                        containerStyle="otpInputContainer"
                        inputStyle="otpInput"
                    />
                    {forgotOtpError && <div style={{ color: 'red', marginTop: '10px' }}>{forgotOtpError}</div>}
                    <input className='newPassword' type='password' placeholder='new password' onChange={(e) => setNewResetPassword(e.target.value)}></input>
                    <input className='newPassword' id='confirmPassword' type='password' placeholder='confirm password' onChange={(e) => setConfirmNewResetPassword(e.target.value)}></input>
                    {forgotPassError && <div style={{ color: 'red', marginTop: '10px' }}>{forgotPassError}</div>}
                    <button className='confirmSettingButton' disabled={!otp||!newResetPassword||!confirmNewResetPassword||(newResetPassword!=confirmNewResetPassword)} onClick={handleResetPassword}>
                        <img src={NextButton} alt="next" className='nextButton'/>
                    </button>
                </div>
                <p className='forgotEmailText'>Forgot your email? <br/> Contact support@civilience.social </p>
            </dialog>
            <dialog ref={confirmSettingRef} className="confirmSettingDialog" >
                <div id="closeDialog">
                    <button className="closeDialogButton" id='closeConfirmation' onClick={() => {toggleDialog(confirmSettingRef)}}>
                        <p>-</p>
                    </button>
                </div>
                <p className="confirmSettingText">Enter your password to confirm setting changes</p>
                <input className='passwordBox' type='password' placeholder='password' onChange={(e) => setConfirmPassword(e.target.value)}></input>
                {confirmError && <div style={{ color: 'red', marginTop: '10px' }}>{confirmError}</div>}
                <button className='confirmSettingButton' disabled={!confirmPassword} onClick={handleConfirmPassword}>
                    <img src={NextButton} alt="next" className='nextButton'/>
                </button>
                <button className='linkText' onClick={() => {handleSendResetCode();}}>Forgot your password?</button>
            </dialog>
            <div className="returnProfile">
                <div className="returnProfileSection">
                    <button onClick={() => navigate(-1)} className="returnProfileText">
                        <img src={ChevronLeft} alt="chevron" className="chevronLeft"/> 
                    </button>
                    <button className="closeMenu" onClick={() => {navigate(parentPath, {state:{dropdownOpenState:{footer:false,expand:false}, options:options, communityHealthInfo,
                    countyData,
                    countyLabel,
                    stateData,
                    stateLabel,
                    countryData,
                    countryLabel,
                    comingSoonOptions,
                    headerTitle,
                    minuteCardOneHeader,
                    minuteCardOneText,
                    minuteCardTwoHeader,
                    minuteCardTwoText,
                    sayComponentText,
                    saySectionLabel,
                    newNavPage,
                    tokenPayload}})}}>
                        <p>-</p>
                    </button>
                </div>
                <hr className="profileDivider" />
            </div>
            <div className="settingsContainer">
                <div className="settingsTop">
                    <div className="settingsTitle">
                        <h1 className="settingsHeader">SETTINGS & PRIVACY</h1>
                    </div>
                </div>
                <div id="settingsSection">
                    {/* May entirely remove this function, create react component*/}
                    {settings.map((setting) => (
                        <div>
                            <div
                                key={setting.settingK}
                                className="settingButton"
                                // onMouseEnter={() => handleSettingButtonHover(setting.settingK)}
                                // onMouseLeave={() => handleSettingButtonLeave(setting.settingK)}
                            >
                                {setting.settingK === "registeredLocation" ? (                          
                                        <div className='settingInfoContainer'>
                                            <div className='flex'>
                                                <span className="settingLabel">{setting.settingLabel}:</span>
                                                <div className="locationSettings">
                                                    {visibleInputs.includes(setting.zipcode.settingK) ? ( 
                                                        <div className="inputContainer" id='locationSettingContainer'>
                                                       { locationBool || !selectedLocation ? ( 
                                                        <DropdownButton
                                                            ButtonAllTextColor={"black"}
                                                            ButtonPrimaryTextColor={'black'}
                                                            ButtonPrimaryBackground={'#f8f9fa'}
                                                            ButtonAllBackground={"#f8f9fa"}
                                                            ButtonFunctionChange={false}
                                                            ButtonAllWidth={'320px'}
                                                            ButtonPrimaryWidth={'320px'}
                                                            ButtonPrimaryIcon={'block'}
                                                            BoxPosition={['0', '10px']}
                                                            FontSize={'11px'}
                                                            Overflow={true}
                                                            listHoverColor={'#00286E'}
                                                            ButtonBoxBorder={true}
                                                            ButtonAllRadius={'8px'}
                                                            ButtonPrimaryRadius={'20px'}
                                                            ButtonAllHeight={'25px'}
                                                            ButtonCharList={zipResultStrings}
                                                            Function={function (e) {
                                                                //if the zip is valid, store the selected location and display it as text
                                                                if(zipExists) {
                                                                    console.log(e.value);
                                                                    handleLocationSelect(e.value);
                                                                    setLocationBool(false);
                                                                }
                                                            }}
                                                            primaryBox={
                                                                <input
                                                                    // className={(status === 'fulfilled' && !data) && 'error'}
                                                                    ref={inputRef}
                                                                    type="text"
                                                                    placeholder="Postal/Zip Code"
                                                                    // onClick={() => inputRef.current.classList.remove('error')}
                                                                    // onKeyUp={e => {
                                                                    //     onLookUp(e.target.value)
                                                                    // }}
                                                                    // onClick={() => {setActivePrimaryLocation("")}}
                                                                    onKeyUp={e => {
                                                                        console.log(e.target.value)
                                                                        getLocations(e.target.value);
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                       ) : (
                                                        <div className="location-text">
                                                            <strong onClick={() => {
                                                                setLocationBool(true);
                                                                setSelectedLocationString('');
                                                                setSelectedLocation({});
                                                            }}
                                                                    /*className={status}*/>
                                                                {selectedLocationString ? selectedLocationString : "Enter a valid ZipCode"}
                                                            </strong>
                                                        </div>
                                                       )
                                                    }
                                                        {/* <input
                                                            className='settingsInputFields'
                                                            type={setting.settingType}
                                                            onChange={(e) => handleInputChange(setting.zipcode.settingK, e)}
                                                            placeholder={setting.zipcode.settingInputPlaceholder}
                                                        /> */}
                                                        </div>
                                                    ) : (                                                    
                                                        <div className='editableZipcode'>
                                                            <span className='zipcode'>{setting.zipcode.settingName}</span>

                                                            <button
                                                                className="showInputButton"
                                                                id='zipcodeButton'
                                                                onClick={() => handleShowInputButtonClick(setting.zipcode.settingK)}
                                                            >
                                                                <MdEdit className='editSettingIcon' />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='settingInfoContainer'>
                                            <div className='flex'>
                                                <span className="settingLabel">{setting.settingLabel}:</span>
                                                    {visibleInputs.includes(setting.settingK) ? ( 
                                                        <div className="inputContainer">
                                                        <input
                                                            className='settingsInputFields'
                                                            type={setting.settingType}
                                                            onChange={(e) => handleInputChange(setting.settingK, e)}
                                                            placeholder={setting.settingInputPlaceholder}
                                                        />
                                                        </div>
                                                    ) : ( 
                                                        <div className="editableSetting">
                                                            <span>{setting.settingName}</span>
                                                            <button
                                                                className="showInputButton"
                                                                onClick={() => handleShowInputButtonClick(setting.settingK)}
                                                            >
                                                                <MdEdit className='editSettingIcon'/>
                                                            </button>
                                                        </div>
                                                    ) }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    ))}
                    {(visibleInputs.length > 0 || privacyLevelChanged ) && (
                        <div className='settingsInputSubmitCancelSection'>
                            <button onClick={() => handleInputCancel()}>Cancel</button>
                            <button onClick={() => toggleDialog(confirmSettingRef)}>Submit</button>
                        </div>)}
                </div>
                <hr className="profileDivider" />
                <div className='privacyLevelSection'>
                    <span className='privacyLevelLabel'>
                        Preferred Privacy Level
                    </span>
                    <div className='privacyLevelSlider'>
                        <CustomSlider
                            aria-label='Privacy Level'
                            value={sliderValue}
                            onChange={handleSliderChange}
                            step={1}
                            min={1} max={5}
                            marks={marks}
                            ValueLabelComponent={(props) => ValueLabelComponent({ ...props, marks })}
                        />
                    </div>
                </div>
                <hr className="profileDivider" />
                <div id="deleteSection">
                    <button className='deleteAccountButton' onClick={() => {toggleDialog(deleteAccountDialogRef)}}>Delete Account</button>
                </div>
            </div>
        </div>
    )
}