import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dropdown } from "../DetailContent/Dropdown";
import SwitchSurvey from "../DetailContent/SwitchSurvey";
import { DiseaseContainer } from "../PageContent/DiseaseContainer"
import * as Survey from 'survey-react-ui';
import "survey-core/defaultV2.min.css";
import axios from 'axios';
import { FunctionFactory } from "survey-core";
import { createMap, removeDays, checkIndivProfile, updateStringComponents, updateProfile, addIIcon, isLessThanFourMonths, isOlderThanSeventy, showFirstQuestion} from "./SurveyHelper";
import {Reward} from "../../animations/Reward/Reward";
import {Model} from "survey-core";
import * as SurveyTheme from "survey-core/themes";
import CustomProgressBar from "../../PublicHealth/FeelingsFlowTrackerForms/CustomProgressBar";
import CancelArrow from "../../../images/orange-left-arrow.svg";
import SubmitArrow from "../../../images/orange-right-arrow.svg"
import CustomCivCoin from "../../PublicHealth/FeelingsFlowTrackerForms/CustomCivCoin";
import { Ad } from "../../Shared/Ad";
import { ShowGuidelines } from "../PageContent/ShowGuidelines";
import { Serializer } from "survey-core";
import loopIcon from "../../../images/loop-icon.svg";
import { Auth } from 'aws-amplify';
import { useLocation, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg"
import { GrLocation } from "react-icons/gr";
import { FaAngleDown, FaSort } from "react-icons/fa";
import { HiArrowPath } from "react-icons/hi2";
import { MdAbc } from "react-icons/md";

export const DiseaseSurvey = ({
    disease,
    setDisease,
    showVerticals,
    tokenPayload,
    countyLabel,
    stateLabel,
    countryLabel }) => {

    const [surveys, setSurveys] = useState(new Map());
    const [surveyComplete, setSurveyComplete] = useState(false)
    const [curSurvey, setCurSurvey] = useState(null);
    const [progressBarKey, setProgressBarKey] = useState(0);
    const confirmPrompt = useRef();
    const [submitClicked, setSubmitClicked] = useState(false);
    const [showGuidelines, setShowGuidelines] = useState(false);
    const [userInfo, setUserInfo] = useState();
    const [loadingSurveys, setLoadingSurveys] = useState(true)
    const [selectedDisease, setSelectedDisease] = useState(null)
    const [healthProfile, setHealthProfile] = useState(null)
    const [backPressed, setBackPressed] = useState(false)
    const nameSortRef = useRef(null)
    const transmissionSortRef = useRef(null)
    
    const location = useLocation();
    const passedUserInfo = location.state;
    const navigate = useNavigate();
    useEffect(() => {
        getAllSurveys();
        try {
            Auth.currentAuthenticatedUser().then((user) => {
                setUserInfo(user);
            })
        } catch (error) {
            console.error('Error fetching user information', error);
        }
    }, []);

    function getAllSurveys() {
        axios.get('https://07hki1tcwj.execute-api.us-east-1.amazonaws.com/default/getdata')
        .then((res) => {
            setSurveys(createMap(JSON.parse(res.data.body)));
            setLoadingSurveys(false);
        })
        .catch((err) => console.log(err))
    };

    function saveIndivProfile(sender) {
        const resultsToParse = {};
        const objs = Object.entries(sender.data);
        if (objs.length !== 0){
            for (const [key, value] of objs) {
                if (key.startsWith("Q0") || key.startsWith("Q1") || key === "age") {
                    resultsToParse[key] = value;
                }
            }
            const results = JSON.stringify(resultsToParse);
            console.log("Saving indiv profile");
            if(Object.keys(resultsToParse).length > 1){
                const today = new Date();

                const day = today.toLocaleDateString('en-US', { day: '2-digit' });
                const month = today.toLocaleDateString('en-US', { month: 'long' });
                const year = today.toLocaleDateString('en-US', { year: 'numeric' });
                const formattedDate = `${day} ${month} ${year}`;

                console.log("Saving indiv profile");
                axios.put("https://07hki1tcwj.execute-api.us-east-1.amazonaws.com/default/saveindivprofile", {
                    "userID": tokenPayload.sub,
                    "results": results,
                    "lastUpdated": formattedDate
                }).then((res) => console.log("Individual Profile Saved"))
                .catch((err) => console.log(err));
            }
        }
    }
    const saveResults = useCallback((sender) => {
        const results = JSON.stringify(sender.data);
        
        axios.post("https://07hki1tcwj.execute-api.us-east-1.amazonaws.com/default/saveresults", {
            "survey": disease,
            "results": results
        }).then((res) => {
            const survey = JSON.parse(res.data).survey
            saveIndivProfile(sender);
            console.log("JSON:" + JSON.stringify(res))
            let delayAnimation, delayGuidelines
            if (survey !== "Health Profile Template") {
                delayAnimation = setTimeout(() => setSurveyComplete(true), 500);
                delayGuidelines = setTimeout(() => setShowGuidelines(true), 4000);
            }
            return () => clearTimeout(delayAnimation, delayGuidelines);
        })
        .catch((err) => console.log(err));
    })
    const isFirstCallRef = useRef(true);
    const cachedResult = useRef();
    // Date of birth - used to check age;
    var age = useRef();
    async function lastUpdated() {
        if(isFirstCallRef.current){
            try {
                const res = await axios.get("https://07hki1tcwj.execute-api.us-east-1.amazonaws.com/default/retrieveindivprofile", {
                        params: {
                            userID: tokenPayload.sub
                        }
                    })
                    const mainObject = JSON.parse(res.data.body);
                    const indivSurveyObject = JSON.parse(mainObject.Item.indivSurvey);
                    age.current = indivSurveyObject.age;
                    cachedResult.current = JSON.parse(res.data.body).Item.lastUpdated;
                    isFirstCallRef.current = false;
                    this.returnResult(JSON.parse(res.data.body).Item.lastUpdated);
            } catch (error) {
                cachedResult.current = "Never";
                isFirstCallRef.current = false;
                this.returnResult("Never");
            }
        } else {
            this.returnResult(cachedResult.current);
        }
    }
    
    //TODO: Return user county, state, country from whereever they are stored
    function getCounty(){
        return passedUserInfo.countyLabel;
    }
    function getState(){
        return passedUserInfo.stateLabel;
    }
    function getCountry(){
        return passedUserInfo.countryLabel ? passedUserInfo.countryLabel : userInfo.attributes["custom:Country"];
    }

    FunctionFactory
    .Instance
    .register("removeDays", removeDays);
    FunctionFactory
    .Instance
    .register("getCountry", getCountry);
    FunctionFactory
    .Instance
    .register("getState", getState);
    FunctionFactory
    .Instance
    .register("getCounty", getCounty);
    
    

    FunctionFactory.Instance.register("lastUpdated", lastUpdated, true);
    FunctionFactory.Instance.register("checkIndivProfile", checkIndivProfile);
    FunctionFactory.Instance.register("updateProf", updateProfile);
    FunctionFactory.Instance.register("prevAge", () => {return age.current;});
    FunctionFactory.Instance.register("ageInUse", (params) => {return (typeof params[0] === 'number' && params[0] >= 0) ? params[0] : params[1]});
    FunctionFactory.Instance.register("isLessThanFourMonths", isLessThanFourMonths);
    FunctionFactory.Instance.register("isOlderThanSeventy", isOlderThanSeventy);
    FunctionFactory.Instance.register("removeDays", removeDays);
    FunctionFactory.Instance.register("removeDaysFromSymptomDate", (params) => { var result;
        if(params[1]){
            result = new Date(params[1]);
        } else if(params[2]){
            result = new Date(params[2]);
        } else {
            result = new Date();
        }
        result.setDate(result.getDate() - params[0]);
        return result.toISOString().split('T')[0];});
    FunctionFactory.Instance.register("removeDaysFromSymptomText", (params) => {var result;
        if(params[1]){
            result = new Date(params[1]);
        } else if(params[2]){
            result = new Date(params[2]);
        } else {
            result = new Date();
        }
        result.setDate(result.getDate() - params[0]);
        return formatedDate(result);
    });
    FunctionFactory.Instance.register("getCountry", getCountry);
    FunctionFactory.Instance.register("getState", getState);
    FunctionFactory.Instance.register("getCounty", getCounty);
    FunctionFactory.Instance.register("showFirstQuestion", showFirstQuestion);
    FunctionFactory.Instance.register("removeMaxSymptomDate", (params) => { var result;
        if(params[1]){
            result = new Date(params[1]);
        } else if(params[2]){
            result = new Date(params[2]);
        } else {
            const today = new Date();
            result = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getDate());
        }
        result.setDate(result.getDate());
        return result.toISOString().split('T')[0];});
    FunctionFactory.Instance.register("formatDateText", (params) => {
        const date = new Date(params[0]);
        return formatedDate(date);
    });
    FunctionFactory.Instance.register("question4DateText", (params) => {
        const A3p1a = params[1];
        const A2p1b = params[2];
        const A2p1f = params[3];
        const A3p1b = params[4];
        const A3p1c = params[5];
        const A3p1d = params[6];
        const A3p1e = params[7];
        const A3p1f = params[8];
        const A3p1g = params[9];
        const A3p1h = params[10];
        var date;
        
        if(A2p1b && (A3p1a?.includes("A3.1a.viii") || A3p1a?.includes("none"))){
            date= new Date(A2p1b);
        }else if(A2p1f && (A3p1a?.includes("A3.1a.viii") || A3p1a?.includes("none"))) {
            date= new Date(A2p1f);
        }
        for (const answer of [A3p1b, A3p1c, A3p1d, A3p1e, A3p1f, A3p1g, A3p1h]){
            if (answer){
                date= new Date(answer);
                break;
            }
        } 
        if(A3p1a?.includes("A3.1a.viii") || A3p1a?.includes("none")){
            const today = new Date();
            date = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getDate() - 15);
        }
        return formatedDate(date);
    });
    //can shorten this function (repeat code)
    FunctionFactory.Instance.register("question4Date", (params) => {
        const A3p1a = params[1];
        const A2p1b = params[2];
        const A2p1f = params[3];
        const A3p1b = params[4];
        const A3p1c = params[5];
        const A3p1d = params[6];
        const A3p1e = params[7];
        const A3p1f = params[8];
        const A3p1g = params[9];
        const A3p1h = params[10];
        var date;
        
        if(A2p1b && (A3p1a?.includes("A3.1a.viii") || A3p1a?.includes("none"))){
            date= new Date(A2p1b);
        }else if(A2p1f && (A3p1a?.includes("A3.1a.viii") || A3p1a?.includes("none"))) {
            date= new Date(A2p1f);
        }
        for (const answer of [A3p1b, A3p1c, A3p1d, A3p1e, A3p1f, A3p1g, A3p1h]){
            if (answer){
                date= new Date(answer);
                break;
            }
        } 
        if(A3p1a?.includes("A3.1a.viii") || A3p1a?.includes("none")){
            const today = new Date();
            date = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getDate() - 15);
        }
        return date?.toISOString().split('T')[0];
    });

    const formatedDate = (date) => {
        const utcDate = new Date(date?.getUTCFullYear(), date?.getUTCMonth(), date?.getUTCDate());
        const day = utcDate.toLocaleDateString('en-US', { day: '2-digit' });
        const month = utcDate.toLocaleDateString('en-US', { month: 'long' });
        const year = utcDate.toLocaleDateString('en-US', { year: 'numeric' });
        return `${day} ${month} ${year}`
    }
    
    const links = {
        "COVID-19":
            [surveys.get("Covid-19 Survey Template"), "Airborne / Respiratory"],
        "Monkeypox":
            [surveys.get("Monkeypox Survey Template"), "STI / Blood-related"],
        "Hepatitics C":
            [surveys.get("Hepatitis C Template"), "STI / Blood-related"],
        "HIV": 
            [surveys.get("HIV/AIDS Survey Template"), "STI / Blood-related"],
        "Influenza":
            [surveys.get("Influenza Survey Template"), "Airborne / Respiratory"],
        "Norovirus": 
            [surveys.get("Norovirus Survey Template"), "Foodborne / Waterborne"],
        "Giardiasis":
            [surveys.get("Giardiasis Survey Template"), "Foodborne / Waterborne"],
        "Shigellosis": 
           [surveys.get("Shigellosis Survey Template"), "Foodborne / Waterborne"],
        "Brucellosis":
            [surveys.get("Brucellosis Survey Template"), "Foodborne / Waterborne"],
        "Leptospirosis":
            [surveys.get("Leptospirosis Survey Template"), "Foodborne / Waterborne"],
        "Botulism": 
            [surveys.get("Botulism Survey Template"), "Foodborne / Waterborne"],
        "Common Cold": 
            [surveys.get("Common Cold Survey Template"), "Airborne / Respiratory"],
        "Chickenpox": 
            [surveys.get("Chickenpox Survey Template"), "Airborne / Respiratory"],
        "Diptheria": 
            [surveys.get("Diptheria Survey Template"), "Airborne / Respiratory"],
        "Leprosy": 
            [surveys.get("Leprosy Survey Template"), "Airborne / Respiratory"],
        "Measles": 
            [surveys.get("Measles Survey Template"), "Airborne / Respiratory"],
        "Meningitis": 
            [surveys.get("Menengitis Survey Template"), "Airborne / Respiratory"],
        "Mumps": 
            [surveys.get("Mumps Survey Template"), "Airborne / Respiratory"],
        "Pertussis": 
            [surveys.get("Pertussis Survey Template"), "Airborne / Respiratory"],
        "Pneumonia": 
            [surveys.get("Pneumonia Survey Template"), "Airborne / Respiratory"],
        "Q Fever": 
            [surveys.get("Q Fever Survey Template"), "Airborne / Respiratory"],
        "Rubella": 
            [surveys.get("Rubella Survey Template"), "Airborne / Respiratory"],
        "Shingles": 
            [surveys.get("Shingles (Herpes Zoster) Survey Template"), "Airborne / Respiratory"],
        "Tuberculosis": 
            [surveys.get("Tuberculosis Survey Template"), "Airborne / Respiratory"],
        "Cholera": 
            [surveys.get("Cholera Survey Template"), "Foodborne / Waterborne"],
        "E.Coli": 
            [surveys.get("E Coli Survey Template"), "Foodborne / Waterborne"],
        "Hepatitis A": 
            [surveys.get("Hepatitis A Survey Template"), "Foodborne / Waterborne"],
        "Legionellosis": 
            [surveys.get("Legionellosis Survey Template"), "Foodborne / Waterborne"],
        "Listeriosis": 
            [surveys.get("Listeriosis Survey Template"), "Foodborne / Waterborne"],
        "Salmonellosis": 
            [surveys.get("Salmonellosis Survey Template"), "Foodborne / Waterborne"],
        "Staph": 
            [surveys.get("Staph Survey Template"), "Foodborne / Waterborne"],
        "Campylobacter": 
            [surveys.get("Campylobacter Survey Template"), "Foodborne / Waterborne"],
        "Chancroid": 
            [surveys.get("Chancroid Survey Template"), "STI / Blood-related"],
        "Chlamydia": 
            [surveys.get("Chlamydia Survey Template"), "STI / Blood-related"],
        "Gonorrhea": 
            [surveys.get("Gonorrhea Survey Template"), "STI / Blood-related"],
        "Hepatitis B": 
            [surveys.get("Hepatitis B Survey Template"), "STI / Blood-related"],
        "HSV1 and HSV2": 
            [surveys.get("HSV-1 and HSV-2 Survey Template"), "STI / Blood-related"],
        "Syphilis": 
            [surveys.get("Syphilis Survey Template"), "STI / Blood-related"],
        "Babesiosis":
            [surveys.get("Babesiosis Survey Template"), "Insect / Animal"],
        "Chikungunya":
            [surveys.get("Chikungunya Survey Template"), "Insect / Animal"],
        "Dengue":
            [surveys.get("Dengue Survey Template"), "Insect / Animal"],
        "Ehrlichia Chaffeensis":
            [surveys.get("Ehrlichia Chaffeensis Survey Template"), "Insect / Animal"],
        "Lassa Fever":
            [surveys.get("Lassa Fever Survey Template"), "Insect / Animal"],
        "Lyme":
            [surveys.get("Lyme Survey Template"), "Insect / Animal"],
        "Malaria":
            [surveys.get("Malaria Survey Template"), "Insect / Animal"],
        "Plague":
            [surveys.get("Plague Survey Template"), "Insect / Animal"],
        "Rabies":
            [surveys.get("Rabies Survey Template"), "Insect / Animal"],
        "Spotted Fever Rickettsiosis":
            [surveys.get("Spotted Fever Rickettsiosis Template"), "Insect / Animal"],
        "Tularemia":
            [surveys.get("Tularemia Survey Template"), "Insect / Animal"],
        "West Nile Virus":
            [surveys.get("West Nile Virus Survey Template"), "Insect / Animal"],
        "Zika Virus":
            [surveys.get("Zika Virus Survey Template"), "Insect / Animal"],
        "Tetanus":
            [surveys.get("Tetanus Survey Template"), "Other"],
        "Health Profile Template":
            [surveys.get("Health Profile Template"), "N/A"],
    };
    const surveyDates = {
        "Botulism": `${new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() - 15).toLocaleDateString('en-US', { day: '2-digit' })} ${new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()).toLocaleDateString('en-US', { month: 'long' })} ${new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()).toLocaleDateString('en-US', { year: 'numeric' })}`
    }
    const handlePageChange = (sender, options) => {
        if(options.isNextPage) {
            for( let i = options.newCurrentPage.questions.length; i > 0; i--){
                options.newCurrentPage.questions[i-1].clearValue();
            }
        }
        const nextButton = document.getElementById("next-bttn-Disease");
        if (sender.isLastPage && options?.newCurrentPage?.jsonObj?.name != "IndivProfile pt.1.9") {
            nextButton.querySelector("span").innerText = "Submit";
        } else {
            nextButton.querySelector("span").innerText = "Next";
        }
        setProgressBarKey((prevKey) => prevKey + 1);
        
    }

    const updateCSSClass = (sender, options) => {
        if(options.question.jsonObj.name.includes("nwf")){
            options.cssClasses.header += " question-nonWaterFall";
            options.cssClasses.title += " question-title";
            options.cssClasses.content += " custom-content";
        } else {
            options.cssClasses.header += " question-waterFall";
        }
    }

    const customPrevButtonClick = (curDisease) => {
        curDisease.prevPage();
      };
    
      // Define the customNextButtonClick function
      const customNextButtonClick = (curDisease) => {

        if(curDisease.isLastPage){
            console.log("last page");
            if (disease !== "Health Profile Template") {
                document.getElementById("diseaseSurvey").style.filter = "blur(5px)";
            }
            setSubmitClicked(true);
        } else {
            curDisease.nextPage();
        }
      };
    const handleRenderQuestion = (sender, options) => {
        if(options.question.title.includes("dd mm yyyy")){
            options.question.title = options.question.title.replace("dd mm yyyy", surveyDates[disease]);
        }
        if(options.question.choices != null){
            for(var i = 0; i < options.question.choices.length; i++){
                if(options.question.choices[i].text.includes("dd mm yyyy")){
                    options.question.choices[i].text = options.question.choices[i].text.replace("dd mm yyyy", surveyDates[disease]);
                }
            }
        }
        if(options.question.classMetaData.name === "checkbox"){
            options.question.choices.forEach((choice) => {
                if(choice.text === "Not sure" || choice.text === "Yes, but I don't know which" || choice.text === "Yes, other / not sure which treatment" || choice.text === "Not sure if I received treatment"){
                    choice.isExclusive = true;
                }
            });
        }
        if(options.question.title.includes("{i}") || options.question.jsonObj.name === "Q0 nwf"){
            addIIcon(sender, options);
        }
        updateStringComponents(sender, options);
        if(sender.currentPageNo === 0 && !healthProfile){
            document.getElementById("prev-bttn-Disease").style.display = "none";
        } else {
            document.getElementById("prev-bttn-Disease").style.display = "block";
        }
    }

    const setSurveyConfig = () => {
        if ((links[disease][0]) === "") {
            return <h2>Survey Unavailable</h2>
        } else if (healthProfile && backPressed) {
            setCurSurvey(healthProfile)
            setHealthProfile(null)
            setBackPressed(false)
        } else {
            Serializer.addProperty(
                "itemvalue", {
                  name: "isExclusive:boolean",
                  default: false,
                  category: "general"
            });
            const curDisease = new Survey.Model((links[disease][0]).toJSON());
            curDisease.onAfterRenderQuestion.add(handleRenderQuestion);
            curDisease.onComplete.add(saveResults);
            curDisease.applyTheme(SurveyTheme.DefaultLightPanelless);
            curDisease.onCurrentPageChanged.add(handlePageChange);
            curDisease.showNavigationButtons = false;
            curDisease.name = disease;
            curDisease.onUpdateQuestionCssClasses.add(updateCSSClass);
            curDisease.onValueChanged.add((sender, options) => { 
                for( let i = sender.activePage.questions.length; i > 0; i--){
                    if(sender.activePage.questions[i-1].propertyHash.isVisible === false){
                        sender.activePage.questions[i-1].clearValue();
                    }
                }
                const q = options.question;
                if(!q || q.getType() !== "checkbox") return;
                let lastSelectedItem = q.selectedItems.pop();
                if (lastSelectedItem?.value === q.prevValue) return;
                if(lastSelectedItem?.isExclusive){
                    if (lastSelectedItem.value === q.prevExclusiveValue) return;
                    q.prevExclusiveValue = lastSelectedItem.value;
                    q.value = lastSelectedItem.value;
                }
                if(!lastSelectedItem?.isExclusive) {
                    if(!!q.prevExclusiveValue){
                    q.prevExclusiveValue = null;
                    q.value = lastSelectedItem.value;
                    }
                } 
                q.prevValue = options.value.slice(); 
            });

            const surveyChanged = curDisease?.name !== curSurvey?.name;
            
            if (curSurvey == null || surveyChanged) {
                setCurSurvey(curDisease);
            }
        }
    }

    const options = {
        'Airborne / Respiratory':
            ['Chickenpox', 'Common Cold', 'COVID-19', 'Diptheria', 'Influenza', 'Leprosy', 'Measles', 'Meningitis', 'Mumps', 'Pertussis', 'Pneumonia', 'Q Fever', 'Rubella', 'Shingles', 'Tuberculosis'],
        'STI / Blood':
            ['Chancroid', 'Chlamydia', 'Gonorrhea', 'Hepatitis B', 'Hepatitis C', 'HIV', 'HSV1 and HSV2', 'Monkeypox', 'Syphilis'],
        'Foodborne / Waterborne':
            ['Botulism', 'Brucellosis', 'Campylobacter', 'Cholera', 'E.Coli', 'Giardiasis', 'Hepatitis A', 'Legionellosis', 'Leptospirosis', 'Listeriosis', 'Norovirus', 'Salmonellosis', 'Shingellosis', 'Staph'],
        'Insect / Animal':
            ['Babesiosis', 'Chikungunya', 'Dengue', 'Ehrlichia Chaffeensis', 'Lassa Fever', 'Lyme', 'Malaria', 'Plague', 'Rabies', 'Spotted Fever Rickettsiosis', 'Tularemia', 'West Nile Virus', 'Zika Virus'],
        'Other':
            ['Tetanus'],
        'Transmission Pathway':
            ['Title']
    }

    const allOptions = [].concat(
        options['Airborne / Respiratory'],
        options['STI / Blood'],
        options['Foodborne / Waterborne'],
        options['Insect / Animal'],
        options['Other']
    ).sort()


    const [nameSortList, setNameSortList] = useState(allOptions)
    const [transmissionSort, setTransmissionSort] = useState('Transmission Pathway');
    const [transmissionSortSelected, setTransmissionSortSelected] = useState(false)
    const [nameSort, setNameSort] = useState('Name')
    const [nameSortSelected, setNameSortSelected] = useState(false)
    const [nameOrder, setNameOrder] = useState('A-Z')
    
    const reverseList = () => {
        if (nameSortList.length <= 1 || nameSort === 'Name') {
            setNameSort('A-Z')
            setNameSortList(allOptions)
        } else {
            setNameOrder(prevSort => (prevSort === 'A-Z' ? 'Z-A' : 'A-Z'))
            setNameSort(nameOrder)
            setNameSortList(nameSortList.reverse())
        }
    }

    const handleNameSort = (option) => {
        setNameOrder('A-Z')
        setNameSort(option)
        setNameSortList([option])
    }

    const backButton = () => {
        navigate('/learnmore', { state: location.state } )
        setDisease(null)
      }

    const backButtonSurvey = () => {
        navigate('/learnmore/survey', { state: location.state } )
        setDisease(null)
        setSurveyComplete(false)
        setShowGuidelines(false)
    }


    useEffect(() => {
        if (submitClicked && disease ===  "Health Profile Template") {
            setSubmitClicked(false);
            setHealthProfile(curSurvey)
            setDisease(selectedDisease)
            setProgressBarKey(prevKey => prevKey + 1)
        }
    }, [submitClicked])

    useEffect(() => {
        const handleClickOutsideName = (event) => {
            if (nameSortRef.current && !nameSortRef.current.contains(event.target)) {
                setNameSortSelected(false)
            }
        }

        const handleClickOutsideTransmission = (event) => {
            if (transmissionSortRef.current && !transmissionSortRef.current.contains(event.target)) {
                setTransmissionSortSelected(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutsideName)
        document.addEventListener('mousedown', handleClickOutsideTransmission)
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideName)
            document.removeEventListener('mousedown', handleClickOutsideTransmission)
        }
    }, [])

    if (loadingSurveys || disease == null) {
        return (
            <div style={{ display: showVerticals ? 'none' : 'block' }}>
                <h1 className="disease-title">SCREEN FOR INFECTIOUS DISEASES</h1>

                    <DiseaseContainer
                        name="Most Relevant To Me"
                        title="Most Relevant to Me"
                        icon={<CgProfile className="icon" />}
                        options = {[]}
                        setDisease = {setDisease}
                        setSurveyComplete = {setSurveyComplete}
                        sort="Most Relevant To Me"
                        setSelectedDisease = {setSelectedDisease}
                    />

                    <DiseaseContainer
                        name="Locally Prevalent"
                        title="Locally Prevalent"
                        icon={<GrLocation className="icon" />}
                        options = {[]}
                        setDisease = {setDisease}
                        setSurveyComplete = {setSurveyComplete}
                        sort="Locally Prevalent"
                        setSelectedDisease = {setSelectedDisease}
                    />

                    {transmissionSortSelected && (
                        <div ref={transmissionSortRef} className="filterDropdown disease-filter-dropdown" style={{top: '610px', left: '35px'}}>
                            <button className="first-button"> Transmission Pathway</button>
                            <button  className={transmissionSort === 'Airborne / Respiratory' ? 'active' : ''}onClick={() => setTransmissionSort('Airborne / Respiratory')}>Airborne / Respiratory</button>
                            <button  className={transmissionSort === 'STI / Blood' ? 'active' : ''} onClick={() => setTransmissionSort('STI / Blood')}>STI / Blood</button>
                            <button  className={transmissionSort === 'Foodborne / Waterborne' ? 'active' : ''}onClick={() => setTransmissionSort('Foodborne / Waterborne')}>Foodborne / Waterborne</button>
                            <button  className={transmissionSort === 'Insect / Animal' ? 'active' : ''}onClick={() => setTransmissionSort('Insect / Animal')}>Insect / Animal</button>
                            <button  className={`${transmissionSort === 'Other' ? 'active' : ''} last-button`} onClick={() => setTransmissionSort('Other')}>Other</button>
                        </div>
                    )}

                    <DiseaseContainer
                        name="Transmission Pathway"
                        title={transmissionSort}
                        icon={<HiArrowPath className="icon" />}
                        options = {options[transmissionSort]}
                        setDisease = {setDisease}
                        setSurveyComplete = {setSurveyComplete}
                        filterSelected = {transmissionSortSelected}
                        setFilterSelected={setTransmissionSortSelected}
                        sort = {transmissionSort}
                        setSelectedDisease = {setSelectedDisease}
                    />

                    <DiseaseContainer
                        name="Name"
                        title="Name"
                        icon={<MdAbc className="icon" style={{borderRadius: '50%', outline: '1px solid white'}}/>}
                        options = {nameSortList}
                        setDisease = {setDisease}
                        setSurveyComplete = {setSurveyComplete}
                        filterSelected={nameSortSelected}
                        setFilterSelected={setNameSortSelected}
                        sort = {nameSort}
                        setSelectedDisease = {setSelectedDisease}
                    />

                    {nameSortSelected && (
                        <div ref={nameSortRef} className="filterDropdown disease-filter-dropdown" style={{top: '685px', left: '30px'}}>
                            <button className="first-button">Name</button>
                            <button className={`${nameSort === 'A-Z' || nameSort === 'Z-A' ? 'active' : ''}`} onClick={reverseList}>{nameOrder} <FaSort className="icon"/></button>
                            <>
                            {allOptions.map((option) => (
                                <button className={nameSort === option ? 'active' : ''} onClick={() => handleNameSort(option)}>{option}</button>
                            ))}
                            </>
                        </div>
                    )}


                    <button id="prev-bttn-Disease" className="surveyNavBttn" onClick={backButton}><img src={CancelArrow}
                                                                                                                alt="left facing arrow"/>Back
                        </button>
                    <br/>
                    
            </div>
        )
    }

    return (
        <div style={{ display: showVerticals ? 'none' : 'block' }}>
            <div className="survey-container">
                {/* if production, use this src url instead: https://main.d26fb7k5zbcwdt.amplifyapp.com/html/survey/one-minute.html */}

                {surveyComplete && !showGuidelines ? (<Reward/>) : setSurveyConfig()}
                {showGuidelines ? (<ShowGuidelines survey={curSurvey} surveyName={disease} backButton={backButtonSurvey}/>) : (<></>)}
                {curSurvey === null || surveyComplete ? <></> : 
                <>
                    <div className="progressHeader" id="progHeader" style={{paddingTop: '25px'}}>
                            <div className="progressBar-container">
                                <CustomProgressBar
                                    progressBarKey={progressBarKey}
                                    currentPage={0}
                                    pageTitles={""}
                                    isFFT={false}
                                    survey={curSurvey}
                                />
                            </div>
                            <div className="coin-container">
                                <CustomCivCoin currentPage={progressBarKey} isFFT={false} survey={curSurvey} id="diseaseSurvey"/>
                            </div>
                    </div>
                    <div className="surveyTitle" id="diseaseTitle">
                        <span className="diseaseName">{selectedDisease}</span>
                        <span className="diseaseNatureTitle">
                            <img src={loopIcon} id="loopIcon"/>
                            {links[selectedDisease][1]}
                        </span>
                    </div>
                    <Survey.Survey model={curSurvey} id="diseaseSurvey"/>
                    <div className="surveyBttnDiseaseContainer">
                        <button id="prev-bttn-Disease" className="surveyNavBttn" onClick={() => {
                            if (!healthProfile || !curSurvey.isFirstPage) {
                                customPrevButtonClick(curSurvey)
                            } else {
                                setDisease("Health Profile Template")
                                setProgressBarKey(prevKey => prevKey - 1)
                                setBackPressed(true)
                            }
                        }}>    
                        <img src={CancelArrow} alt="left facing arrow"/>Back</button>
                        <button id="next-bttn-Disease" className="surveyNavBttn"
                                onClick={() => {customNextButtonClick(curSurvey)}}><span>Next</span><img
                            src={SubmitArrow} alt="right facing arrow"/></button>
                    </div>
                </>
                }
                {submitClicked && disease !==  "Health Profile Template" ? (
                    
                    <div className="submit-prompt-container diseaseSurveyPrompt" ref={confirmPrompt}
                    style={submitClicked ? {display: "block"} : {display: "none"}}>
                        <p className="submit-prompt-text submitText-Disease">
                        You may screen only once per day - so, make it count! <b>Submit only if all your information is accurate.</b>
                        </p>
                        <div className="submit-button-container">
                            <button className="submit-cancel-button" onClick={(e) => {
                                e.preventDefault();
                                setSubmitClicked(false);
                                confirmPrompt.current.style.display = "none";
                                document.getElementById("diseaseSurvey").style.filter = "blur(0px)";
                            }}>
                                <img src={CancelArrow} alt="cancel" className="cancel-arrow"/>
                                <p className="submit-cancel-text">Cancel</p>
                            </button>
                            <button className="submit-confirm-button" onClick={(e) => {
                                e.preventDefault();
                                confirmPrompt.current.style.display = "none";
                                document.getElementById("progHeader").style.display = "none";
                                document.getElementsByClassName("surveyTitle")[0].style.display = "none";
                                document.getElementById("diseaseTitle").style.display = "none";
                                curSurvey.completeLastPage();
                                if (healthProfile) {
                                    healthProfile.completeLastPage();
                                }
                                setSubmitClicked(false);
                                document.getElementById("diseaseSurvey").style.filter = "blur(0px)";
                                
                            }}>
                                <p className="submit-confirm-text">Submit</p>
                                <img src={SubmitArrow} alt="submit" className="submit-arrow"/>
                            </button>
                        </div>
                    </div>
                ) : (<> </>)}
                <br/>
            </div>
   
            <br/>
        </div>
    );
};