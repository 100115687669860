import { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import {} from "@material-ui/icons"
import {IoClose} from "react-icons/io5";
import backgroundF from "../../../images/header-footer-bg.jpg"
import glossaryData from "./glossary-data";



// WORK IN PROGRESS. NOT FINAL. REALLY NOT GOOD RIGHT NOW.
const Box = styled.div.attrs(props => ({
    className: props.className
}))`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  width: 23.5rem;
  height: auto;
  background: #f8f9fa;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 35%);
  overflow: hidden;
`;

const Header = styled.div.attrs(props => ({
    className: props.className
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 10px 10px 10px;
  height: 95px;
  // background: url(${backgroundF});
  background: rgb(36, 73, 167);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

`;


const HeaderTag = styled.div.attrs( props =>({
    className: props.className
}))`
    height: 30px;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    gap: 50px;

    button {
        background: none;
        border: none;
        width: 40px;
        padding: 0;
        cursor: pointer;
        outline: inherit;
        color: #8A8A8A;
        font-family: Poppins;
        font-weight: 400;
        font-size: 17px;
    }


    button:focus {
        font-weight: 600;
        color: rgb(36, 73, 167);
    }
    
    
    

`;

const CloseBox = styled.div.attrs(props => ({
    className: props.className
}))`
  z-index: 1;
  width: 100%;
  display: flex;
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-left: auto;
    svg {
      margin-right: -5px;
      height: 24px;
      width: 24px;
      color: white;
    }
  }
`;

const Header_info = styled.div.attrs(props => ({
    className: props.className
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 5px;
  
  span {
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
    color: white;
  }
`;

const Main_box = styled.div.attrs(props => ({
    className: props.className
}))`
  
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 20px;
 

  ul {
    display: grid ;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)) ;
    column-gap: 10px;
  }

  li {
    list-style: none;
    button {
      background: none;
      color: inherit;
      border: none;
      //padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      font-size: 12px;
      border-radius: 6px;
      padding: 3px;
      font-family: Poppins;
      font-weight: 450;
    }
    
    button:hover{
      background: #e5e5e5;
    }
    
    button:focus{
      color: rgb(36, 73, 167);
    }
    
  }
`;


const Main_name = styled.div.attrs(props => ({
    className: props.className
}))`
  width: 100%;
    position: relative;
  display: flex;
  align-content: center;
    flex-flow: row;
    align-items: center;
    gap: 5px;
  height: 62px;
  //padding-left: 20px;
    padding: 0 15px;
    span {
        width: 100%;
        height: 100%;
        color: #2449A7;
        padding-left: 13px;
        margin-left: -10px;
        font-family: Poppins;
        font-size: 21px;
        font-weight: 600;
        display: flex;
        align-items: center;
        border: 1.5px solid #C1C3C4;
        border-left: none;
        border-right: none;
    }
    div{
      width: 0;
      height: 0;
        margin-left: -20px;
      border-top: 29px solid transparent;
      border-left: 25px solid #2449A7;
      border-bottom: 29px solid transparent;
    }
`;

const Footer = styled.div.attrs(props => ({
    className: props.className
}))`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 10px 20px 20px 20px;
  hr{
    width: 200px;
    height: .6px;
  }
`;

const Footer_info = styled.div.attrs(props => ({
    className: props.className
}))`
  display: flex;
  flex-direction: column;
  gap: 5px;
  
  span{
    font-size: 12px;
    font-weight: 600;
  }
  
  span:first-child{
    color: black;
  }
  
  span:last-child{
    font-weight: 400 !important;
    font-style: ${(props) => props.fontStyle};
  }
  

`;

const Popup = styled.div.attrs(props => ({
    className: props.className
}))`
  display: ${(props) => props.display};
  justify-content: center;
  margin-top: 35%;
  z-index: 2;
  position: absolute;
  width: 100%;
  //height: 68%;
  //background: rgba(255, 255, 255, 0.6);

`;

const data = glossaryData[2].items
export const BodyS_def = ({name, render}) => {

    const [dataDefs, setDataDefs] = useState(data[0])
    const [valueAssign, setValueAssign] = useState( data[0].listAtoF)
    const [defName, setDefname] = useState(data[0].listAtoF[0])
    const [footerDefinition, setFooterDefinition] = useState(data[0].text.Achy)


    const [close, setClose] = useState('none')


    const changeDisplay = (e) => {
        setClose('none')
        const InfoButton_svg = document.querySelector('#flow-survey-json-info_bs_def svg')
        const Frame = document.querySelector('.sensations-custom-frame')
        if (InfoButton_svg) {
            Frame.style.minHeight= "auto"
            Frame.style.backgroundImage = "url('../../../images/body-sensation-bg2.svg') !important"
            InfoButton_svg.style.fill = 'grey'
        }
    }

    const changeHeaderDef = (e) => {
        const text = e.target.innerHTML
        if (text === "A-F") {
            setDataDefs(data[0])
            setValueAssign(data[0].listAtoF)
            setFooterDefinition(data[0].text.Achy)
        }
        if (text === "G-L") {
            setDataDefs(data[1])
            setValueAssign(data[1].listGtoL)
            setFooterDefinition(data[1].text.Gentle)

        }
        if (text === "M-R") {
            setDataDefs(data[2])
            setValueAssign(data[2].listMtoR)
            setFooterDefinition(data[2].text.Nauseous)

        }
        if (text === "S-Z") {
            setDataDefs(data[3])
            setValueAssign(data[3].listStoZ)
            setFooterDefinition(data[3].text.Sensitive)
        }
    }

    const changeDef = (e) => {
        const text = e.target.innerHTML
        const value = dataDefs.text[text]
        setFooterDefinition(value)
        setDefname(text)
    }

    const listItems = valueAssign.map((element) =>
        <li>
            <button onClick={changeDef}>{element}</button>
        </li>
    );

    const button = () => {
        const InfoButton = document.getElementById('flow-survey-json-info_bs_def')
        const InfoButton_svg = document.querySelector('#flow-survey-json-info_bs_def svg')
        const Frame = document.querySelector('.sensations-custom-frame')
        if (InfoButton) {
            InfoButton_svg.style.fill = 'grey'
            InfoButton.onclick = function () {
                setClose('flex')
                InfoButton_svg.style.fill = 'orange'
                Frame.style.minHeight = "860px"
            }
        }

    }

    useEffect(() => {
        setClose('none')
        button()
    }, [render]);


    return (
        <Popup display={close}>
            <Box>
                <Header>
                    <CloseBox>
                        <button onClick={changeDisplay}>
                            <IoClose/>
                        </button>
                    </CloseBox>
                    <Header_info>
                        <span> Definitions: Body Sensations</span>
                    </Header_info>
                </Header>

                <HeaderTag>
                    <button onClick={changeHeaderDef}>A-F</button>
                    <button onClick={changeHeaderDef}>G-L</button>
                    <button onClick={changeHeaderDef}>M-R</button>
                    <button onClick={changeHeaderDef}>S-Z</button>
                </HeaderTag>

                <Main_box>

                    <ul>
                        {listItems}
                    </ul>

                </Main_box>

                <Main_name>
                    <div/>
                    <span>{defName}</span>
                </Main_name>

                <Footer>
                    <Footer_info font-style={'normal'}>
                        <span>Definition:</span>
                        <span>{footerDefinition.definition}</span>
                    </Footer_info>
                    <hr/>
                    <Footer_info fontStyle={'italic'}>
                        <span>Synonym(s):</span>
                        <span>{footerDefinition.synonym2}</span>
                    </Footer_info>
                    <hr/>
                    <Footer_info fontStyle={'italic'}>
                        <span>Example Sentence:</span>
                        <span>{footerDefinition.sentence}</span>
                    </Footer_info>
                </Footer>


            </Box>
        </Popup>
    )

}
