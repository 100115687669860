export const Despair = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 2363 2363\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-470.603 -363.254) translate(-445.106 -265.352)\">\n" +
    "        <ellipse\n" +
    "          cx=\"3430.13\"\n" +
    "          cy=\"3550.95\"\n" +
    "          fill=\"#C25022\"\n" +
    "          rx=\"1444.45\"\n" +
    "          ry=\"1384.27\"\n" +
    "          transform=\"matrix(.81768 0 0 .85323 -707.939 -1220.08)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(2110.71 1821.05) scale(24.1703) translate(-34.5 -34.5)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H69V69H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.498 68c18.5 0 33.498-14.998 33.498-33.5C67.996 15.999 52.998 1 34.498 1S1 15.999 1 34.5C1 53.002 15.998 68 34.498 68z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M26.745 8.346s2.273 8.315-4.917 12.885c0 0-6.041 3.793-12.896-1.627 0 0 12.789 2.632 17.813-11.258z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M23.407 40.553c4.07 0 7.369-4.542 7.369-10.146 0-5.603-3.299-10.145-7.369-10.145s-7.37 4.542-7.37 10.145c0 5.604 3.3 10.146 7.37 10.146z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M24.747 37.156c2.035 0 3.685-2.272 3.685-5.073 0-2.802-1.65-5.073-3.685-5.073s-3.684 2.271-3.684 5.073c0 2.801 1.649 5.073 3.684 5.073z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M41.677 8.346s-2.273 8.315 4.917 12.885c0 0 6.042 3.793 12.897-1.627 0 0-12.789 2.632-17.814-11.258z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M45.014 40.553c4.07 0 7.37-4.542 7.37-10.146 0-5.603-3.3-10.145-7.37-10.145s-7.369 4.542-7.369 10.145c0 5.604 3.299 10.146 7.369 10.146z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M43.673 37.156c2.035 0 3.685-2.272 3.685-5.073 0-2.802-1.65-5.073-3.685-5.073s-3.685 2.271-3.685 5.073c0 2.801 1.65 5.073 3.685 5.073z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M27.762 55.916a6.734 6.734 0 016.735-6.736 6.735 6.735 0 016.736 6.736\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M27.762 55.916a6.734 6.734 0 016.735-6.736 6.735 6.735 0 016.736 6.736\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#00ADEB\"\n" +
    "              d=\"M45.779 40.555s-6.939 11.521 0 11.521 0-11.521 0-11.521z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#00ADEB\"\n" +
    "              d=\"M51.941 34.799s-6.938 11.521 0 11.521c6.939 0 0-11.521 0-11.521z\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"