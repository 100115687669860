import React, { useEffect, useState } from "react";
import { PublicHealth } from "../../components/PublicHealth/index";
import { useSelector, useDispatch } from "react-redux";
// import { selectTokenPayload } from "../../store/emotionalHealthSlice";
// import "./App.css";
import "../../App.css";
import civLogo from "../../images/civ-logo.svg";
import Amplify, { Auth, API, graphqlOperation, I18n, FederatedButtons } from "aws-amplify";
import awsconfig from '../../aws-exports';
import '@aws-amplify/ui-react/styles.css';
import {
  withAuthenticator,
  Button,
  Heading,
  Image,
  View,
  Card,
  useTheme,
  Flex,
  Grid,
  Authenticator,
  useAuthenticator,
} from "@aws-amplify/ui-react";



// import { I18n } from "aws-amplify-react";
// import { Text } from "react-native";
// import {Header} from './Header';
import { listSongs } from "../../graphql/queries";
import { updateSong } from "../../graphql/mutations";
import CivLogo from "./../../images/civ-logo.svg";
import CivAuthHeader from "./../../images/civ-auth-header.png";
import CustomSignIn from "./CustomSignIn";
import CustomSignUp from "./CustomSignUp";
import ConfirmationPage from "./SignUpConfirmationPage";
import './RegistrationLoginFlow.css';
// import { Paper, IconButton } from "@material-ui/core";
// import PlayArrowIcon from "@material-ui/icons/PlayArrow";
// import FavoriteIcon from "@material-ui/icons/Favorite";

Amplify.configure(awsconfig);


//Define the new vocabulary for the 'Sign In' key

//CHANGES TEXT INSIDE AUTHENTICATOR COMPONENT. i.e. to change Submit button we do 'submit': 'new text' where 'text in auth': 'our new text'
// "Sign In": "Members", // Update the tab header
// "Sign in": "Continue", // Update the button label
const newVocabulary = {
  "Sign In": "Members", // Update the tab header
  "Sign in": "Sign In", // Update the button label
  "Sign in to your account": "Welcome Back!",
  "Create Account": "Sign Up",
  "Create account": "Continue",
  "Enter your email": "Enter your email",
  "Send code": "continue",
  Submit: "continue",
  Username: "username",
  Email: "email address",
  Password: "password",
  "Forgot your password?": "forgot password?",
};

// Set the new vocabulary for the 'en' language
I18n.putVocabulariesForLanguage("en", newVocabulary);

export const GoogleSignIn = () => {
  return (
    <button onClick={() => Auth.federatedSignIn({ provider: "Google" })}>
      {/* <img src={btn} alt="Google Sign In button" className="googleSignIn" style={{ height: "45px", width: "190px" }} /> */}
      SIGN IN WITH GOOGLE
    </button>
  );
};

// ADD LOGINSTYLING HERE

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        {/* <Image alt="Amplify logo" src="https://docs.amplify.aws/assets/logo-dark.svg" /> */}
        <p>
          <span style={{ fontSize: 24, fontWeight: "semi-bold" }}>WELCOME TO</span>
          <br />
          {/* <CivAuthHeader /> */}
          <img src={CivAuthHeader} />
        </p>
        <Image alt="Amplify logo" src={`${CivLogo}`} />
      </View>
    );
  },
  
  // Footer() {
  //   const { tokens } = useTheme();

  //   return (
  //     <View textAlign="center" padding={tokens.space.large}>
  //       <div color={tokens.colors.neutral[80]}>&copy; All Rights Reserved</div>
  //     </View>
  //   );
  // },

  // SignIn: {
  //   // Header() {
  //   //   const { tokens } = useTheme();

  //   //   return (
  //   //     <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
  //   //       Sign in to your account
  //   //     </Heading>
  //   //   );
  //   // },
  //   Footer() {
  //     const { toResetPassword } = useAuthenticator();

  //     return (
  //       <View textAlign="center">
  //         <Button fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
  //           Forgot Password?
  //         </Button>
  //       </View>
  //     );
  //   },
  // },

  // SignUp: {
  //   Header() {
  //     const { tokens } = useTheme();

  //     return (
  //       <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
  //         Create a new account
  //       </Heading>
  //     );
  //   },

  // Footer() {
  //   const { toSignIn } = useAuthenticator();

  //   return (
  //     <View textAlign="center">
  //       <Button fontWeight="normal" onClick={toSignIn} size="small" variation="link">
  //         Back to Sign In
  //       </Button>
  //     </View>
  //   );
  // },
  // },

  //EMAIL CONFIRMATION SCREEN AFTER INPUTTING Email, Password, Confirm password inside 'Create Account' tab
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          level={3}
          style={{ justifyContent: "center", display: "flex", fontSize: "28px", fontWeight: "bold", color: "#00286E" }}
        >
          Check your email
        </Heading>
      );
    },
    Footer() {
      return <div>Footer Information</div>;
    },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <div>
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        </div>
      );
    },
    // Footer() {
    //   return <div>Footer Information</div>;
    // },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Enter Information:
        </Heading>
      );
    },
    // Footer() {
    //   return <div>Footer Information</div>;
    // },
  },
  ResetPassword: {
    //THIS IS FOR THE RESET PASSWORD SCREEN
    Header() {
      const { tokens } = useTheme();
      return (
        // <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={2}>
        <div>
          <Heading
            padding={` 0 0 ${tokens.space.xl}`}
            fontSize={"32px"}
            level={2}
            fontWeight={"600"}
            style={{ textAlign: "center", color: "#00286E" }}
          >
            Reset Password
          </Heading>
          <Heading
            padding={` 0 0 ${tokens.space.xl}`}
            level={5}
            style={{ textAlign: "start", color: "#00286E", fontWeight: "normal" }}
          >
            Please enter your email to receive a verification code.
          </Heading>
        </div>
      );
    },
    // Footer() {
    //   return <div>Footer Information</div>;
    // },
  },
  //RESET PASSWORD SCREEN HEADER
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <div>
          <Heading
            level={3}
            style={{ justifyContent: "center", display: "flex", fontSize: "28px", fontWeight: "bold", color: "#00286E" }}
          >
            Create new password
          </Heading>
          <Heading
            padding={`${tokens.space.large} 0 ${tokens.space.large} 0`}
            fontSize={"16px"}
            fontWeight={"500"}
            color={"#00286E"}
            level={5}
          >
            {/* <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={5}> */}
            Your password must be different from previous used passwords.
          </Heading>
        </div>
      );
    },
    // Footer() {
    //   return <div>Footer Information</div>;
    // },
  },
};
//END OF COMPONENTS OBJECT

const formFields = {
  //EMAIL INPUT INSIDE MEMBERS TAB
  signIn: {
    username: {
      placeholder: "email address",
    },
    // GoogleSignIn: {
    //   placeholder: "Sign in with Google",
    // },
  },
  //ORDER OF THE PASSWORD INPUT & CONFIRM PASSWORD "CREATE ACCOUNT"
  signUp: {
    email: {
      label: "Email",
      placeholder: "email address",
      order: 1,
    },
    password: {
      label: "Password",
      placeholder: "password",
      // hint: "Must be at least 8 characters",
      isRequired: true,
      order: 2,
    },
    confirm_password: {
      label: "Confirm Password",
      placeholder: "confirm password",
      isRequired: true,
      order: 3,
    },
    'custom:Country': {
      label: 'Primary Residence',
      placeholder: "Country abbreviation (US, CA, etc.)",
      isRequired: true,
      order: 4,
    },
    'custom:Zipcode': {
      placeholder: "Zipcode",
      isRequired: true,
      order: 5,
    },
    
  },
  forceNewPassword: {
    password: {
      placeholder: "Enter your Password:",
    },
  },
  //'RESET PASSWORD' SCREEN
  resetPassword: {
    username: {
      placeholder: "email address",
    },
  },
  // THESE ARE THE TWO ITEMS INSIDE 'CREATE NEW PASSWORD' SCREEN
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "",
      label: "Confirmation Code",
      name: "confirmation_code",
      isRequired: true,
      order: 1,
    },
    password: {
      label: "Password",
      placeholder: "",
      isRequired: true,
      hint: "Must be at least 8 characters",
      order: 2,
    },
    confirm_password: {
      label: "Confirm Password",
      placeholder: "",
      isRequired: true,
      order: 3,
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: "test issuer",
      totpUsername: "amplify_qr_test_user",
    },
    confirmation_code: {
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
};

function Home(props) {
  // function Home({ signOut }) {
  
  // const token = useSelector(userValue)
  // const setUserToken = useSelector(setUser)
  

  // console.log('userValue', userValue)
  // const user = useSelector(selectUserValue)

  // function Home({ user }) {
  // const [songs, setSongs] = useState([]);
  const [tokenPayload, setTokenPayload] = useState(null);
  // const [emotionalData, setEmotionalData] = useState({});
  // const extractMostRecent = (data) => {
  //   return data.payload.Items[0].survey;
  // }
  // console.log("HOME PROPS - signOut: ", signOut);
  // console.log("HOME PROPS - user: ", user);

  useEffect(() => {
    // fetchSongs();
    getUser();
  }, []);

  // console.log('user from redux', user)

  const getUser = async () => {
    setTimeout(async () => {
      try {
        const currSession = await Auth.currentSession();
        // console.log('email:', currSession.email);
        const tokenPayload = currSession.idToken.payload;
        setTokenPayload(tokenPayload);

        console.log("USER LOGGED IN, callback: set isLoginScreen to false");
        props.isLoginScreen();
        // console.log("User:", JSON.stringify(tokenPayload));
        // console.log("User:", JSON.stringify(tokenPayload));
        // logUser(tokenPayload);
      } catch (error) {
        console.log("error signing out: ", error);
        // event.preventDefault();
      }
    });
  };

  // const logUser = async (tokenPayload) => {
  //   setTimeout(async () => {
  //     var isoDateString = new Date().toISOString();
  //     console.log(isoDateString);
  //     try {
  //       if (tokenPayload) {
  //         const payload = {
  //           body: {
  //             id: Date.now() + "-" + tokenPayload.sub,
  //             userId: tokenPayload.sub,
  //             activity: "emotionalHealthSurvey",
  //             email: tokenPayload.email,
  //             // here1: "TEST 1a",
  //             // here2: "TEST 2b",
  //             survey: { q1: "newtest", q2: "q2results", q3: "q3results", q4: "q4results", q5: "q5results" },
  //             dateAdded: isoDateString,
  //           },
  //         };

  //         API.post("civAPI", "/emotional/store", payload).then((response) => {
  //           if (!response.error) {
  //             console.log(response);
  //           } else {
  //             console.log(response);
  //           }
  //         });

  //         console.log("Logged User:", JSON.stringify(payload));
  //       }
  //     } catch (error) {
  //       console.log("Error currentSession: ", error);
  //     }
  //   });
  // };

  // const signOut = async (event) => {
  //   event.preventDefault();
  //   setTimeout(async () => {
  //     try {
  //       await Auth.signOut();
  //       window.location.href = "/";
  //     } catch (error) {
  //       console.log("error signing out: ", error);
  //       event.preventDefault();
  //     }
  //   });
  // };

  //DO NOT REMOVE - RECALCULATES SCALE
  // function Home(props) {
  
  useEffect(() => {
    // console.log("Home");
    props.reRender();
  }, [props]);
  
  return (
    <div style={{ backgroundColor: "#00286E", width: "100vw", minHeight: "100vh", display: "flex" }}>
      <Authenticator hideDefault={true} components={{
        Header() {
          return (
            <div className="loginHeader">
              <div className="civilience">
                <h2 className="sloganHeader">CIVILIENCE</h2>
                <p className="sloganSubHeader">TECHNOLOGY • VITALITY • HUMANITY </p>
              </div>
              <img className="civLogo" src={civLogo} />
            </div>
          );
        },
        SignUp: {
          FormFields() {
            return (
              <CustomSignUp />
            )
          }
        },
        ConfirmSignUp: {
          FormFields() {
            return (
              <ConfirmationPage />
            )
          }
        },
        SignIn: { //the custom sign in does not render for some reason
          FormFields(props) {
            console.log("Custom Sign-In Form is rendering");  // Check if this is logged
            return <div>Custom Sign-In Form</div> 
          },
        },       
      }}>
        {({ signOut, user }) => (
          <View className="App">
            <Card>
              <Button
                onClick={() => {
                  signOut();
                  props.isLoginScreen();
                }}
              >
                Sign Out
              </Button>
              <div>Signed in as: {tokenPayload && tokenPayload.email}</div>
              <div>
                <PublicHealth
                  tokenPayload={tokenPayload}
                  savedFFTData={props.savedFFTData}
                  setSavedFFTData={props.setSavedFFTData}
                  setNewEmotionalData={props.setNewEmotionalData}
                  // emotionalData={emotionalData}
                />
              </div>
            </Card>
          </View>
        )}
      </Authenticator>
    </div>
    
  );
}

/* CUSTOM CIV */
// export default Home;

/* DEFAULT AMPLIFY */
export default Home;
