import MainIcon from '../../images/civilience_triangle.png'
export const Slogan = () => {
    return (
        <nav className="slogan" translate="no">
            <a href='/'><img src={MainIcon} alt="Civilience logo" className="civilience-logo" /></a>
            <div className="civilience">
                <h2>CIVILIENCE</h2>
                <p>TECHNOLOGY • VITALITY • HUMANITY </p>
            </div>
        </nav>
    )
}