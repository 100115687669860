import { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import {} from "@material-ui/icons"
import {IoClose} from "react-icons/io5";
import backgroundF from "../../../images/header-footer-bg.jpg"
import glossaryData from "./glossary-data";



// WORK IN PROGRESS. NOT FINAL. REALLY NOT GOOD RIGHT NOW.
const Box = styled.div.attrs(props => ({
    className: props.className
}))`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  width: 23.5rem;
  height: auto;
  background: #f8f9fa;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 35%);
  overflow: hidden;
`;

const Header = styled.div.attrs(props => ({
    className: props.className
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 10px 10px 10px;
  height: 95px;
  // background: url(${backgroundF});
  background: rgb(36, 73, 167);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

`;

const CloseBox = styled.div.attrs(props => ({
    className: props.className
}))`
  z-index: 1;
  width: 100%;
  display: flex;
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-left: auto;
    svg {
      margin-right: -5px;
      height: 24px;
      width: 24px;
      color: white;
    }
  }
`;

const Header_info = styled.div.attrs(props => ({
    className: props.className
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 5px;
  
  span {
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
    color: white;
  }
`;

const Main_box = styled.div.attrs(props => ({
    className: props.className
}))`
  
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 20px;
 

  ul {
    display: grid ;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)) ;
    column-gap: 10px;
  }

  li {
    list-style: none;
    button {
      background: none;
      color: inherit;
      border: none;
      //padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      font-size: 12px;
      border-radius: 6px;
      padding: 3px;
      font-family: Poppins;
      font-weight: 450;
    }
    
    button:hover{
      background: #e5e5e5;
    }
    
    button:focus{
      color: rgb(36, 73, 167);
    }
    
  }
`;


const Main_name = styled.div.attrs(props => ({
    className: props.className
}))`
  width: 100%;
  background: ${(props) => props.color};
  display: flex;
  align-content: center;
  height: 62px;
  padding-left: 20px;
  span {
    color: ${(props) => props.text_color};
    font-family: Poppins;
    font-size: 21px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
`;

const Footer = styled.div.attrs(props => ({
    className: props.className
}))`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 10px 20px 20px 20px;
  hr{
    width: 200px;
    height: .6px;
  }
`;

const Footer_info = styled.div.attrs(props => ({
    className: props.className
}))`
  display: flex;
  flex-direction: column;
  gap: 5px;
  
  span{
    font-size: 12px;
    font-weight: 600;
  }
  
  span:first-child{
    color: black;
  }
  
  span:last-child{
    font-weight: 400 !important;
    font-style: ${(props) => props.fontStyle};
  }
  

`;

const Popup = styled.div.attrs(props => ({
    className: props.className
}))`
  display: ${(props) => props.display};
  justify-content: center;
  margin-top: 35%;
  z-index: 2;
  position: absolute;
  width: 100%;
  //height: 68%;
  //background: rgba(255, 255, 255, 0.6);

`;

const result_name = {
    item1: "Aliveness/Joy",
    item2: "Grateful",
    item3: "Courageous/Powerful",
    item4: "Connected/Loving",
    item5: "Accepting/Open",
    item6: "Hopeful",
    item7: "Curious",
    item8: "Tender",
    item9: "Disconnected/Numb",
    item10: "Stressed/Tense",
    item11: "Unsettled/Doubt",
    item12: "Fragile",
    item13: "Despair/Sad",
    item14: "Embarassed/Shame",
    item15: "Angry/Annoyed",
    item16: "Guilt",
    item17: "Fear",
    item18: "Powerless",
}





export const AssociatedF_def = ({name, render}) => {

    const name2 = 'item1'

    const data = glossaryData[1].items.filter((el) => el.title === result_name["item1"])[0]
    const [valueAssign, setValueAssign] = useState(data)
    const [defName, setDefname] = useState(data.list[0])
    const [footerDefinition, setFooterDefinition] = useState(data.text[data.list[0]])
    // const [reRender, setReRender] = useState(false)


    const [close, setClose] = useState('none')


    const changeDisplay = (e) => {
        setClose('none')
        const InfoButton_svg = document.querySelector('#flow-survey-json-info_af_def svg')
        if (InfoButton_svg) {
            InfoButton_svg.style.fill = 'grey'
        }
    }

    const changeDef = (e) => {
        const text = e.target.innerHTML
        // const value = valueAssign.list.filter((e)=> e === text)[0]
        const value = valueAssign.text[text]
        setFooterDefinition(value)
        setDefname(text)
    }

    const listItems = valueAssign.list.map((element) =>
        <li>
            <button onClick={changeDef}>{element}</button>
        </li>
    );

    const button = () => {
        const InfoButton = document.getElementById('flow-survey-json-info_af_def')
        const InfoButton_svg = document.querySelector('#flow-survey-json-info_af_def svg')
        if (InfoButton) {
            InfoButton_svg.style.fill = 'grey'
            InfoButton.onclick = function () {
                setClose('flex')
                InfoButton_svg.style.fill = 'orange'
            }
        }

    }

    useEffect(() => {
        setClose('none')
        if (render && render.question1){
            const data = glossaryData[1].items.filter((el) => el.title === result_name[render.question1])[0]
            setValueAssign(data)
            setDefname(data.list[0])
            setFooterDefinition(data.text[data.list[0]])
        }
        button()
    }, [render]);


    return (
        <Popup display={close}>
            <Box>
                <Header>
                    <CloseBox>
                        <button onClick={changeDisplay}>
                            <IoClose/>
                        </button>
                    </CloseBox>
                    <Header_info>
                        <span> Definitions: Associated Feelings</span>
                    </Header_info>
                </Header>

                <Main_box>

                    <ul>
                        {listItems}
                    </ul>

                </Main_box>

                <Main_name
                    color={valueAssign.icon.backgroundColor}
                    text_color={render && render.question1 && render.question1 === 'item1' ? "black" : "white"}
                >
                    <span>{defName}</span>
                </Main_name>

                <Footer>
                    <Footer_info font-style={'normal'}>
                        <span>Definition:</span>
                        <span>{footerDefinition.definition}</span>
                    </Footer_info>
                    <hr/>
                    <Footer_info fontStyle={'italic'}>
                        <span>Synonym(s):</span>
                        <span>{footerDefinition.synonym2}</span>
                    </Footer_info>
                    <hr/>
                    <Footer_info fontStyle={'italic'}>
                        <span>Example Sentence:</span>
                        <span>{footerDefinition.sentence}</span>
                    </Footer_info>
                </Footer>


            </Box>
        </Popup>
    )

}
