export const Guilt = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 2363 2363\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-437.434 -3442.62)\">\n" +
    "        <ellipse\n" +
    "          cx=\"3430.13\"\n" +
    "          cy=\"3550.95\"\n" +
    "          fill=\"#897476\"\n" +
    "          rx=\"1444.45\"\n" +
    "          ry=\"1384.27\"\n" +
    "          transform=\"matrix(.81768 0 0 .85323 -1186.21 1593.93)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(1605.91 4610.86) scale(25.353) translate(-34.5 -36)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H69V72H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.5 70.247c18.502 0 33.5-15 33.5-33.503C68 18.242 53.002 3.242 34.5 3.242c-18.501 0-33.5 15-33.5 33.502 0 18.503 14.999 33.503 33.5 33.503z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M27.298 16.213s-.909 5.3-6.365 6.377c0 0-4.558.886-7.118-3.745 0 0 6.783 4.308 13.483-2.632zM13.742 31.481s1.95-7.036 9.452-7.742c0 0 6.281-.574 9.105 6.019 0 0-8.567-6.737-18.557 1.723zM41.762 16.213s.909 5.3 6.365 6.377c0 0 4.558.886 7.119-3.745 0 0-6.784 4.308-13.484-2.632zM55.246 31.481s-1.95-7.036-9.452-7.742c0 0-6.281-.574-9.104 6.019 0 0 8.566-6.737 18.556 1.723z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M23.015 46.769c4.07 0 7.37-4.542 7.37-10.146 0-5.604-3.3-10.146-7.37-10.146-4.071 0-7.37 4.542-7.37 10.146 0 5.604 3.299 10.146 7.37 10.146z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M24.248 44.352c2.035 0 3.685-2.272 3.685-5.074s-1.65-5.073-3.685-5.073c-2.036 0-3.685 2.271-3.685 5.073 0 2.802 1.649 5.074 3.685 5.074z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M45.974 46.769c4.07 0 7.37-4.542 7.37-10.146 0-5.604-3.3-10.146-7.37-10.146-4.071 0-7.371 4.542-7.371 10.146 0 5.604 3.3 10.146 7.371 10.146z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M47.193 44.352c2.035 0 3.685-2.272 3.685-5.074s-1.65-5.073-3.685-5.073-3.685 2.271-3.685 5.073c0 2.802 1.65 5.074 3.685 5.074z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#F01F29\"\n" +
    "              d=\"M59.434 51.198c3.37 0 6.102-1.982 6.102-4.427 0-2.445-2.732-4.427-6.102-4.427s-6.102 1.982-6.102 4.427c0 2.445 2.732 4.427 6.102 4.427zM9.555 51.198c3.37 0 6.102-1.982 6.102-4.427 0-2.445-2.732-4.427-6.102-4.427s-6.102 1.982-6.102 4.427c0 2.445 2.732 4.427 6.102 4.427z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M24.246 58.702s9.703-8.053 21.727-3.063l-21.727 3.063z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M24.246 58.702s9.703-8.053 21.727-3.063\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#00ADEB\"\n" +
    "              d=\"M63.478 10.027s-9.105 15.148 0 15.148 0-15.148 0-15.148zM50.879 0s-9.105 15.148 0 15.148c9.104 0 0-15.148 0-15.148z\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"