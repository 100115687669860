export const Grateful = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 2363 2363\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-1838.02 -9005.37)\">\n" +
    "        <ellipse\n" +
    "          cx=\"3430.13\"\n" +
    "          cy=\"3550.95\"\n" +
    "          fill=\"#F6C5F6\"\n" +
    "          rx=\"1444.45\"\n" +
    "          ry=\"1384.27\"\n" +
    "          transform=\"matrix(.81768 0 0 .85323 214.372 7156.69)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(3014.59 10194.2) scale(23.9593) translate(-34.5 -35)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H69V70H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.5 68.004c18.502 0 33.5-14.999 33.5-33.502S53.002 1 34.5 1C15.999 1 1 15.999 1 34.502s14.999 33.502 33.5 33.502z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M51.597 22.276s-.276-5.373-5.552-7.096c0 0-4.427-1.424-7.525 2.86 0 0 7.25-3.458 13.065 4.236h.012z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M45.446 41.719c4.071 0 7.37-4.543 7.37-10.147 0-5.603-3.299-10.146-7.37-10.146-4.07 0-7.37 4.543-7.37 10.146 0 5.604 3.3 10.147 7.37 10.147z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M44.681 37.158c2.035 0 3.685-2.271 3.685-5.073 0-2.802-1.65-5.073-3.685-5.073s-3.685 2.271-3.685 5.073c0 2.802 1.65 5.073 3.685 5.073z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M17.246 22.476s.072-5.384 5.276-7.298c0 0 4.367-1.592 7.622 2.572 0 0-7.382-3.183-12.898 4.726z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M30.646 31.572c0 5.6-3.302 10.147-7.37 10.147s-7.37-4.547-7.37-10.147c0-5.599 3.302-10.146 7.37-10.146s7.37 4.547 7.37 10.146z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M24.042 37.158c2.036 0 3.685-2.271 3.685-5.073 0-2.802-1.649-5.073-3.685-5.073-2.035 0-3.685 2.271-3.685 5.073 0 2.802 1.65 5.073 3.685 5.073z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#F01F29\"\n" +
    "              stroke=\"#F01F29\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M27.477 56.34c2.072 1.371 4.544 2.203 7.236 2.203 3.019 0 5.76-1.036 7.954-2.709-2.741-1.697-7.701-1.705-7.701-1.705-4.772-.016-7.244 1.999-7.497 2.211h.008z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M21.897 47.766c.546 3.573 2.643 6.633 5.58 8.574 2.072 1.371 4.544 2.203 7.236 2.203 3.019 0 5.76-1.036 7.955-2.708 2.553-1.95 4.356-4.781 4.862-8.053-5.531 2.333-15.011 4.626-25.641-.016h.008z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#F97D85\"\n" +
    "              d=\"M59.369 49.263a7.43 7.43 0 007.43-7.43 7.43 7.43 0 10-7.43 7.43zM10.1 49.263a7.43 7.43 0 10-.001-14.861 7.43 7.43 0 00.001 14.861z\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"