import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ChevronDown from "../../images/chevron_down.png";
import ChevronUp from "../../images/chevron_up.png";
import arrow from "../../images/arrow-right.svg";
import { FaChevronRight } from "react-icons/fa6";
import DeepRedVelvetBG from "../../images/deep_red_velvet_bg.jpg";
import { Link, Outlet } from "react-router-dom";



export const WidgetContainer = ({ name, comingSoon, children, onclick }) => {
  // const [expanded, setExpanded] = useState(name === 'Community Health' ? true : false)
  const location = useLocation();
  const stateInfo = location.state;

  const [expanded, setExpanded] = useState(false);
  const soon = useRef();

  return (
    <section className={`widget-container ${expanded && "expanded"} ${comingSoon && "coming-soon"}`} onClick={onclick}>
      <div className="top-section">
        <span className="left-sliver" >
          <div className="slivershape" style={name === "Health" ? {background: "#DDE1E8"}: null}></div>
        </span>
        <h2>
        {name.split(" ").map((word, index) => (
            <span key={index}>
              {word} &nbsp;
            </span>
          ))}
          {comingSoon && (
            <span className="coming-soon" style={{display: "block"}}>
              Coming Soon!
            </span>
          )}
        </h2>
          <button
              // onClick={(e) => {
              //   e.preventDefault();
              //   if (!comingSoon) {
              //     //if this isn't a coming soon widget, expand it
              //     setExpanded(!expanded);
              //   } else {
              //     //display coming soon
              //     soon.current.style.display = "block";
              //   }
              // }}
              // onMouseOver={(e) => {
              //   e.preventDefault();
              //   if (comingSoon) {
              //     //display coming soon
              //     soon.current.style.display = "block";
              //   }
              // }}
              className="chevron-button" style={name === "Health" ? {filter: "none"}: null}>
              <img className="chevron" src={arrow} alt="navigation arrow"/>
          </button>
      </div>
      {/*  <div style={expanded ? { display: "block" } : { display: "none" }} className="content">*/}
      {/*  {children}*/}
      {/*</div>*/}
    </section>
  );
};
