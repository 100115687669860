import "./App.css";
// import "./app.scss";
import "@aws-amplify/ui-react/styles.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Home from "./pages/home/index.js";
import LearnMore from "./pages/LearnMore";
import { Provider } from "react-redux";
import store from "./store/store";
import EmotionalHealth from "./components/PublicHealth/EmotionalHealth";
import LearnDetail from "./components/LearnDetail";
import ProcessScreen from "./components/PublicHealth/EmotionalHealth/ProcessScreen";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { DiseaseSurvey } from "./components/LearnDetail/PageContent/DiseaseSurvey";
import Survey from "./pages/Survey";
import axios from 'axios';
import {ProfilePage} from "../src/components/Profile/profilePage";
import {ProfileSettingsPage} from "../src/components/Profile/profileSettingsPage";
import {SafetySubdomain} from "../src/components/Subdomains/safetySubdomain/safetySubdomain";
import {HealthSubdomain} from "../src/components/Subdomains/healthSubdomain/healthSubdomain";
import {WealthSubdomain} from "../src/components/Subdomains/wealthSubdomain/wealthSubdomain";
import {EnvironmentSubdomain} from "../src/components/Subdomains/environmentSubdomain/environmentSubdomain";
import {GovernanceSubdomain} from "../src/components/Subdomains/governanceSubdomain/governanceSubdomain";
import {SocialRelationsSubdomain} from "../src/components/Subdomains/socialRelationsSubdomain/socialRelationsSubdomain";
import {Reward} from "./components/animations/Reward/Reward"
import ConfirmationPage from "../src/pages/home/SignUpConfirmationPage";
import { Model } from "survey-core";
import fftJSON from "./components/PublicHealth/FeelingsFlowTrackerForms/FeelingsFlowJSON.js";

Amplify.configure(awsExports);

function App() {
  //Possible upgrade to saving fft data is to create a Context for the savedFFTData state to be used in /emotionhealth instead of lifting up the state to top level
  const surveyTemplate = new Model(fftJSON);
  
  const [savedFFTData,setSavedFFTData]= useState({    
    fftSurvey: surveyTemplate,
    memoEventsAttached: false,
    surveyInstance: null,
    surveyData: null,
    currentPageNo: 0,
    introPage: true,
    reRender: false,
    completeClicked: false,
    pageNo: surveyTemplate.currentPageNo,
    latestAnswer: null
  });
  const [newEmotionalData, setNewEmotionalData] = useState(
    {
      "q1": "Tender",
      "q2": [
        "calm"
      ],
      "q3": "Test Default,",
      "location": {
        "zipcode": "20500",
        "latitude": "38.897676",
        "longitude": "-77.036529"
      },
      "q4": [
        "Contracted",
        "Clammy",
        "Airy",
        "Contained",
        "Drained"
      ],
      "q5": "Lorem ipsum dolor sit amet,"
    }
  )
  const AppRef = useRef();
  // once its set to false, we will allow scaling - this is to prevent render issue with scaling, we do not want scaling under 1 if its login screen
  const [isLoginScreen, setIsLoginScreen] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      controlMainWindowSize();
    });
  });

  useEffect(() => {
    console.log("On Login Screen? ", isLoginScreen);
  }, [isLoginScreen]);

  const isLoginScreenHandler = () => {
    // changing handler to false
    console.log("User Logged In: ", isLoginScreen);
    setIsLoginScreen((isLoginScreen) => !isLoginScreen);
  };

  const controlMainWindowSize = () => {
    //FOR SCREENS 500px and below
    if (width < 500) {
      let zoomScale = AppRef.current.clientWidth / 500.0;
      if (isLoginScreen) {
        // Setting transform to none on login screen to prevent white screen render issue
        // console.log("#1 login screen - mobile");
        AppRef.current.style.transform = `none`;
        AppRef.current.style.height = `${AppRef.current.clientHeight * zoomScale}px`;
      } else {
        // NOT ON LOGIN SCREEN - WE ARE IN THE VERTICAL
        // console.log("#2 NOT login screen - mobile");
        AppRef.current.style.transform = `scale(${zoomScale})`;
        AppRef.current.style.height = `${AppRef.current.clientHeight * zoomScale}px`;
      }
    } else {
      //FOR SCREENS 500px and above
      if (isLoginScreen) {
        // Setting transform to none on login screen to prevent white screen render issue
        // console.log("#3 login screen - desktop");
        // AppRef.current.style.transform = "scale(1)";
        AppRef.current.style.transform = "none";
        AppRef.current.style.height = "100%";
      } else {
        // NOT ON LOGIN SCREEN
        // console.log("#4 Not login - 500+ px desktop");
        AppRef.current.style.transform = "scale(1)";
        AppRef.current.style.height = "100%";
      }
    }
  };

  // const controlMainWindowSize = () => {
  //   if (width < 500) {
  //     let zoomScale = AppRef.current.clientWidth / 500.0;
  //     AppRef.current.style.transform = `scale(${zoomScale})`;
  //     AppRef.current.style.height = `${AppRef.current.clientHeight * zoomScale}px`;
  //   } else {
  //     // THE TRANSFORM is causing our screen to render white
  //     AppRef.current.style.transform = "scale(1)";
  //     AppRef.current.style.height = "100%";
  //   }
  // };


  return (
      <Provider store={store}>
        {/* <div className="App" id="App" ref={AppRef} style={{ minHeight: "100vh", minWidth: "100vw" }}> */}
        <div className="App" id="App" ref={AppRef}>
          <Router>
            <Routes>
              <Route path="/" element={<Home setNewEmotionalData={setNewEmotionalData} reRender={controlMainWindowSize} isLoginScreen={isLoginScreenHandler} savedFFTData={savedFFTData} setSavedFFTData={setSavedFFTData}/>}/>
              <Route path="/success" element={<Reward reRender={controlMainWindowSize}/>}/>
              <Route path="/confirm" element={<ConfirmationPage />} />
              <Route exact path="/learnmore/*" element={<LearnDetail newEmotionalData={newEmotionalData} setNewEmotionalData={setNewEmotionalData} savedFFTData={savedFFTData} setSavedFFTData={setSavedFFTData} reRender={controlMainWindowSize}/>}/>
              <Route path="/profile" element={<ProfilePage reRender={controlMainWindowSize}/>}/>
              <Route path="/profile/settings" element={<ProfileSettingsPage reRender={controlMainWindowSize}/>}/>
              <Route path="/safetysubdomain" element={<SafetySubdomain reRender={controlMainWindowSize}/>}/>
              <Route path="/healthsubdomain" element={<HealthSubdomain reRender={controlMainWindowSize}/>}/>
              <Route path="/wealthsubdomain" element={<WealthSubdomain reRender={controlMainWindowSize}/>}/>
              <Route path="/environmentsubdomain" element={<EnvironmentSubdomain reRender={controlMainWindowSize}/>}/>
              <Route path="/governancesubdomain" element={<GovernanceSubdomain reRender={controlMainWindowSize}/>}/>
              <Route path="/socialrelationssubdomain"
                     element={<SocialRelationsSubdomain reRender={controlMainWindowSize}/>}/>
              {/*<Route path="/learnmore/survey" element={<Survey reRender={controlMainWindowSize} />} />*/}
              <Route exact path="/emotionalhealth/*" element={<LearnDetail newEmotionalData={newEmotionalData} setNewEmotionalData={setNewEmotionalData} savedFFTData={savedFFTData} setSavedFFTData={setSavedFFTData} reRender={controlMainWindowSize}/>}/>
            </Routes>
          </Router>

          {/* <Router>
          <Routes>
            <Route path="/" element={<Home reRender={controlMainWindowSize} />} >
              <Route path=":emotionalhealth" element={<ProcessScreen reRender={controlMainWindowSize} />}>
                 <Route path=":feelingsflow" element={<FeelingsFlowQuestion reRender={controlMainWindowSize} />}>
                  <Route path=":processscreens" element={<ProcessScreen reRender={controlMainWindowSize} />}/>
                </Route>
              </Route>
            </Route>
            <Route path="/learnmore" element={<LearnDetail reRender={controlMainWindowSize}/>}>
              
            </Route>
            
            <Route
              path="/learnmore/survey"
                element={<Survey
                reRender={controlMainWindowSize}
              />} />
            
            <Route path="/" element={<Home reRender={controlMainWindowSize} />} />
            <Route path="/learnmore" element={<LearnDetail reRender={controlMainWindowSize} />} />
            <Route path="/learnmore/survey" element={<Survey reRender={controlMainWindowSize} />} />
            <Route path="/emotionalhealth" element={<LearnDetail reRender={controlMainWindowSize} />} />
          </Routes>
        </Router> */}
        </div>
        {/* <p>API message: {myMessage}</p> */}
        {/* </ApiProvider> */}
        {/* <p>API message: {myMessage}</p> */}
        <ToastContainer autoClose={2000}/>
      </Provider>
  );
}

export default App;
