import { useContext, useState, useEffect } from "react";
import "./FeelingsFlowSurvey.css";

export const FftIntroPage = ()=>{

    return (
        <div className={"fft-container custom-all-box"}>
            <div className={"fft-intro"}>
                <h1> Feelings Flow Tracker = Awareness</h1>
                <div className={"fft-intro-box-p"}>
                    <p>
                        Emotional intelligence starts with identifying, by labeling, the various parts of your emotional
                        process. You have just completed this step.
                    </p>
                    <p>
                        The next step is a review of the emotional process flow: how your feelings arise from thoughts
                        and in turn give rise to body sensations and to actions.Together, both steps support your
                        emotional regulation and well-being.
                    </p>
                </div>
            </div>
        </div>
    )
}