export const Hopeful = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 1615 1623\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-344.883 -2045.75) translate(-4064.71 -1154.64) scale(.68478)\">\n" +
    "        <ellipse\n" +
    "          cx=\"5162.14\"\n" +
    "          cy=\"2648.17\"\n" +
    "          fill=\"#77B1E4\"\n" +
    "          rx=\"2173.37\"\n" +
    "          ry=\"2032.94\"\n" +
    "          transform=\"matrix(.54253 0 0 .5826 4817.92 4315.15)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(7614.2 5860.44) scale(23.3653) translate(-34.5 -35)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H69V70H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.5 68.004c18.502 0 33.5-14.999 33.5-33.502S53.002 1 34.5 1C15.999 1 1 15.999 1 34.502s14.999 33.502 33.5 33.502z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M52.112 22.273s-.275-5.372-5.551-7.095c0 0-4.427-1.424-7.526 2.86 0 0 7.251-3.458 13.065 4.235h.012z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M45.962 41.717c4.07 0 7.37-4.543 7.37-10.147 0-5.603-3.3-10.146-7.37-10.146s-7.37 4.543-7.37 10.146c0 5.604 3.3 10.147 7.37 10.147z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M45.197 37.158c2.035 0 3.685-2.271 3.685-5.073 0-2.802-1.65-5.073-3.685-5.073-2.036 0-3.685 2.271-3.685 5.073 0 2.802 1.649 5.073 3.685 5.073z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M17.762 22.478s.072-5.384 5.276-7.298c0 0 4.367-1.592 7.621 2.572 0 0-7.382-3.183-12.897 4.726z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M31.162 31.57c0 5.6-3.302 10.147-7.37 10.147s-7.37-4.547-7.37-10.147c0-5.599 3.302-10.146 7.37-10.146s7.37 4.547 7.37 10.146z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M24.558 37.158c2.035 0 3.685-2.271 3.685-5.073 0-2.802-1.65-5.073-3.685-5.073s-3.685 2.271-3.685 5.073c0 2.802 1.65 5.073 3.685 5.073z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#FF7EF4\"\n" +
    "              d=\"M12.461 46.145c3.37 0 6.102-1.982 6.102-4.427 0-2.445-2.732-4.427-6.102-4.427s-6.102 1.982-6.102 4.427c0 2.445 2.732 4.427 6.102 4.427zM56.705 46.145c3.37 0 6.102-1.982 6.102-4.427 0-2.445-2.732-4.427-6.102-4.427s-6.102 1.982-6.102 4.427c0 2.445 2.732 4.427 6.102 4.427z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M39.442 55.873c1.83-.79 7.765-3.769 7.848-9.297 0 0-8.183 6.605-20.327 6.988 3.458 4.319 9.978 3.015 11.904 2.512l.587-.203h-.012z\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"