import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import "./index.css";
import InfoIcon from "../../images/info.png";
import { WidgetContainer } from "./WidgetContainer";
import DetailFooter from "../Shared/DetailFooter";
import { Ad } from "../Shared/Ad";
import { Info } from "../Shared/Info";
import { selectCommunityData, selectStatus } from "../../store/communityHealthSlice";
import { getGradientColorFromValue } from "../LearnDetail/DetailContent/getColorFromValue.js";
import { Slogan } from "../Shared/Slogan";
import FeelingsFlowQuestion from "./FeelingsFlowQuestion";
import { API, JS } from "aws-amplify";
import DetailHeader from "../LearnDetail/DetailHeader";
import {IoHome} from "react-icons/io5";
import { TiHome } from "react-icons/ti";
import {isEqual} from "lodash"

// import BodySensationForm from "./BodySensationForm";
// import { useContext } from "react";
// import { QuestionContext } from "../../context/question.context";
export function PublicHealth(props) {
  const location = useLocation();
  useEffect(()=>{
    console.log(JSON.stringify("Going back to publichealth location.state -"+JSON.stringify(location.state)));
    if(location.state?.dropdownOpenState?.expand) {
      setDropdownOpen(dropdownOpenState);
    }
  }, [location.state]);
  // Will pass payload to emotionalHealth widget
  const [dropdownOpen, setDropdownOpen] = useState({
    footer: false,
    expand: false,
  });
  const { tokenPayload, savedFFTData, setSavedFFTData, setNewEmotionalData} = props;
const {dropdownOpenState = {footer:false,expand:false}} = location.state || {};
  // console.log("User signed in - tokenPayload: ", tokenPayload);
  const options = {
    community: ["Location"],
    emotional: ["Today","This Week", "This Month", "Select Date/Range"],
  }
  
  const comingSoonOptions = {
    community: ["Disease", "Transmission Type", "Demographics", "Advanced"],
    emotional: ["Thoughts", "Feelings","Body Sensations", "Actions"],
  };
  const data = useSelector(selectCommunityData);
  const linkToStateMap = () => {
    window.open(data.url_state);
  };
  const linkToNationalMap = () => {
    window.open(data.url_USA);
  };
  const countyData = data.K_historical;
  const countyLabel = data ? data.county_name.split(",")[0] : "County";
  const stateData = data.K_state;
  const stateLabel = data ? data.state : "State";
  const countryData = data.k_US;
  const countryLabel = "USA";
  const status = useSelector(selectStatus);
  const emotional = {
      options:options.emotional,
      comingSoonOptions: comingSoonOptions.emotional,
      headerTitle: "Emotional Health",
      minuteCardOneHeader: "Feelings Flow Tracker",
      minuteCardOneText: "Identify, understand, and process your emotions",
      minuteCardTwoHeader: "Boundary Builder",
      minuteCardTwoText: "Understand your personal boundaries",
      sayComponentText:
        "In addition to Reading, Writing and Arithmetic, should schools teach Relationships (how we relate to ourselves, others and situations)?",
      saySectionLabel: "VIEW ACTIVITY AS",
      tokenPayload,
    }

    const communityHealthInfo = {
      headerTitle: "Community Health",
      options: options,
      comingSoonOptions: comingSoonOptions,
      countyData,
      countyLabel,
      stateData,
      stateLabel,
      countryData,
      countryLabel,
      // disease: disease,
      // setDisease:setDisease,
      // page: page,
      // setPage:setPage,
      // showVerticals: showVerticals,
      // setShowVerticals: setShowVerticals,
      minuteCardOneHeader: "My Guidelines",
      minuteCardOneText: "Know what guidelines apply to you",
      minuteCardTwoText: "Know guidelines and your personal risk of catching & spreading",
      minuteCardTwoHeader: "My Risk",
      sayComponentText: " Do you think the Covid-19 pandemic is over? Why or why not?",
      saySectionLabel: "VIEW RISK BY",
      tokenPayload
    }

    const [comingSoonList, setComingSoonList] = useState({
        Safety: false,
        Wealth: false,
        Environment: false,
        Governance:false,
        SocialRelations: false
    })

    const ComingSoonClick = (name)=> {
        const NewList = {
        Safety: false,
        Wealth: false,
        Environment: false,
        Governance:false,
        SocialRelations: false
        }
        setComingSoonList({...NewList, [`${name}`]: true})
    }


    useEffect(() => {    
      //Get today's date in yyyy-mm-dd format, append this as a query param in axios URL 
      let today = new Date().toISOString().slice(0, 10);
      console.log(today);

      if(tokenPayload) {
        //Will dictate whether user goes to the fft survey or the summary page depending on if they filled one out today already.
        const fetchData = async () => {
          try {
            const response = await API.get("civAPI", `/emotional/retrieve/${tokenPayload.sub}?dateAdded=${today}`)
            if (!response.error && isEqual(response.payload?.[0].dateAdded.split('T')[0], today)) {
              console.log("Response: ", response);
              console.log("Recent after split dateAdded",response.payload[0].dateAdded.split('T')[0]);
              await setSavedFFTData((oldData) => {
                return {
                  ...oldData,
                  latestAnswer: response
                }
              });
              if(response.payload) {
                setNewEmotionalData(response.payload[0].survey);
              }
            } else {
              console.error("Error of response: ", response);
              
            }
          } catch (err) {
            console.error("Error fetching data: ", err);
          }
          
        };
        fetchData(); 
      }
    }, [tokenPayload])

  return (
    <div className="publicHealth">
      <DetailHeader parentPath={location.pathname} setDropdownOpen={setDropdownOpen} dropdownOpen={dropdownOpen} tokenPayload={tokenPayload} communityHealthInfo={{headerTitle: "Community Health",
      options: options,
      comingSoonOptions: comingSoonOptions,
      countyData,
      countyLabel,
      stateData,
      stateLabel,
      countryData,
      countryLabel,
      // disease: disease,
      // setDisease:setDisease,
      // page: page,
      // setPage:setPage,
      // showVerticals: showVerticals,
      // setShowVerticals: setShowVerticals,
      minuteCardOneHeader: "My Guidelines",
      minuteCardOneText: "Know what guidelines apply to you",
      minuteCardTwoText: "Know guidelines and your personal risk of catching & spreading",
      minuteCardTwoHeader: "My Risk",
      sayComponentText: " Do you think the Covid-19 pandemic is over? Why or why not?",
      saySectionLabel: "VIEW RISK BY",
      tokenPayload}} variant={false}/>
      
      <div id="top-content">
        <div className="title">
            <TiHome size={25}/>
        </div>
        <div className="titleDropShadow">
        </div>
      </div>
      <div className="body">
        <Link
             onClick={()=>{
                 ComingSoonClick("Safety")
             }} state={communityHealthInfo} to={"/"} className="learnmore"
        >
          <WidgetContainer name="Safety" comingSoon={comingSoonList.Safety} link="/safetysubdomain" tokenPayload={tokenPayload}>
          </WidgetContainer>
        </Link>
        <Link state={communityHealthInfo} to={"/healthsubdomain"} className="learnmore" tokenPayload={tokenPayload}>
          <WidgetContainer name="Health" >
          <div className="mainContent">
              <div className="covid-info">
                <div className="panel-left">
                  <p className="simpleinfo"> Composite of Covid-19 case prevalence, severity and health resources utilization</p>
                  <div className="info-days-hours">
                    <div className="text-center border-right">
                      <p className={`font-lg ${status}`}>{data ? Math.round(data.K_historical) : 0}</p>
                      <p className="font-sm">7 DAYS</p>
                    </div>
                    <div className="text-center border-right">
                      <p className={`font-lg ${status}`}>{data ? Math.round(data.K_state) : 0}</p>
                      <p className="font-sm">24 HRS</p>
                    </div>
                  </div>
                </div>
                <div className="panel-right">
                  <p
                    className={`circle-bg font-xl ${status}`}
                    style={{ background: status === "fulfilled" ? getGradientColorFromValue(data.K) : "hsl(0 0% 90%)" }}
                  >
                    {data ? Math.round(data.K) : 0}
                  </p>
                  <p className=""></p>
                </div>
              </div>
              {/* Color Slider */}
              <input type="range" className="color-slider" min="0" max="100" value={data ? data.K : 0} readOnly />
              <div className="text-center">
                Local Area Infection Risk
                <div className="Mytooltip">
                  <img src={InfoIcon} className="InfoIcon"></img>
                  <span className="Mytooltiptext wide">
                    <p className="mb-10">
                      <strong>Risk score:</strong> 0 (low) -{">"} 100+ (high)
                    </p>
                    <p>
                      <strong>Prevalence:</strong> number of new cases.
                    </p>
                    <p>
                      <strong>Severity:</strong> number of new morbidity cases, hospitalizations, deaths.
                    </p>
                    <p className="mb-10">
                      <strong>Health resources utilization:</strong> percentage use of infrastructure, equipment, supplies or medicines.
                    </p>
                    <p>
                      <strong>Data source:</strong> CDC
                    </p>
                  </span>
                </div>
              </div>
            </div>
            </WidgetContainer>
        </Link>

        <Link state={communityHealthInfo} onClick={()=>{ComingSoonClick("Wealth")}} to={"/"} className="learnmore">
          <WidgetContainer name="Wealth" comingSoon={comingSoonList.Wealth} link="/wealthsubdomain" tokenPayload={tokenPayload}>
            
          </WidgetContainer>
        </Link>
        <Link state={communityHealthInfo} onClick={()=>{ComingSoonClick("Environment")}} to={"/"} className="learnmore">
        <WidgetContainer name="Environment" comingSoon={comingSoonList.Environment} link="/environmentsubdomain" tokenPayload={tokenPayload}>
          
        </WidgetContainer>
        </Link>
        <Link state={communityHealthInfo} onClick={()=>{ComingSoonClick("Governance")}} to={"/"} className="learnmore">
        <WidgetContainer name="Governance" comingSoon={comingSoonList.Governance} link="/governancesubdomain" tokenPayload={tokenPayload}>
          
        </WidgetContainer>
        </Link>
        <Link state={communityHealthInfo} onClick={()=>{ComingSoonClick("SocialRelations")}} to={"/"} className="learnmore">
        <WidgetContainer name="Social Relations" comingSoon={comingSoonList.SocialRelations} link="/socialrelationssubdomain" className="social-widget">
          
        </WidgetContainer>
        </Link>
      </div>
      <DetailFooter setDropdownOpen={setDropdownOpen} dropdownOpen={dropdownOpen} />
    </div>
  );
}