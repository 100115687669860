import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import cities from '../components/Shared/StatesCitiesZipcode/USCities.json'

const initialState = {
    status: "idle2",
    zipCode: "",
    city: "",
    state: "",
    data: ""
}

const fetchAQI = async(param) =>{
    if (!param){
        return ''
    }
    try {
        const response = await fetch(`https://api.openweathermap.org/data/2.5/air_pollution?lat=${param.lat}&lon=${param.lon}&appid=${process.env.REACT_APP_OPENWEATHER_AQI_API_KEY}`);
        const parseRes = await response.json()
        return parseRes
    } catch (err) {
        console.error(err.message);
    }
    return false;


}

const fetchWeather = async (param) => {
    try {
        const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${param.lat}&lon=${param.lon}&appid=${process.env.REACT_APP_OPENWEATHER_AQI_API_KEY}`)
        const weatherData = await response.json();
        return weatherData
    } catch (error) {
        console.error("Error fetching weather data:", error);
    }
};


//wrapper for navigator.geolocation to convert it to promise based
function getPosition(options) {
    return new Promise((resolve, reject) => 
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
    );
}

export const fetchByZipCode = createAsyncThunk(
    'communityHealth/fetchByZipCode',
    async (zipCode) => {
        const payload = {
            body: { ZIP: zipCode },
        };
        const response = await API.post('civAPI', '/county', payload) 
        const getLatLong = cities.filter(el => el.zip_code.toString() === zipCode)
        let aqiResponse = ''
        if (getLatLong && getLatLong[0]){
            aqiResponse = await fetchAQI({lat: getLatLong[0].latitude, lon: getLatLong[0].longitude})
        }
        if (response.error) {
            return ""
        }
        const newPayload = {...response.payload, ...{aqi: aqiResponse ? aqiResponse.list[0].main.aqi: ''}}
        return newPayload
    }
)


export const getLocationDetails = async (location) => {
    const coord = {
        lat: location.coord.lat,
        lon: location.coord.lon
    };
    const aqi = (await fetchAQI({lat: coord.lat, lon: coord.lon})).list[0].main.aqi
    const weather_result = await fetchWeather({lat: coord.lat, lon: coord.lon})
    const weather = {
        f: Math.round((weather_result.main.temp - 273.15) * 1.8 + 32),
        c: Math.round((weather_result.main.temp - 273.15)),
        icon: `https://openweathermap.org/img/wn/${weather_result.weather[0].icon}@2x.png`
    }
    return {aqi: aqi, weather: weather}
}


export const fetchZipCode = createAsyncThunk(
    'communityHealth/fetchZipCode',
    async () => {
        const position = await getPosition()
        const latLong = position.coords.latitude + ',' + position.coords.longitude
        const payload = {
            body: { latLong: latLong },
        }
        const response = await API.post('civAPI', '/zipcode', payload)
        if(response.error){
            return ""
        } else {
            return response.payload
        }

    }
)

export const communityHealthSlice = createSlice({
    name: 'communityHealth',
    initialState,
    reducers: {
        setZipCode: (state, action) => {
            state.zipCode = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchByZipCode.pending, (state) => {
            state.status = "pending"
        })
        builder.addCase(fetchByZipCode.fulfilled, (state, action) => {
            state.data = action.payload
            state.status = "fulfilled"
        })
        builder.addCase(fetchByZipCode.rejected, (state, action) => {
            state.data = ""
            state.status = "rejected"
        })
        builder.addCase(fetchZipCode.fulfilled, (state, action) => {
            state.zipCode = action.payload
        })
        builder.addCase(fetchZipCode.rejected, (state, action) => {
            state.zipCode = 0
        })
    }
})

export default communityHealthSlice.reducer
export const {setZipCode} = communityHealthSlice.actions

export const selectZipCode = state => state.communityHealth.zipCode
export const selectCommunityData = state => state.communityHealth.data
export const selectStatus = state => state.communityHealth.status