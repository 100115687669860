import React, { useState, useEffect, useRef, Fragment } from "react";
import { useSelector } from "react-redux";
import downArrow from "../../../images/chevron_down.png";
import upArrow from "../../../images/chevron_up.png";
import Calendar_Icon from "../../../images/Vector.png";
import {
  selectCommunityData,
  selectZipCode,
} from "../../../store/communityHealthSlice";
import nextIcon from "../../../images/seeNext.svg"
import backIcon from "../../../images/seeBack.svg"
import { LineChart } from "../LineChart";
import { SeeCircle } from "./SeeCircle";
import { DetailSubHeader } from "./DetailSubHeader";
import { API } from "aws-amplify";
import { Dropdown } from "./Dropdown";
import { DetailSubsection } from "./DetailSubsection";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { dynamoDBFetch } from "../../../store/emotionalHealthSlice";
import { PieChart } from "react-minimal-pie-chart";
import { CarouselItem } from "../../PublicHealth/EmotionalHealth/Process/Carousel";
import Carousel from "../../PublicHealth/EmotionalHealth/Process/Carousel";
import { Slider } from "../../PublicHealth/EmotionalHealth/scroll-menu";
import uuid from "react-uuid";
import {Link, useLocation, useNavigate} from "react-router-dom";
import GLOSSARY_DATA from "../../PublicHealth/FeelingsFlowTrackerForms/glossary-data";
import styled from "styled-components";
import "./CalendarStyles.css";
import dreamIcon from "../../../images/dream-dopage.svg";
import gratitudeIcon from "../../../images/gratitude-dopage.svg";
import fftIcon from "../../../images/fft-tree-dopage.svg";
import infectiousIcon from "../../../images/infectious-dopage.svg";
import boundaryIcon from "../../../images/boundary-dopage.svg";
import ttpIcon from "../../../images/ttp-dopage.svg";
import CancelArrow from "../../../images/orange-left-arrow.svg";
import filterIcon from "../../../images/filterIcon.svg";
import calenderIcon from "../../../images/calenderIcon.svg";
import checkIcon from "../../../images/check_marks.png"
import removeIcon from "../../../images/RemoveImage.png"
import scanIcon from "../../../images/ScanImage.png"
import exclamationIcon from "../../../images/ExclamationImage.png"
import {isEqual} from 'lodash'



const SeeBox = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const HeaderBox = styled.div.attrs(props => ({
    className: props.className
}))`
    padding: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: center;
    justify-content: center;
`;

const HeaderBoxSelect = styled.div.attrs(props => ({
    className: props.className
}))`
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        img{
            height: 18px;
            width: 18px;
        }
    }
    
    button:nth-child(1){
    }
    button:nth-child(2){
    }
`;

const HeaderBoxOption = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 55px;
    
    .SeeOptionRow{
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
        
    }
    
    .SeeOptionRow div{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        width: 90px;
        background-color: ${(props) => props.color};
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        img{
            min-height: 140%;
            min-width: 140%;
        }
        
        
    }


    .SeeOptionRow:nth-child(1) {
        div {
            filter: blur(2px);
            height: 60px;
            width: 60px;
        }
    }

    .SeeOptionRow:nth-child(2) {
        position: relative;
        
        div {
            z-index: 2;
        }
        span{
            top: 105px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 160px;
            z-index: 2;
            position: absolute;
            font-weight: 600;
            color: black;
        }
    }

    .SeeOptionRow:nth-child(3) {
        div {
            filter: blur(2px);
            height: 60px;
            width: 60px;
        }
    }

`;


const InfoBox = styled.div.attrs(props => ({
    className: props.className
}))`
    margin-top: 35px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

`;

const InfoHeaderName = styled.div.attrs(props => ({
    className: props.className
}))`
    width: 100%;
    display: flex;
    flex-direction: row;
    background: ${(props) => props.color};
    height: 60px;
    padding: 5px 4px;
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;

    button {
        //background: linear-gradient(180deg, #FCE6DD 41.92%, rgba(217, 217, 217, 0) 97.59%); //Onclick Change
        background: none;
        border: none;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0 0 8px;
        //font-weight: 600; //Onclick Change
        //color: white; //Onclick Change
        //border-radius: 20px 0px 0px 0px; //Onclick Change
        font-weight: 500;
        color: white;
        justify-content: center;
        width: 100%;
    }

`;

const InfoHeaderTag = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    gap: 10px;
    button{
        background: #FCE6DD;
        border: none;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        gap: 3px;
        padding: 3px;
        font-weight: 600;
        border-radius: 10px;
    }


`;

const InfoMain = styled.div.attrs(props => ({
    className: props.className
}))`
    padding: 1rem 1.5rem;
    border: 1px solid #808080;


`;

// Feelings Page/////////////////////////////

const FeelingsPage = styled.div.attrs(props => ({
    className: props.className
}))`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    

    


`;

const FeelingsHeader = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    
    



`;

const SectionButton = styled.button`
    background: ${props => (props.active ? '#2449A7' : '#E0E0E0')};
    color: ${props => (props.active ? 'white' : 'inherit')};
    border: none;
    padding: 0;
    font: inherit;
    font-weight: 500;
    font-size: 10px;
    cursor: pointer;
    outline: inherit;
    border-radius: 4px;
    height: 35px;
    width: 100px;
`;

const FeelingsHeaderMain = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #2449A7;

    ul {
        list-style-type: none;
    }

    h1 {
        font-size: 14.5px;

    }
`;

const FeelingsPrimary = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: column;
    gap: 25px;


`;

const FeelingsPrimaryVisual = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .FeelingsPColor {
        height: 140px;
        width: 140px;
        border: 36px solid #D94941;
        background: white;
        border-radius: 50%;

    }
    ul li{
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 20px ;
        font-weight: 500;
        div{
            width: 100%;
            height: 1.5px;
            border-radius: 0.5px;
            background: #00000029;
        }
    }
`;

const FeelingsAssociated = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: column;
    gap: 15px;
    ul{
        margin-left: 22px;
    }
    ul li{
        display: flex;
        flex-direction: column;
        color: black;
        gap: 4px;
        font-size: 11px;
        font-weight: 500;
        div{
            height: 4px;
            width: 30%;
            border-radius: 5px;
            background: #D9D9D9;
            div{
                height: 100%;
                width: 70%;
                background: #2449A787;
            }
        }
    }
    
`;

// TTP Page////////////////////////////////////

const TTPPage = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: column;
    gap: 30px;
    //align-items: center;
    justify-content: center;

        h1{
            font-size: 34px;
            margin: 0 auto;
            font-weight: 500;
        }

    div{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        row-gap: 15px;
        column-gap: 15px;
        img{
            height: 160px;
            width: 160px;
        }
    }
    
`;



function SeeComponent({
  countyData,
  countyLabel,
  stateData,
  stateLabel,
  countryData,
  countryLabel,
  saySectionLabel,
  headerTitle,
  options,
  comingSoonOptions,
  tokenPayload,
  page,
  value,
  emotionalData,
  savedFFTData
}) {
    const location = useLocation()
    const navigate = useNavigate()
    const [noSubmission, setNoSubmission] = useState(!(savedFFTData.latestAnswer && isEqual(emotionalData,savedFFTData.latestAnswer.payload?.[0].survey)));

    const [summarySection, setSummarySection] = useState({
        thoughts: true,
        feelings: false,
        bodySensations: false,
        actions: false
    })

    const match = !(savedFFTData.latestAnswer && isEqual(emotionalData,savedFFTData.latestAnswer.payload?.[0].survey))

    const [headerInfoSelect, setHeaderInfoSelect] = useState(true)

    const [seeEHealthOption, setSeeEHealthOption] = useState([
         {name: "Boundary Builder", src:boundaryIcon, imgCss:{minHeight:"100%", minWidth: "100%"}},
        {name: "Feelings Flow", src:fftIcon},
        {name:"Dream Journal", src:dreamIcon},
        {name:"Gratitude Journal", src:gratitudeIcon, imgCss:{minHeight:"80%", minWidth: "80%"}}])
    const [seeIDiseaseOption, setSeeIDiseaseOption] = useState([
        {name: "Screen for Infectious Disease", src: infectiousIcon, imgCss:{minHeight:"1rem", minWidth: "1rem"}},
        {name: "TTP", src: ttpIcon, imgCss:{minHeight:"130%", minWidth: "130%"}},
        {name: "Coming Soon", src: null, imgCss:{minHeight:"0%", minWidth: "0%"}}])

        const communityData = {
            "q1": "Tender",
            "q2": [
              "calm"
            ],
            "q3": "\n1. Career and personal development (job duties, learning new skills, learning back-end technologies) \n2. Family and personal relationships (excitement for niece's achievement, going on a hike, going camping with family, thinking about boyfriend) \n3. Health and well-being (feeling exhausted and burned out from being sick, thinking of changing lifestyle to a healthier way)",
            "location": {
              "zipcode": "20500",
              "latitude": "38.897676",
              "longitude": "-77.036529"
            },
            "q4": [
              "Contracted",
              "Clammy",
              "Airy",
              "Contained",
              "Drained"
            ],
            "q5": "\n1. Personal activities and self-care (making milk tea, going on a hike, taking medicine and resting, setting up plans for a camping trip) \n2. Skill development and learning (working extra hours, looking into online courses, buying a course on back-end technologies) \n3. Relationship and emotional expression (telling boyfriend and expressing feelings)"
          }

    const headerInfoSelectStyleMe = {
        background: "linear-gradient(180deg, #FCE6DD 41.92%, rgba(217, 217, 217, 0) 97.59%)",
        fontWeight: "600",
        color: "#01173F", //Onclick Change
        borderRadius: "20px 0px 0px 0px" //Onclick Change
    }

    const headerInfoSelectStyleCommunity = {
        background: "linear-gradient(180deg, #FCE6DD 41.92%, rgba(217, 217, 217, 0) 97.59%)",
        fontWeight: "600",
        color: "#01173F", //Onclick Change
        borderRadius: "0px 20px 0px 0px" //Onclick Change
    }

    const handleProcessPage = (section) => {
        // version 1 to handle navigation to /process screens for button click
        // if(headerInfoSelect){
        //     if(savedFFTData.latestAnswer && isEqual(emotionalData,savedFFTData.latestAnswer.payload?.[0].survey)) {
        //         navigate('/emotionalhealth/process', {state: {
        //         tokenPayload,
        //         summaryPage:pageNumber
        //     }})
        //     } else {
        //         console.log("latestAnswer:",savedFFTData.latestAnswer,"\nemotionalData:",emotionalData)
        //         console.log("No survey submission!")
        //     }
        // } else {
        //     navigate('/emotionalhealth/process', {state: {
        //         tokenPayload,
        //         summaryPage:pageNumber,
        //         hardcode: true
        //     }})
        // }      

        // Version 2 show data on summary page.
        let newSections = {
            thoughts: false,
            feelings: false,
            bodySensations: false,
            actions: false
        }
        newSections[`${section}`] = true 
        setSummarySection(newSections);
 
        
    }

    const NextButton = () => {

        if (location.pathname === "/emotionalhealth") {
            const firstItem = seeEHealthOption[0]
            let newArray = seeEHealthOption.filter((value, index) => index !== (0))
            newArray.push(firstItem)
            setSeeEHealthOption(newArray)

        }
        if (location.pathname === "/learnmore") {
            const firstItem = seeIDiseaseOption[0]
            let newArray = seeIDiseaseOption.filter((value, index) => index !== (0))
            newArray.push(firstItem)
            setSeeIDiseaseOption(newArray)

        }


    }
    const backButton = () => {

        if (location.pathname === "/emotionalhealth") {
            const lastItem = seeEHealthOption[seeEHealthOption.length - 1]
            let newArray = seeEHealthOption.filter((value, index) => index !== (seeEHealthOption.length - 1))
            newArray.unshift(lastItem)
            setSeeEHealthOption(newArray)

        }
        if (location.pathname === "/learnmore") {
            const lastItem = seeIDiseaseOption[seeIDiseaseOption.length - 1]
            let newArray = seeIDiseaseOption.filter((value, index) => index !== (seeIDiseaseOption.length - 1))
            newArray.unshift(lastItem)
            setSeeIDiseaseOption(newArray)

        }


    }
    

  return (
      <SeeBox>
          <HeaderBox>
              <HeaderBoxSelect>
                  <button onClick={backButton}><img src={backIcon} alt={"next Icon"}/></button>
                  <button onClick={NextButton}><img src={nextIcon} alt={"next Icon"}/></button>
              </HeaderBoxSelect>
              <HeaderBoxOption color={location.pathname === "/learnmore"? "#EA4C01": "#F76E2D"}>
                  {location.pathname === "/emotionalhealth" &&
                      <Fragment>
                          {
                              seeEHealthOption.map((el, index) => {
                                  return (
                                      <React.Fragment>
                                          {index < 3 &&
                                              <div className={"SeeOptionRow"}>

                                                  <div key={index}><img src={el.src}
                                                                        style={el.imgCss ? el.imgCss : null}/>
                                                  </div>
                                                  {index === 1 ?
                                                      <span>{el.name}</span> :
                                                      null
                                                  }
                                              </div>
                                          }
                                      </React.Fragment>

                                  )
                              })

                          }

                      </Fragment>

                  }
                  {location.pathname === "/learnmore" &&
                      <Fragment>
                          {
                              seeIDiseaseOption.map((el, index) => {
                                  return (
                                      <React.Fragment>
                                          {index < 3 &&
                                              <div className={"SeeOptionRow"}>

                                                  <div key={index}><img src={el.src}
                                                                        style={el.imgCss ? el.imgCss : null}/>
                                                  </div>
                                                  {index === 1 ?
                                                      <span>{el.name}</span> :
                                                      null
                                                  }
                                              </div>
                                          }
                                      </React.Fragment>

                                  )
                              })

                          }

                      </Fragment>

                  }
              </HeaderBoxOption>
              {/*{seeEHealthOption}*/}
          </HeaderBox>
          <InfoBox>
              <InfoHeaderName color={location.pathname === "/learnmore"? "#EA4C01": "#F76E2D"}>
                  <button style={headerInfoSelect === true ? headerInfoSelectStyleMe: null} onClick={()=>{setHeaderInfoSelect(true); setNoSubmission(match); handleProcessPage('thoughts')}}>ME</button>
                  <button style={headerInfoSelect === false ? headerInfoSelectStyleCommunity: null} onClick={()=>{setHeaderInfoSelect(false); setNoSubmission(false); handleProcessPage('thoughts')}}>COMMUNITY</button>
              </InfoHeaderName>
              <InfoHeaderTag>
                  {!headerInfoSelect &&
                      <button>
                          <img src={filterIcon} alt='filter'/>
                          <span>Geolocation</span>
                      </button>
                  }
                  <button>
                      <img src={calenderIcon} alt={"calendar"}/>
                      <span>Today</span>
                  </button>
              </InfoHeaderTag>
              <InfoMain>
                  {location.pathname === "/emotionalhealth" &&
                      <FeelingsPage>
                            {headerInfoSelect && //If on the Me Page
                            <FeelingsHeader>    
                                <SectionButton active={summarySection.thoughts ? true : false} onClick={()=> {handleProcessPage('thoughts')}}>Thoughts</SectionButton>
                                <SectionButton active={summarySection.feelings ? true : false} onClick={()=> {handleProcessPage('feelings')}}>Feelings</SectionButton>
                                <SectionButton active={summarySection.bodySensations ? true : false} onClick={()=> {handleProcessPage('bodySensations')}}>Body<br/>Sensations</SectionButton>
                                <SectionButton active={summarySection.actions ? true : false} onClick={()=> {handleProcessPage('actions')}}>Actions</SectionButton>
                            </FeelingsHeader>
                            }
                            {!headerInfoSelect && //If on the community page
                            <FeelingsHeader>    
                                <SectionButton active={summarySection.thoughts ? true : false} onClick={()=> {handleProcessPage('thoughts')}}>Thoughts</SectionButton>
                                <SectionButton active={summarySection.feelings ? true : false} onClick={()=> {handleProcessPage('feelings')}}>Feelings</SectionButton>
                                <SectionButton active={summarySection.bodySensations ? true : false} onClick={()=> {handleProcessPage('bodySensations')}}>Body<br/>Sensations</SectionButton>
                                <SectionButton active={summarySection.actions ? true : false} onClick={()=> {handleProcessPage('actions')}}>Actions</SectionButton> 
                            </FeelingsHeader>
                            }
    {/*-----------------------------------------------Me Tab-----------------------------------------------*/}                              

                            {noSubmission &&  
                                <FeelingsHeaderMain>
                                    You Have Not Made a Submission Today!
                                </FeelingsHeaderMain>
                            }

                            {(!noSubmission && headerInfoSelect && summarySection.thoughts) &&
                                <FeelingsHeaderMain>
                                    {emotionalData.q3}
                                </FeelingsHeaderMain>
                            }
                            {(!noSubmission && headerInfoSelect && summarySection.feelings) && 
                                <FeelingsHeaderMain>
                                    <FeelingsPrimary>
                                        <h1>Primary Feelings</h1>
                                        <FeelingsPrimaryVisual>
                                            <div className={"FeelingsPColor"}/>
                                            <ul>
                                                <li>100% Embarrassed <div/></li>
                                            </ul>
                                        </FeelingsPrimaryVisual>

                                    </FeelingsPrimary>
                                    <FeelingsAssociated>
                                        <h1>Associated Feelings</h1>
                                        <ul>
                                            <li>Ashamed
                                                <div>
                                                    <div style={{width: "80%"}}/>
                                                </div>
                                            </li>
                                            <li>Mortified
                                                <div>
                                                    <div style={{width: "30%"}}/>
                                                </div>
                                            </li>
                                            <li>Weak
                                                <div>
                                                    <div style={{width: "10%"}}/>
                                                </div>
                                            </li>
                                        </ul>
                                    </FeelingsAssociated>
                                </FeelingsHeaderMain>
                            }
                            {(!noSubmission && headerInfoSelect && summarySection.bodySensations) &&
                                    <FeelingsHeaderMain>
                                        <p>
                                            {
                                            emotionalData.q4.map((el) => {
                                                    return (
                                                        <li className={"associated-process-single"}>{el}</li>
                                                    )
                                            })
                                            }
                                        </p>
                                    </FeelingsHeaderMain>
                            }

                            {(!noSubmission && headerInfoSelect && summarySection.actions) &&
                                <FeelingsHeaderMain>
                                    {emotionalData.q5}
                                </FeelingsHeaderMain>
                            }

    {/*-----------------------------------------------Community Tab-----------------------------------------------*/}                              

                            {(!noSubmission && !headerInfoSelect && summarySection.thoughts) &&
                                <FeelingsHeaderMain>
                                    <p>{communityData.q3}</p>
                                </FeelingsHeaderMain>
                            }
                            {(!noSubmission && !headerInfoSelect && summarySection.feelings) && 
                                <FeelingsHeaderMain>
                                    <FeelingsPrimary>
                                        <h1>Primary Feelings</h1>
                                        <FeelingsPrimaryVisual>
                                            <div className={"FeelingsPColor"}/>
                                            <ul>
                                                <li>100% Embarrassed <div/></li>
                                            </ul>
                                        </FeelingsPrimaryVisual>

                                    </FeelingsPrimary>
                                    <FeelingsAssociated>
                                        <h1>Associated Feelings</h1>
                                        <ul>
                                            <li>Ashamed
                                                <div>
                                                    <div style={{width: "80%"}}/>
                                                </div>
                                            </li>
                                            <li>Mortified
                                                <div>
                                                    <div style={{width: "30%"}}/>
                                                </div>
                                            </li>
                                            <li>Weak
                                                <div>
                                                    <div style={{width: "10%"}}/>
                                                </div>
                                            </li>
                                        </ul>
                                    </FeelingsAssociated>
                                </FeelingsHeaderMain>
                            }
                            {(!noSubmission && !headerInfoSelect && summarySection.bodySensations) &&
                                <FeelingsHeaderMain>
                                    <p>
                                        {
                                        communityData.q4.map((el) => {
                                                return (
                                                    <li className={"associated-process-single"}>{el}</li>
                                                )
                                        })
                                        }
                                    </p>
                                </FeelingsHeaderMain>
                            }

                            {(!noSubmission && !headerInfoSelect && summarySection.actions) &&
                                <FeelingsHeaderMain>
                                    <p>{communityData.q5}</p>
                                </FeelingsHeaderMain>
                            }
                      </FeelingsPage>


                  }
                  {location.pathname === "/learnmore" &&
                      <TTPPage>
                          <h1>TBD</h1>
                          <div>
                              <img src={checkIcon} alt={"check Icon"}/>
                              <img src={scanIcon} alt={"scan image"}/>
                              <img src={exclamationIcon} alt={"exclamation Icon"}/>
                              <img src={removeIcon} alt={"remove"}/>

                          </div>
                      </TTPPage>


                  }


              </InfoMain>
          </InfoBox>

      </SeeBox>
  );
}

export default SeeComponent;
