
import { Model } from 'survey-core';
import ReactDOM from 'react-dom';
import '../index.css';
import InfoIconComponent from './InfoIconComponent';

export function removeDays(params) {
    var result = new Date();
    result.setDate(result.getDate() - params[0]);
    return result.toISOString().split('T')[0];
}

export function isLessThanFourMonths(params) {
    const dob = new Date(params[0]);
    const fourMonthsAgo = new Date();
    fourMonthsAgo.setMonth(fourMonthsAgo.getMonth() - 4);
    return dob >= fourMonthsAgo && dob <= new Date();
}

export function isOlderThanSeventy(params) {
    const dob = new Date(params[0]);
    const seventyYearsAgo = new Date();
    seventyYearsAgo.setFullYear(seventyYearsAgo.getFullYear() - 70);
    return dob <= seventyYearsAgo;
}

export function createMap(data){
    const surveyMap = new Map();
    for (let i = 0; i < data.length; i++){
        surveyMap.set(data[i].name, new Model(data[i].json));
    }
    return surveyMap;
}


const titleDivider = "||";
export function updateStringComponents(_, options) {
    options.htmlElement.querySelectorAll('.sv-string-viewer').forEach((el) => {
        const text = el.innerText;
        if (text.indexOf(titleDivider) > -1) {
            const strings = text.split(titleDivider);
            if(strings.length > 2){
                if(true){
                    el.title = strings[1];
                    el.innerText = strings[0];
                } else {
                    el.title = strings[2];
                    el.innerText = strings[0];
                }
            } else {
                el.title = strings[1];
                el.innerText = strings[0];
            }
        }
    });
}

export function addIIcon(_, options){
    //Manually add the i icon to the first question
    if (options.question.jsonObj.name === "Q0 nwf") {
        var h5Header = options.htmlElement.querySelector("h5")
        var header = options.htmlElement.querySelector("h5").querySelector("span");
        const container = document.createElement('div');
        container.className = "toolTipContainer";
        ReactDOM.render(<InfoIconComponent superScript={false} />, container);
        if (!header) 
            header = options.htmlElement;
        h5Header.style.display = 'flex';
        header.parentNode.insertBefore(container, header.nextSibling);
    } else {
        var h5Header = options.htmlElement.querySelector("h5");
        var header = options.htmlElement.querySelector("h5").querySelector("span");
        if (header) {

            const iComponentContainer = document.createElement('div');
            iComponentContainer.className = "superScriptTipContainer";
            ReactDOM.render(<InfoIconComponent superScript={true} />, iComponentContainer, () => {header.outerHTML = header.outerHTML.replace('{i}', iComponentContainer.outerHTML);});
        }

        h5Header.style.display = 'flex';
    }
}

// export async function checkIndivProfile(date) {
//     const lastUpdated = new Date(date[0]);
//     const sixMonthsAgo = new Date();
//     sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
//     //Need to update;survey was last updated more than 6 months ago return true else false
//     console.log(!(lastUpdated >= sixMonthsAgo && lastUpdated <= new Date()))
//     this.returnResult(!(lastUpdated >= sixMonthsAgo && lastUpdated <= new Date())); 
// }
export function checkIndivProfile(date) {
        const lastUpdated = new Date(date[0]);
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
        //Need to update;survey was last updated more than 6 months ago return true else false
        return(!(lastUpdated >= sixMonthsAgo && lastUpdated <= new Date())); 
    }
export function showFirstQuestion(params){
    if(params[0] === "Never" || params[1] === "true"){
        return false;
    }else {
        return true;
    }
}
export function updateProfile(param1){
    if(param1[0] === true && param1[1] === false){
        return false;
    }else {
        return true;
    }
    
}