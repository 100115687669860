import { useState, useRef, forwardRef,useEffect } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCommunityData, selectStatus } from "../../store/communityHealthSlice"
import { Ad } from "../Shared/Ad";
import { Slogan } from "../Shared/Slogan";
import {Info} from "../Shared/Info"
import MenuIcon from "../../images/hamburgerIcon.svg"
import { Link } from "react-router-dom";
import "../PublicHealth/index.css";
import ProfileIcon from "../../images/profileIcon.svg";
import DropdownArrow from "../../images/arrow-right.svg";
import { deepPurple } from "@material-ui/core/colors";
import DomainDropdown from "./domainDropdowns";
import DropdownButton from "../Shared/DropdownButton";
import downArrow from "../../images/down_chevron_white.svg"
// import MenuIcon from '@material-ui/icons/Menu';

function DetailHeader({parentPath, setDropdownOpen, dropdownOpen, setShowVerticals, showVerticals, tokenPayload, communityHealthInfo,
    options,
    countyData,
    countyLabel,
    stateData,
    stateLabel,
    countryData,
    countryLabel,
    comingSoonOptions,
    headerTitle,
    minuteCardOneHeader,
    minuteCardOneText,
    minuteCardTwoHeader,
    minuteCardTwoText,
    sayComponentText,
    saySectionLabel,
    newNavPage,
    navPage, 
}) {
    const location = useLocation();
    const [domainExpanded, setDomainExpanded] = useState(false);
    const [activeDomain, setActiveDomain] = useState("None");

    const [comingSoonList, setComingSoonList] = useState({
        about: false,
        dataDignity: false,
        FAQs: false,
        contact: false,
        socialMedia: false,
        help: false,
    })
    const navigate = useNavigate();

    const handleComingSoonLinks = (link) => {
        const newList = {
            about: false,
            dataDignity: false,
            FAQs: false,
            contact: false,
            socialMedia: false,
            help: false,
        }
        if(comingSoonList[link] === true) {
            setComingSoonList(newList);
        } else {
            setComingSoonList({...newList, [`${link}`]: true})
        }
    }

    useEffect(() => {
        if(dropdownOpen.expand === true && dropdownOpen.footer === true) {
            const dropdown = document.getElementById('footerSelected')
            dropdown.scrollIntoView({behavior:"instant"});
        } else if(dropdownOpen.expand === false && dropdownOpen.footer===true) {
            const footer = document.getElementById('bottom-blue')
            footer.scrollIntoView({behavior:"instant"});
        }
    }, [dropdownOpen])

    function handleHover(event) {
        if (event.target.classList.contains("generalTitle") || event.target.classList.contains("generalTitleRight")) {
            const generalInfo = document.querySelector(".generalInfo");
            if (generalInfo.nextElementSibling.tagName !== "SPAN") {
                const comingSoonSpan = document.createElement("span");
                comingSoonSpan.textContent = "Coming Soon!";
                comingSoonSpan.classList.add("comingSoonSpan");
                comingSoonSpan.classList.add("generalComingSoon");
                generalInfo.insertAdjacentElement("afterend", comingSoonSpan);
            
            }

          } else {
            const button = event.currentTarget;
            const domainInfo = button.closest(".domainInfo");
            const dropDownButtonContainer = domainInfo.querySelector(".dropdownButtonContainer");
            const bttn = dropDownButtonContainer.querySelector("button");
            if (!bttn.querySelector("span")) {
                const comingSoonSpan = document.createElement("span");
                comingSoonSpan.textContent = "Coming Soon!";
                comingSoonSpan.classList.add("comingSoonSpan");
                bttn.insertBefore(comingSoonSpan, bttn.firstChild);
            }
        }
    }
    function handleMouseOut(event) {
        if (event.target.classList.contains("generalTitle") || event.target.classList.contains("generalTitleRight")) {
            const generalInfo = document.querySelector(".generalInfo");
            const comingSoonSpan = generalInfo.nextElementSibling;
            if (comingSoonSpan) {
                comingSoonSpan.remove();
            }
        } else {
            const button = event.currentTarget;
            const domainInfo = button.closest(".domainInfo");
            const dropDownButtonContainer = domainInfo.querySelector(".dropdownButtonContainer");
            const bttn = dropDownButtonContainer.querySelector("button");
            const comingSoonSpan = bttn.querySelector(".comingSoonSpan");
            if (comingSoonSpan) {
                comingSoonSpan.remove();
            }
        }
        
    }
    const dropdownMenu = (isExpanded) => {
        return(
          <div className="dropdown" id={dropdownOpen.footer===true ? "footerSelected" : ""} style={isExpanded ? { display: "block" } : { display: "none" }}>
            <div className="closeHamburgerSection">
                <button className="closeHamburgerMenuButton" onClick={e => {
                    setDropdownOpen({...dropdownOpen, expand: false});
                    setComingSoonList({
                        about: false,
                        dataDignity: false,
                        FAQs: false,
                        contact: false,
                        socialMedia: false,
                        help: false,
                    });
                }}><p>-</p></button>
            </div>
            <Link to={"/profile"} className="profileLink" state={{parentPath, tokenPayload, communityHealthInfo, 
                options,
                countyData,
                countyLabel,
                stateData,
                stateLabel,
                countryData,
                countryLabel,
                comingSoonOptions,
                headerTitle,
                minuteCardOneHeader,
                minuteCardOneText,
                minuteCardTwoHeader,
                minuteCardTwoText,
                sayComponentText,
                saySectionLabel,
                newNavPage, 
                dropdownOpen,
                navPage,
                footerDrop:dropdownOpen.footer}}>
                <h3 className="profileLinkText">PROFILE</h3>
                <img src={ProfileIcon} alt="profile" className="profileIconLink"/>
            </Link>
            <hr/>
            <h3 className="domainHeader">DOMAIN</h3>
            <div className="domainInfo" onMouseLeave={handleMouseOut}>
                <h5 className="domainTitle">SAFETY </h5>
                <div className="dropdownButtonContainer">
                <button onMouseEnter={e => {
                    e.preventDefault();
                    handleHover(e);
                }}>
                    <img src={DropdownArrow} alt="" className="dropdownMenuArrow"/>
                </button>
                </div>
            </div>
            <hr/>
            <div className="domainInfo">
                <h5 className="domainTitle">HEALTH</h5>
                <div className="dropdownButtonContainer">
                    {location.pathname!=="/healthsubdomain" ? 
                        <Link state={communityHealthInfo} to={"/healthsubdomain"} tokenPayload={tokenPayload}>                        
                            <img src={DropdownArrow} alt="" className="dropdownMenuArrow"/>
                        </Link>
                        :
                        <img src={DropdownArrow} alt="" className="dropdownMenuArrow"/>
                    }
                    
                </div>
            </div>
            <hr/>
            <div className="domainInfo" onMouseLeave={handleMouseOut}>
                <h5 className="domainTitle">WEALTH</h5>
                <div className="dropdownButtonContainer">
                    <button onMouseEnter={e => {
                        e.preventDefault();
                   
                        handleHover(e);
                    }}>
                        <img src={DropdownArrow} alt="" className="dropdownMenuArrow" />
                </button>
                </div>
            </div>
            <hr/>
            <div className="domainInfo" onMouseLeave={handleMouseOut}>
                <h5 className="domainTitle">ENVIRONMENT</h5>    
                <div className="dropdownButtonContainer">
                    <button onMouseEnter={e => {
                        e.preventDefault();
         
                        handleHover(e);
                    }}>
                        <img src={DropdownArrow} alt="" className="dropdownMenuArrow"/>
                </button>
                </div>
            </div>
            <hr/>
            <div className="domainInfo" onMouseLeave={handleMouseOut}>
                <h5 className="domainTitle">GOVERNANCE</h5>
                <div className="dropdownButtonContainer">
                    <button onMouseEnter={e => {
                        e.preventDefault();

                        handleHover(e);
                    }}>
                        <img src={DropdownArrow} alt="" className="dropdownMenuArrow"/>
                </button>
                </div>
            </div>
            <hr/>
            <div className="domainInfo" onMouseLeave={handleMouseOut}>
                <h5 className="domainTitle">SOCIAL RELATIONS</h5>
                <div className="dropdownButtonContainer">
                    <button onMouseEnter={e => {
                        e.preventDefault();

                        handleHover(e);
                    }}>
                        <img src={DropdownArrow} alt="" className="dropdownMenuArrow"/>
                </button>
                
                </div>
            </div>                

            <div className="generalSection">
                <h4 className="generalInfo">GENERAL INFORMATION</h4>
                <hr />
                <div className="generalRow">
                    <h6 className="generalTitle" onMouseEnter={() => handleComingSoonLinks('about')}>About {comingSoonList.about && <p className="comingSoonText">Coming Soon!</p>}</h6>
                    <h6 className="generalTitleRight" onMouseEnter={() =>handleComingSoonLinks('dataDignity')}>Data Dignity {comingSoonList.dataDignity && <p className="comingSoonText">Coming Soon!</p>}</h6>
                </div><div className="generalRow">
                    <h6 className="generalTitle" onMouseEnter={() =>handleComingSoonLinks('FAQs')}>FAQ's {comingSoonList.FAQs && <p className="comingSoonText">Coming Soon!</p>}</h6>
                    <h6 className="generalTitleRight" onMouseEnter={() =>handleComingSoonLinks('contact')}>Contact {comingSoonList.contact && <p className="comingSoonText">Coming Soon!</p>}</h6>
                </div><div className="generalRow">
                    <h6 className="generalTitle" onMouseEnter={() =>handleComingSoonLinks('socialMedia')}>Social Media {comingSoonList.socialMedia && <p className="comingSoonText">Coming Soon!</p>}</h6>
                    <h6 className="generalTitleRight" onMouseEnter={() =>handleComingSoonLinks('help')}>Help & Support {comingSoonList.help && <p className="comingSoonText">Coming Soon!</p>}</h6>
                </div>
              </div>
            </div>
        )
      }

    return (
        <header >
            <nav className="blue">
                <div className="top-row">
                    <Slogan/>
                    {/* UPDATED  VERSION */}
                    <div className="hamburger-menu">
                        <button onClick={(e) => {
                            e.preventDefault();
                            if (showVerticals != null) {
                                setShowVerticals(prev => !prev);
                            }
                            const dropdownStatus = dropdownOpen.expand;
                            setDropdownOpen({ footer: false, expand: !dropdownStatus});
                            
                        }}>
                            <img src={MenuIcon} alt="Display menu icon"/>
                        </button>
                        <div className="dropdown-container">
                            {dropdownOpen.expand && dropdownMenu(dropdownOpen.expand)}
                        </div>
                        <div className="domainDropdownComtainer">
                            {domainExpanded ? <DomainDropdown domain={activeDomain} tokenPayload={tokenPayload}
                                                              communityHealthInfo={communityHealthInfo}
                                                              changeExpanded={setDropdownOpen}
                                                              domainExpanded={domainExpanded}
                                                              changeDomainExpanded={setDomainExpanded}/> : null}
                        </div>
                    </div>
                </div>
                <div className="bottom-row">
                    <Info/>
                </div>
                {/* DISABLED AD FOR CLARITY. WILL ENABLE LATER*/}

                {/* <Ad /> */}
            </nav>
        </header>
    );
}

export default DetailHeader;
