import React, { useState, useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
import ConfirmationPage from './SignUpConfirmationPage';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import DropdownButton from '../../components/Shared/DropdownButton';
import { getZipcodeDetails } from '../../components/Shared/ZipcodeSearch';
import axios from 'axios';

const CustomSignUp = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const [locationBool, setLocationBool] = useState(true);
  const [zipResults, setZipResults] = useState([]);
  const [zipResultStrings, setZipResultStrings] = useState([]);
  const [zipExists, setZipExists] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedLocationString, setSelectedLocationString] = useState('');
  const controllerRef = useRef(null);

  const getLocations = async (zipcode) => {
    if(controllerRef.current) {
      controllerRef.current.abort()
      console.log('aborting previous request')
    }
    controllerRef.current = new AbortController()
    try {
        const res = await axios.get(`https://ehomoi5b95.execute-api.us-east-1.amazonaws.com/dev/zipcode/getAllPlacesByZipcodeStartWith`, {
            params: { zipcodeStartWith: zipcode },
        });
        console.log(res.data);
        const zipcodes = res.data.places;

        let locationArray = [];
        for (const zip of zipcodes) {
            for (const place of zip.places) {
                const location = {...place, zipcode: zip.zipcode};
                locationArray.push(location);
            }
        };
        setZipExists(true);
        setZipResults(locationArray);
    }
    catch (error) {
        console.error(error);
        setZipExists(false);
        setZipResultStrings(['Please enter a valid zipcode']);
    }
};

useEffect(() => {
  //TODO: insert sorting logic here
  let locationArray = [];
  for (const location of zipResults) {
      const country = location.cc;
      //formatting the location details from smallest to largest
      locationArray.push(`${location.zipcode}, ${location.pn ? location.pn + ',' : ''} ${location.adm3 ? location.adm3 + ',' : ''} ${location.adm2 ? location.adm2 + ',' : ''} ${location.adm1 ? location.adm1 + ',' : ''} ${country}`);
  };
  setZipResultStrings(locationArray);
}, [zipResults]);

const handleLocationSelect = async (index) => {
  setSelectedLocation(zipResults[index]);
  setSelectedLocationString(zipResultStrings[index]);
  setLocationBool(false);
  // setEditingIndex(null);
};

useEffect(() => {
  setFormData(prevFormData => ({
    ...prevFormData,
    zipcode: selectedLocationString.split(',')[0], country: selectedLocation.cc, city: selectedLocation.pn, 
  }));
}, [selectedLocation]);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    country: '',
    zipcode: '',
    error: '',
    preferred_username: '', //preferred username is the user's handle, it's called preferred_username in cognito
    signUpSuccess: false, // Track signup success
  });

  const handleLocationSelection = (e) => {
    const location = e.textContent;
    setFormData(prevFormData => ({
      ...prevFormData,
      zipcode: location.split(',')[0]
    }));
    setLocationBool(false);
    console.log('location bool is: ' + locationBool);
    setSelectedLocation(location);
    console.log(e.textContent + ' selected');
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'country' && value === '') {
      setFormData({ ...formData, [name]: value, zipcode: '' });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    const { preferred_username, email, password, confirmPassword, country, zipcode, city } = formData;

    // Validate fields
    if (!preferred_username || !email || !password || !confirmPassword || !country || !zipcode || !city) {
      setFormData({ ...formData, error: 'Please fill in all fields.' });
      return;
    }

    // Validate password match
    if (password !== confirmPassword) {
      setFormData({ ...formData, error: 'Passwords do not match.' });
      return;
    }

    // Validate zipcode
    try {
      const isValidZipcode = await validateZipcode(country, zipcode);
      if (!isValidZipcode) {
        setFormData({ ...formData, error: 'Please enter a valid zipcode.' });
        return;
      }
    } catch (error) {
      console.error('Error validating zipcode:', error);
      setFormData({
        ...formData,
        error: 'An error occurred during zipcode validation.',
      });
      return;
    }

    setFormData({ ...formData, error: '' });

    // Proceed with signup
    try {
      await Auth.signUp({
        username: email,
        email,
        password,
        attributes: {
          'custom:Country': country,
          'custom:Zipcode': zipcode,
          'custom:City': city,
          preferred_username: preferred_username,
        },
      });
      // Update state to indicate successful signup
      setFormData({ ...formData, signUpSuccess: true });
      navigate('/confirm', { state: { formData } }); // Pass formData to confirmation page
    } catch (error) {
      console.error('Error signing up:', error);
      setFormData({
        ...formData,
        error: error.message || 'An error occurred.',
      });
    }
  };

  const validateZipcode = async (country, zipcode) => {
    try {
      const response = await fetch(
        `http://api.geonames.org/postalCodeLookupJSON?postalcode=${zipcode}&country=${country}&username=sagar50`
      );
      const data = await response.json();
      // Check if the returned data contains information about the provided zipcode
      console.log(
        'Zipcode validation response:',
        data && data.postalcodes && data.postalcodes.length > 0
      );
      return data && data.postalcodes && data.postalcodes.length > 0;
    } catch (error) {
      console.error('Error validating zipcode:', error);
      return false;
    }
  };

  // If signUpSuccess is true, render the ConfirmationPage component
  // if (formData.signUpSuccess) {
  //   return <ConfirmationPage formData={formData} />;
  // }

  return (
    <div style={{ padding: '20px' }}>
      <input
        className="signUpInput"
        type="text"
        name="preferred_username"
        value={formData.preferred_username}
        onChange={handleChange}
        placeholder="@username"
      />
      
      <input
        className="signUpInput"
        type="text"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email"
      />

      <input
        className="signUpInput"
        type="password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        placeholder="Password"
      />

      <input
        className="signUpInput"
        type="password"
        name="confirmPassword"
        value={formData.confirmPassword}
        onChange={handleChange}
        placeholder="Confirm Password"
      />

      {/* Container allows for country and zip code fields to be placed next to each other */}
      <div className='primaryResidenceContainer'>
        { locationBool || !selectedLocationString ? ( 
         <DropdownButton
            ButtonAllTextColor={"black"}
            ButtonPrimaryTextColor={'black'}
            ButtonPrimaryBackground={'#f8f9fa'}
            ButtonAllBackground={"#f8f9fa"}
            ButtonFunctionChange={false}
            ButtonAllWidth={'370px'}
            ButtonPrimaryWidth={'370px'}
            ButtonPrimaryIcon={'block'}
            BoxPosition={['0', '10px']}
            FontSize={'11px'}
            Overflow={true}
            listHoverColor={'#00286E'}
            ButtonBoxBorder={true}
            ButtonAllRadius={'8px'}
            ButtonPrimaryRadius={'20px'}
            ButtonAllHeight={'25px'}
            ButtonCharList={zipResultStrings}
            maxPrimaryWidth={'300px'}
            Function={function (e) {
              if(zipExists) {
                console.log(e.textContent);
                handleLocationSelect(e.value);
              }
            }}
            primaryBox={
                <input
                  className={'signUpInput'}
                  id='location'
                  // ref={inputRef}
                  type="text"
                  placeholder="Postal/Zip Code"
                  // onClick={() => inputRef.current.classList.remove('error')}
                  onKeyUp={e => {
                    getLocations(e.target.value);
                  }}
                />
            }
         />
          ) : (
           <div className="location-text">
               <strong onClick={() => {
                   setLocationBool(true)
               }}
                       /*className={status}*/>
                   {selectedLocationString ? selectedLocationString : "Enter a valid ZipCode"}
               </strong>
           </div>
          )
        }
        {/* <input
          className="signUpInput"
          type="text"
          name="country"
          value={formData.country}
          onChange={handleChange}
          placeholder="Country"
        />

        <input
          className="signUpInput"
          type="text"
          name="zipcode"
          value={formData.zipcode}
          onChange={handleChange}
          placeholder="Zip Code"
          disabled={!formData.country}
        /> */}
      </div>
      {formData.error && (
        <div style={{ color: 'red', marginTop: '10px' }}>{formData.error}</div>
      )}
      <br />
      <button className="signUpButton" onClick={handleSignUp}>
        Sign Up
      </button>
    </div>
  );
};

export default CustomSignUp;
