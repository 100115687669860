import React, { useState, useEffect, useRef, useMemo } from 'react';
import filterIcon from '../../../images/filterIcon.svg';
import calenderIcon from '../../../images/calenderIcon.svg';
import searchIcon from '../../../images/searchIcon.svg';
import './ScanAndSay.css';
import scanCounty from '../../../images/scanCounty.svg';
import scanMaryland from '../../../images/scanMaryland.svg';
import scanUsa from '../../../images/scanUsa.svg';
import scanGlobal from '../../../images/scanGlobal.svg';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-calendar/dist/Calendar.css';
import civLogo from '../../../images/civilience_triangle.png';
import storyImg from '../../../images/storyImg.png';
import CDCStory from '../../../images/CDCStory.png';
import CancelArrow from "../../../images/orange-left-arrow.svg";
import CloseBttn from "../../../images/close.svg";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchByZipCode, fetchZipCode, selectCommunityData, setZipCode } from '../../../store/communityHealthSlice';
import { Info } from '../../Shared/Info';
import USStates from '../../Shared/StatesCitiesZipcode/USStates.json';


function ScanComponent( ) {
    const dispatch = useDispatch();
    const locationData = useSelector(selectCommunityData);
    const status = useSelector(selectCommunityData);

    const [selectedCategory, setSelectedCategory] = useState('county');
    const [expandStory, setExpandStory] = useState(false);
    const [story, setStory] = useState();
    const [searchMode, setSearchMode] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [dateMode, setDateMode] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedSortOption, setSelectedSortOption] = useState('newest');
    const [data, setData] = useState(null);
    const [locationBool, setLocationBool] = useState(false)
    const location = useLocation();
    const pathName = location.pathname;
    const firstUpdate = useRef(true);
    const [locationList, setLocationList] = useState([])

    const stateNameMapping = {
        AL: 'Alabama',
        AK: 'Alaska',
        AZ: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming',
        PR: "Puerto Rico",
        HI: "Hawaii"
    };

 

   // Function to determine the title based on category
   const getCategoryTitle = (category) => {
    switch (category) {
        case 'county':
            return locationData?.county_name?.split(',')[0] || 'Allegany County';
        case 'state':
            return stateNameMapping[locationData?.state] || 'Maryland';
        case 'country':
            return locationData?.country || 'United States';
        case 'global':
            return 'Global';
        default:
            return '';
    }
};

    var fillerStories = {
        county:[
            {
                title: 'Placeholder County Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                county: 'Placeholder County',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
            },
            {
                title: 'Placeholder County Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                county: 'Placeholder County',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
            },
            {
                title: 'Placeholder County Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                county: 'Placeholder County',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
            },
            {
                title: 'Placeholder County Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                county: 'Placeholder County',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
            },
            {
                title: 'Placeholder County Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                county: 'Placeholder County',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
            },
            {
                title: 'Placeholder County Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                county: 'Placeholder County',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
            },
            {
                title: 'Placeholder County Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                county: 'Placeholder County',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
            },
        ],
        state:[
            {
                title: 'Placeholder State Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                state: "Placeholder State",
            },
            {
                title: 'Placeholder State Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                state: "Placeholder State",
            }
        ],
        country:[
            {
                title: 'Placeholder Country Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '5 January 2024',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
                img: civLogo,
                country: 'Placeholder Country',
            },
            {
                title: 'Placeholder Country Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '5 January 2024',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
                img: civLogo,
                country: 'Placeholder Country',
            },
            {
                title: 'Placeholder Country Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '5 January 2024',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
                img: civLogo,
                country: 'Placeholder Country',
            },
            {
                title: 'Placeholder Country Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '5 January 2024',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
                img: civLogo,
                country: 'Placeholder Country',
            }

        ],
        global:[
            {
                title: 'Placeholder Global Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '15 January 2024',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
                img: civLogo,
                country: 'Placeholder Country',
            },
            {
                title: 'Placeholder Global Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '15 January 2024',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
                img: civLogo,
                country: 'Placeholder Country',
            },
            {
                title: 'Placeholder Global Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '15 January 2024',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
                img: civLogo,
                country: 'Placeholder Country',
            },
            {
                title: 'Placeholder Global Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '15 January 2024',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
                img: civLogo,
                country: 'Placeholder Country',
            },
            {
                title: 'Placeholder Global Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '15 January 2024',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
                img: civLogo,
                country: 'Placeholder Country',
            },
            {
                title: 'Placeholder Global Title',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '15 January 2024',
                content: [`Lorem ipsum odor amet, consectetuer adipiscing elit. At ultrices potenti leo malesuada sapien? Per commodo donec egestas neque habitasse pretium vitae bibendum. Vitae luctus mus felis ex, tellus curae. Neque magna massa nostra consequat ad nisi bibendum. Malesuada neque mi integer mattis id vitae montes et.`],
                img: civLogo,
                country: 'Placeholder Country',
            }
        ]
    }

    var stories = {   
        county: [
            {
                title: 'Frostburg Area to Issue Mask Mandate',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                county: 'Allegany County',
                content: [`The screenings include checks for diabetes, hypertension, and cholesterol levels, focusing on vulnerable populations. Local clinics are partnering with public health officials to provide follow-up care for those with abnormal results. The initiative hopes to reduce healthcare disparities and improve overall public health in the county.`],
            }, // Nevada
            {
                title: 'Clark County Launches Free Health Screenings for Low-Income Residents',
                summary: 'Clark County is offering free health screenings for low-income residents to detect and prevent chronic diseases...',
                date: '9 September 2023',
                content: [`The screenings include checks for diabetes, hypertension, and cholesterol levels, focusing on vulnerable populations. Local clinics are partnering with public health officials to provide follow-up care for those with abnormal results. The initiative hopes to reduce healthcare disparities and improve overall public health in the county.`],
                img: civLogo,
                county: 'Clark County',
            },
            {
                title: 'Clark County to Build New Public Health Research Facility',
                summary: 'A new public health research facility is being built in Clark County, focusing on infectious diseases and environmental health...',
                date: '3 October 2023',
                content: [`The research center will address issues such as air quality, water contamination, and emerging health threats. Scientists at the facility will also study the long-term effects of urbanization on public health in Las Vegas. The county hopes the new facility will attract funding and provide data that can be used to inform public health policies.`],
                img: civLogo,
                county: 'Clark County',
            },
            {
                title: 'Clark County Schools Introduce Mental Health Curriculum to Combat Student Stress',
                summary: 'Clark County schools are implementing a new mental health curriculum to address increasing levels of stress and anxiety among students...',
                date: '12 November 2023',
                content: [`The curriculum will teach students coping mechanisms and ways to seek help if they are experiencing mental health issues. School counselors and psychologists will also provide workshops for parents to better understand their children’s mental health needs. The initiative is part of a broader push to improve student well-being and academic performance.`],
                img: civLogo,
                county: 'Clark County',
            }, // MA
            {
                title: 'Franklin County Partners with Local Farms to Combat Food Insecurity and Malnutrition',
                summary: 'Franklin County’s health department is launching an initiative that connects local farms with food-insecure households to improve nutrition...',
                date: '9 January 2024',
                content: [`The partnership provides fresh produce to low-income families through food banks and community-supported agriculture (CSA) programs. Health officials believe improving access to nutritious foods will lower rates of obesity, diabetes, and malnutrition. The initiative will also educate residents on how to grow their own food and prepare healthier meals.`],
                img: civLogo,
                county: 'Franklin County',
            },
            {
                title: 'Franklin County Hospitals Enhance Mental Health Services Amid Crisis',
                summary: 'In response to rising mental health concerns, Franklin County hospitals are expanding their mental health services to better meet community needs...',
                date: '3 February 2024',
                content: [`The expansion includes crisis intervention teams, new psychiatric beds, and virtual therapy options. Public health officials are also implementing mental health education in schools to target early intervention in teens and young adults. The program is expected to reduce emergency room visits related to mental health crises.`],
                img: civLogo,
                county: 'Franklin County',
            },
            {
                title: 'Franklin County Schools Promote Vaccination with Immunization Clinics on Campus',
                summary: 'Franklin County schools are setting up on-campus immunization clinics to boost vaccination rates for flu and other preventable diseases...',
                date: '14 March 2024',
                content: [`The clinics will offer free vaccines to students and staff, making it more convenient to get immunized. Parents are encouraged to take advantage of this initiative, which focuses on improving public health and preventing outbreaks of preventable diseases. Health officials anticipate this will lead to fewer missed school days due to illness.`],
                img: civLogo,
                county: 'Franklin County',
            },// NJ
            {
                title: 'Hunterdon County Introduces Free Health Screenings for Rural Residents',
                summary: 'A new program in Hunterdon County is providing free health screenings for rural residents who have limited access to healthcare...',
                date: '11 October 2023',
                content: [`The screenings include checks for high blood pressure, diabetes, and cholesterol levels. Mobile health units will visit various parts of the county, ensuring that residents in remote areas can receive care. The goal is to prevent chronic diseases before they develop into more serious conditions.`],
                img: civLogo,
                county: 'Hunterdon County',
            },
            {
                title: 'Hunterdon County Expands Access to Mental Health Services for Youth',
                summary: 'Hunterdon County is expanding its mental health services for children and adolescents in response to rising rates of anxiety and depression...',
                date: '22 November 2023',
                content: [`The county’s mental health clinics are hiring more counselors and offering new programs focused on coping strategies and stress management. School-based mental health programs are also being launched to support students. Health officials hope that early intervention will reduce the long-term effects of mental health conditions.`],
                img: civLogo,
                county: 'Hunterdon County',
            },
            {
                title: 'Hunterdon County Holds First Annual Biotech Conference',
                summary: 'Hunterdon County hosted its first-ever biotech conference, attracting scientists and companies interested in healthcare innovation...',
                date: '1 December 2023',
                content: [`The conference focused on emerging trends in gene therapy, biotechnology, and personalized medicine. Local universities and biotech firms presented their latest research and discussed collaboration opportunities. County officials hope the event will boost the local biotech industry and lead to more innovation in healthcare.`,
                    `The conference focused on emerging trends in gene therapy, biotechnology, and personalized medicine. Local universities and biotech firms presented their latest research and discussed collaboration opportunities. County officials hope the event will boost the local biotech industry and lead to more innovation in healthcare.`,
                    `The conference focused on emerging trends in gene therapy, biotechnology, and personalized medicine. Local universities and biotech firms presented their latest research and discussed collaboration opportunities. County officials hope the event will boost the local biotech industry and lead to more innovation in healthcare.`,
                ],
                img: civLogo,
                county: 'Hunterdon County',
            },
            {
                title: 'Warren County to Launch Free Cancer Screening Program',
                summary: 'Warren County is introducing a free cancer screening program aimed at increasing early detection and improving survival rates...',
                date: '25 November 2023',
                content: [
                    `The program will offer screenings for breast, colorectal, and lung cancers, targeting at-risk populations. Health officials are working with local hospitals to provide the screenings and follow-up care if needed. They hope that early detection will significantly improve outcomes for cancer patients in the county.`,
                    `The program will offer screenings for breast, colorectal, and lung cancers, targeting at-risk populations. Health officials are working with local hospitals to provide the screenings and follow-up care if needed. They hope that early detection will significantly improve outcomes for cancer patients in the county.`,
                    `The program will offer screenings for breast, colorectal, and lung cancers, targeting at-risk populations. Health officials are working with local hospitals to provide the screenings and follow-up care if needed. They hope that early detection will significantly improve outcomes for cancer patients in the county.`
                ],
                img: civLogo,
                county: 'Warren County',
            },
            {
                title: 'Warren County Tackles High Rates of Obesity with New Wellness Initiative',
                summary: 'A wellness initiative is being rolled out in Warren County to combat rising obesity rates, especially among children...',
                date: '15 October 2023',
                content: [`The initiative focuses on nutrition education, physical activity programs, and healthier food options in schools. Local gyms and community centers are offering free fitness classes as part of the initiative. Officials hope that these efforts will help lower obesity rates and prevent related health conditions like diabetes and heart disease.`],
                img: civLogo,
                county: 'Warren County',
            },
            {
                title: 'Warren County Hospitals See Increase in Respiratory Illnesses, Issue Health Alert',
                summary: 'Warren County hospitals have issued a health alert as cases of respiratory illnesses rise, urging residents to take precautions...',
                date: '7 December 2023',
                content: [`Health officials are encouraging residents to get vaccinated against the flu and COVID-19 to prevent severe respiratory illnesses. The alert also emphasizes the importance of handwashing and staying home if sick. Public health officials are monitoring the situation closely and working to prevent an overwhelming strain on hospital resources.`],
                img: civLogo,
                county: 'Warren County',
            },
            {
                title: 'Los Angeles County Expands Free Health Clinics for Undocumented Immigrants',
                summary: 'Los Angeles County has opened new free clinics to provide healthcare services to undocumented immigrants, ensuring access to basic medical care...',
                date: '21 January 2024',
                content: [`The clinics will offer vaccinations, prenatal care, mental health counseling, and chronic disease management. Public health officials emphasize the importance of providing these services to protect both the individual and public health at large. The county hopes this initiative will prevent the spread of infectious diseases and improve health outcomes for vulnerable populations.`],
                img: civLogo,
                county: 'Los Angeles County',
            },
            {
                title: 'Los Angeles County Addresses Air Pollution-Related Health Issues with New Research Initiative',
                summary: 'A new research initiative in Los Angeles County aims to tackle the health impacts of air pollution, particularly in lower-income communities...',
                date: '9 February 2024',
                content: [`The initiative focuses on studying respiratory illnesses, asthma, and cardiovascular diseases caused by long-term exposure to poor air quality. Public health experts are advocating for stricter air quality regulations and community-based interventions to protect residents. The research will inform policy changes aimed at reducing air pollution levels and mitigating health risks.`],
                img: civLogo,
                county: 'Los Angeles County',
            },
            {
                title: 'Los Angeles County Launches Mobile Mental Health Crisis Units to Reduce ER Overload',
                summary: 'Los Angeles County has deployed mobile mental health crisis units to provide on-the-spot care and reduce emergency room overcrowding...',
                date: '25 February 2024',
                content: [`The units are equipped with trained mental health professionals who respond to calls involving mental health crises, including suicidal ideation and severe anxiety. Officials hope the program will provide immediate support, reduce unnecessary hospitalizations, and offer follow-up care to prevent future crises. This initiative aims to address the county’s growing mental health challenges, particularly in underserved areas.`],
                img: civLogo,
                county: 'Los Angeles County',
            },
            { // KY
                title: 'Muhlenberg County Expands Access to Mental Health Resources',
                summary: 'Mental health clinics are expanding their services in Muhlenberg County, offering more support for residents dealing with anxiety and depression...',
                date: '17 October 2023',
                content: [`The county’s health department is partnering with local providers to offer more mental health services, including telehealth options. Officials are focusing on reaching residents in the county's most rural areas, where access to care has been limited. The initiative is expected to reduce the stigma around mental health and encourage more people to seek help.`],
                img: civLogo,
                county: 'Muhlenberg County',
            },
            {
                title: 'New Opioid Addiction Treatment Program in Muhlenberg County',
                summary: 'A new program is being introduced in Muhlenberg County to combat the opioid crisis and provide treatment for those struggling with addiction...',
                date: '1 December 2023',
                content: [`The program will offer both inpatient and outpatient services, including medication-assisted treatment (MAT) and counseling. Health officials are working to make sure that treatment options are available to all residents, especially those in rural areas. The county has seen a rise in opioid overdoses in recent years, and the program aims to reduce those numbers.`],
                img: civLogo,
                county: 'Muhlenberg County',
            },
            {
                title: 'Muhlenberg County Schools Introduce Wellness Programs to Combat Childhood Obesity',
                summary: 'Schools in Muhlenberg County are launching new wellness programs to address high rates of childhood obesity in the region...',
                date: '5 November 2023',
                content: [`The program includes physical activity initiatives, healthier school meals, and educational sessions on nutrition. Teachers and staff are also being trained on how to promote healthy lifestyles among students. Health officials hope that by targeting children at a young age, they can reduce the county’s long-term obesity rates.`],
                img: civLogo,
                county: 'Muhlenberg County',
            },
            { // KY
                title: 'Ohio County Implements Rural Health Telemedicine Program for Seniors',
                summary: 'A new telemedicine program in Ohio County is designed to provide healthcare services to elderly residents who face travel barriers...',
                date: '14 November 2023',
                content: [`The telemedicine initiative aims to provide seniors with virtual consultations for chronic conditions like arthritis and diabetes. Healthcare workers will assist residents in using telehealth platforms to improve access to care. The program is part of a statewide effort to bring modern healthcare technology to underserved areas.`],
                img: civLogo,
                county: 'Ohio County',
            },
            {
                title: 'Ohio County Hospital Launches Free Flu Vaccination Drive',
                summary: 'Ohio County Hospital is offering free flu vaccinations for the upcoming flu season to prevent an outbreak in the area...',
                date: '8 October 2023',
                content: [`Hospital officials are concerned about a potential spike in flu cases as colder weather approaches. Free vaccinations are available at multiple locations across the county, including mobile clinics for rural communities. The program hopes to increase the county’s low vaccination rates by making the process more accessible.`],
                img: civLogo,
                county: 'Ohio County',
            },
            {
                title: 'Ohio County High Schools Begin Mental Health Awareness Program',
                summary: 'High schools in Ohio County have launched a mental health awareness program to address the rising number of students struggling with anxiety and depression...',
                date: '28 October 2023',
                content: [`The program includes mental health education, group therapy sessions, and one-on-one counseling for students. Counselors are collaborating with teachers to identify and support students who may be at risk. This initiative aims to reduce the stigma around mental health and improve student well-being.`],
                img: civLogo,
                county: 'Ohio County',
            },
            {
                title: 'Trimble County Launches Rural Health Initiative for Elderly Residents',
                summary: 'The county is rolling out a rural health initiative to address the needs of elderly residents, who face barriers to accessing healthcare...',
                date: '2 November 2023',
                content: [`The program includes mobile health units that will provide basic healthcare services, such as vaccinations and blood pressure screenings. Elderly residents often have difficulty traveling to healthcare facilities, so the mobile units aim to reduce this gap. Officials are focusing on preventive care to reduce hospitalizations and improve overall well-being.`],
                img: civLogo,
                county: 'Trimble County',
            },
            {
                title: 'Trimble County Schools Partner with Health Department for Vaccination Drive',
                summary: 'Trimble County schools have teamed up with the local health department to promote vaccinations for school-age children...',
                date: '13 October 2023',
                content: [`The campaign targets families with children entering kindergarten and middle school, where vaccination rates have dropped. Health officials are visiting schools to educate parents and ensure that children receive all required immunizations. School nurses have also been trained to administer vaccines on-site to make the process easier for families.`],
                img: civLogo,
                county: 'Trimble County',
            },
            {
                title: 'Trimble County Hosts Free Health Workshop on Opioid Addiction Prevention',
                summary: 'Community leaders in Trimble County are holding a series of workshops to educate the public on opioid addiction and prevention strategies...',
                date: '28 November 2023',
                content: [`The workshops will focus on the impact of opioid abuse on rural communities and how to recognize the early signs of addiction. Experts will discuss alternative pain management options and provide resources for those struggling with opioid use. Local officials hope the initiative will reduce opioid-related overdoses in the county.`],
                img: civLogo,
                county: 'Trimble County',
            },
            {
                title: 'Washington County Tackles High Rates of Respiratory Illness in Children',
                summary: 'A new public health campaign in Washington County is focusing on reducing respiratory illness among children, particularly asthma...',
                date: '9 October 2023',
                content: [`Health officials have noticed a significant uptick in childhood asthma cases, which they believe is linked to air quality issues. The county is working with schools to provide air purifiers and conduct education campaigns about reducing indoor allergens. Parents are being encouraged to schedule regular check-ups to monitor their children’s lung health.`],
                img: civLogo,
                county: 'Washington County',
            },
            {
                title: 'Washington County Hospital to Open Cancer Screening Center',
                summary: 'The county’s main hospital is opening a new cancer screening center to offer early detection services for residents...',
                date: '24 November 2023',
                content: [`The facility will provide screenings for breast, lung, and colorectal cancers, which are the leading causes of cancer-related deaths in the region. Health officials believe early detection can significantly improve survival rates for residents in rural areas. The center is also working with local organizations to offer transportation services for patients who need to travel to appointments.`],
                img: civLogo,
                county: 'Washington County',
            },
            {
                title: 'New Mental Health Program Launched in Washington County Schools',
                summary: 'A mental health initiative in Washington County is bringing licensed counselors into schools to address student mental health issues...',
                date: '6 December 2023',
                content: [`The program will offer one-on-one counseling, group therapy, and mental health education for students. This initiative aims to address the rising rates of anxiety and depression among young people in the county. School officials hope this will lead to better academic performance and a reduction in behavioral problems.`],
                img: civLogo,
                county: 'Washington County',
            },
            { // OK
                title: 'Oklahoma County Expands Public Health Labs to Research Infectious Diseases',
                summary: 'The county is investing in expanded lab facilities to study infectious diseases and improve pandemic preparedness...',
                date: '7 September 2023',
                content: [`Oklahoma County will open new laboratories aimed at detecting and preventing future infectious disease outbreaks. These state-of-the-art facilities will focus on diseases like influenza, COVID-19, and antibiotic-resistant bacteria. Health officials say the labs will improve the county’s ability to respond quickly to public health emergencies.`],
                img: civLogo,
                county: 'Oklahoma County',
            },
            {
                title: 'Oklahoma County Starts Health Literacy Program for Underserved Communities',
                summary: 'A new public health initiative aims to improve health literacy in low-income and minority populations...',
                date: '20 November 2023',
                content: [`The county’s health department is rolling out a series of workshops to teach residents about managing chronic diseases, understanding medications, and navigating the healthcare system. The program targets underserved communities where health disparities are the greatest. Health officials hope that increasing health literacy will lead to better health outcomes and reduce hospitalizations.`],
                img: civLogo,
                county: 'Oklahoma County',
            },
            {
                title: 'Biotech Hub Planned for Oklahoma County to Spur Innovation in Medicine',
                summary: 'A new biotech hub is in the works, designed to attract research firms specializing in healthcare and pharmaceuticals...',
                date: '18 December 2023',
                content: [`The biotech hub will feature state-of-the-art labs, research facilities, and incubators for startups in the healthcare industry. The project is expected to create hundreds of jobs and position Oklahoma County as a leader in biotech research. Local universities are also partnering with the hub to offer internships and job opportunities for students studying biotechnology.`],
                img: civLogo,
                county: 'Oklahoma County',
            },
            {
                title: 'McClain County Introduces Mobile Health Clinics to Combat Rural Health Disparities',
                summary: ' New mobile health clinics are rolling out across McClain County, bringing essential health services to rural communities...',
                date: '3 November 2023',
                content: [`Residents of McClain County, many of whom live far from healthcare facilities, can now access free health check-ups and screenings. The mobile units will focus on preventive care, including blood pressure checks, vaccinations, and diabetes screenings. Health officials believe this could help address the county’s high rates of heart disease and diabetes.`],
                img: civLogo,
                county: 'McClain County',
            },
            {
                title: 'New Health Study Investigates Cancer Rates in McClain County',
                summary: 'Researchers are conducting a long-term study to investigate why McClain County has higher-than-average cancer rates...',
                date: '15 October 2023',
                content: [`The study, led by the Oklahoma Department of Health, is focusing on environmental factors such as water quality and industrial pollution. Preliminary data suggests a possible link between local chemical plants and the spike in cancer cases. Officials hope the study will lead to better regulations and preventive measures to protect residents.`],
                img: civLogo,
                county: 'McClain County',
            },
            { // KS
                title: 'McClain County Schools to Teach New Health and Science Curriculum',
                summary: 'A revamped science curriculum focusing on health education is being introduced in McClain County schools...',
                date: '29 August 2023',
                content: [`The new curriculum will include lessons on public health, mental health awareness, and nutrition. Teachers will also educate students about emerging health threats like pandemics and antibiotic resistance. The goal is to prepare students for careers in healthcare and science, while promoting healthier lifestyle choices.`],
                img: civLogo,
                county: 'McClain County',
            },
            {
                title: 'Shawnee County Implements New Telehealth Services to Reach Rural Patients',
                summary: 'Shawnee County is introducing new telehealth services to expand healthcare access for residents in rural areas...',
                date: '13 January 2024',
                content: [`Telehealth services will provide virtual consultations for patients dealing with chronic illnesses, mental health concerns, and routine check-ups. Healthcare providers aim to reduce travel burdens and ensure rural patients can access timely care. The county expects this service will improve health outcomes, especially during flu season and other high-demand periods."`],
                img: civLogo,
                county: 'Shawnee County',
            },
            {
                title: 'Shawnee County Expands Substance Abuse Recovery Program for Youth',
                summary: 'A new expansion of Shawnee County’s youth substance abuse recovery program is focusing on early intervention and long-term support...',
                date: '24 February 2024',
                content: [`The initiative includes educational outreach, counseling services, and peer support groups for teens at risk of drug and alcohol abuse. School districts and community centers are partnering with public health officials to ensure the program reaches as many youths as possible. The goal is to reduce the rising rates of substance abuse and provide pathways to recovery for Shawnee County’s younger population.`],
                img: civLogo,
                county: 'Shawnee County',
            },
            {
                title: 'Shawnee County Hospitals Prepare for Expanded Services in Anticipation of Aging Population',
                summary: 'With a growing elderly population, Shawnee County hospitals are expanding services related to geriatric care and chronic disease management...',
                date: '8 March 2024',
                content: [`Additional resources are being dedicated to memory care, cardiovascular health, and arthritis management. Public health officials are working with caregivers to offer more in-home support for seniors with mobility challenges. The hospital expansion is aimed at preventing hospital readmissions and improving quality of life for the elderly.`],
                img: civLogo,
                county: 'Shawnee County',
            },
            {
                title: 'Surry County Tackles Opioid Crisis with New Treatment Facilities',
                summary: 'Surry County has opened two new treatment centers to provide support for individuals struggling with opioid addiction...',
                date: '11 September 2023',
                content: [`The centers will offer medication-assisted treatment (MAT), counseling, and rehabilitation services. Public health officials hope these centers will reduce the high number of overdose deaths in the county. The initiative is part of North Carolina's broader effort to address the opioid crisis in rural communities.`],
                img: civLogo,
                county: 'Surry County',
            },
            {
                title: 'Surry County Launches Public Health Campaign to Combat Childhood Asthma',
                summary: 'Health officials in Surry County are launching a new campaign to raise awareness about childhood asthma and reduce its prevalence...',
                date: '20 October 2023',
                content: [`The campaign includes free asthma screenings and educational workshops for parents and caregivers. Local schools are also participating by ensuring that air quality is monitored and allergens are reduced. The county hopes this initiative will help children manage their asthma symptoms and reduce emergency room visits.`],
                img: civLogo,
                county: 'Surry County',
            },
            {
                title: 'New Biotech Lab to Open in Surry County, Focused on Infectious Disease Research',
                summary: 'A new biotechnology lab focused on infectious disease research is set to open in Surry County...',
                date: '4 December 2023',
                content: [`The lab will collaborate with local universities to study diseases like COVID-19 and antibiotic-resistant bacteria. Researchers will focus on developing new vaccines and treatments to prevent future pandemics. This project could attract scientific talent to the area and improve the county’s economy.`],
                img: civLogo,
                county: 'Surry County',
            },
            { // NM
                title: 'Roosevelt County Launches New Vaccination Awareness Campaign',
                summary: 'Public health officials in Roosevelt County have kicked off a campaign to boost vaccination rates amid concerns over preventable diseases...',
                date: '5 October 2023',
                content: [`Health workers are going door-to-door in rural areas, educating residents about the importance of vaccinations. The initiative, funded by a state health grant, comes after a recent outbreak of measles in neighboring counties. Local clinics are offering free vaccinations, and the public health department aims to increase immunization rates by 20% over the next year."`],
                img: civLogo,
                county: 'Roosevelt County',
            },
            {
                title: 'New Biotech Firm to Research Agricultural Health Solutions in Roosevelt County',
                summary: 'A biotech startup has set up a research facility in Roosevelt County, aiming to develop health solutions for livestock...',
                date: '20 September 2023',
                content: [`The new firm, AgriHealth Bio, will focus on developing vaccines and treatments for diseases affecting cattle and sheep in the region. Researchers hope to address common livestock diseases that have devastated farms over the last decade. This project could boost the local economy and strengthen the county’s agricultural sector.`],
                img: civLogo,
                county: 'Roosevelt County',
            },
            {
                title: 'Mental Health Services Expand in Roosevelt County with Telemedicine Program',
                summary: 'Telemedicine is making mental health care more accessible in rural Roosevelt County, where services have been limited...',
                date: '12 December 2023',
                content: [`Through the New Mexico Telehealth Alliance, residents of Roosevelt County can now access mental health professionals via video consultations. This initiative is critical in addressing the growing mental health crisis, particularly for farmers and rural youth. Health officials hope that by providing remote access, they can reduce suicide rates and improve overall well-being in the community.`],
                img: civLogo,
                county: 'Roosevelt County',
            },
            {
                title: 'Valencia County Expands Access to Free Diabetes Screenings',
                summary: 'Public health clinics in Valencia County are offering free diabetes screenings to combat the rising prevalence of the disease...',
                date: '15 November 2023',
                content: [`The initiative focuses on underserved communities, where access to healthcare has historically been limited. Local health officials are working with primary care providers to ensure early detection and treatment of Type 2 diabetes. Organizers hope that widespread testing will reduce the long-term health complications associated with the disease.`],
                img: civLogo,
                county: 'Valencia County',
            },
            {
                title: 'New Clinical Trial for Heart Disease Drugs to Begin in Valencia County',
                summary: 'A biotech company is launching a clinical trial for a new heart disease medication in Valencia County...',
                date: '27 December 2023',
                content: [`The trial will involve hundreds of local participants at high risk for cardiovascular disease. Researchers hope the new drug, which targets cholesterol levels, will provide more effective treatment options for patients. The trial is part of a nationwide effort to find new solutions for heart disease, the leading cause of death in the United States.`],
                img: civLogo,
                county: 'Valencia County',
            },
            {
                title: 'Minnesota',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in...',
                date: "31 October 2023",
                img: storyImg,
                country: 'United States',
                state: 'Minnesota',
            },
            {
                title: 'Kings County 2',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in...',
                date: "31 October 2023",
                img: storyImg,
                county: "Kings County",
                country: 'United States',
                state: 'Maine',
            },
            {
                title: 'Kings County 3',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in...',
                date: "31 October 2023",
                img: storyImg,
                county: "Kings County",
                country: 'United States',
                state: 'Maine',
            },
            {
                title: 'Kings County 4',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in...',
                date: "31 October 2023",
                img: storyImg,
                county: "Kings County",
                country: 'United States',
                state: 'Maine',
            },
        ],
        state: [
            {
                title: 'Hawaii',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                state: "Hawaii",
            },
            {
                title: 'Hawaii 2',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                state: "Hawaii",
            },
            {
                title: 'Hawaii 3',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                state: "Hawaii",
            },
            {
                title: 'Maryland Department of Health Announces..',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                state: "Maryland",
            },
            {
                title: 'Mpox Cases Trending in Right Direction,..',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in...',
                date: "31 October 2023",
                img: storyImg,
                state: "Maryland",
            },
            {
                title: 'Maryland Delegation Announces Nearly $7..',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in...',
                date: "31 October 2023",
                img: storyImg,
                state: "Maryland",
            },
            {
                title: 'Nevada Launches Statewide Initiative to Combat Opioid Addiction',
                summary: 'Nevada is implementing a comprehensive statewide initiative to address the opioid crisis through prevention, treatment, and support.',
                date: '10 January 2024',
                content: [`The initiative includes expanded access to addiction treatment programs, naloxone distribution, and public education campaigns. New partnerships with local pharmacies will offer free opioid overdose prevention training. State officials aim to reduce overdose deaths and provide support for those in recovery.`],
                img: civLogo,
                state: 'Nevada',
            },
            {
                title: 'Nevada State Government Invests in Telemedicine Expansion to Reach Rural Areas',
                summary: 'The Nevada state government is increasing investment in telemedicine to improve healthcare access for residents in remote areas.',
                date: '22 February 2024',
                content: [`Telemedicine services will include virtual consultations, remote monitoring, and mental health support. New funding will help build the necessary infrastructure and provide training for healthcare providers. The expansion aims to reduce disparities in healthcare access between urban and rural communities.`],
                img: civLogo,
                state: 'Nevada',
            },
            {
                title: 'Nevada Introduces New Public Health Campaign to Address Diabetes',
                summary: 'A new statewide public health campaign in Nevada aims to tackle rising diabetes rates through education and prevention.',
                date: '5 March 2024',
                content: [`The campaign focuses on promoting healthy eating, physical activity, and regular health screenings. Public health officials are partnering with community organizations to provide resources and support. The goal is to improve early detection and management of diabetes across the state.`],
                img: civLogo,
                state: 'Nevada',
            },
            {
                title: 'Massachusetts Launches Statewide Mental Health Awareness Program',
                summary: 'Massachusetts is rolling out a statewide mental health awareness program to improve understanding and support for mental health issues.',
                date: '15 January 2024',
                content: [`The program includes public service announcements, school-based mental health education, and community workshops. Efforts will focus on reducing stigma and increasing access to mental health services. State officials are collaborating with mental health organizations to provide resources and support.`],
                img: civLogo,
                state: 'Massachusetts',
            },
            {
                title: 'Massachusetts Expands Access to Substance Abuse Treatment Across the State',
                summary: 'Massachusetts is expanding substance abuse treatment services to address the growing addiction crisis statewide.',
                date: '28 February 2024',
                content: [`New treatment centers will offer comprehensive care, including detoxification, counseling, and rehabilitation. The expansion aims to provide more accessible and effective treatment options for individuals struggling with addiction. State funding will also support outreach and education efforts to prevent substance abuse.`],
                img: civLogo,
                state: 'Massachusetts',
            },
            {
                title: 'Massachusetts State Government Introduces New Initiative for Preventing Chronic Diseases',
                summary: 'The Massachusetts state government is introducing a new initiative aimed at preventing chronic diseases through lifestyle interventions.',
                date: '10 March 2024',
                content: [`The initiative includes statewide programs focused on nutrition, physical activity, and regular health check-ups. Partnerships with local health organizations will provide resources and support for healthy living. The goal is to reduce the incidence of chronic diseases such as heart disease, diabetes, and obesity.`],
                img: civLogo,
                state: 'Massachusetts',
            },
            {
                title: 'New Jersey Launches Statewide Campaign to Reduce Vaccine Hesitancy',
                summary: 'New Jersey is launching a statewide campaign to address vaccine hesitancy and increase vaccination rates.',
                date: '18 January 2024',
                content: [`The campaign includes educational outreach, public service announcements, and community vaccination events. State health officials are working to provide accurate information and address common concerns about vaccines. The goal is to improve vaccination rates and prevent outbreaks of vaccine-preventable diseases.`],
                img: civLogo,
                state: 'New Jersey',
            },
            {
                title: 'New Jersey State Government Expands Access to Mental Health Services for Youth',
                summary: 'New Jersey is expanding mental health services for youth to address rising mental health issues among children and adolescents.',
                date: '1 February 2024',
                content: [`New programs will offer counseling, therapy, and support groups in schools and community centers. The initiative includes training for educators and parents to better support children’s mental health needs. State officials aim to improve mental health outcomes and reduce the stigma associated with mental health challenges.`],
                img: civLogo,
                state: 'New Jersey',
            },
            {
                title: 'New Jersey Launches Statewide Initiative to Improve Access to Diabetes Care',
                summary: 'New Jersey is implementing a statewide initiative to enhance access to diabetes care and management.',
                date: '12 March 2024',
                content: [`The initiative includes expanded diabetes education programs, free screenings, and support for diabetes management. Healthcare providers will work to improve patient access to necessary medications and supplies. State officials hope the initiative will lead to better diabetes control and reduce related health complications.`],
                img: civLogo,
                state: 'New Jersey',
            },
            {
                title: 'California Introduces Statewide Program to Address Mental Health Needs in Schools',
                summary: 'California is introducing a new statewide program to enhance mental health services and support in schools.',
                date: '10 January 2024',
                content: [`The program includes the implementation of mental health counselors in schools, student wellness programs, and training for teachers. The initiative aims to provide early intervention and support for students facing mental health challenges. State officials believe this will improve student well-being and academic performance.`],
                img: civLogo,
                state: 'California',
            },
            {
                title: 'California Expands Access to Preventive Healthcare Services for Low-Income Residents',
                summary: 'California is expanding access to preventive healthcare services for low-income residents across the state.',
                date: '25 February 2024',
                content: [`The expansion includes free screenings, vaccinations, and health education programs. Partnerships with community health centers will help reach underserved populations. The goal is to reduce health disparities and improve overall health outcomes for low-income individuals.`],
                img: civLogo,
                state: 'California',
            },
            {
                title: 'California State Government Launches Initiative to Combat Rising Asthma Rates',
                summary: 'California is launching a statewide initiative to address rising asthma rates and improve asthma management.',
                date: '7 March 2024',
                content: [`The initiative includes expanded access to asthma medications, educational programs on asthma management, and environmental interventions. Public health officials will work with schools and communities to reduce asthma triggers and support affected individuals. The goal is to improve asthma control and reduce emergency room visits related to asthma.`],
                img: civLogo,
                state: 'California',
            },
            {
                title: 'New York State Launches Comprehensive Tobacco Cessation Program',
                summary: 'New York State is launching a comprehensive program to help residents quit smoking and reduce tobacco use.',
                date: '15 January 2024',
                content: [`The program includes free cessation aids, counseling services, and public education campaigns. Health officials are targeting high-risk populations and offering resources through community centers and healthcare providers. The goal is to lower smoking rates and prevent tobacco-related illnesses.`],
                img: civLogo,
                state: 'New York',
            },
            {
                title: 'New York State Expands Access to Mental Health Services in Rural Areas',
                summary: 'New York State is expanding mental health services to better serve residents in rural and underserved areas.',
                date: '22 February 2024',
                content: [`The expansion includes new telehealth services, mobile mental health units, and increased funding for local mental health providers. The initiative aims to address the shortage of mental health professionals in rural regions. State officials hope this will improve mental health care access and reduce mental health disparities.`],
                img: civLogo,
                state: 'New York',
            },
            {
                title: 'New York State Launches Initiative to Improve Nutrition and Combat Obesity',
                summary: 'New York State is launching an initiative to improve nutrition and combat rising obesity rates.',
                date: '1 March 2024',
                content: [`The initiative includes nutrition education programs, healthier school lunch options, and community fitness programs. Partnerships with local organizations will provide resources and support for healthier lifestyles. The goal is to reduce obesity rates and improve overall health across the state.`],
                img: civLogo,
                state: 'New York',
            },
            {
                title: 'Kentucky Launches Statewide Program to Address High Rates of Heart Disease',
                summary: 'Kentucky is introducing a statewide program to tackle high rates of heart disease through prevention and treatment.',
                date: '20 January 2024',
                content: [`The program includes free heart health screenings, public education on heart disease prevention, and support for healthy lifestyle changes. State health officials are working with local healthcare providers to offer resources and support. The initiative aims to reduce heart disease rates and improve cardiovascular health.`],
                img: civLogo,
                state: 'Kentucky',
            },
            {
                title: 'Kentucky Expands Access to Substance Abuse Treatment Services',
                summary: 'Kentucky is expanding its substance abuse treatment services to address the ongoing opioid crisis.',
                date: '5 February 2024',
                content: [`New treatment facilities will offer comprehensive care, including detoxification, counseling, and rehabilitation. The expansion includes increased funding for community-based programs and support services. State officials hope this will improve access to treatment and support recovery efforts.`],
                img: civLogo,
                state: 'Kentucky',
            },
            {
                title: 'Kentucky Introduces New Public Health Initiative to Address Tobacco Use',
                summary: 'Kentucky is launching a new public health initiative aimed at reducing tobacco use across the state.',
                date: '15 March 2024',
                content: [`The initiative includes public education campaigns, smoking cessation programs, and restrictions on tobacco advertising. Health officials are targeting high-risk populations and providing resources for quitting smoking. The goal is to reduce tobacco-related health issues and improve overall public health.`],
                img: civLogo,
                state: 'Kentucky',
            },
            {
                title: 'Oklahoma Launches Statewide Initiative to Improve Rural Healthcare Access',
                summary: 'Oklahoma is launching a statewide initiative to improve healthcare access in rural areas through new programs and resources.',
                date: '12 January 2024',
                content: [`The initiative includes expanded telehealth services, mobile health clinics, and increased funding for rural healthcare providers. State officials aim to reduce healthcare disparities and improve access to essential services. The goal is to enhance healthcare delivery and support rural communities.`],
                img: civLogo,
                state: 'Oklahoma',
            },
            {
                title: 'Oklahoma State Government Invests in Diabetes Prevention and Management Programs',
                summary: 'Oklahoma is investing in programs aimed at preventing and managing diabetes across the state.',
                date: '27 February 2024',
                content: [`Programs will focus on education, early detection, and support for diabetes management. State health officials are working with community organizations to provide resources and support. The initiative aims to reduce diabetes rates and improve health outcomes for those affected.`],
                img: civLogo,
                state: 'Oklahoma',
            },
            {
                title: 'Oklahoma Launches Public Health Campaign to Address Rising Obesity Rates',
                summary: 'Oklahoma is introducing a statewide public health campaign to tackle rising obesity rates through education and intervention.',
                date: '8 March 2024',
                content: [`The campaign includes nutrition education, physical activity programs, and support for healthy lifestyle changes. Partnerships with schools and community organizations will provide resources and promote healthy behaviors. The goal is to reduce obesity rates and improve overall health.`],
                img: civLogo,
                state: 'Oklahoma',
            },
            {
                title: 'Kansas Launches Initiative to Improve Rural Health Services',
                summary: 'Kansas is launching a new initiative aimed at enhancing healthcare services in rural areas across the state.',
                date: '18 January 2024',
                content: [`The initiative includes funding for rural health clinics, telemedicine services, and workforce development. State health officials are focusing on improving access to care and addressing health disparities in rural communities. The goal is to ensure residents in underserved areas receive timely and quality healthcare.`],
                img: civLogo,
                state: 'Kansas',
            },
            {
                title: 'Kansas Expands Substance Abuse Treatment and Prevention Programs',
                summary: 'Kansas is expanding its substance abuse treatment and prevention programs to address the growing addiction crisis.',
                date: '2 February 2024',
                content: [`New programs will offer comprehensive treatment options, including counseling, detoxification, and support services. The expansion includes increased funding for community outreach and prevention efforts. State officials aim to improve access to treatment and reduce substance abuse rates.`],
                img: civLogo,
                state: 'Kansas',
            },
            {
                title: 'Kansas Introduces Statewide Diabetes Prevention and Management Initiative',
                summary: 'Kansas is introducing a statewide initiative to address diabetes through prevention and management programs.',
                date: '10 March 2024',
                content: [`The initiative includes public education campaigns, free health screenings, and support for diabetes management. Partnerships with local health organizations will provide resources and support for individuals at risk of diabetes. The goal is to improve diabetes care and reduce the incidence of diabetes-related complications.`],
                img: civLogo,
                state: 'Kansas',
            },
            {
                title: 'New Mexico Launches Initiative to Improve Rural Health Services',
                summary: 'Kansas is launching a new initiative aimed at enhancing healthcare services in rural areas across the state.',
                date: '18 January 2024',
                content: [`The initiative includes funding for rural health clinics, telemedicine services, and workforce development. State health officials are focusing on improving access to care and addressing health disparities in rural communities. The goal is to ensure residents in underserved areas receive timely and quality healthcare.`],
                img: civLogo,
                state: 'New Mexico',
            },
            {
                title: 'New Mexico Expands Substance Abuse Treatment and Prevention Programs',
                summary: 'Kansas is expanding its substance abuse treatment and prevention programs to address the growing addiction crisis.',
                date: '2 February 2024',
                content: [`New programs will offer comprehensive treatment options, including counseling, detoxification, and support services. The expansion includes increased funding for community outreach and prevention efforts. State officials aim to improve access to treatment and reduce substance abuse rates.`],
                img: civLogo,
                state: 'New Mexico',
            },
            {
                title: 'New Mexico Introduces Statewide Diabetes Prevention and Management Initiative',
                summary: 'Kansas is introducing a statewide initiative to address diabetes through prevention and management programs.',
                date: '10 March 2024',
                content: [`The initiative includes public education campaigns, free health screenings, and support for diabetes management. Partnerships with local health organizations will provide resources and support for individuals at risk of diabetes. The goal is to improve diabetes care and reduce the incidence of diabetes-related complications.`],
                img: civLogo,
                state: 'New Mexico',
            }
            
        ],
        country: [
                {
                    title: 'United States Launches National Campaign to Combat Mental Health Crisis',
                    summary: 'The United States is rolling out a nationwide campaign aimed at addressing the mental health crisis with a focus on prevention, treatment, and support.',
                    date: '5 January 2024',
                    content: [`The campaign will include increased funding for mental health services, public education initiatives, and expanded access to counseling and support groups. Efforts will focus on reducing stigma and improving access to care, particularly in underserved communities. The goal is to provide comprehensive support and improve mental health outcomes across the nation.`],
                    img: civLogo,
                    country: 'United States',
                },
                {
                    title: 'US Government Introduces New National Strategy to Combat Diabetes Epidemic',
                    summary: 'The US government has unveiled a new national strategy to tackle the rising rates of diabetes through prevention and management initiatives.',
                    date: '20 February 2024',
                    content: [`The strategy includes expanded access to diabetes education programs, free health screenings, and funding for research into better treatment options. Public health officials will work with healthcare providers to improve early detection and management of diabetes. The initiative aims to reduce the burden of diabetes and related complications nationwide.`],
                    img: civLogo,
                    country: 'United States',
                },
                {
                    title: 'National Program to Improve Access to Healthcare for Low-Income Families Announced',
                    summary: 'The US has announced a new national program designed to enhance healthcare access for low-income families across the country.',
                    date: '1 March 2024',
                    content: [`The program will include subsidies for health insurance, expanded eligibility for public health programs, and increased funding for community health centers. Efforts will focus on reducing healthcare disparities and improving access to essential services for underserved populations. The goal is to improve health outcomes and reduce financial barriers to care.`],
                    img: civLogo,
                    country: 'United States',
                },
                {
                    title: 'New C.D.C. Director Seeks to Foster Trust...',
                    summary: 'Five months into her tenure at the Centers for Disease Control and Prevention, Dr. Mandy Cohen is trying to put a human face on public health',
                    date: '15 December 2023',
                    content: [
                        `Dr. Mandy K. Cohen dropped by the Fox affiliate in Dallas in November, just days after the governor of Texas signed a law barring private employers from requiring Covid-19 shots. If she thought promoting vaccination would be a tough sell in a ruby-red state, Dr. Cohen, the new director of the Centers for Disease Control and Prevention, did not give any indication.`,
                        `“I’m not just the C.D.C. director, I’m also a mom,” she said cheerily, noting on live television that her daughters, 9 and 11, had already received the latest Covid and flu shots. She added, “So I wouldn’t recommend something for the American people I wouldn’t recommend for my own family.”`,
                        `It was the kind of stock phrase that Dr. Cohen has repeatedly invoked as she pursues a task that some public health experts fear is impossible: restoring Americans’ faith in public health, and in her battered agency. Five months into her tenure, with the Covid public health emergency officially over, the C.D.C.’s new leader is relentlessly on message.`,
                        `Americans’ trust in the agency, and in science more broadly, was badly damaged by the coronavirus pandemic, and the loss of faith is particularly pronounced among Republicans. In a recent survey by the Pew Research Center, 38 percent of Republicans said they had little or no confidence in scientists to act in the public’s best interests, up from 14 percent in April 2020.`
                    ],
                    img: CDCStory,
                    country: "United States"
                },
                {
                    title: 'US Launches Nationwide Campaign to Promote Vaccination and Prevent Outbreaks',
                    summary: 'A new nationwide campaign is being launched in the US to increase vaccination rates and prevent outbreaks of vaccine-preventable diseases.',
                    date: '15 March 2024',
                    content: [`The campaign includes public service announcements, mobile vaccination clinics, and partnerships with local health organizations. The initiative aims to address vaccine hesitancy and improve vaccination coverage, particularly in high-risk communities. The goal is to enhance public health and prevent the spread of infectious diseases.`],
                    img: civLogo,
                    country: 'United States',
                },
                {
                    title: 'United States to Implement National Obesity Prevention Program',
                    summary: 'The United States is introducing a national obesity prevention program to address rising obesity rates through education and intervention.',
                    date: '5 April 2024',
                    content: [`The program includes nutrition education, physical activity initiatives, and community-based wellness programs. Public health officials are working with schools and local organizations to promote healthy lifestyle choices. The goal is to reduce obesity rates and improve overall health across the country.`],
                    img: civLogo,
                    country: 'United States',
                },
                {
                    title: 'Global Health Organization Launches Worldwide Initiative to Combat Antimicrobial Resistance',
                    summary: 'The Global Health Organization is launching a worldwide initiative to address antimicrobial resistance through coordinated efforts and public health strategies.',
                    date: '10 January 2024',
                    content: [`The initiative includes global surveillance of antibiotic use, public education campaigns, and improved infection control practices. Efforts will focus on reducing unnecessary antibiotic use and promoting responsible prescribing. The goal is to combat the rise of antimicrobial resistance and ensure effective treatment options for infectious diseases worldwide.`],
                    img: civLogo,
                    country: 'Global',
                },
                {
                    title: 'WHO Announces Global Initiative to Improve Access to Clean Water and Sanitation',
                    summary: 'The World Health Organization has announced a global initiative aimed at improving access to clean water and sanitation in developing countries.',
                    date: '18 February 2024',
                    content: [`The initiative includes funding for water infrastructure projects, public health education, and sanitation programs. The goal is to reduce waterborne diseases and improve overall health outcomes in regions with inadequate access to clean water and sanitation facilities. Efforts will focus on both urban and rural areas to ensure widespread impact.`],
                    img: civLogo,
                    country: 'Global',
                },
                {
                    title: 'European Union Launches Program to Combat Rising Rates of Antibiotic Resistance',
                    summary: 'The European Union is launching a new program to address rising antibiotic resistance through research, policy changes, and public health initiatives.',
                    date: '22 March 2024',
                    content: [`The program includes funding for research into new antibiotics, public education on responsible antibiotic use, and stricter regulations on antibiotic prescriptions. Efforts will focus on reducing the spread of resistant infections and improving treatment options. The goal is to ensure effective antibiotics are available for future generations.`],
                    img: civLogo,
                    country: 'European Union',
                },
            
            
            {
                title: 'Frostburg Area to Issue Mask Mandate',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
                country: 'United States',
            },
            {
                title: 'France Produce Company Recalls Fruit Amid List...',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in...',
                date: '20 November 2023',
                img: storyImg,
                country: "France"
            },
        ],
        global: [
            {
                title: 'Sweden Reports Rise in Monkeypox Cases Amid Ongoing Outbreak',
                summary: 'Sweden has seen an increase in monkeypox cases, prompting new public health measures to control the outbreak.',
                date: '15 January 2024',
                content: [`Sweden's public health authorities have reported a surge in monkeypox cases, leading to increased surveillance and vaccination efforts. Health officials are working to track the spread of the virus and provide resources to affected communities. The outbreak has highlighted the need for global cooperation to manage emerging infectious diseases.`],
                img: civLogo,
                country: 'Sweden',
            },
            {
                title: 'New COVID-19 Variant Causes Concern in South Africa',
                summary: 'South Africa is monitoring a new COVID-19 variant that shows signs of increased transmissibility and potential vaccine resistance.',
                date: '25 January 2024',
                content: [`The new variant has been identified as causing a rise in COVID-19 cases in South Africa. Public health officials are conducting studies to understand its impact on vaccine efficacy and transmission rates. The country is enhancing its response with booster vaccination campaigns and public health advisories.`],
                img: civLogo,
                country: 'South Africa',
            },
            {
                title: 'Global Health Organizations Urge Increased Vaccination Efforts to Combat Measles Outbreaks',
                summary: 'International health organizations are calling for increased vaccination efforts to address rising measles outbreaks in various countries.',
                date: '5 February 2024',
                content: [`Measles outbreaks have been reported in multiple regions worldwide, including parts of Europe and Asia. Health organizations are stressing the importance of vaccination to prevent further spread. Campaigns are being launched to increase vaccination coverage and address vaccine hesitancy.`],
                img: civLogo,
                country: 'Global',
            },
            {
                title: 'UN Reports Surge in Malaria Cases in Sub-Saharan Africa',
                summary: 'The United Nations reports a significant increase in malaria cases in Sub-Saharan Africa, highlighting the need for renewed prevention efforts.',
                date: '12 February 2024',
                content: [`Sub-Saharan Africa has experienced a rise in malaria cases due to various factors, including disrupted health services and climate changes. The UN is coordinating with local health authorities to enhance prevention measures, including mosquito net distribution and anti-malarial treatments.`],
                img: civLogo,
                country: 'Sub-Saharan Africa',
            },
            {
                title: 'Japan Launches Nationwide Campaign to Improve Mental Health Awareness',
                summary: 'Japan is launching a nationwide campaign aimed at improving mental health awareness and support services.',
                date: '20 February 2024',
                content: [`The campaign includes public education initiatives, increased funding for mental health services, and community support programs. The goal is to address rising mental health issues and provide better support for individuals experiencing mental health challenges.`],
                img: civLogo,
                country: 'Japan',
            },
            {
                title: 'WHO Issues Global Alert for Increased Risk of Dengue Fever',
                summary: 'The World Health Organization has issued a global alert due to an increased risk of dengue fever outbreaks in tropical regions.',
                date: '1 March 2024',
                content: [`Dengue fever cases are on the rise in tropical regions, with increased transmission rates reported in parts of Asia and the Americas. The WHO is advising countries to enhance mosquito control measures and public education efforts to prevent outbreaks and reduce the burden of the disease.`],
                img: civLogo,
                country: 'Global',
            },
            {
                title: 'Brazil Reports Record Number of Chikungunya Cases in 2024',
                summary: 'Brazil has reported a record number of Chikungunya cases this year, prompting new public health measures to combat the outbreak.',
                date: '10 March 2024',
                content: [`The outbreak of Chikungunya in Brazil has reached unprecedented levels, with significant impacts on public health. Health authorities are implementing mosquito control measures and expanding access to diagnostic and treatment services. The goal is to reduce transmission and manage the health crisis effectively.`],
                img: civLogo,
                country: 'Brazil',
            },
            {
                title: 'Australia Expands COVID-19 Testing and Vaccination Efforts Amid New Surge',
                summary: 'Australia is expanding its COVID-19 testing and vaccination efforts in response to a new surge in cases.',
                date: '18 March 2024',
                content: [`The country is ramping up its COVID-19 testing capacity and vaccination drive to manage a recent surge in cases. New testing centers are being established, and vaccination clinics are increasing their hours to meet demand. Public health officials are also emphasizing the importance of booster doses.`],
                img: civLogo,
                country: 'Australia',
            },
            {
                title: 'India Implements New Tuberculosis Control Program to Reduce Cases',
                summary: 'India is launching a new tuberculosis control program aimed at reducing the number of TB cases and improving treatment outcomes.',
                date: '25 March 2024',
                content: [`The new program includes expanded access to diagnostic services, improved treatment protocols, and community health education. Health officials are focusing on high-burden areas to enhance TB control efforts and ensure better management of the disease.`],
                img: civLogo,
                country: 'India',
            },
            {
                title: 'Global Health Experts Warn of Rising Non-Communicable Diseases',
                summary: 'Global health experts are warning about the increasing prevalence of non-communicable diseases (NCDs) linked to lifestyle changes and urbanization.',
                date: '1 April 2024',
                content: [`The rise in non-communicable diseases such as heart disease, diabetes, and cancer is attributed to changes in diet, physical activity, and urban living conditions. Experts are calling for global efforts to promote healthier lifestyles and preventive measures to combat the growing NCD burden.`],
                img: civLogo,
                country: 'Global',
            },
            {
                title: 'France',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
            },
            {
                title: 'Brazil',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
            },
            {
                title: 'Why Salmonella Makes So Many People Sick',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue...',
                date: '23 December 2023',
                img: civLogo,
            },
            {
                title: 'Measles Cases Climb Across the Globe',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in...',
                date: '16 November 2023',
                img: storyImg,
            },
            {
                title: 'Gonorrhea is Becoming Drug Resistant...',
                summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in...',
                date: '10 November 2023',
                img: storyImg,
            },
            {
                title: 'New C.D.C. Director Seeks to Foster Trust...',
                summary: 'Five months into her tenure at the Centers for Disease Control and Prevention, Dr. Mandy Cohen is trying to put a human face on public health',
                date: '15 December 2023',
                content: [
                    `Dr. Mandy K. Cohen dropped by the Fox affiliate in Dallas in November, just days after the governor of Texas signed a law barring private employers from requiring Covid-19 shots. If she thought promoting vaccination would be a tough sell in a ruby-red state, Dr. Cohen, the new director of the Centers for Disease Control and Prevention, did not give any indication.`,
                    `“I’m not just the C.D.C. director, I’m also a mom,” she said cheerily, noting on live television that her daughters, 9 and 11, had already received the latest Covid and flu shots. She added, “So I wouldn’t recommend something for the American people I wouldn’t recommend for my own family.”`,
                    `It was the kind of stock phrase that Dr. Cohen has repeatedly invoked as she pursues a task that some public health experts fear is impossible: restoring Americans’ faith in public health, and in her battered agency. Five months into her tenure, with the Covid public health emergency officially over, the C.D.C.’s new leader is relentlessly on message.`,
                    `Americans’ trust in the agency, and in science more broadly, was badly damaged by the coronavirus pandemic, and the loss of faith is particularly pronounced among Republicans. In a recent survey by the Pew Research Center, 38 percent of Republicans said they had little or no confidence in scientists to act in the public’s best interests, up from 14 percent in April 2020.`
                ],
                img: CDCStory,
                country: "United States"
            },
        ],
    }

        const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        };

        const handleSearchClick = () => {
            setSearchMode(!searchMode);
            setSearchKeyword('');
        };
        const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
        };
        const parseDate = (dateString) => {
            
            const months = {
              January: 0, February: 1, March: 2, April: 3, May: 4, June: 5,
              July: 6, August: 7, September: 8, October: 9, November: 10, December: 11
            };
          
            const [day, month, year] = dateString.match(/(\d+) (\w+) (\d+)/).slice(1);
            const monthIndex = months[month];
            return new Date(year, monthIndex, day);
          };

        const filterStoriesByLocation = (stories, locationData) => {
            if (!stories || !locationData || !selectedCategory) {
                switch (selectedCategory) {
                    case 'county':
                        return fillerStories.county;
                    case 'state':
                        return fillerStories.state;
                    case 'country':
                        return fillerStories.country;
                    case 'global':
                        return fillerStories.global;
                    default:
                        return fillerStories.county;
                }
            }
    
            const county_name = locationData?.county_name?.split(',')[0]?.toLowerCase();
            const state = stateNameMapping[locationData?.state]?.toLowerCase();
            const country = locationData?.country?.toLowerCase();
            
            let filteredStories = stories.filter((story) => {
            const storyCounty = story.county?.toLowerCase();
            const storyState = story.state?.toLowerCase();
            const storyCountry = story.country?.toLowerCase();

            switch (selectedCategory) {
                case 'county':
                    return county_name && storyCounty && county_name === storyCounty;
                case 'state':
                    return (state && storyState && state === storyState);
                case 'country':
                    return storyCountry === "united states";
                case 'global':
                    return true;
                default:
                    return false;
            }
            });

            // if(filteredStories.county.length() === 0) {
            //     filteredStories = {...filteredStories, county:fillerStories.county};
            // }
            // if(filteredStories.state.length() === 0) {
            //     filteredStories = {...filteredStories, state:fillerStories.state};
            // }
            // if(filteredStories.country.length() === 0) {
            //     filteredStories = {...filteredStories, country:fillerStories.country};
            // }
            // if(filteredStories.global.length() === 0) {
            //     filteredStories = {...filteredStories, global:fillerStories.global};
            // }

            

            if(filteredStories.length === 0) {
                switch (selectedCategory) {
                    case 'county':
                        return fillerStories.county
                    case 'state':
                        return fillerStories.state
                    case 'country':
                        return fillerStories.country
                    case 'global':
                        return fillerStories.global
                    default:
                        return fillerStories.global
                }
            } else {
                return filteredStories;
            }
        };

    

        const filterStoriesBySearchAndDate = (story) => {
        const isKeywordMatch =
            !searchMode || searchKeyword.trim() === '' || story.title.toLowerCase().includes(searchKeyword.toLowerCase()) || story.summary.toLowerCase().includes(searchKeyword.toLowerCase());
    
            const isDateMatch =
            !dateMode || // If date mode is not active, consider it a match
            (!dateRange[0] || // If start date not selected, consider it a match
                (parseDate(story.date) >= dateRange[0] && parseDate(story.date) <= dateRange[1]));
        return isKeywordMatch && isDateMatch;
        };
        const handleCalendarOpen = () => {
            setDateMode(true);
        };
    
        const handleCalendarClose = () => {
            if (!startDate && !endDate) {
                setDateMode(false);
            }
        };

        const highlightSearchKeyword = (text) => {
            if (searchMode && searchKeyword.trim() !== '') {
                const regex = new RegExp(`(${searchKeyword.trim()})`, 'gi');
                return text.replace(regex, (match) => `<span class="highlight">${match}</span>`);
            }
            return text;
        };

        const handleDropdownToggle = () => {
            setShowDropdown(!showDropdown);
        };
    
        const handleSortOptionClick = (option) => {
            setSelectedSortOption(option);
            setShowDropdown(false);
        };


        
        const sortStories = (a, b) => {
            switch (selectedSortOption) {
                case 'newest':
                    return parseDate(b.date) - parseDate(a.date);
                case 'oldest':
                    return parseDate(a.date) - parseDate(b.date);
                default:
                    return 0;
            }
        };
        
    return (
    <div>
        <div className='scanHeader-bttnContainer'>
            {searchMode ? (
            <input
                type='text'
                value={searchKeyword}
                onChange={handleSearchInputChange}
                placeholder='Enter keywords...'
            />) : 
               null
            }
             <button onClick={handleSearchClick}><img id="scanSearchBttn" src={searchMode ? CloseBttn : searchIcon} alt='search'/></button>
            <button onClick={handleDropdownToggle}><img id="scanFilterBttn" src={filterIcon} alt='filter'/></button>
            {showDropdown && (
                    <div className="filterDropdown">
                        <button  className={selectedSortOption === 'newest' ? 'active' : ''} onClick={() => handleSortOptionClick('newest')}>Newest first</button>
                        <button  className={selectedSortOption === 'oldest' ? 'active' : ''}onClick={() => handleSortOptionClick('oldest')}>Oldest first</button>
                        <button  className={selectedSortOption === 'mostViewed' ? 'active' : ''}onClick={() => handleSortOptionClick('mostViewed')}>Most viewed</button>
                    </div>
                )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                    setDateRange(update);   
                }}
                onCalendarOpen={handleCalendarOpen}
                onCalendarClose={handleCalendarClose}
                isClearable={true}
                customInput={
                    <button className={startDate && endDate? 'flex pr-5' : ''} id='dateRangeBttn'>
                    <img id="scanCalendarBttn" src={calenderIcon} alt='calendar'/>
                    <div className='dateRangeWrapper'>
                        {startDate ? (
                        <div>
                            {startDate.getDate()} {startDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }) + ' - '}
                        </div>
                        ) : null}
                        {endDate ? (
                        <div>
                            {endDate.getDate()} {endDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
                        </div>
                        ) : null}
                    </div>
                    </button>
                }
                />
            </div>
        </div>
        <div>
            <div className={'categoryHeader' + (pathName === '/learnmore' ? ' diseaseScanCategoryColor' : ' emotionalScanCategoryColor')}>
                <div className={`scanCategory-bttn ${selectedCategory === 'county' ? 'categoryActive countyActive' : ''}`} onClick={() => {handleCategoryClick('county'); setExpandStory(false)}}>
                    <img className="categoryImg" src={scanCounty} alt='county'/>
                    <span>{getCategoryTitle("county") || "County"}</span>
                </div>
                <div className={`scanCategory-bttn ${selectedCategory === 'state' ? 'categoryActive stateActive' : ''}`} onClick={() => {handleCategoryClick('state'); setExpandStory(false)}}>
                    <img className="categoryImg" src={scanMaryland} alt='state'/>
                    <span>{getCategoryTitle("state") || 'State'}</span>
                </div>
                <div className={`scanCategory-bttn ${selectedCategory === 'country' ? 'categoryActive countryActive' : ''}`} onClick={() => {handleCategoryClick('country'); setExpandStory(false)}}>
                    <img className="categoryImg" src={scanUsa} alt='country'/>
                    <span> {getCategoryTitle("country") || "United States" }</span>
                </div>
                <div className={`scanCategory-bttn ${selectedCategory === 'global' ? 'categoryActive globalActive' : ''}`} onClick={() => {handleCategoryClick('global'); setExpandStory(false)}}>
                    <img className="categoryImg" src={scanGlobal} alt='global'/>
                    <span>Global</span>
                </div>
            </div>
            {selectedCategory === 'county' && !expandStory ?

            <div className="news-storyboard">
                {filterStoriesByLocation([...stories['county'], ...stories['state'], ...stories['country']], locationData)
                .filter(filterStoriesBySearchAndDate)
                .sort(sortStories).map((story, index) => (
                    <div className="story" key={index} onClick={() => {setExpandStory(true); setStory(story);}}>
                       <h3 dangerouslySetInnerHTML={{ __html: highlightSearchKeyword(story.title)}} />
                        <div className="story-content">
                            <div className='storyImgContainer'>
                                <img className="storyImages" src={story.img} alt="Story" />
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: highlightSearchKeyword(story.summary) }} />
                        </div>
                        <span className="date">{story.date}</span>
                    </div>
                ))}
            </div>
            : null}
            {selectedCategory === 'state' && !expandStory ? 
                <div className="news-storyboard">
                    {filterStoriesByLocation([...stories['county'], ...stories['state'], ...stories['country']], locationData)
                        .filter(filterStoriesBySearchAndDate)
                        .sort(sortStories).map((story, index) => (
                        <div className="story" key={index} onClick={() => {setExpandStory(true); setStory(story);}}>
                            <h3 dangerouslySetInnerHTML={{ __html: highlightSearchKeyword(story.title)}} />
                            <div className="story-content">
                                <div className='storyImgContainer'>
                                    <img className="storyImages" src={story.img} alt="Story" />
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: highlightSearchKeyword(story.summary) }} />
                            </div>
                            <span className="date">{story.date}</span>
                        </div>
                    ))}      
                </div> : null
            }
            {selectedCategory === 'country' && !expandStory ?
            <div className="news-storyboard">
                {filterStoriesByLocation(stories[selectedCategory], locationData)
                    .filter(filterStoriesBySearchAndDate)
                    .sort(sortStories).map((story, index) => (
                    <div className="story" key={index} onClick={() => {setExpandStory(true); setStory(story);}}>
                        <h3 dangerouslySetInnerHTML={{ __html: highlightSearchKeyword(story.title)}} />
                        <div className="story-content">
                            <div className='storyImgContainer'>
                                <img className="storyImages" src={story.img} alt="Story" />
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: highlightSearchKeyword(story.summary) }} />
                        </div>
                        <span className="date">{story.date}</span>
                    </div>
                ))}
            </div> : null}
            {selectedCategory === 'global' && !expandStory ?
            <div className="news-storyboard">
                {filterStoriesByLocation(stories[selectedCategory], locationData)
                    .filter(filterStoriesBySearchAndDate)
                    .sort(sortStories).map((story, index) => (
                    <div className="story" key={index} onClick={() => {setExpandStory(true); setStory(story);}}>
                        <h3 dangerouslySetInnerHTML={{ __html: highlightSearchKeyword(story.title)}} />
                        <div className="story-content">
                            <div className='storyImgContainer'>
                                <img className="storyImages" src={story.img} alt="Story" />
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: highlightSearchKeyword(story.summary) }} />
                        </div>
                        <span className="date">{story.date}</span>
                    </div>
                ))}
            </div> : null}
            {expandStory ? (
                <div className='expandedStory'>
                    <div className='expandedStory-header'>
                        <h2 className='expandedStory-title' dangerouslySetInnerHTML={{ __html: highlightSearchKeyword(story.title) }} />
                        <span className='expandedStory-date'>{story.date}</span>
                    </div>
                    <div className='expandedStory-content'>
                        {story.content ? story.content.map((content, index) => (
                            <p key={index} dangerouslySetInnerHTML={{ __html: highlightSearchKeyword(content) }} />
                        )) : <p dangerouslySetInnerHTML={{ __html: highlightSearchKeyword(story.summary) }} />}
                    </div>
                </div>
            ) : null}
            {
                expandStory ? 
                <div className='scanStoryNavigation'>
                    <button className="scanPrevBttn" onClick={() => {setExpandStory(false); setStory(null);}}><img src={CancelArrow} alt="left facing arrow"/>Back
                    </button>
                </div> : null
            }
        </div>
    </div>
    )
}


export default ScanComponent