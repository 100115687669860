import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import "../PublicHealth/index.css";
import {DetailHeader} from "./DetailHeader";
import ChevronLeft from "../../images/chevron-left-solid.svg";

function DomainDropdown({domain, tokenPayload, communityHealthInfo, changeExpanded, domainExpanded, changeDomainExpanded}){
    const domainDropdown = useRef();
    const [activeElements, setActiveElements] = useState([]);
    const handleHover = (element) => {
        setActiveElements((prevElements) => {
          if (!prevElements.includes(element)) {
            return [...prevElements, element];
          } else {
            return prevElements;
          }
        });
      };
    const handleSubdomain = (domain) =>{
        switch(domain){
            case "Safety": {
                console.log("safety");
                return(
                    <div>
                        <Link to={"/safetysubdomain"} state={communityHealthInfo} className="learnmore">
                            <h3 className="subdomainTitle">SAFETY</h3>
                        </Link>
                        <div className="subdomainSubheadings">
                            <h6 className="pageTitle">Hello Officer</h6>
                        </div>
                    </div>
                )
            }
            case "Health": {
                console.log("Health");
                return(
                    <div>
                        <Link to={"/healthsubdomain"} state={communityHealthInfo} tokenPayload={tokenPayload} className="learnmore">
                            <h5 className="domainTitle">HEALTH</h5>
                        </Link>
                        <div className="subdomainSubheadings">
                            <Link to={"/learnmore"} state={communityHealthInfo}>
                                <h6 className="pageTitle">Infectious Disease</h6>
                            </Link>
                            <Link to={"/emotionalhealth"} state={{
                                options: communityHealthInfo.options.emotional,
                                comingSoonOptions: communityHealthInfo.comingSoonOptions.emotional,
                                headerTitle: "Emotional Health",
                                minuteCardOneHeader: "Feelings Flow Tracker",
                                minuteCardOneText: "Identify, understand, and process your emotions",
                                minuteCardTwoHeader: "Boundary Builder",
                                minuteCardTwoText: "Understand your personal boundaries",
                                sayComponentText:
                                    "In addition to Reading, Writing and Arithmetic, should schools teach Relationships (how we relate to ourselves, others and situations)?",
                                saySectionLabel: "VIEW ACTIVITY AS",
                                tokenPayload,
                                }}>
                                <h6 className="pageTitle">Emotional Health</h6>
                            </Link>
                            <div
                                className="comingSoon"
                                onMouseOver={() => handleHover('wellness')}
                                onClick={() => handleHover('wellness')}
                            >
                                <h6 className="pageTitle">Wellness</h6>
                                <span style={{ display: activeElements.includes('wellness') ? 'block' : 'none' }}>Coming Soon!</span>
                            </div>
                            <div
                                className="comingSoon"
                                onMouseOver={() => handleHover('chronicHealth')}
                                onClick={() => handleHover('chronicHealth')}
                            >
                                <h6 className="pageTitle comingSoon">Chronic Health</h6>
                                <span style={{ display: activeElements.includes('chronicHealth') ? 'block' : 'none' }}>Coming Soon!</span>
                            </div>
                            
                        </div>
                    </div>
                )
            }
            case "Wealth": {
                return(
                    <div>
                        <Link to={"/wealthsubdomain"} state={communityHealthInfo} className="learnmore">
                            <h3 className="subdomainTitle">WEALTH</h3>
                        </Link>
                        <div className="subdomainSubheadings">
                            <h6 className="pageTitle">Subdomain heading 1</h6>
                        </div>
                    </div>
                )
            }
            case "Environment": {
                return(
                    <div>
                        <Link to={"/environmentsubdomain"} state={communityHealthInfo} className="learnmore">
                            <h3 className="subdomainTitle">ENVIRONMENT</h3>
                        </Link>
                        <div className="subdomainSubheadings">
                            <h6 className="pageTitle">You've Got the Power
                            </h6>
                        </div>
                    </div>
                )
            }
            case "Governance": {
                return(
                    <div>
                        <Link to={"/governancesubdomain"} state={communityHealthInfo} className="learnmore">
                            <h3 className="subdomainTitle">GOVERNANCE</h3>
                        </Link>
                        <div className="subdomainSubheadings">
                            <h6 className="pageTitle">Subdomain heading 1</h6>
                        </div>
                    </div>
                )
            }
            case "Social": {
                return(
                    <div>
                        <Link to={"/socialrelationssubdomain"} state={communityHealthInfo} className="learnmore">
                            <h3 className="subdomainTitle">SOCIAL RELATIONS</h3>
                        </Link>
                        <div className="subdomainSubheadings">
                            <h6 className="pageTitle">Subdomain heading 1</h6>
                        </div>
                    </div>
                )
            }
        }
    }

    return(
        <div className="domainDropdown" ref={domainDropdown} style={domainExpanded ? { display: "block" } : { display: "none" }}>
            <button onClick={e => {
                e.preventDefault();
                changeDomainExpanded(false);
                changeExpanded(true);
            }}>
                <img src={ChevronLeft} alt="chevron" className="chevronLeft"/>
                <h3 className="mainMenuText">Main Menu</h3>
            </button>
            <hr className="subdomainLine"/>
            {handleSubdomain(domain)}
        </div>
    )

}

export default DomainDropdown;