import React from 'react'
import './ShowGuidelines.css'
import {isLessThanFourMonths, isOlderThanSeventy} from "./SurveyHelper";
import CancelArrow from "../../../images/orange-left-arrow.svg";
export const ShowGuidelines = ({survey, surveyName, backButton}) => {
	const symptomDateUnderOne = survey.data["Q2.1b"];
	const symptomDateOverOne = survey.data["Q2.1f"];
	const dateOfSymptom = new Date(symptomDateUnderOne? symptomDateUnderOne : symptomDateOverOne);
	const watchSymptomsDate = new Date(dateOfSymptom.setDate(dateOfSymptom.getDate() + 15)).toLocaleDateString();
	const surveyGuidelines = {
		"Botulism": {
			"A": "Continue activies and movements as usual.",
			"B": "Do not feed honey to children less than 1 year old.",
			"C": "Take care to properly clean any open wounds until they are fully healed.",
			"D": "Follow proper food handling and preparation techniques. This is especially important if home-canning, preserving, or fermenting. Be sure to discard any canned goods that have been damaged.",
			"E": "You may try some home remedies to relieve constipation if there has been no bowel movement within the past 24 hours.",
			"F": "Eat small meals that do not have a lot of fat in them.",
			"G": "Drink fluids to prevent dehydration.",
			"H": "Rest if you feel tired.",
			"H2": "You can prevent wound botulism by not injecting illicit drugs. If you are ready to quit using, there are resources to help you succeed: call the nation help line 24/7 at 1-800-662-HELP (1-800-662-4357), find a treatment center near you, or find a doctor experienced in treating drug addiction. They may discuss different options that can help you quit, such as counseling, peer support groups, and medicine called buprenorphine. If you can't or don't want to stop using right now, don't share needles or works. Always use safe injection practices. CDC's HIV Risk Reduction Tool provides information on the safe injection practices you can do to protect yourself and others if you use needles. Reduce your chance of getting a serious illness by using clean needles and equipment and cleaning your skin before you inject.",
			"I": "Continue to monitor symptoms by taking this survey daily until " + watchSymptomsDate + ", and follow the resulting guidelines on what to do.",
			"JL1": "Until " + watchSymptomsDate + " Pay particular attention for the following symptoms of muscle paralysis -- if they develop, call 911 or seek emergency medical care: suddent loss of head control, difficulty breathing, weak cry that sounds different than usual, face showing less expression than usual, pupils that are slow to react to light, and drooping eyelids.",
			"JL2": "Until " + watchSymptomsDate + " Pay particular attention for the following symptoms of muscle paralysis -- if they develop, call 911 or seek emergency medical care: difficulty breathing, slurred speech, difficulty moving eyes, drooping eyelids, difficulty swallowing, double vision, and blurry vision.",
			"K": "See a doctor or seek medical attention.",
			"L": "Call 911 or seek emergency medical care.",
			"M": "If you have suspected food or drink source, bring it with you to your medical provider for sample analysis.",
			"N": "If symptoms start, return or worsen see a doctor or seek medical attention.",
			"P": "Continue to follow any treatment and/or orders given by medical personnel.",
		},
	}
	var guidelinesToDisplay = [];
	const getSurveyGuidelines = () => {
		//Part 2
		if (survey.data.hasOwnProperty("Q2.1a nwf") && survey.data["Q2.1a nwf"][0] === "none") {
			// guidelinesToDisplay.push(surveyGuidelines[surveyName][1]);
			guidelinesToDisplay.push(surveyGuidelines[surveyName]["A"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
		} else if(survey.data.hasOwnProperty("Q2.1c")){
			if(survey.data["Q2.1a nwf"].includes("A2.1a.ii") || survey.data["Q2.1a nwf"].includes("A2.1a.iii")|| survey.data["Q2.1a nwf"].includes("A2.1a.iv") || survey.data["Q2.1a nwf"].includes("A2.1a.v") || survey.data["Q2.1a nwf"].includes("A2.1a.vi") || survey.data["Q2.1a nwf"].includes("A2.1a.vii")){
				//guidelinesToDisplay.push(surveyGuidelines[surveyName][2]);
				guidelinesToDisplay.push(surveyGuidelines[surveyName]["L"].replace(".", " immediately."), surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
			} else if (survey.data["Q2.1c"] === "A2.1c.iii" || survey.data["Q2.1c"] === "A2.1c.iv" || survey.data["Q2.1c"] === "A2.1c.v") {
				if (survey.data.hasOwnProperty("isLessThanFourMonths") && survey.data.isLessThanFourMonths === true) {
					//guidelinesToDisplay.push(surveyGuidelines[surveyName][3]);
					guidelinesToDisplay.push(surveyGuidelines[surveyName]["K"].replace(".", " as soon as possible."), surveyGuidelines[surveyName]["JL1"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
				}else {
					//guidelinesToDisplay.push(surveyGuidelines[surveyName][3]);
					guidelinesToDisplay.push(surveyGuidelines[surveyName]["K"].replace(".", " as soon as possible."), surveyGuidelines[surveyName]["JL1"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"], surveyGuidelines[surveyName]["E"]);

				}
			} else {
				//No to "Were any of A2.1c.iii-v selected?"
				if(survey.data["Q2.1c"] === "A2.1c.ii"){
					//guidelinesToDisplay.push(surveyGuidelines[surveyName][4]);
					guidelinesToDisplay.push(surveyGuidelines[surveyName]["I"], surveyGuidelines[surveyName]["JL1"], surveyGuidelines[surveyName]["N"].replace(".", " as soon as possible."), surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
				} else {
					//guidelinesToDisplay.push(surveyGuidelines[surveyName][5]);
					guidelinesToDisplay.push(surveyGuidelines[surveyName]["JL1"], surveyGuidelines[surveyName]["N"].replace(".", " as soon as possible."), surveyGuidelines[surveyName]["A"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
				}
			}
		}
		if (survey.data.hasOwnProperty("Q2.1e nwf") && survey.data["Q2.1e nwf"][0] === "none") {
			// guidelinesToDisplay.push(surveyGuidelines[surveyName][1]);
			guidelinesToDisplay.push(surveyGuidelines[surveyName]["A"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
		}else if(survey.data.hasOwnProperty("Q2.1g")){
			if(survey.data["Q2.1e nwf"].includes("A2.1e.viii") || survey.data["Q2.1e nwf"].includes("A2.1e.ix")|| survey.data["Q2.1e nwf"].includes("A2.1e.x") || survey.data["Q2.1e nwf"].includes("A2.1e.xi") || survey.data["Q2.1e nwf"].includes("A2.1e.xii") || survey.data["Q2.1e nwf"].includes("A2.1e.xiii") || survey.data["Q2.1e nwf"].includes("A2.1e.xiv")){
				guidelinesToDisplay.push(surveyGuidelines[surveyName]["L"].replace(".", " immediately."), surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
			} else if(survey.data["Q2.1g"] === "A2.1g.v"){
				//guidelinesToDisplay.push(surveyGuidelines[surveyName][7]);
				guidelinesToDisplay.push(surveyGuidelines[surveyName]["K"].replace(".", " as soon as possible."), surveyGuidelines[surveyName]["JL2"], surveyGuidelines[surveyName]["F"], surveyGuidelines[surveyName]["G"], surveyGuidelines[surveyName]["H"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
			} else if(survey.data["Q2.1g"] === "A2.1g.ii" || survey.data["Q2.1g"] === "A2.1g.iii" || survey.data["Q2.1g"] === "A2.1g.iv"){
				if((survey.data["Q2.1e nwf"].includes("A2.1e.iv") || survey.data["Q2.1e nwf"].includes("A2.1e.v")) && (survey.data["Q2.1i"].includes("A2.1i.i") || survey.data["Q2.1i"].includes("A2.1i.ii") || survey.data["Q2.1i"].includes("A2.1i.iii"))){
					//guidelinesToDisplay.push(surveyGuidelines[surveyName][7]);
					guidelinesToDisplay.push(surveyGuidelines[surveyName]["K"].replace(".", " as soon as possible."), surveyGuidelines[surveyName]["JL2"], surveyGuidelines[surveyName]["F"], surveyGuidelines[surveyName]["G"], surveyGuidelines[surveyName]["H"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
				} else if((survey.data.hasOwnProperty("isOlderThanSeventy") && survey.data.isOlderThanSeventy === true) || survey.data["Q2.1e nwf"].includes("A2.1e.vi")){
					//guidelinesToDisplay.push(surveyGuidelines[surveyName][7]);
					guidelinesToDisplay.push(surveyGuidelines[surveyName]["K"].replace(".", " as soon as possible."), surveyGuidelines[surveyName]["JL2"], surveyGuidelines[surveyName]["F"], surveyGuidelines[surveyName]["G"], surveyGuidelines[surveyName]["H"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
				} else {
					//guidelinesToDisplay.push(surveyGuidelines[surveyName][6]);
					guidelinesToDisplay.push(surveyGuidelines[surveyName]["I"], surveyGuidelines[surveyName]["JL2"], surveyGuidelines[surveyName]["N"].replace(".", " as soon as possible."), surveyGuidelines[surveyName]["F"], surveyGuidelines[surveyName]["G"], surveyGuidelines[surveyName]["H"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
					
				}
			} else {
				//guidelinesToDisplay.push(surveyGuidelines[surveyName][1]);
				guidelinesToDisplay.push(surveyGuidelines[surveyName]["A"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"], surveyGuidelines[surveyName]["JL2"]);
			}
		}
		//Part 3
		//Not sure/no, none of the above;
		if(survey.data["Q3.1a nwf"] === "none" || survey.data["Q3.1a nwf"].includes("A3.1a.viii")){
			//continue
			//TODO: Add "Was A3.1a.vi selected?" internal logic below could be shortened ALOT
		} else {
			if(survey.data["Q3.1a nwf"].includes("A3.1a.vi") && survey.data["Q3.1c"] === "Yes"){
				guidelinesToDisplay.push(surveyGuidelines[surveyName]["H2"]);
			}
			if(survey.data["Q3.1a nwf"].includes("A3.1a.i") || survey.data["Q3.1a nwf"].includes("A3.1a.ii") || survey.data["Q3.1a nwf"].includes("A3.1a.iii") || survey.data["Q3.1a nwf"].includes("A3.1a.iv") || survey.data["Q3.1a nwf"].includes("A3.1a.vii")){
				guidelinesToDisplay.push(surveyGuidelines[surveyName]["M"]);
			}
			if(survey.data["Q3.1a nwf"].includes("A3.1a.i") || survey.data["Q3.1a nwf"].includes("A3.1a.ii") || survey.data["Q3.1a nwf"].includes("A3.1a.iii") || survey.data["Q3.1a nwf"].includes("A3.1a.iv") || survey.data["Q3.1a nwf"].includes("A3.1a.v") || survey.data["Q3.1a nwf"].includes("A3.1a.vi") || survey.data["Q3.1a nwf"].includes("A3.1a.vii")){
				if(survey.data["ageToUse"] < 1){
					if(survey.data["Q2.1a nwf"].includes("A2.1a.ii") || survey.data["Q2.1a nwf"].includes("A2.1a.iii") || survey.data["Q2.1a nwf"].includes("A2.1a.iv") || survey.data["Q2.1a nwf"].includes("A2.1a.v") || survey.data["Q2.1a nwf"].includes("A2.1a.vi") || survey.data["Q2.1a nwf"].includes("A2.1a.vii") || survey.data["Q2.1a nwf"].includes("A2.1a.viii")){
						//continue
					} else if (survey.data["Q2.1c"] === "A2.1c.ii" || survey.data["Q2.1c"] === "A2.1c.iii" || survey.data["Q2.1c"] === "A2.1c.iv" || survey.data["Q2.1c"] === "A2.1c.v"){
						guidelinesToDisplay = [];
						guidelinesToDisplay.push(surveyGuidelines[surveyName]["L"].replace(".", " immediately."), surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);						
					} else if (survey.data["Q3.1a nwf"].includes("A3.1a.vi")){
						guidelinesToDisplay = [];
						guidelinesToDisplay.push(surveyGuidelines[surveyName]["L"].replace(".", " immediately."), surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);						
					} else if (survey.data["Q2.1a nwf"].includes("A2.1a.ix")){
						guidelinesToDisplay = [];
						guidelinesToDisplay.push(surveyGuidelines[surveyName]["JL1"], surveyGuidelines[surveyName]["N"].replace(".", " as soon as possible."), surveyGuidelines[surveyName]["A"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
					}
				} else {
					if(survey.data["Q2.1e nwf"].includes("A2.1e.viii") || survey.data["Q2.1e nwf"].includes("A2.1e.ix") || survey.data["Q2.1e nwf"].includes("A2.1e.x") || survey.data["Q2.1e nwf"].includes("A2.1e.xi") || survey.data["Q2.1e nwf"].includes("A2.1e.xii") || survey.data["Q2.1e nwf"].includes("A2.1e.xiii") || survey.data["Q2.1e nwf"].includes("A2.1e.xiv")){
						//continue
					} else if (survey.data["Q2.1g"] === "A2.1g.ii" || survey.data["Q2.1g"] === "A2.1g.iii" || survey.data["Q2.1g"] === "A2.1g.iv" || survey.data["Q2.1g"] === "A2.1g.v"){
						if(survey.data["Q2.1g"] === "A2.1g.ii"){
							if(survey.data["ageToUse"] > 70 || survey.data["Q2.1e nwf"].includes("A2.1e.vi") || survey.data["Q2.1i"].includes("A2.1i.i") || survey.data["Q2.1i"].includes("A2.1i.ii") || survey.data["Q2.1i"].includes("A2.1i.iii")){
								guidelinesToDisplay = [];
								guidelinesToDisplay.push(surveyGuidelines[surveyName]["K"].replace(".", " as soon as possible."), surveyGuidelines[surveyName]["JL2"], surveyGuidelines[surveyName]["F"], surveyGuidelines[surveyName]["G"], surveyGuidelines[surveyName]["H"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
							} else {
								guidelinesToDisplay = [];
								guidelinesToDisplay.push(surveyGuidelines[surveyName]["I"], surveyGuidelines[surveyName]["JL2"], surveyGuidelines[surveyName]["N"].replace(".", " as soon as possible."), surveyGuidelines[surveyName]["F"], surveyGuidelines[surveyName]["G"], surveyGuidelines[surveyName]["H"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
							}
						}else {
							guidelinesToDisplay = [];
							guidelinesToDisplay.push(surveyGuidelines[surveyName]["K"].replace(".", " as soon as possible."), surveyGuidelines[surveyName]["JL2"], surveyGuidelines[surveyName]["F"], surveyGuidelines[surveyName]["G"], surveyGuidelines[surveyName]["H"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
						}
					} else if (survey.data["Q3.1a nwf"].includes("A3.1a.vi")){
						guidelinesToDisplay = [];
						guidelinesToDisplay.push(surveyGuidelines[surveyName]["K"].replace(".", " as soon as possible."), surveyGuidelines[surveyName]["JL2"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"]);
					} else {
						guidelinesToDisplay.push(surveyGuidelines[surveyName]["A"], surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"], surveyGuidelines[surveyName]["JL2"]);
					}
				}
			}
		}

		// //Part 4
		if(survey.data["Q4.1a nwf"].includes("A4.1a.iv")){
			//continue
		} else {
			guidelinesToDisplay = [];
			guidelinesToDisplay.push(surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"], surveyGuidelines[surveyName]["P"], surveyGuidelines[surveyName]["N"].replace(".", " immediately."));
		}
		if(survey.data["Q4.2a nwf"] === "A4.2a.iv" || survey.data["Q4.2a nwf"] === "A4.2a.v"){
			if(survey.data["Q4.1a nwf"].includes("A4.1a.i") || survey.data["Q4.1a nwf"].includes("A4.1a.ii") || survey.data["Q4.1a nwf"].includes("A4.1a.iii")){
				guidelinesToDisplay = [];
				guidelinesToDisplay.push(surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"], surveyGuidelines[surveyName]["P"], surveyGuidelines[surveyName]["N"].replace(".", " immediately."));
			}
		} else {
			guidelinesToDisplay = [];
			guidelinesToDisplay.push(surveyGuidelines[surveyName]["B"], surveyGuidelines[surveyName]["C"], surveyGuidelines[surveyName]["D"], surveyGuidelines[surveyName]["P"], surveyGuidelines[surveyName]["N"].replace(".", " immediately."));
		}

		const uniqueSet = new Set(guidelinesToDisplay);
		guidelinesToDisplay = Array.from(uniqueSet);

	}

	if (surveyName !== "Health Profile Template" && surveyGuidelines[surveyName]) {
		getSurveyGuidelines();
	}
  return (
		<div style={{paddingTop: '25px'}}>
			<div className="guidelineTitle"><span>{surveyName}</span><span>Guidelines</span></div>
			<ul className='guidelineList'>
				{ surveyGuidelines[surveyName] ? (
					guidelinesToDisplay.map((guideline, index) => {
						return <li key={index}>{guideline}</li>
					})
				) : (
					<h1 className='disease-title'>GUIDELINES UNAVAILABLE</h1>
				)
				}

			</ul>
			<button id="prev-bttn-Disease" className="surveyNavBttn" onClick={backButton}><img src={CancelArrow}
                                                                                                                alt="left facing arrow"/>Back
                        </button>
		</div>
    
  )
}

export default ShowGuidelines