export const Curious = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 1492 1499\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-2163.82 -2122.25) matrix(.63242 0 0 .63242 1813.09 -2651.94)\">\n" +
    "        <ellipse\n" +
    "          cx=\"5162.14\"\n" +
    "          cy=\"2648.17\"\n" +
    "          fill=\"#3C90F0\"\n" +
    "          rx=\"2173.37\"\n" +
    "          ry=\"2032.94\"\n" +
    "          transform=\"matrix(.54253 0 0 .5826 -1066.89 7190.69)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(1704.93 8633.25) scale(23.1029) translate(-37.5 -41.5)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H75V83H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M39.489 81.783c19.059 0 34.51-15.452 34.51-34.512 0-19.061-15.451-34.513-34.51-34.513-19.06 0-34.51 15.452-34.51 34.513 0 19.06 15.45 34.512 34.51 34.512z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#00ADEB\"\n" +
    "              d=\"M2.958 52.078l-.062-.85c-.197-1.775.395-3.723 2.046-5.695 1.479-1.75 2.305-3.032 2.305-4.511 0-1.676-1.047-2.798-3.13-2.835-1.184 0-2.502.394-3.328 1.023L0 37.139c1.085-.789 2.97-1.319 4.708-1.319 3.784 0 5.497 2.342 5.497 4.844 0 2.244-1.257 3.858-2.835 5.732-1.442 1.713-1.972 3.155-1.873 4.844l.037.851H2.97l-.012-.013zm-.727 4.61c0-1.22.826-2.071 1.972-2.071s1.947.851 1.947 2.071c0 1.147-.764 2.046-1.972 2.046-1.158 0-1.947-.887-1.947-2.046zM37.751 16.258l-.062-.85c-.197-1.775.395-3.723 2.046-5.695 1.479-1.75 2.305-3.032 2.305-4.511 0-1.677-1.047-2.798-3.13-2.835-1.184 0-2.502.394-3.328 1.023l-.789-2.071C35.878.53 37.763 0 39.501 0c3.784 0 5.497 2.342 5.497 4.844 0 2.243-1.257 3.858-2.835 5.732-1.442 1.713-1.972 3.155-1.873 4.844l.037.85h-2.564l-.012-.012zm-.727 4.61c0-1.22.826-2.071 1.972-2.071s1.947.851 1.947 2.071c0 1.146-.764 2.046-1.972 2.046-1.158 0-1.947-.888-1.947-2.046z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M14.803 31.508s2.909-9.491 12.719-9.516c0 0 8.221.013 11.561 9.516 0 0-10.833-10.44-24.28 0z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M26.943 49.38c4.683 0 8.479-5.232 8.479-11.685 0-6.454-3.796-11.685-8.479-11.685-4.684 0-8.48 5.231-8.48 11.685 0 6.453 3.796 11.685 8.48 11.685z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M27.72 45.386c2.342 0 4.24-2.616 4.24-5.842 0-3.227-1.898-5.843-4.24-5.843-2.341 0-4.239 2.616-4.239 5.843 0 3.226 1.898 5.842 4.239 5.842z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M64.177 31.508s-2.909-9.491-12.72-9.516c0 0-8.22.013-11.56 9.516 0 0 10.833-10.44 24.28 0z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M52.036 49.38c4.683 0 8.48-5.232 8.48-11.685 0-6.454-3.797-11.685-8.48-11.685s-8.479 5.231-8.479 11.685c0 6.453 3.796 11.685 8.479 11.685z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M51.259 45.386c2.342 0 4.24-2.616 4.24-5.842 0-3.227-1.898-5.843-4.24-5.843-2.341 0-4.239 2.616-4.239 5.843 0 3.226 1.898 5.842 4.239 5.842z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M36.791 66.351c.099-.481-.025-.999.086-1.48.185-.801 1.11-1.331 1.861-1.047.493.185.888.641 1.406.69.529.049.998-.345 1.355-.764.358-.419.666-.912 1.147-1.147.628-.308 1.368-.098 2.009.185.456.198.924.457 1.183.9.431.74.135 1.701-.185 2.502\"\n" +
    "            ></path>\n" +
    "            <path d=\"M37.568 66.559c.061-.358.061-.678.061-1.036 0-.234-.024-.48.124-.678.172-.246.493-.369.776-.258.493.209.85.591 1.405.69.678.123 1.294-.173 1.8-.616.406-.358.653-.863 1.072-1.208.419-.346.986-.161 1.442.012.407.16.9.382.998.838.123.53-.16 1.171-.357 1.652-.161.407.172.875.554.986.456.123.826-.16.986-.555.272-.702.493-1.43.395-2.194-.099-.813-.579-1.454-1.282-1.861-.641-.382-1.454-.69-2.206-.69-.838 0-1.516.419-2.034 1.035-.271.32-.727 1.171-1.232.974-.296-.111-.53-.358-.814-.493-.32-.148-.665-.247-1.023-.234a2.327 2.327 0 00-1.75.912 2.112 2.112 0 00-.419.961c-.074.444 0 .888-.062 1.319-.061.431.111.863.555.986.382.099.912-.136.986-.555l.025.013z\"></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"