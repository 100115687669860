import React, { useRef, useState, useEffect} from "react";
import { MdEdit } from 'react-icons/md';
import { FaRegTrashAlt } from 'react-icons/fa';
import acceptButton from "../../images/acceptButton.svg"
import rejectButton from "../../images/rejectButton.svg"
import searchIcon from "../../images/searchIcon.svg"
import submitArrow from "../../images/submitArrow.svg"
import inactiveArrow from "../../images/inactiveArrow.svg"

const Groups = ({ 
    expand,
    pageBook,
    closePage,
    setPage,
    tokenPayload,
    DropdownHeader,
    buttonActivity,
    handleButtonActivity,
    handleReset
}) => {


    const [viewGroup, setViewGroup] = useState({
        id: -1,
        members: ["No Members"],
        inviter: "",
        groupName: "No Group Name",
        accepted: false,
        rejected: false,
        owner: false,
    });
    const [activeGroupDropdown, setActiveGroupDropdown] = useState([]);
    const [groupsReqs, setGroupReqs] = useState(
    [
        {
            id: 1,
            members: ["rudy13", "doby18", "ron33"],
            inviter: "rudy13",
            groupName: "007 Dudes",
            accepted: false,
            rejected: false,
            owner: false,
        }, 
        {
            id: 2,
            members: ["rudy13", "doby18", "draco1"],
            inviter: "doby18",
            groupName: "Merkles",
            accepted: false,
            rejected: false,
            owner: false,
        }, 
        {
            id: 3,
            members: ["harmonie13", "draco1"],
            inviter: "harmonie13",
            groupName: "Gryffindor Girls",
            accepted: false,
            rejected: false,
            owner: false,
        },
        {
            id: 4,
            members: ["doby18", "draco1", "harmonie13"],
            inviter: "Draco1",
            groupName: "Diffies",
            accepted: false,
            rejected: false,
            owner: false,
        },
        {
            id: 5,
            members: ["doby18", "draco1", "harmonie13", "rudy13", "ron33"],
            inviter: "ron33",
            groupName: "Swann Family",
            accepted: false,
            rejected: false,
            owner: false,
        }
    ]);
    
    const [affiliationReqs, setAffiliationReqs] = useState(
    [
        {
            id: 1,
            affiliate: "U of Washington Alumni",
            accepted: false,
            rejected: false
        },
        {
            id: 2,
            affiliate: "Goldman Sachs",
            accepted: false,
            rejected: false
        },
        {
            id: 3,
            affiliate: "Manchester United Fan Club",
            accepted: false,
            rejected: false
        },
        {
            id: 4,
            affiliate: "British Airways Avios Gold",
            accepted: false,
            rejected: false
        },
        
    ]);

    const [affiliationsConnected, setAffiliationsConnected] = useState([
        {
            id: 5,
            affiliate: "Costco Executive Club",
            accepted: false,
            rejected: false
        }
    ]);
    const [groupsConnected, setGroupsConnected] = useState([
        {
            id: 6,
            members: ["devin3", "troy1"],
            inviter: "devin3",
            groupName: "Roma Team",
            accepted: false,
            rejected: false,
            owner: true,
        },
        {
            id: 7,
            members: ["jacky102", "delilah7"],
            inviter: "delilah7",
            groupName: "Cauli Trope",
            accepted: false,
            rejected: false,
            owner: false,
        },
    ]);


    
    
    const handleGroupEdit = (id) => {
        setActiveGroupDropdown((oldArr) => {
            if(oldArr.includes(id)) {
                return oldArr.filter(activeId => activeId !== id )
            } else {
                return [...oldArr, id]
            }
        })
    }
    
    
    const handleGroupRemove = (id) => {
        setGroupsConnected((oldGroups) => {
            return oldGroups.filter((group) => group.id !== id);
        })
    }
    
    const handleAffiliationRemove = (id) => {
        setAffiliationsConnected((oldAffiliations) => {
            return oldAffiliations.filter((affiliation) => affiliation.id !== id);
        })
    }
    
    const handleMember = (member) => {
        //Within the array of groups, find the group with the member, and then remove that member from the group.
        setViewGroup((oldGroup) => {
            return {
            ...oldGroup, members: oldGroup.members.filter((oldMember) => oldMember !== member)
            }
        });
        setGroupsConnected((oldGroups) => {
            return oldGroups.map((group) => {
                if(group.id === viewGroup.id) {
                    return {...group, members: group.members.filter((oldMember) => oldMember !== member)}
                }
                    return group;
            });
        })
    }
    
    const handleNewGroup = (event) => {
        event.preventDefault(); 
        console.log("New Group Form Submitted")
        const form = event.target;
        const groupLabel = form.groupName.value
        let membersRaw = form.members.value || ""
        console.log(membersRaw);
    
        if(membersRaw === "" && groupLabel.length !==0) {
            setGroupsConnected((oldConnections) => {
                let newGroup = {
                    id: Math.round(Math.random()*100+8),
                    members: [],
                    inviter: tokenPayload.email,
                    groupName: groupLabel,
                    accepted: false,
                    rejected: false,
                    owner: true,
                }
                return [...oldConnections, newGroup];
            });
            setPage('groups', 2);
        } else {
            //process the groupMembers line first, if there are commas then split the string up based on the commas.
            const membersClean = membersRaw.trim().split(',');
            
            if(groupLabel.length !==0 && membersClean.length !==0 && membersClean[0] !== '') {
                //For demoing purposes due to time constraint, we will accept all inputs.
                setGroupsConnected((oldConnections) => {
                    let newGroup = {
                        id: Math.round(Math.random()*100+8),
                        members: membersClean,
                        inviter: membersClean[0],
                        groupName: groupLabel,
                        accepted: false,
                        rejected: false,
                        owner: true,
                    }
                    console.log(newGroup);
                    return [...oldConnections, newGroup];
                });
                setPage('groups', 2);
            }
        }
        handleReset("newGroup");
    }
    
    const handleAddMember = (event) => {
        event.preventDefault();
    
        const form = event.target;
        let membersRaw = form.manageMembers.value
        document.getElementById('manageMemberSearch').value = '';        
    
        //process the groupMembers line first, if there are commas then split the string up based on the commas.
        const membersClean = membersRaw.trim().split(',');
    
        if(membersClean.length !== 0 && membersClean[0] !== '') {
            setViewGroup((oldGroup) => {
                return {
                    ...oldGroup, members: [...new Set([...membersClean,...oldGroup.members])]
                }
            })
            setGroupsConnected((oldGroups) => {
                return oldGroups.map((group)=> {
                    if(group.id === viewGroup.id) {
                        return {
                            ...group, members: [...new Set([...membersClean, ...group.members])]
                        }
                    } 
                    return group;
                        
                })
            })      
        }
    
    }
    
    const handleViewGroup = (id) => {
        setViewGroup(groupsConnected.find((group) => group.id === id));
    }
    
    const handleJoinAffiliation = (id) => {
        setAffiliationsConnected((oldAffiliations) => {
            return [...oldAffiliations, affiliationReqs.find((connection) => connection.id === id)];
        })
        setAffiliationReqs((oldAffiliations) => {
            return oldAffiliations.filter((connection)=> connection.id !== id);
        })
        setPage("groups", 6);
    }
    
    const handleRejectAffiliation = (id) => {
        setAffiliationReqs((oldAffiliations) => {
            return oldAffiliations.filter((connection)=> connection.id !== id);
        })
    }
    
    const handleJoinGroup = (id) => {
        setGroupsConnected((oldGroups) => {
            return [...oldGroups, groupsReqs.find((group) => group.id === id)];
        })
        setGroupReqs((oldGroups) => {
            return oldGroups.filter((group)=> group.id !== id);
        })
        setPage("groups", 2);
    }
    
    const handleRejectGroup = (id) => {
        setGroupReqs((oldGroups) => {
            return oldGroups.filter((group)=> group.id !== id);
        })
    }

    return (
        <>
            {expand.groups && 
                    <>
                        <div className="dropdownSection">
                            <div className="dropdownPanel">                                
                                <div className="dropdownHeader">
                                    {pageBook.groups===1 && 
                                        <ul className="headerNav">
                                            <li className="firstPage"><p className="dropdownChevron">&lt;</p></li>
                                            <li><p>Groups & Affiliations</p></li>
                                            <li><button onClick={() => {closePage()}} className="dropdownClose"><p>-</p></button></li>
                                        </ul>
                                    }
                                    {pageBook.groups===2 && <DropdownHeader parent="groups" title="My Groups" parentPage={1}/>}
                                    {pageBook.groups===3 && <DropdownHeader parent="groups" title="My Groups" parentPage={2}/>}

                                    {pageBook.groups===4 && <DropdownHeader parent="groups" title={`Group Requests (${groupsReqs.length > 99 ? '+99' : groupsReqs.length})`} parentPage={2}/>}
                                    {pageBook.groups===5 && <DropdownHeader parent="groups" title={viewGroup.groupName} parentPage={2}/>}

                                    {pageBook.groups===6 && <DropdownHeader parent="groups" title="My Affiliations" parentPage={1}/>}
                                    {pageBook.groups===7 && <DropdownHeader parent="groups" title={`Affiliation Invitations (${affiliationReqs.length > 99 ? '+99': affiliationReqs.length})`} parentPage={6}/>}
                                </div>
                                <div className='dropdownBody'>
                                    {pageBook.groups===1 &&
                                        <div className="dropdownBody">
                                            <li className="centered"><button onClick={() => setPage('groups', 2)}>My Groups</button></li>
                                            <li className="centered"><button onClick={() => setPage('groups', 6)}>My Affiliations</button></li>
                                        </div>
                                    }
                                    {pageBook.groups===2 &&
                                        <div className="dropdownBody groups">
                                            {
                                                groupsConnected.map((group) => {
                                                    return(
                                                        
                                                        <li className="spread">
                                                        {!activeGroupDropdown.includes(group.id) ? 
                                                            (
                                                                <button className="spreadItems spreadItemsGroups" onClick={() => handleGroupEdit(group.id)}>
                                                                    <p className="invisible">......</p>
                                                                    <p className="unbolded">{group.groupName}</p>
                                                                    <p className="chevronRight unbolded">&lt;</p>
                                                                </button>
                                                            )
                                                        :
                                                            (
                                                                <button className="spreadItems spreadItemsGroups">
                                                                    <p className={group.owner ? 'spreadItemTitle unbolded' : 'spreadItemTitle unbolded toLeft'} onClick={() => handleGroupEdit(group.id)}>{group.groupName}</p>
                                                                    {
                                                                        group.owner &&                                                                    
                                                                        <p className="spreadItemButton spreadItemEdit" onClick={() => {handleViewGroup(group.id); setPage('groups', 5); }}><MdEdit/></p>
                                                                    }
                                                                    <p className="spreadItemButton spreadItemDelete"onClick={() => handleGroupRemove(group.id)}><FaRegTrashAlt/></p>
                                                                </button>
                                                            )
                                                        }
                                                        </li>
                                                    )
                                                })
                                            }
                                            
                                            <li className="centered"><button className="bolded blueFont" onClick={() => setPage('groups', 3)}>+ New</button></li>
                                            <li className="centered"><button className="bolded blueFont" onClick={() => setPage('groups', 4)}>Requests ({groupsReqs.length > 99 ? '+99' : groupsReqs.length})</button></li>
                                        </div>
                                    }
                                    {pageBook.groups===3 && 
                                        <div className="dropdownBody">
                                            <form id="newGroupForm" onSubmit={(e)=> {handleNewGroup(e); }}>
                                                <input type="text" onKeyUp={()=>handleButtonActivity("newGroupForm", 'newGroup')} minLength={1} id="newGroup" name="groupName" placeholder="New Group Label" required></input> <br/>
                                                <img src={searchIcon} className="dropdownSearchIcon"/><input type="text" id="memberSearch" name="members" placeholder="Add Member 1, Member 2,..."></input>
                                                <br/>
                                                <button type="submit" className={buttonActivity.newGroup ? "submitLinkActive" : "submitLinkInactive"}>Add</button>
                                            </form>
                                        </div>
                                    }
                                    {pageBook.groups===4 &&
                                        <div className="dropdownBody">
                                        {groupsReqs.length === 0 ?
                                            <li className="centered">No Group Requests</li> 
                                            :    
                                            groupsReqs.map((invite) => {
                                                return ( 
                                                    <li className="groupReqRow">
                                                        <p className="groupReqItem1">{invite.inviter}</p>
                                                        <p className="groupReqItem2">invites you to </p>
                                                        <p className="groupReqItem3">{invite.groupName}</p>
                                                        <p className="groupReqItem4"><img onClick={() => handleJoinGroup(invite.id)}src={acceptButton}/><img onClick={() => handleRejectGroup(invite.id)} src={rejectButton}/></p>
                                                    </li>
                                                )
                                            })
                                        }
                                        </div>
                                    }
                                    {pageBook.groups===5 &&
                                        <div className="dropdownBody">
                                        <form id="viewGroupForm" onSubmit={(e)=> {handleAddMember(e); handleReset("addMember");}}>
                                            <img src={searchIcon} className="dropdownSearchIcon"/><input type="text" minLength={1} onKeyUp={()=>handleButtonActivity("viewGroupForm", 'addMember')} id="manageMemberSearch" name="manageMembers" placeholder={"Add Member 1, Member 2,..."} required></input>
                                            <li className="member"> <p className="unbolded">{tokenPayload.email}</p></li>
                                            {
                                                viewGroup.members.map((member) => {
                                                    return (
                                                        <li className="member"><p className="unbolded">{member}</p> <a onClick={() => handleMember(member)}>remove</a></li>
                                                    )    
                                                })
                                            }
                                            <br/>
                                            <button type="submit"><img src={buttonActivity.addMember ? submitArrow : inactiveArrow}/></button>
                                        </form>
                                        </div>
                                    }
                                    {pageBook.groups===6 &&
                                        <div className="dropdownBody">
                                        {
                                            affiliationsConnected.map((connection) => {
                                                return (
                                                    <li className="spread">  
                                                        <button className="spreadItems spreadItemsGroups">
                                                            <p className='spreadItemTitle unbolded'>{connection.affiliate}</p>
                                                            <p className="spreadItemButton spreadItemDelete"onClick={() => handleAffiliationRemove(connection.id)}><FaRegTrashAlt/></p>
                                                        </button>       
                                                    </li>
                                                )
                                            })    
                                        }
                                            <li className="centered"><button className="bolded blueFont" onClick={() => setPage('groups', 7)}>Invitations ({affiliationReqs.length})</button></li>
                                        </div>
                                    }
                                    {pageBook.groups===7 &&
                                    <div className="dropdownBody affiliations">
                                        {affiliationReqs.length === 0 ?
                                        <li className="centered">No Affiliation Invitations</li>
                                        : 
                                        affiliationReqs.map((connection) => {
                                            return (
                                            <li className="spread">
                                                <button className="spreadItems spreadItemsGroups">
                                                    <p className='spreadItemTitle unbolded'>{connection.affiliate}</p>
                                                    <p  onClick={() => handleJoinAffiliation(connection.id)}><img  src={acceptButton}/></p>
                                                    <p onClick={() => handleRejectAffiliation(connection.id)}><img className='spreadItemImage' src={rejectButton}/></p>
                                                </button>
                                            </li>
                                            )
                                        })
                                        
                                        }
                                        
                                    </div>
                                    }

                                </div>
                            </div>
                        </div>    
                    </>
                }

        </>
    )
}

export default Groups