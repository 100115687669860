import bodySensationBackground from "../../../../images/ftt-process/bodysensations.jpg";
import { motion } from "framer-motion";

const BodySensations = ({ emotionalData}) => {
  return (
    <motion.div
      className="myftt "
      initial={{ x: 250, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ delay: 0.2 }}
    >
      {/*<div className="header">*/}
      {/*  <h2>My Feelings Flow Tracker</h2>*/}
      {/*  <div className="colorLine" style={{ width: "100%", height: "6px" }} />*/}
      {/*</div>*/}
      <div
        className="process-container body-sensations-container"
        style={{
          backgroundImage: `url(${bodySensationBackground})`,
          backgroundSize: "cover",
        }}
      >
        <div className="header-container ">
          {/*<p>Process: Body Sensations</p>*/}
          <p>These feelings appeared in me as the following <b>Body Sensation(s)</b>...</p>
        </div>
        <div className="body-container  ">
          {/*<div className="body-description body-sensations">*/}
            {/*<p>These feelings appeared in my body </p>*/}
            {/*<p>as the following sensations...</p>*/}
          {/*</div>*/}

            <div
                className="body-summary"
                style={{
                  // display: "grid",
                  // gridTemplateColumns: "1fr",
                  // overflow: "scroll",
                  // rowGap: "10px",
                    marginTop: "-24rem"
                }}
            >
                {/*{emotionalData.q4.map((item) => (*/}
                {/*    <p style={{paddingLeft: "105px"}}>{item}</p>*/}
                {/*))}*/}

                <div className={"associated-process-box"}>
                    {emotionalData.q4.map((el) => {
                            if (emotionalData.q4.length === 1) {
                                return (
                                    <span className={"associated-process-single"}>{el}</span>
                                )
                            } else {
                                return (<span>{el}</span>)
                            }
                        }
                    )}
                </div>
            </div>
        </div>
      </div>
    </motion.div>
  );
};
export default BodySensations;
