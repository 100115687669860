import React, { useEffect, useRef, useState } from "react";
import { FaAngleDown } from "react-icons/fa";

export const DiseaseContainer = ({ title, options, icon, setDisease, setSurveyComplete, filterSelected, setFilterSelected, sort, name, setSelectedDisease }) => {
    const [overflow, setOverflow] = useState(false);
    const [displayOptions, setDisplayOptions] = useState(options)
    const wrapperRef = useRef(null);
    const timerRef = useRef(null);
    const spanRef = useRef(null)
    const overflowRef = useRef(null)

    useEffect(() => {
        setDisplayOptions(options)
        spanRef.current.style.transform = `translateX(0px)`

        const checkOverflow = () => {
          if (wrapperRef.current) {
            setTimeout(() => {
              setOverflow(spanRef.current.scrollWidth > wrapperRef.current.clientWidth)
              let move_left = 0;
              const spanElement = spanRef.current;
              const newMinWidth = spanElement.getBoundingClientRect().width - 100

              if (newMinWidth >= 0) overflowRef.current.style.minWidth = `${spanElement.getBoundingClientRect().width - 100}px`
              else overflowRef.current.style.minWidth = `0px`
              if (spanRef.current.scrollWidth > wrapperRef.current.clientWidth) {
                timerRef.current = setInterval(() => {
                  move_left += 1;
                  spanElement.style.transform = `translateX(-${move_left}px)`;
                  const firstItemWidth = spanElement.firstChild.getBoundingClientRect().width + 11;
                  if (move_left >= firstItemWidth) {
                    setDisplayOptions(prevOptions => {
                      const newOptions = [...prevOptions];
                      const removed = newOptions.shift();
                      newOptions.push(removed);
                      return newOptions;
                    });
            
                    move_left = 0; 
                    spanElement.style.transform = `translateX(0px)`
                  }
                }, 35);
              } else {
                clearInterval(timerRef.current)
              }
            }, 0)
          }
        };
        checkOverflow();
        window.addEventListener('checkOverflow', checkOverflow)
    
        return () => {
          window.removeEventListener('checkOverflow', checkOverflow)
          clearInterval(timerRef.current)
        };
    }, [sort]);

    return (
      <div id="id" className="disease-container">
        <div className="disease-container-title">
          <span>{icon}</span>
          <span>{title}</span>
          <span><button onClick={() => setFilterSelected(!filterSelected)}><FaAngleDown className="icon"/></button></span>
        </div>
          <div ref={wrapperRef} className={`disease-content-wrapper ${overflow ? 'disease-container-offset' : ''}`} style={(sort == name) ? {justifyContent: 'center'} : {}}>
            <div ref={overflowRef} className="disease-content-overflow">
              <span ref={spanRef} id={title}>
              {displayOptions?.length > 0 && sort !== name &&
                  <>
                  {displayOptions.map((option) => (
                    <li className="disease-container-content">
                            <button id={option} onClick={() => {
                                    setDisease("Health Profile Template")
                                    setSelectedDisease(option) 
                                    setSurveyComplete(false)
                                }}
                            >
                                {option}
                            </button>
                    </li>
                ))}
                </>
              }
              {displayOptions?.length <= 0 &&
                <div style={{color: '#00286E', fontSize: '12px'}}>Coming Soon!</div>
              }
              { displayOptions?.length > 0 && sort === name &&
                
                <div style={{ color: '#EA4C01', fontSize: '12px'}}>Select {name}</div>

              }
              </span>
            </div>
          </div>
        </div>
    );
  };


