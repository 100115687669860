import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { selectCommunityData, selectStatus, selectZipCode, setZipCode, getLocationDetails } from "../../store/communityHealthSlice";
import { fetchByZipCode, fetchZipCode } from "../../store/communityHealthSlice";
import horseShoeIcon from "../../images/horseShoeIcon.svg";
import WeatherIcon from "../../images/weather.png";
import { useEffect } from "react";
import DropdownButton from "./DropdownButton";
import { input } from "aws-amplify";
import cities from "./StatesCitiesZipcode/USCities.json"
import states from "./StatesCitiesZipcode/USStates.json"
import lidrIcon from "../../images/lidr-icon.png"
import lidrGradient from "../../images/lidr-gradient.svg"
import worldIcon from "../../images/worldIcon.svg"
import downArrow from "../../images/down_chevron_white.svg"
import { ReturnConsumedCapacity } from "@aws-sdk/client-dynamodb";
import axios from "axios";

export const Info = () => {
    const dispatch = useDispatch()
    const inputRef = useRef()
    const firstUpdate = useRef(true);
    const zipCode = useSelector(selectZipCode)
    const data = useSelector(selectCommunityData)
    const [status, setStatus] = useState("idle2")
    const [locationBool, setLocationBool] = useState(false)
    const [locationList, setLocationList] = useState([])
    const [zipExists, setZipExists] = useState(false)
    const [zipResults, setZipResults] = useState([])
    const [zipResultStrings, setZipResultStrings] = useState([]);
    const [gaugeClass, setGaugeClass] = useState('gauge-adjGood')
    const [lidrClass, setLidrClass] = useState({backgroundImage: "linear-gradient(315deg, #f8ef42 0%, #0fd64f 74%)"})
    const [selectedLocation, setSelectedLocation] = useState('')
    const [sortedZipResults, setSortedZipResults] = useState([])
    const [locDetails, setLocDetails] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const initializeHeader = async() => {
            const locationData = JSON.parse(localStorage.getItem('location'))
            const locationDataJSON = JSON.parse(localStorage.getItem('location-json'))
            if(locationData && locationDataJSON)  {
                const details = await locationDetails(locationDataJSON)
                setLocDetails(details)
                checkGauge(details.aqi, details.lidr)
                setSelectedLocation(locationData)
                setLocationBool(true)
                setStatus("fulfilled")
                const breakText = locationData.split(',');
            }
            setLoading(false)
        }
        initializeHeader()
    }, [])

    /*
    const locationSubmit = (event) =>{
        event.preventDefault()
        if (locationList && locationList.length) {
            const breakText = locationList[0].split(/\s/)
            dispatch(setZipCode(breakText ? breakText[0] : ''))
            if (data) {
                setLocationBool(!locationBool)
                checkGauge(data.aqi, Math.round(data.K))
            }
        }
        inputRef.current.classList.add('error')
        return
    }
    */

    useEffect(() => {
        if (!locDetails)
            setLidrClass({backgroundImage: "linear-gradient(315deg, #d3d3d3 0%, #a9a9a9 74%)"})
    }, [locDetails])

    const buttonSubmit = async(e) =>{
        localStorage.setItem('location-json', JSON.stringify(sortedZipResults[e.value]))
        const event = e.textContent
        const details = await locationDetails(sortedZipResults[e.value])

        console.log("event:", event);
        const breakText = event.split(',');
        console.log(breakText[0]);
        dispatch(setZipCode(breakText ? breakText[0] : ''))
        setSelectedLocation(event);
        localStorage.setItem('location', JSON.stringify(event))
        if (details) {
            setLocDetails(details)
            setStatus("fulfilled")
            setLocationBool(!locationBool)
            checkGauge(details.aqi, details.lidr)
        }
        return
    }
    // test

    const checkGauge = (aqi, lidr) =>{
        if (aqi === 1){
            setGaugeClass('gauge-adjGood')
        }
        if (aqi === 2){
            setGaugeClass('gauge-adjModerate')
        }
        if (aqi === 3){
            setGaugeClass('gauge-adjSensitive')
        }
        if (aqi === 4){
            setGaugeClass('gauge-adjUnhealthy')
        }
        if (aqi === 5){
            setGaugeClass('gauge-adjUnhealthyV')
        }

        if (lidr < 25){
            setLidrClass({backgroundImage: "linear-gradient(315deg, #f8ef42 0%, #0fd64f 74%)"})
        }else if (lidr < 50){
            setLidrClass({backgroundImage: "linear-gradient(to right, #fff500, #ffe000, #ffcc00, #ffb800, #ffa400)"})
        }else if (lidr < 75){
            setLidrClass({backgroundImage: "linear-gradient(to right, #ffa400, #ff9700, #ff8a00, #ff7c00, #ff6e00)"})
        }else if (lidr < 100){
            setLidrClass({backgroundImage: "linear-gradient(to right, #ff0000, #ff001e, #ff0030, #ff0040, #ff004e)"})
        }else if (lidr >= 100){
            setLidrClass({backgroundImage: "linear-gradient(to right, #ff006f, #ea0081, #ce0092, #a900a3, #7800b0)"})
        }
    }

        const locationDetails = async (location) => {
            const lidr = await getLIDR(location)
            const locationDetails = await getLocationDetails(location)

            const details = {
                lidr: lidr,
                aqi: locationDetails.aqi,
                weather_f: locationDetails.weather.f,
                weather_c: locationDetails.weather.c,
                icon: locationDetails.weather.icon
            }
            return details
        }


        const getLIDR = async (location) => {
            console.log("getting LIDR here:", location)

            const zipcode = location.zipcode            
            try {
                const res = await axios.get(`https://ehomoi5b95.execute-api.us-east-1.amazonaws.com/dev/lidr/getLidrDataByZipcode`, {
                    params: { zipcode: zipcode },
                });

                const lidrArray = res.data.data
                const matchingLidr = lidrArray.find(lidr => 
                    lidr.adm1 === location.adm1 &&
                    lidr.adm2 === location.adm2 &&
                    lidr.adm3 === location.adm3 &&
                    lidr.cc === location.cc &&
                    lidr.cn === location.cn &&
                    lidr.pn === location.pn
                )

                const lidr = matchingLidr.details.lidrScore
                return lidr;
            } catch (error) {
                console.error(error);
            }
        }


        const controllerRef = useRef(null)
        //connects to zip code api to get location details
        const getLocations = async (zipcode) => {
            try {
                if(controllerRef.current) {
                    controllerRef.current.abort()
                }
                controllerRef.current = new AbortController()
                const res = await axios.get(`https://ehomoi5b95.execute-api.us-east-1.amazonaws.com/dev/zipcode/getAllPlacesByZipcodeStartWith`, {
                    params: { zipcodeStartWith: zipcode.toUpperCase() },
                    signal: controllerRef.current.signal
                });
                console.log(res.data);
                const zipcodes = res.data.places;
    
                let locationArray = [];
                for (const zip of zipcodes) {
                    for (const place of zip.places) {
                        const location = {...place, zipcode: zip.zipcode};
                        locationArray.push(location);
                    }
                };
                setZipExists(true);
                setZipResults(locationArray);
            }
            catch (error) {
                console.error(error);
                setZipExists(false);
                setZipResultStrings(['Please enter a valid zipcode']);
            }
        };
    
        useEffect(() => {
            //TODO: insert sorting logic here
            let locationArray = [];
            const sortedResults = [...zipResults].sort((a, b) => {
                if (a.zipcode < b.zipcode) return -1;
                if (a.zipcode > b.zipcode) return 1;
        
                if (a.pn < b.pn) return -1;
                if (a.pn > b.pn) return 1;
        
                if (a.adm3 < b.adm3) return -1;
                if (a.adm3 > b.adm3) return 1;
        
                if (a.adm2 < b.adm2) return -1;
                if (a.adm2 > b.adm2) return 1;
        
                if (a.adm1 < b.adm1) return -1;
                if (a.adm1 > b.adm1) return 1;
        
                if (a.cc < b.cc) return -1;
                if (a.cc > b.cc) return 1;
        
                return 0;
            });
            setSortedZipResults(sortedResults)
            for (const location of sortedResults) {
                const country = location.cc;
                //formatting the location details from smallest to largest
                locationArray.push(`${location.zipcode}, ${location.pn ? location.pn + ',' : ''} ${location.adm3 ? location.adm3 + ',' : ''} ${location.adm2 ? location.adm2 + ',' : ''} ${location.adm1 ? location.adm1 + ',' : ''} ${country}`);
            };
            setZipResultStrings(locationArray);
        }, [zipResults]);

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                autoDisplay: true,
            },
            "google_translate_element"
        );



    const setEnglishLanguage = () => {
        const languageDropdown = document.querySelector('.goog-te-combo');
        languageDropdown.children[0].textContent = "English"
    };

    const intervalId = setInterval(() => {
        setEnglishLanguage();
    }, 10); // Check every 500ms
    
    setTimeout(() => {
        clearInterval(intervalId);
    }, 1000); // Stop after 5 seconds
    /*
// sets default language to english when the page is refreshed
const setEnglishLanguage = () => {
    const languageDropdown = document.querySelector('.goog-te-combo');
    if (languageDropdown) {
        // Make the dropdown to select "English" for now
        if (languageDropdown.value !== 'en') {
            languageDropdown.value = 'en'; // Set the dropdown to English
            languageDropdown.dispatchEvent(new Event('change')); // Trigger the change event
        }
    }
};

const intervalId = setInterval(() => {
    setEnglishLanguage();
}, 10); // Check every 500ms

setTimeout(() => {
    clearInterval(intervalId);
}, 1000); // Stop after 5 seconds
*/
};



    useEffect(() => {
        var addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);

    /*
    useEffect(() => { //initially there is no data, automatically fetch it
        if (status === "idle2") {
            dispatch(fetchZipCode())
            if (data)
                checkGauge(data.aqi, Math.round(data.K))
        }
        if (data)
            checkGauge(data.aqi, Math.round(data.K))

        
    }, [dispatch, status])
    */

    useEffect(() => {
        if (!firstUpdate.current) { //only fetch data if zipcode changes and it's not the initial mount of Info
            dispatch(fetchByZipCode(zipCode))
       
        } else {
            firstUpdate.current = false
        }
        if (locationBool){
            inputRef.current.value = zipCode || ""
        }
    }, [zipCode, dispatch])

    let newDate = new Date();
    let year = newDate.getFullYear()
    let date = newDate.getDate();
    let month = newDate.toLocaleString("en-us", { month: "long" });


    return (
        <div className="info">
          
            <form className="info-left">
                <div className={"time_language"}>
                    <div>
                        <time>{` ${date} ${month} ${year} `}</time>
                    </div>
                    <div className="language-select">
                        <img src={worldIcon} alt={"world icon"}/>
                        <div id="google_translate_element"></div>
                    </div>

                </div>
                <div className="zipcode-row">
                    {!locDetails && !loading ?
                        <DropdownButton
                            ButtonAllTextColor={"black"}
                            ButtonPrimaryTextColor={'black'}
                            ButtonPrimaryBackground={'#f8f9fa'}
                            ButtonAllBackground={"#f8f9fa"}
                            ButtonFunctionChange={false}
                            ButtonAllWidth={'21.6rem'}
                            ButtonPrimaryWidth={'150px'}
                            ButtonPrimaryIcon={'block'}
                            BoxPosition={['0', '10px']}
                            FontSize={'11px'}
                            Overflow={true}
                            maxDropdownHeight={'230px'}
                            listHoverColor={'#00286E'}
                            ButtonBoxBorder={true}
                            ButtonAllRadius={'8px'}
                            ButtonPrimaryRadius={'20px'}
                            ButtonAllHeight={'25px'}
                            ButtonCharList={zipResultStrings}
                            maxPrimaryWidth={'12.5rem'}
                            Function={function (e) {
                                buttonSubmit(e)
                            }}
                            primaryBox={
                                <input
                                    className={(status === 'fulfilled' && !data) && 'error'}
                                    ref={inputRef}
                                    type="text"
                                    placeholder="Postal/Zip Code"
                                    onClick={() => inputRef.current.classList.remove('error')}
                                    onChange={e => {
                                        getLocations(e.target.value)
                                    }}
                                />

                            }
                        />

                        :
                        <div className="location-text">
                            <strong onClick={() => {
                                setLocationBool(!locationBool)
                                setLocDetails()
                                localStorage.setItem('location', null)
                                localStorage.setItem('location-header', null)
                            }}
                                    className={status}>
                                {selectedLocation ? selectedLocation : "Enter a valid ZipCode"}
                            </strong>
                        </div>

                    }

                </div>
            </form>
            <div className="info-center">
                <div id="temperatures">
                    <p className={`temp-f ${status}`}>
                        {(locDetails) ? Math.round(locDetails.weather_f) : '--'}° F
                    </p>
                    <p className={`temp-c ${status}` }>
                        {(locDetails) ? Math.round(locDetails.weather_c) : '--'}° C
                    </p>
                </div>
                {locDetails?.icon ?
                    <Link className={status} to="/success" state={{loggedIn:false}}><img src={locDetails.icon} className="weatherIcon" alt="weathericon"/>
                    </Link> :
                    <strong className={status} style={{marginLeft: '10px', marginTop: '-6px'}}>--</strong>
                }
            </div>
            <div className="info-right">
                <div className="gauge-visual">
                    <img className="horseshoe-icon" src={horseShoeIcon} alt="gaugeicon"/>
                    <div className={`donutGauge-icon ${gaugeClass}`} alt="donutIcon">
                        <div/>
                    </div>
                </div>

                <div className="gauge-info" role={'link'} onClick={()=>{window.open('https://openweathermap.org/api/air-pollution', "_blank", "noreferrer");}}>
                    <p className={status}>{(locDetails) ? locDetails.aqi : '--'}</p>
                    <span>AQI</span>
                    <div className={'gauge-hover-dir'}>
                        <b>Air Quality Index</b>
                    </div>
                </div>

            </div>

            <div className={"info-far-right"}>
                <div className={"lidr-visual"}>
                    <img src={lidrIcon} alt={"lidr icon"}/>
                    <div className={"lidr-visual-color"} style={lidrClass}/>
                </div>
                 <div className="lidr-info" role={'link'} onClick={()=>{window.open('https://openweathermap.org/api/air-pollution', "_blank", "noreferrer");}}>
                    <p className={status}>{(locDetails) ? Math.round(locDetails.lidr) : '--'} {/* default LIDR to 14 for now, need to change later! */}
                        {/* {(data && !locationBool) ? Math.round(data.K) : '--'} */}
                    </p>
                    <span>LIDR</span>
                    <div className={'gauge-hover-dir'}>
                        <div className={"lidr-hover-box"}>
                            <div className={"lidr-hover-header"}>
                                <h1>Local Infectious Disease Risk (LIDR)</h1>
                            </div>
                            <div className={"lidr-hover-main"}>
                                <div className={"lidr-main-right"}>
                                    <div className={"lidr-main-right-h"}>
                                        <h2>Risk Score:</h2>
                                    </div>
                                    <div className={"lidr-main-right-m"}>
                                        <div className={"right-m-gradient"}>
                                            <img src={lidrGradient} alt={"lidr gradient"}/>
                                        </div>
                                        <div className={"right-m-info"}>
                                            <span>0 = <i>low</i></span>
                                            <span>50 = <i>medium</i></span>
                                            <span>100+ = <i>high</i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"lidr-main-middle"}>
                                    <div className={"lidr-main-middle-h"}>
                                        <h2>Methodology:</h2>
                                    </div>
                                    <div className={"lidr-main-middle-m"}>
                                        <span>Prevalence:</span>
                                        <span>Severity:</span>
                                        <span>Health resources<br/>
                                            utilization:</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"lidr-hover-footer"}>
                                <span><b>Data source:</b> CDC; state and country public health agencies</span>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}