export const Open = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 1457 1464\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-7574.11 -440.264) matrix(.61777 0 0 .61777 5438.55 -2438.67)\">\n" +
    "        <ellipse\n" +
    "          cx=\"5162.14\"\n" +
    "          cy=\"2648.17\"\n" +
    "          fill=\"#362584\"\n" +
    "          rx=\"2173.37\"\n" +
    "          ry=\"2032.94\"\n" +
    "          transform=\"matrix(.54253 0 0 .5826 1835.39 4301.78)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(4640.75 5784.79) scale(24.3016) translate(-41.5 -33.5)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H83V67H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M42.052 65.237c17.737 0 32.116-14.38 32.116-32.118C74.168 15.38 59.789 1 42.052 1 24.315 1 9.936 15.38 9.936 33.119c0 17.738 14.379 32.118 32.116 32.118z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M20.512 21.658s1.651-6.802 8.809-7.72c0 0 5.999-.746 8.912 5.483 0 0-8.419-6.183-17.721 2.225v.012zM63.581 21.658s-1.652-6.802-8.809-7.72c0 0-5.999-.746-8.913 5.483 0 0 8.431-6.183 17.722 2.237z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M1 59.49s2.409-16.048 5.253-13.628c0 0 4.072-13.375 8.729-10.152 4.657 3.224 1.376 13.674 1.376 13.674s2.054-2.249 2.478-.195c.425 2.053-8.729 13.822-8.729 13.822\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M1 59.49s2.409-16.048 5.253-13.628c0 0 4.072-13.375 8.729-10.152 4.657 3.224 1.376 13.674 1.376 13.674s2.054-2.249 2.478-.195c.425 2.053-8.729 13.822-8.729 13.822\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M81.544 59.49s-2.409-16.048-5.254-13.628c0 0-4.071-13.375-8.728-10.152-4.657 3.224-1.377 13.674-1.377 13.674s-2.053-2.249-2.477-.195c-.425 2.053 8.729 13.822 8.729 13.822\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M81.544 59.49s-2.409-16.048-5.254-13.628c0 0-4.071-13.375-8.728-10.152-4.657 3.224-1.377 13.674-1.377 13.674s-2.053-2.249-2.477-.195c-.425 2.053 8.729 13.822 8.729 13.822\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M38.564 28.209s-5.482 7.846-14.785.78l14.785-.78z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M38.564 28.209s-5.482 7.846-14.785.78\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M46.535 28.209s5.483 7.846 14.785.78l-14.785-.78z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M46.535 28.209s5.483 7.846 14.785.78\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#F01F29\"\n" +
    "              stroke=\"#F01F29\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M31.433 50.544c2.914 1.927 6.389 3.098 10.174 3.098 4.244 0 8.098-1.457 11.184-3.809-3.854-2.386-10.828-2.397-10.828-2.397-6.71-.023-10.186 2.81-10.541 3.108h.011z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M23.427 39.43c.769 5.024 3.717 9.326 7.846 12.056 2.914 1.927 6.389 3.097 10.174 3.097 4.244 0 8.098-1.457 11.184-3.809 3.59-2.741 6.125-6.721 6.836-11.321-7.777 3.28-21.105 6.504-36.051-.023h.011z\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"