export const Tender = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 1508 1515\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-3866.68 -2192.87) matrix(.63943 0 0 .63943 1673.36 -2668.44)\">\n" +
    "        <ellipse\n" +
    "          cx=\"5162.14\"\n" +
    "          cy=\"2648.17\"\n" +
    "          fill=\"#AEEE7E\"\n" +
    "          rx=\"2173.37\"\n" +
    "          ry=\"2032.94\"\n" +
    "          transform=\"matrix(.54253 0 0 .5826 1808.64 7244.19)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(4627.4 8797.81) scale(23.3653) translate(-34.5 -35)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H69V70H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.5 68.004c18.502 0 33.5-14.999 33.5-33.502S53.002 1 34.5 1C15.999 1 1 15.999 1 34.502s14.999 33.502 33.5 33.502z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M30.229 9.734s1.256 5.791-3.853 9.13c0 0-4.295 2.788-8.817-.886 0 0 8.698 1.592 12.67-8.244zM38.771 9.734s-1.256 5.791 3.853 9.13c0 0 4.295 2.788 8.818-.886 0 0-8.699 1.592-12.671-8.244z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M44.753 44.565c0 3.721-4.594 6.736-10.253 6.736-5.66 0-10.254-3.015-10.254-6.736\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M44.753 44.565c0 3.721-4.594 6.736-10.253 6.736-5.66 0-10.254-3.015-10.254-6.736\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M30.385 28.543s-5.719 8.184-15.422.814l15.422-.814z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M30.385 28.543s-5.719 8.184-15.422.814\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M38.688 28.543s5.718 8.184 15.421.814l-15.421-.814z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M38.688 28.543s5.718 8.184 15.421.814\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#F97D85\"\n" +
    "              d=\"M56.598 47.306a7.43 7.43 0 10-.001-14.861 7.43 7.43 0 00.001 14.861zM11.002 47.306a7.43 7.43 0 10-.001-14.861 7.43 7.43 0 00.001 14.861z\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"