export const Courageous = '<svg\n' +
    '      xmlns="http://www.w3.org/2000/svg"\n' +
    '      fillRule="evenodd"\n' +
    '      strokeMiterlimit="10"\n' +
    '      clipRule="evenodd"\n' +
    '      viewBox="0 0 1539 1545"\n' +
    '    >\n' +
    '      <g transform="translate(-4024.5 -365.635) matrix(.65224 0 0 .65224 -158.068 -903.074)">\n' +
    '        <ellipse\n' +
    '          cx="5162.14"\n' +
    '          cy="2648.17"\n' +
    '          fill="#D57DCE"\n' +
    '          rx="2173.37"\n' +
    '          ry="2032.94"\n' +
    '          transform="matrix(.54253 0 0 .5826 4791.17 1586.74)"\n' +
    '        ></ellipse>\n' +
    '        <g transform="translate(7606.61 3123.79) scale(46.063) translate(-17.5 -18)">\n' +
    '          <clipPath id="_clip1">\n' +
    '            <path d="M0 0H35V36H0z"></path>\n' +
    '          </clipPath>\n' +
    '          <g fillRule="nonzero" clipPath="url(#_clip1)">\n' +
    '            <path\n' +
    '              fill="#fff"\n' +
    '              stroke="#231F20"\n' +
    '              strokeWidth="1"\n' +
    '              d="M17.837 34.619c9.205 0 16.666-7.462 16.666-16.667 0-9.205-7.461-16.667-16.666-16.667-9.204 0-16.665 7.462-16.665 16.667 0 9.205 7.461 16.667 16.665 16.667z"\n' +
    '            ></path>\n' +
    '            <path\n' +
    '              fill="#fff"\n' +
    '              stroke="#000"\n' +
    '              strokeWidth="0.5"\n' +
    '              d="M11.978 21.7c2.261 0 4.095-2.527 4.095-5.643 0-3.116-1.834-5.643-4.095-5.643-2.262 0-4.095 2.527-4.095 5.643 0 3.116 1.833 5.643 4.095 5.643z"\n' +
    '            ></path>\n' +
    '            <path d="M12.626 20.131c1.13 0 2.047-1.263 2.047-2.821 0-1.558-.917-2.822-2.047-2.822-1.131 0-2.048 1.264-2.048 2.822s.917 2.821 2.048 2.821z"></path>\n' +
    '            <path\n' +
    '              fill="#231F20"\n' +
    '              d="M26.32 9.311s-1.523-2.429-4.261-1.703c0 0-2.292.619-2.512 3.518 0 0 2.238-3.72 6.773-1.815zM17.222 8.103s-1.565 2.399-4.291 1.619c0 0-2.28-.661-2.447-3.566 0 0 2.167 3.762 6.738 1.947z"\n' +
    '            ></path>\n' +
    '            <path\n' +
    '              fill="#fff"\n' +
    '              stroke="#000"\n' +
    '              strokeWidth="0.5"\n' +
    '              d="M24.095 21.7c2.262 0 4.095-2.527 4.095-5.643 0-3.116-1.833-5.643-4.095-5.643-2.262 0-4.095 2.527-4.095 5.643 0 3.116 1.833 5.643 4.095 5.643z"\n' +
    '            ></path>\n' +
    '            <path d="M24.981 20.131c1.131 0 2.048-1.263 2.048-2.821 0-1.558-.917-2.822-2.048-2.822s-2.047 1.264-2.047 2.822.916 2.821 2.047 2.821z"></path>\n' +
    '            <path\n' +
    '              fill="#fff"\n' +
    '              d="M12.625 26.215s5.309 2.048 10.428 0H12.625z"\n' +
    '            ></path>\n' +
    '            <path\n' +
    '              fill="none"\n' +
    '              stroke="#231F20"\n' +
    '              strokeLinecap="round"\n' +
    '              strokeLinejoin="round"\n' +
    '              strokeMiterlimit="4"\n' +
    '              strokeWidth="1"\n' +
    '              d="M12.625 26.215s5.309 2.048 10.428 0"\n' +
    '            ></path>\n' +
    '          </g>\n' +
    '        </g>\n' +
    '      </g>\n' +
    '    </svg>'