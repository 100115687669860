export const Tense = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 1543 1550\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-7508.31 -2163.44)\">\n" +
    "        <ellipse\n" +
    "          cx=\"5162.14\"\n" +
    "          cy=\"2648.17\"\n" +
    "          fill=\"#E0DC48\"\n" +
    "          rx=\"2173.37\"\n" +
    "          ry=\"2032.94\"\n" +
    "          transform=\"matrix(.35486 0 0 .38107 6447.72 1929)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(8298.23 2947.58) scale(14.8922) translate(-34.5 -35)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H69V70H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.5 68.004c18.502 0 33.5-14.999 33.5-33.502S53.002 1 34.5 1C15.999 1 1 15.999 1 34.502s14.999 33.502 33.5 33.502z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M43.582 54.974c-.658-4.308-4.451-7.634-9.081-7.634s-8.411 3.314-9.081 7.622c3.912-1.652 10.636-3.279 18.162 0v.012z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M29.163 21.568a14.474 14.474 0 01-8.973 2.106c-1.16 1.604-2.01 3.65-2.273 5.995-.634 5.564 2.118 10.457 6.162 10.924 4.043.467 7.836-3.673 8.47-9.249.479-4.164-.945-7.933-3.374-9.776h-.012z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M29.762 33.689c.319-2.784-1.062-5.228-3.084-5.46-2.022-.231-3.919 1.838-4.238 4.621-.318 2.784 1.062 5.228 3.084 5.46 2.022.231 3.92-1.838 4.238-4.621z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M32.143 13.898s-1.723 7.096-9.188 8.053c0 0-6.258.778-9.297-5.719 0 0 8.782 6.449 18.485-2.322v-.012zM36.869 13.898s1.723 7.096 9.189 8.053c0 0 6.257.778 9.296-5.719 0 0-8.794 6.449-18.485-2.334z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M40.673 21.568a14.479 14.479 0 008.974 2.106c1.16 1.604 2.01 3.65 2.273 5.995.634 5.564-2.118 10.457-6.162 10.924-4.044.467-7.836-3.673-8.47-9.249-.479-4.164.945-7.933 3.373-9.776h.012z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M44.272 38.353c2.022-.232 3.403-2.676 3.084-5.459-.318-2.784-2.216-4.853-4.238-4.622-2.022.232-3.403 2.676-3.084 5.46.319 2.783 2.216 4.852 4.238 4.621z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#00ADEB\"\n" +
    "              d=\"M49.24 2.365s-6.939 11.523 0 11.523c6.94 0 0-11.523 0-11.523z\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"