export const Info = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      width=\"17\"\n" +
    "      height=\"16\"\n" +
    "      fill=\"none\"\n" +
    "      viewBox=\"0 0 17 16\"\n" +
    "    >\n" +
    "      <path\n" +
    "        fill=\"linear-gradient(216.87deg, #FAA945 15.37%, #ED5944 48.66%, #E22E5C 82.33%)\"\n" +
    "        d=\"M8.17 4.673h1.333v1.334H8.17V4.673zm0 2.667h1.333v4H8.17v-4zm.667-6A6.67 6.67 0 002.17 8.007a6.67 6.67 0 006.667 6.666 6.67 6.67 0 006.666-6.666A6.67 6.67 0 008.837 1.34zm0 12a5.34 5.34 0 01-5.334-5.333 5.34 5.34 0 015.334-5.334 5.34 5.34 0 015.333 5.334 5.34 5.34 0 01-5.333 5.333z\"\n" +
    "      ></path>\n" +
    "      <defs>\n" +
    "        <linearGradient\n" +
    "          id=\"paint0_linear_2877_8456\"\n" +
    "          x1=\"12.336\"\n" +
    "          x2=\"4.836\"\n" +
    "          y1=\"2.551\"\n" +
    "          y2=\"12.551\"\n" +
    "          gradientUnits=\"userSpaceOnUse\"\n" +
    "        >\n" +
    "          <stop stopColor=\"#FAA945\"></stop>\n" +
    "          <stop offset=\"0.497\" stopColor=\"#ED5944\"></stop>\n" +
    "          <stop offset=\"1\" stopColor=\"#E22E5C\"></stop>\n" +
    "        </linearGradient>\n" +
    "      </defs>\n" +
    "    </svg>"