import actions from '../../../../images/ftt-process/actions.jpg'
import { motion } from 'framer-motion'

const Actions = ({ emotionalData }) => {
    function truncate(string, num) {
        return string?.length > num ? string.substr(0, num - 1) + '...' : string;
    }
    const newData = "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean m\n"
   
    return (
        <motion.div
            initial={{ x: 250, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="myftt">
            {/*<div className='header'>*/}
            {/*    <h2>My Feelings Flow Tracker</h2>*/}
            {/*    <div className="colorLine" style={{ width: "100%", height: "6px" }} />*/}
            {/*</div>*/}

            <div className='process-container'
                style={{ backgroundImage: `url(${actions})`, backgroundSize: "cover" }}
            >
                <div className='header-container'>
                    {/*<p>Process: Actions</p>*/}
                    <p>...and I took the following <b>Actions</b> in response to my feelings and body sensations:</p>
                </div>
                <div className='body-container'>
                    {/*<div className='body-description'>*/}
                        {/*<p>... and I took the following actions </p>*/}
                        {/*<p>in response to my feelings and</p>*/}
                        {/*<p>body sensations</p>*/}
                    {/*</div>*/}
                    <div className='body-summary' style={{marginTop: "-6.2rem"}}>
                        <p> {emotionalData.q5}</p>
                        {/*<p> "I worked out for 1 hour and I made myself a lavender tea." </p>*/}
                    </div>
                </div>
            </div>

        </motion.div>
    )
}
export default Actions;