import thoughtsBackground from '../../../../images/ftt-process/thoughts.jpg'
import {motion} from 'framer-motion'

const Thoughts = ({emotionalData}) => {
    function truncate(string, num) {
        return string?.length > num ? string.substr(0, num - 1) + '...' : string;
    }
    return(
        <motion.div
            initial={{x: 250, opacity: 0}}
            animate={{x: 0, opacity: 1}}
            transition={{delay: 0.2}}
            className="myftt">
            {/*<div className='header'>*/}
            {/*    <h2>My Feelings Flow Tracker</h2>*/}
            {/*    <div className="colorLine" style={{width: "100%", height: "6px"}}/>*/}
            {/*</div>*/}
            <div className='process-container'
                 style={{
                     backgroundImage: `url(${thoughtsBackground})`, backgroundSize: "cover",
                     // margin: 5, zIndex: -10
                 }}
            >
                <div className='header-container'>
                    <p>The following <b>Thought(s)</b> on my mind....</p>
                </div>
                <div className='body-container'>
                    {/*<div className='body-description'>*/}
                    {/*    <p></p>*/}
                    {/*</div>*/}
                    <div className='body-summary' style={{marginTop: "-6.2rem"}}>
                         <p> {emotionalData.q3}</p>
                        {/*<p> "I am thinking to start an exercise challenge, which makes me feel energetic..."</p>*/}
                    </div>
                </div>
            </div>

        </motion.div>
    )
}
export default Thoughts;