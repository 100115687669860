import React from "react";
import "./FeelingsFlowSurvey.css";
import {Joy} from "../../../images/FTT-Feelings-Icons/Joy"
import {Grateful} from "../../../images/FTT-Feelings-Icons/Grateful";
import {Courageous} from "../../../images/FTT-Feelings-Icons/Courageous";
import {Loving} from "../../../images/FTT-Feelings-Icons/Loving";
import {Open} from "../../../images/FTT-Feelings-Icons/Open";
import {Hopeful} from "../../../images/FTT-Feelings-Icons/Hopeful";
import {Curious} from "../../../images/FTT-Feelings-Icons/Curious";
import {Numb} from "../../../images/FTT-Feelings-Icons/Numb";
import {Tense} from "../../../images/FTT-Feelings-Icons/Tense";
import {Doubt} from "../../../images/FTT-Feelings-Icons/Doubt";
import {Fragile} from "../../../images/FTT-Feelings-Icons/Fragile";
import {Despair} from "../../../images/FTT-Feelings-Icons/Despair";
import {Tender} from "../../../images/FTT-Feelings-Icons/Tender";
import {Shame} from "../../../images/FTT-Feelings-Icons/Shame";
import {Angry} from "../../../images/FTT-Feelings-Icons/Angry";
import {Guilt} from "../../../images/FTT-Feelings-Icons/Guilt";
import {Fear} from "../../../images/FTT-Feelings-Icons/Fear";
import {Powerless} from "../../../images/FTT-Feelings-Icons/Powerless";
import {Info} from "../../../images/FTT-Feelings-Icons/Info"


const ftt_pf_item1 = "<span class='flow-survey-json-span'><span>Aliveness</span><div/><span>Joy</span></span>"
const ftt_pf_item2 = "<span class='flow-survey-json-span flow-survey-json-span-single'><span>Grateful</span></span>"
const ftt_pf_item3 = "<span class='flow-survey-json-span'><span>Courageous</span><div/><span>Powerful</span></span>"
const ftt_pf_item4 = "<span class='flow-survey-json-span'><span>Connected</span><div/><span>Loving</span></span>"
const ftt_pf_item5 = "<span class='flow-survey-json-span'><span>Accepted</span><div/><span>Open</span></span>"
const ftt_pf_item6 = "<span class='flow-survey-json-span flow-survey-json-span-single'><span>Hopeful</span></span>"
const ftt_pf_item7 = "<span class='flow-survey-json-span flow-survey-json-span-single'><span>Curious</span></span>"
const ftt_pf_item8 = "<span class='flow-survey-json-span flow-survey-json-span-single'><span>Tender</span></span>"
const ftt_pf_item9 = "<span class='flow-survey-json-span'><span>Disconnected</span><div/><span>Numb</span></span>"
const ftt_pf_item10 = "<span class='flow-survey-json-span'><span>Stressed</span><div/><span>Tense</span></span>"
const ftt_pf_item11 = "<span class='flow-survey-json-span'><span>Unsettled</span><div/><span>Doubt</span></span>"
const ftt_pf_item12 = "<span class='flow-survey-json-span flow-survey-json-span-single'><span>Fragile</span></span>"
const ftt_pf_item13 = "<span class='flow-survey-json-span'><span>Despair</span><div/><span>Sad</span></span>"
const ftt_pf_item14 = "<span class='flow-survey-json-span'><span>Embarrassed</span><div/><span>Shame</span></span>"
const ftt_pf_item15 = "<span class='flow-survey-json-span'><span>Angry</span><div/><span>Annoyed</span></span>"
const ftt_pf_item16 = "<span class='flow-survey-json-span flow-survey-json-span-single'><span>Guilt</span></span>"
const ftt_pf_item17 = "<span class='flow-survey-json-span flow-survey-json-span-single'><span>Fear</span></span>"
const ftt_pf_item18 = "<span class='flow-survey-json-span flow-survey-json-span-single'><span>Powerless</span></span>"

const info_PF = `<button class='flow-survey-json-info' id="flow-survey-json-info_pf_def">${Info}</button>`
const info_AF = `<button class='flow-survey-json-info' id="flow-survey-json-info_af_def">${Info}</button>`
const info_BS = `<button class='flow-survey-json-info' id="flow-survey-json-info_bs_def">${Info}</button>`



const fftJSON = {
    "pages": [
     {
      "name": "Primary Feelings",
      "elements": [
       {
        "type": "radiogroup",
        "name": "question1",
        "title": `Which best describes your **Primary Feeling** over the past 24 hours? ${info_PF}`,
        "isRequired": true,
        "choices": [
         {
          "value": "item1",
          "text":  `${Joy} ${ftt_pf_item1}`
         },
         {
          "value": "item2",
          "text": `${Grateful} ${ftt_pf_item2}`
         },
         {
          "value": "item3",
          "text": `${Courageous} ${ftt_pf_item3}`
         },
         {
          "value": "item4",
          "text": `${Loving} ${ftt_pf_item4}`
         },
         {
          "value": "item5",
          "text": `${Open} ${ftt_pf_item5}`
         },
         {
          "value": "item6",
          "text": `${Hopeful} ${ftt_pf_item6}`
         },
         {
          "value": "item7",
          "text": `${Curious} ${ftt_pf_item7}`
         },
         {
          "value": "item8",
          "text": `${Tender} ${ftt_pf_item8}`
         },
         {
          "value": "item9",
          "text": `${Numb} ${ftt_pf_item9}`
         },
         {
          "value": "item10",
          "text": `${Tense} ${ftt_pf_item10}`
         },
         {
          "value": "item11",
          "text": `${Doubt} ${ftt_pf_item11}`
         },
         {
          "value": "item12",
          "text": `${Fragile} ${ftt_pf_item12}`
         },
         {
          "value": "item13",
          "text": `${Despair}${ftt_pf_item13}`
         },
         {
          "value": "item14",
          "text": `${Shame} ${ftt_pf_item14}`
         },
         {
          "value": "item15",
          "text": `${Angry} ${ftt_pf_item15}`
         },
         {
          "value": "item16",
          "text": `${Guilt} ${ftt_pf_item16}`
         },
         {
          "value": "item17",
          "text": `${Fear} ${ftt_pf_item17}`
         },
         {
          "value": "item18",
          "text": `${Powerless} ${ftt_pf_item18}`
         }
        ],
        "colCount": 2
       }
      ],
     },
     {
      "name": "Associated Feelings",
      "elements": [
       // {
       //
       //  name: 'question3',
       //  title: "valueName equals to question name from the first page",
       //  type: 'text',
       //  valueName: "name"
       //
       // },

       {
        "type": "html",
        "name": "Associated Feelings Icon",
        "html": ""
       },


       {
        "type": "checkbox",
        "name": "question2_Accepting",
        "visibleIf": "{question1} = 'item5'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1
         }
        ],
        "choices": [
         "Amazed"
         ,
         "Awe"
         ,
         "Bliss"
         ,
         "Delighted"
         ,
         "Eager"
         ,
         "Ecstatic"
         ,
         "Enchanted"
         ,
         "Energized"
         ,
         "Engaged"
         ,
         "Enthusiastic"
         ,
         "Enthusiastic"
         ,
         "Excited"
         ,
         "Free"
         ,
         "Happy"
         ,
         "Inspired"
         ,
         "Invigorated"
         ,
         "Lively"
         ,
         "Passionate"
         ,
         "Playful"
         ,
         "Radiant"
         ,
         "Refreshed"
         ,
         "Rejuvenated"
         ,
         "Renewed"
         ,
         "Satisfied"
         ,
         "Thrilled"
         ,
         "Vibrant"

        ]
       },
       {
        "type": "checkbox",
        "name": "question2_Aliveness",
        "visibleIf": "{question1} = 'item1'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1,
          "maxCount": 12
         }
        ],
        "choices": [
         "Amazed"
         ,
         "Awe"
         ,
         "Bliss"
         ,
         "Delighted"
         ,
         "Eager"
         ,
         "Ecstatic"
         ,
         "Enchanted"
         ,
         "Energized"
         ,
         "Engaged"
         ,
         "Enthusiastic"
         ,
         "Free"
         ,
         "Happy"
         ,
         "Inspired"
         ,
         "Invigorated"
         ,
         "Lively"
         ,
         "Passionate"
         ,
         "Playful"
         ,
         "Radiant"
         ,
         "Refreshed"
         ,
         "Rejuvenated"
         ,
         "Renewed"
         ,
         "Satisfied"
         ,
         "Thrilled"
         ,
         "Vibrant"

        ],
        "maxSelectedChoices": ""
       },
       {
        "type": "checkbox",
        "name": "question2_Angry",
        "visibleIf": "{question1} = 'item15'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1,
          "maxCount": 12
         }
        ],
        "choices": [
         "Agitated",
         "Aggravated",
         "Bitter",
         "Contempt",
         "Cynical",
         "Disdain",
         "Disgruntled",
         "Disturbed",
         "Edgy",
         "Exasperated",
         "Frustrated",
         "Furious",
         "Grouchy",
         "Hostile",
         "Impatient",
         "Irritated",
         "Irate",
         "Moody",
         "On edge",
         "Outraged",
         "Pissed",
         "Resentful",
         "Upset",
         "Vindictive"
        ],
        "maxSelectedChoices": ""
       },
       {
        "type": "checkbox",
        "name": "question2_Courageous",
        "visibleIf": "{question1} = 'item3'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1,
          "maxCount": 12
         }
        ],
        "choices": [
         "Adventurous",
         "Brave",
         "Capable",
         "Confident",
         "Daring",
         "Determined",
         "Free",
         "Grounded",
         "Proud",
         "Strong",
         "Worthy",
         "Valiant"
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Connected",
        "visibleIf": "{question1} = 'item4'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1,
          "maxCount": 12
         }
        ],
        "choices": [
         "Accepting",
         "Affectionate",
         "Caring",
         "Compassion",
         "Empathy",
         "Fulfilled",
         "Present",
         "Safe",
         "Warm",
         "Worthy"
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Curious",
        "visibleIf": "{question1} = 'item7'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1
         }
        ],
        "choices": [
         "Engaged",
         "Exploring",
         "Fascinated",
         "Interested",
         "Intrigued",
         "Involved",
         "Stimulated"
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Despair",
        "visibleIf": "{question1} = 'item13'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1,
          "maxCount": 12
         }
        ],
        "choices": [
         "Anguish",
         "Depressed",
         "Despondent",
         "Disappointed",
         "Discouraged",
         "Forlorn",
         "Gloomy",
         "Grief",
         "Heartbroken",
         "Hopeless",
         "Lonely",
         "Longing",
         "Melancholy",
         "Sorrow",
         "Teary",
         "Unhappy",
         "Upset",
         "Weary",
         "Yearning"
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Disconnected",
        "visibleIf": "{question1} = 'item9'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "choices": [
         "Aloof",
         "Bored",
         "Confused",
         "Distant",
         "Empty",
         "Indifferent",
         "Isolated",
         "Lethargic",
         "Listless",
         "Removed",
         "Resistant",
         "Shut Down",
         "Uneasy",
         {
          "value": "Withdraw",
          "text": "Withdrawn"
         }
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Embarrassed",
        "visibleIf": "{question1} = 'item14'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1
         }
        ],
        "choices": [
         "Ashamed",
         "Humiliated",
         "Inhibited",
         "Mortified",
         "Self-conscious",
         "Useless",
         "Weak",
         "Worthless"
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Fear",
        "visibleIf": "{question1} = 'item17'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "choices": [
         "Afraid",
         "Anxious",
         "Apprehensive",
         "Frightened",
         "Hesitant",
         "Nervous",
         "Panic",
         "Paralyzed",
         "Scared",
         "Terrified",
         "Worried"
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Fragile",
        "visibleIf": "{question1} = 'item12'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1
         }
        ],
        "choices": [
         "Helpless",
         "Sensitive"
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Grateful",
        "visibleIf": "{question1} = 'item2'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1,
          "maxCount": 12
         }
        ],
        "choices": [
         "Appreciative",
         "Blessed",
         "Delighted",
         "Fortunate",
         "Grace",
         "Humbled",
         "Lucky",
         "Moved",
         "Thankful",
         "Touched"
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Guilt",
        "visibleIf": "{question1} = 'item16'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1,
          "maxCount": 12
         }
        ],
        "choices": [
         "Regret",
         "Remorseful",
         "Sorry"
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Hopeful",
        "visibleIf": "{question1} = 'item6'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1
         }
        ],
        "choices": [
         "Encouraged",
         "Expectant",
         "Optimistic",
         "Trusting"
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Powerless",
        "visibleIf": "{question1} = 'item18'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1,
          "maxCount": 12
         }
        ],
        "choices": [
         "Impotent",
         "Incapable",
         "Resigned",
         "Trapped",
         "Victim"
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Tender",
        "visibleIf": "{question1} = 'item8'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1,
          "maxCount": 12
         }
        ],
        "choices": [
         "Calm",
         "Caring",
         "Loving",
         "Reflective",
         "Self-loving",
         "Serene",
         "Vulnerable",
         "Warm"
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Stressed",
        "visibleIf": "{question1} = 'item10'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1,
          "maxCount": 12
         }
        ],
        "choices": [
         "Anxious",
         "Burned out",
         "Cranky",
         "Depleted",
         "Edgy",
         "Exhausted",
         "Frazzled",
         "Overwhelm",
         "Rattled",
         "Rejecting",
         "Restless",
         "Shaken",
         "Tight",
         "Weary",
         "Worn out"
        ],
        "maxSelectedChoices": 12
       },
       {
        "type": "checkbox",
        "name": "question2_Unsettled",
        "visibleIf": "{question1} = 'item11'",
        "title": `What **Associated Feeling(s)** have you had over the past 24 hours? ${info_AF}`,
        "isRequired": true,
        "requiredErrorText": "Must choose between 1 and 12 options.",
        "validators": [
         {
          "type": "answercount",
          "text": "Must choose between 1 and 12 options.",
          "minCount": 1,
          "maxCount": 12
         }
        ],
        "choices": [
         "Apprehensive",
         "Concerned",
         "Dissatisfied",
         "Disturbed",
         "Grouchy",
         "Hesitant",
         "Inhibited",
         "Perplexed",
         "Questioning",
         "Rejecting",
         "Reluctant",
         "Shocked",
         "Skeptical",
         "Suspicious",
         "Ungrounded",
         "Unsure",
         "Worried"
        ],
        "maxSelectedChoices": 12
       }
      ],
     },
     {
      "name": "Thoughts",
      "elements": [
       {
        "type": "comment",
        "name": "question3",
        "title": "What **Thought(s)** have been on your mind that gave rise to your feelings?",
        "isRequired": true,
        "minLength": 1,
        "placeholder": "I am thinking about my family coming to visit me",
        "maxValueExpression": 300,
        "maxLength": 300,
        "rows": 6,
        "validators": [
         {
            "type": "text",
            "text": "Max length 300 characters"
         }
        ]
       }
      ],
     },
     {
      "name": "Body Sensations",
      "elements": [
       {
        "type": "panel",
        "name": "panel1",
        "title": `How did your feelings show up as **Body Sensation(s)**? ${info_BS}`,
        "isRequired": true,
        "elements": [
         {
          "type": "radiogroup",
          "name": "question4_Letters",
          "title": " ",
          "defaultValue": "item1",
          "isRequired": true,
          "choices": [
           {
            "value": "item1",
            "text": "A - F"
           },
           {
            "value": "item2",
            "text": "G - L"
           },
           {
            "value": "item3",
            "text": "M - R"
           },
           {
            "value": "item4",
            "text": "S - Z"
           }
          ],
          "colCount": 4
         },
         {
          "type": "checkbox",
          "name": "question4_A-F",
          "visibleIf": "{question4_Letters} = 'item1'",
          "title": " ",
          "isRequired": true,
          "requiredErrorText": "Must choose between 1 and 12 options.",
          "validators": [
           {
            "type": "answercount",
            "text": "Must choose between 1 and 12 options.",
            "minCount": 0,
            "maxCount": 12
           }
          ],
          "choices": [
           "Achy",
           "Airy",
           "Blocked",
           "Breathless",
           "Bruised",
           "Burning",
           "Buzzy",
           "Clammy",
           "Clenched",
           "Cold",
           "Constricted",
           "Contained",
           "Contracted",
           "Dizzy",
           "Drained",
           "Dull",
           "Electric",
           "Empty",
           "Expanded",
           "Flowing",
           "Fluttery",
           "Frozen",
           "Full",
           "Fluid"
          ],
          "colCount": 3,
          "maxSelectedChoices": ""
         },
         {
          "type": "checkbox",
          "name": "question4_G-L",
          "visibleIf": "{question4_Letters} = 'item2'",
          "title": " ",
          "isRequired": true,
          "requiredErrorText": "Must choose between 1 and 12 options.",
          "validators": [
           {
            "type": "answercount",
            "text": "Must choose between 1 and 12 options.",
            "minCount": 0,
            "maxCount": 12
           }
          ],
          "choices": [
           "Gentle",
           "Hard",
           "Heavy",
           "Hollow",
           "Hot",
           "Icy",
           "Itchy",
           "Jumpy",
           "Knotted",
           "Light",
           "Loose"
          ],
          "colCount": 3,
          "maxSelectedChoices": ""
         },
         {
          "type": "checkbox",
          "name": "question4_M-R",
          "visibleIf": "{question4_Letters} = 'item3'",
          "title": " ",
          "isRequired": true,
          "requiredErrorText": "Must choose between 1 and 12 options.",
          "validators": [
           {
            "type": "answercount",
            "text": "Must choose between 1 and 12 options.",
            "minCount": 1,
            "maxCount": 12
           }
          ],
          "choices": [
           "Nauseous",
           "Numb",
           "Pain",
           "Pounding",
           "Prickly",
           "Pulsing",
           "Queasy",
           "Radiating",
           "Relaxed",
           "Releasing",
           "Rigid"
          ],
          "colCount": 3,
          "maxSelectedChoices": ""
         },
         {
          "type": "checkbox",
          "name": "question4_S-Z",
          "visibleIf": "{question4_Letters} = 'item4'",
          "title": " ",
          "isRequired": true,
          "requiredErrorText": "Must choose between 1 and 12 options.",
          "validators": [
           {
            "type": "answercount",
            "text": "Must choose between 1 and 12 options.",
            "minCount": 0,
            "maxCount": 12
           }
          ],
          "choices": [
           "Sensitive",
           "Settled",
           "Shaky",
           "Shivery",
           "Slow",
           "Smooth",
           "Soft",
           "Sore",
           "Spacey",
           "Spacious",
           "Sparkly",
           "Stiff",
           "Still",
           "Suffocated",
           "Sweaty",
           "Tender",
           "Tense",
           "Throbbing",
           "Tight",
           "Tingling",
           "Trembly",
           "Twitchy",
           "Vibrating",
           "Warm",
           "Wobbly",
           "Wooden"
          ],
          "colCount": 3,
          "maxSelectedChoices": ""
         }
        ],
       }
      ],
     },
     {
      "name": "Actions",
      "elements": [
       {
        "type": "comment",
        "name": "question5",
        "title": "What **Action(s)** did you take, to process your feelings and body sensations?",
        "isRequired": true,
        "placeholder": "I went for a run.",
        "maxLength": 300,
        "rows": 6
       }
      ],
     }
    ],
    "showQuestionNumbers": "off",
    "requiredText": ""
   }

export default fftJSON;