import cities from "./StatesCitiesZipcode/USCities.json"
import states from "./StatesCitiesZipcode/USStates.json"

export const getZipcodeDetails = (data) => {
    let newData = data.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
        letter.toUpperCase()
    );
    const breakText = newData.split(/\s/).filter(Boolean);
    newData = breakText.join(' ')
    if (!newData) {
        return null;
    }

    let list = [];

    for (let el of cities){
        const fullState = states.filter(
            (name) => name.abbreviation === el.state
          );
        const newList = [`${el.city} ${el.state}`,
                         `${el.state} ${el.city}`,
                         `${el.city} ${fullState && fullState[0]? fullState[0].name : ''}`,
                         `${fullState && fullState[0]? fullState[0].name : ''} ${el.city}`,
                         `${el.zip_code}`      
                        ]
        for (let x of newList ){
            if (newData.substring(0, newData.length) === x.substring(0, newData.length)){
                list.push(
                    `${el.zip_code}, ${el.city}${el.county ? ', ' + el.county : ''}, ${el.state}, USA `
                );
            }
        }
        list.splice(5);
    }

    return list
}