import React, {useEffect,useState, useRef} from 'react';
import logo_civ from "./images/LogoUpdate.svg";
import './animation.css';
import styled from "styled-components";
import { Slogan } from '../../Shared/Slogan';
import { useLocation, useNavigate } from 'react-router-dom';

const CivV2 = styled.div.attrs(props =>({
    className: props.className
}))`
  
   background: linear-gradient(89.97deg, rgba(242, 37, 104, ${(props) => props.opacity}) 4.75%, rgba(226, 57, 107, ${(props) => props.opacity}) 17.64%, rgba(250, 59, 61, ${(props) => props.opacity}) 30.84%, rgba(241, 93, 42, ${(props) => props.opacity}) 45.55%, rgba(242, 116, 68, ${(props) => props.opacity}) 61.78%, rgba(255, 200, 67, ${(props) => props.opacity}) 84.22%);

`;

export const Reward = (props) => {
    const navigate = useNavigate();
    const [counter, setCounter] = useState(1);
    const interval =  useRef(null);
    const location = useLocation();

    const {loggedIn=true} = location.state || {};

    const clear = () => {
        clearInterval(interval.current)
    }

    useEffect(()=> {
        if(!loggedIn) {
            setTimeout(()=>navigate('/'), 5600);
        }
    }, [])

    useEffect(() => {
         interval.current = setInterval(() => {
            setCounter(counter - .01);
        }, 40);
        console.log(counter);
        return () => clear();
        
    }, [counter]);

    useEffect(() => {
        if (counter === 0.5899999999999996) {
            clear()
        }
    }, [counter])


    return(
            <div className='civ_loading'> {/*The background size frame*/}
                <div className='civ_loading_v1'/> {/*The background loading animation */}
                <CivV2 className='civ_loading_v2' opacity={counter}> 
                    <img src={logo_civ} className={'civ_loading_img1'}/> {/*The logo loading animation */}
                    <div className={'civ_loading_img2'} />{/*The coin loading animation */}
                    {!loggedIn && 
                        <> 
                            <p className='civ_welcome_text'>Welcome To </p> 
                            <p className='civ_welcome_text2'><Slogan/></p> 
                        </>
                    }
                </CivV2>                    
            </div>

    )
}