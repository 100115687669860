import {useState, useEffect, Fragment} from "react";
import styled from "styled-components";
import {} from "@material-ui/icons"
import {IoClose} from "react-icons/io5";
import leftArrow from "../../../images/arrow_fft_pf_left.svg"
import rightArrow from "../../../images/arrow_fft_pf_right.svg"
import glossaryData from "./glossary-data";


// WORK IN PROGRESS. NOT FINAL. REALLY NOT GOOD RIGHT NOW.
const Box = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    width: 23.5rem;
    height: auto;
    background: #f8f9fa;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 35%);
    overflow: hidden;
`;

const Header = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px 10px 10px 10px;
    height: 100px;
    background-color: ${(props) => props.color};
    -webkit-transition-duration: 0.30s; /* Safari */
    transition-duration: 0.30s;
`;

const CloseBox = styled.div.attrs(props => ({
    className: props.className
}))`
    z-index: 1;
    width: 100%;
    display: flex;

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 5px;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        margin-left: auto;

        svg {
            height: 25px;
            width: 25px;
            color: ${(props) => props.color};
            -webkit-transition-duration: 0.30s; /* Safari */
            transition-duration: 0.30s;
        }
    }
`;

const Header_info = styled.div.attrs(props => ({
    className: props.className
}))`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: -30px;
    gap: 5px;

    img {
        height: 50px;
        width: 50px;
    }

    span {
        font-family: Poppins;
        font-weight: 600;
        color: ${(props) => props.color};
        -webkit-transition-duration: 0.30s; /* Safari */
        transition-duration: 0.30s;
    }
`;

const Main_slider_box = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 20px;
`;

const Abstract = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: ${(props) => props.marginLeft};

    p {
        font-weight: 400 !important;
        font-size: 11px;
        padding-right: ${(props) => props.paddingRight};
        color: ${(props) => props.color};
        margin-left: auto;
    }

    img {
        height: 5px;
        width: 100%;
    }
`;

const Slider = styled.div.attrs(props => ({
    className: props.className
}))`
    input {
        -webkit-appearance: none;
        overflow: visible;
        appearance: none;
        width: 100%;
        cursor: pointer;
        outline: none;
        border-radius: 15px;
        height: 8px;
        background: linear-gradient(to right,
        #F0E3F0 5.5%,
        #F6C5F6 5.5% 11%,
        #D57DCE 11% 16.5%,
        #B22C96 16.5% 22%,
            //#77B1E4 22% 27.5%,
        #362584 22% 27.5%,
        #3C90F0 27.5% 33%,
            //#362584 33% 38.5%,
        #3C90F0 33% 38.5%,
        #AEEE7E 38.5% 44%,
        #F3ED58 44% 49.5%,
        #E0DC48 49.5% 55%,
        #E57F30 55% 60.5%,
        #C15023 60.5% 66%,
        #BE3A06 66% 71.5%,
        #D94941 71.5% 77%,
        #D33022 77% 82.5%,
        #897476 82.5% 88%,
        #44403D 88% 93.5%,
        #000002 93.5% 100%);
    }

    input::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        height: 19px;
        width: 18px;
        background: ${(props) => props.color};
        border-radius: 7px;
        border: 1.5px solid white;
        transition: .2s ease-in-out;
    }

    input::-moz-range-thumb {
        -webkit-appearance: none;
        appearance: none;
        height: 19px;
        width: 18px;
        background: ${(props) => props.color};
        border-radius: 7px;
        border: 1.5px solid white;
        transition: .2s ease-in-out;
    }
`;

const Main_name = styled.div.attrs(props => ({
    className: props.className
}))`
    padding: 0 40px 0 20px;
    display: flex;
    flex-direction: row;
    height: 39px;
    position: relative;
`;


const Main_name_box = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    position: relative;
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    border-radius: 50px;
    border: 2px solid ${(props) => props.color};
    overflow: hidden;
    background: ${(props) => props.button ? "none" : props.color};
    pointer-events: ${(props) => props.button ? "auto" : "none"};
    cursor: ${(props) => props.button ? "pointer" : "default"};;
`;

const Main_name_toggle = styled.div.attrs(props => ({
    className: props.className
}))`
    position: absolute;
    display: ${(props) => props.button ? "none" : "flex"};
    background: ${(props) => props.color};
    margin-left: ${(props) => props.primary ? "0" : "50%"};
    width: 50%;
    height: 100%;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
    border-radius: 50px;
    pointer-events: none;
    cursor: default;

`;

const Main_name_context = styled.div.attrs(props => ({
    className: props.className
}))`

    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: center;
    pointer-events: none;
    cursor: default;
    transition: all 0.15s;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        min-width: 50%;

    }

    span {
        z-index: 1;
        user-select: none;
        color: black;
        -webkit-transition-duration: 0.2s;
        /* Safari */
        transition-duration: 0.2s;
    }

    div:nth-child(1) {
        span {
            color: ${(props) => props.primary ? props.color : 'black'};
        }
    }

    div:nth-child(2) {

        span {
            color: ${(props) => props.primary ? 'black' : props.color};
        }
    }

`;


const Footer = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 20px 20px 20px;
`;

const Footer_info = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
        font-size: 12px;
        font-weight: 500;
    }

    span:first-child {
        color: #898989;
    }

    span:last-child {
        font-weight: 400 !important;
        font-style: ${(props) => props.fontStyle};
    }
`;

const Popup = styled.div.attrs(props => ({
    className: props.className
}))`
    display: ${(props) => props.display};
    margin-top: 35%;
    justify-content: center;
    z-index: 2;
    position: absolute;
    width: 100%;
    //height: 68%;
    //background: rgba(255, 255, 255, 0.6);

`;


export const PrimaryF_def = ({render}) => {
    const [sliderValue, setSliderValue] = useState(0)
    const [valueAssign, setValueAssign] = useState(glossaryData[0].items[0])
    const [defAssign, setDefAssign] = useState({
        definition: glossaryData[0].items[0].definition,
        synonym: glossaryData[0].items[0].synonym,
        example: glossaryData[0].items[0].sentence,
    })
    const [twoDef_first, setTwoDef_first] = useState(true)

    const [close, setClose] = useState('none')

    const handleOnchange = (e) => {
        setSliderValue(e.target.value)
    }

    const handleToggle = () => {
        setTwoDef_first(!twoDef_first)
    }

    const changeDisplay = (e) => {
        setClose('none')
        const InfoButton_svg = document.querySelector('#flow-survey-json-info_pf_def svg')
        if (InfoButton_svg) {
            InfoButton_svg.style.fill = 'grey'
        }
    }


    useEffect(() => {
        setTwoDef_first(true)
        // let def= definitions[parseInt(sliderValue)]
        let def = glossaryData[0].items[parseInt(sliderValue)]
        let newDefA = {
            definition: def.definition,
            synonym: def.synonym,
            example: def.sentence,
        }
        setDefAssign(newDefA)
        setValueAssign(def)
    }, [sliderValue]);

    useEffect(() => {
        // let def= definitions[parseInt(sliderValue)]
        let def = glossaryData[0].items[parseInt(sliderValue)]
        let newDefA = twoDef_first ? {
            definition: def.definition,
            synonym: def.synonym,
            example: def.sentence,

        } : {
            definition: def.altdefinition,
            synonym: def.altsynonym,
            example: def.altsentence,
        }
        setDefAssign(newDefA)
    }, [twoDef_first]);

    const buttonAction = () => {
        setClose('none')
        const InfoButton = document.getElementById('flow-survey-json-info_pf_def')
        const InfoButton_svg = document.querySelector('#flow-survey-json-info_pf_def svg')
        if (InfoButton) {
            InfoButton_svg.style.fill = 'grey'
            InfoButton.onclick = function () {
                setClose('flex')
                InfoButton_svg.style.fill = 'orange'
            }
        }

    }

    useEffect(() => {
        buttonAction()
    }, [render]);


    return (
        <Popup display={close}>
            <Box>
                <Header color={valueAssign.activeColor}>
                    <CloseBox color={valueAssign.fontColor}>
                        <button onClick={changeDisplay}>
                            <IoClose/>
                        </button>
                    </CloseBox>
                    <Header_info color={valueAssign.fontColor}>
                        {/*<shame/>*/}
                        <img src={valueAssign.icon} alt={'Icon'}/>
                        <span>{valueAssign.headerDescription}</span>
                    </Header_info>
                </Header>

                <Main_slider_box>

                    <Abstract
                        color={'#898989'}
                        paddingRight={0}
                        marginLeft={0}
                    >
                        <p> Pleasant Feeling</p>
                        <img src={leftArrow} alt={'left Arrow'}/>
                    </Abstract>

                    <Slider color={valueAssign.activeColor}>
                        <input type="range" min="0" max="17" value={sliderValue} step="1" id="range2"
                               onChange={handleOnchange}/>
                    </Slider>

                    <Abstract
                        color={'black'}
                        paddingRight={'10px'}
                        marginLeft={'auto'}
                    >
                        <img src={rightArrow} alt={'Right Arrow'}/>
                        <p> Unpleasant Feeling</p>
                    </Abstract>

                </Main_slider_box>

                <Main_name color={valueAssign.activeColor} textColor={valueAssign.fontColor}>
                    <Main_name_box onClick={handleToggle} color={valueAssign.activeColor}
                                   button={(valueAssign.headerDescription.split(' ').length > 1)}>
                        <Main_name_toggle primary={twoDef_first} color={valueAssign.activeColor}/>
                        <Main_name_context color={valueAssign.fontColor} primary={twoDef_first}>
                            {(valueAssign.headerDescription.split(' ').length > 1) ?
                                <Fragment>
                                    <div><span>{valueAssign.headerDescription.split(' ')[0]}</span></div>
                                    <div><span>{valueAssign.headerDescription.split(' ')[2]}</span></div>
                                </Fragment> :
                                <div><span>{valueAssign.headerDescription}</span></div>
                            }
                        </Main_name_context>
                    </Main_name_box>
                </Main_name>

                <Footer>
                    <Footer_info font-style={'normal'}>
                        <span>Definition</span>
                        <span>{defAssign.definition}</span>
                    </Footer_info>
                    <Footer_info fontStyle={'italic'}>
                        <span>Synonyms</span>
                        <span>{defAssign.synonym}</span>
                    </Footer_info>
                    <Footer_info fontStyle={'italic'}>
                        <span>Example Sentence</span>
                        <span>{defAssign.example}</span>
                    </Footer_info>
                </Footer>


            </Box>
        </Popup>
    )

}