import React, { useEffect, useState } from 'react';
import civCoin1 from '../../../images/civ-coin-1.svg';
import civCoin2 from '../../../images/civ-coin-2.svg';
import civCoin3 from '../../../images/civ-coin-3.svg';
import civCoin4 from '../../../images/civ-coin-4.svg';

import civCoin5 from '../../../images/civ-coin-5.svg';
import civCoin6 from '../../../images/civ-coin-6.svg';

const CustomCivCoin = ({ currentPage, isFFT, survey}) => {
    let civCoin;
    const [indexedPage, setIndexedPage] = useState();
    
    useEffect(() => {
        if (!isFFT) {
          const pages = {
            "pt.1": "Individual Profile",
            "pt.2": "Symptoms",
            "pt.3": "Exposures & Behaviors",
            "pt.4": "TTP",
          };

          for (const [key2, value] of Object.entries(pages)) {
            if (survey.currentPage.jsonObj.name.includes(key2)) {
                setIndexedPage(Object.keys(pages).indexOf(key2));
                break;
            }
          }
          
        }
      }, [isFFT, currentPage]);
    
    if (isFFT) {
        switch (currentPage) {
            case 0:
                civCoin = civCoin2;
                break;
            case 1:
                civCoin = civCoin3;
                break;
            case 2:
                civCoin = civCoin4;
                break;
            case 3:
                civCoin = civCoin5;
                break;
            case 4:
                civCoin = civCoin6;
                break;
            default:
                civCoin = civCoin2;
        }
    } else {
        switch (indexedPage) {
            case 0:
                civCoin = civCoin1;
                break;
            case 1:
                civCoin = civCoin2;
                break;
            case 2:
                civCoin = civCoin5;
                break;
            case 3:
                civCoin = civCoin6;
                break;
            default:
                civCoin = civCoin1;
        }
    
    }
    

    return (
        <div>
            <img src={civCoin} alt={`Civ Coin ${currentPage}`} />
        </div>
    );
};
  
export default CustomCivCoin;