import "./index.css";
import { useContext, useState, useEffect, useRef, useMemo, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import { QuestionContext } from "../../context/question.context";
import {Survey} from "survey-react-ui";
import 'survey-core/defaultV2.min.css';
import "./FeelingsFlowTrackerForms/FeelingsFlowSurvey.css";
import CancelArrow from "../../images/orange-left-arrow.svg";
import SubmitArrow from "../../images/orange-right-arrow.svg"
import { Converter } from "showdown";
import CustomProgressBar from "./FeelingsFlowTrackerForms/CustomProgressBar";
import CustomCivCoin from "./FeelingsFlowTrackerForms/CustomCivCoin";
import {AssociatedF_def} from "./FeelingsFlowTrackerForms/AssociatedF_def";
import {PrimaryF_def} from "./FeelingsFlowTrackerForms/PrimaryF_def";
import {FftIntroPage} from "./FeelingsFlowTrackerForms/FftIntroPage";
import {BodyS_def} from "./FeelingsFlowTrackerForms/BodyS_def";
import { API } from "aws-amplify";
import {isEqual} from 'lodash'

const FeelingsFlowQuestion = (props) => {
  /// Contains our email/sub for the civAPI
  const {
    tokenPayload,
    setEmotionalData,
    savedFFTData,
    setSavedFFTData,
    emotionalData,
    communityHealthInfo,
  } = props;

  const goodToNavigate = useRef(true);
  // Current Question tracks when to render new question upon user clicking next or back
  const { changeQuestion, resetResultsList } =
    useContext(QuestionContext);
  
  const confirmPrompt = useRef();

  const bttnTextToDisplay = ["Next", "Submit"];
  
  const navigate = useNavigate();

  //check app.js for the savedFFTData state hook, it has the "attributes" in question, and this function serves as a helper for the savedFFTData setter function.
  const handleSurveyState = (attribute, newVal) => {
    setSavedFFTData((oldData) => {
      return {
        ...oldData,
        [attribute]: newVal
      }
    })
  };

  //TODO - UNCOMMENT ONCE WASHINGTON DC ZIPCODE IS REMOVED
  // const [userLocation, setUserLocation] = useState({});

  // const [opacityDark, setOpacityDark] = useState(false);

  

  //TODO - UNCOMMENT ONCE WASHINGTON DC ZIPCODE IS REMOVED
  //using communityHealthSlice to get user's zipcode, latitude & longitude
  // const data = useSelector(selectCommunityData);
  // const { ZIP, latitude, longitude } = data;
  // console.log(`ZIP: ${ZIP} | latitude: ${latitude} | longitude: ${longitude}`);
  //const [reachedLimit, setReachedLimit] = useState(false);

  //TODO - UNCOMMENT ONCE WASHINGTON DC ZIPCODE IS REMOVED
  // useEffect(() => {
  //   console.log(`User Location: Zipcode: ${ZIP}, Latitude: ${latitude}, Longitude: ${longitude}`);
  //   setUserLocation({ ZIP, latitude, longitude });
  // }, [data]);

  // // Send User Data to our DynamoDB using civAPI
  const logUser = async (tokenPayload, carryOn) => {
    // GUARD TO NOT ALLOW FORM SUBMISSION UNLESS ALL 5 QUESTIONS ARE ANSWERED
    //answer is not a existing attribute in the resultList object.
    // if (resultsList[1].answer.length === 0) {
    //   return;
    // }

    const [question1t_answer, question2_answer, question3_answer, question4_answer, question5_answer] = carryOn

    console.log("USER SUBMITTED FORM - see dynamoDB");
    
    // TEMPORARY: HARDCODED TO WASHINGTON DC
    const zipcode = 20500;
    const latitude = 38.897676;
    const longitude = -77.036529;
    const userZipcode = JSON.stringify(zipcode);
    const userLatitude = JSON.stringify(latitude);
    const userLongitude = JSON.stringify(longitude);
    console.log(`userZipcode: ${userZipcode} | userLatitude: ${userLatitude} | userLongitude: ${userLongitude}`);

    // Washington DC LAT/LONG = Lat: 38.897676 Long: -77.036529
    setTimeout(async () => {
      var isoDateString = new Date().toISOString();
      try {
        // TODO - add condition to check if all 5 questions have been answered to pass
        if (tokenPayload) {
          const payload = {
            body: {
              id: Date.now() + "-" + tokenPayload.sub,
              userId: tokenPayload.sub,
              activity: "emotionalHealthSurvey",
              email: tokenPayload.email,
              survey: {
                q1: question1t_answer,
                q2: question2_answer,
                q3: question3_answer,
                q4: question4_answer,
                q5: question5_answer,
                location: {
                  zipcode: userZipcode,
                  latitude: userLatitude,
                  longitude: userLongitude,
                },
              },
              dateAdded: isoDateString,
            },
          };

          API.post("civAPI", "/emotional/store", payload).then((response) => {
            if (!response.error) {
              console.log(response);
            } else {
              console.log(response);
            }
          });

          console.log("Logged User:", JSON.stringify(payload));
        }
      } catch (error) {
        // TODO - add error to mention to user to answer all 5 questions
        console.log("Error currentSession: ", error);
      }
    });
    //setPage("Process"); //Might be unnecessary? need to doublecheck
    // RESETS RESULT LIST TO EMPTY ARRAY UPON SUCCESSFUL SUBMISSION
    resetResultsList();
    // RESET CURRENT QUESTION BACK TO 1 TO RESET FORM IF USER WANTS TO SUBMIT ANOTHER FORM
    changeQuestion(1);  

  };


  const confirmComplete = (sender, options) => {
    options.allow = false;
    options.allowComplete = false;
    handleSurveyState("completeClicked",true);
    handleSurveyState("surveyData", sender.data);
    console.log("confirmComplete: " + JSON.stringify(options));
  }

  const result_name = {
    item1: "Aliveness/Joy",
    item2: "Grateful",
    item3: "Courageous/Powerful",
    item4: "Connected/Loving",
    item5: "Accepting/Open",
    item6: "Hopeful",
    item7: "Curious",
    item8: "Tender",
    item9: "Disconnected/Numb",
    item10: "Stressed/Tense",
    item11: "Unsettled/Doubt",
    item12: "Fragile",
    item13: "Despair/Sad",
    item14: "Embarrassed/Shame",
    item15: "Angry/Annoyed",
    item16: "Guilt",
    item17: "Fear",
    item18: "Powerless",
}



  // Upon completion of survey, prompts the user to confirm if they want to finish.
  const checkConfirmComplete = (e) =>{
      e.preventDefault();
      goodToNavigate.current = false;

      const text_num = savedFFTData.surveyData.question1.replace(/[^0-9]/g, "")
      const AF_page_icon = savedFFTData.fftSurvey.getQuestionByName('question1').jsonObj.choices[text_num - 1];
      const question1t_answer = result_name[`item${text_num}`] 
      const question1_answer = parse(AF_page_icon.text)
      const question2_answer = savedFFTData.surveyData[`question2_${question1t_answer.split('/')[0]}`]
      const question3_answer = savedFFTData.surveyData.question3
      const question4_answer = []
      for (const [key, value] of Object.entries(savedFFTData.surveyData)) {
        if (key.match("question4_") && key !== "question4_Letters") {
          question4_answer.push(...value)
        }
      }
      const question5_answer = savedFFTData.surveyData.question5

      //To avoid processing/redefining these variables, just bring them over to logUser. 
      const carryOn = [question1t_answer, question2_answer, question3_answer, question4_answer, question5_answer]
  
      confirmPrompt.current.style.display = "none";

      logUser(tokenPayload, carryOn);

      const answerPackage = {
        "location": {
          "zipcode": "20500",
          "latitude": "38.897676",
          "longitude": "-77.036529"
        },
        "q1": question1_answer ,
        "q2": question2_answer,
        "q3": question3_answer,
        "q4": question4_answer,
        "q5": question5_answer
      }

      handleSurveyState("latestAnswer", {
        payload: [
          {
            survey: answerPackage
          }
        ]
      })

      setEmotionalData(
      {
        "q1": question1_answer ,
        "q2": question2_answer,
        "q3": question3_answer,
        "location": {
          "zipcode": "20500",
          "latitude": "38.897676",
          "longitude": "-77.036529"
        },
        "q4": question4_answer,
        "q5": question5_answer
      })

      goToSummary();      
  }


  const goToSummary = () => {
    navigate('/emotionalhealth/process', {state: communityHealthInfo});
  }

  

  const customPrevButtonClick = () => {
    if (!savedFFTData.introPage && savedFFTData.pageNo === 0){
      handleSurveyState("introPage",true);
    }else{
      savedFFTData.fftSurvey.prevPage();
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 300,
      behavior: "smooth", // Optional, smooth scrolling animation
    });
  };
  // Define the customNextButtonClick function
  const customNextButtonClick = () => {
    if (savedFFTData.introPage && savedFFTData.pageNo === 0){
      handleSurveyState("introPage",false);
      scrollToTop();
    } else if(savedFFTData.pageNo === 4){
      savedFFTData.fftSurvey.completeLastPage();
    } else {
      savedFFTData.fftSurvey.nextPage();
      scrollToTop();
      console.log("SurveyData: "+JSON.stringify(savedFFTData.surveyData));
    }
  };

  // Special validation for Body Sensations question. Consists of 4 seperate checkboxes who's answers need to be added
  // together into a single array. Check if total num of answers is greater than 12(max).
  const validateQ4AnswerCount = (sender, options) => {
    if (options.name === "question4_A-F" || options.name === "question4_G-L" ||
          options.name === "question4_M-R" || options.name === "question4_S-Z"){
      const checkboxA_F = sender.getQuestionByName("question4_A-F");
      const checkboxG_L = sender.getQuestionByName("question4_G-L");
      const checkboxM_R = sender.getQuestionByName("question4_M-R");
      const checkboxS_Z = sender.getQuestionByName("question4_S-Z");
      var choicesArray = checkboxA_F.value.concat(checkboxG_L.value.concat(checkboxM_R.value.concat(checkboxS_Z.value)));
      if (choicesArray.length > 12){
        options.error = "Must choose between 1 and 12 options.";
      }
    }
    else return;
  }

  // Change survey CSS based on the page being rendered.


  const customCSS = {
    "page": {
      "root": "custom-page"
    },
    "question": {
      "content": "question-content",
      "header": "custom-header",
    },
    "comment": {
      "root": "custom-comment",
      "remainingCharacterCounter": "custom-character-counter"
    }
  }
  const updateCSS = (sender, options) => {
    if(sender.currentPage == "Primary Feelings"){
      sender.css = {
          "question": {
          "withFrame": "primary-custom-frame custom-all-box"
        } //Note that withFrame is the background of the current question, .css applies the style from the FeelingsFlowSurvey.css stylesheet.
      }
    }
    else if(sender.currentPage == "Associated Feelings"){
      sender.css = {
          "question": {
          "withFrame": "associated-custom-frame custom-all-box"
        }
      }
    }
    else if(sender.currentPage == "Thoughts"){
      sender.css = {
        "question": {
          "withFrame": "thoughts-custom-frame custom-all-box"
        }
        }
    }
    else if (sender.currentPage == "Body Sensations"){
      sender.css = {
        "panel": {
          "header": "custom-header",
          "title": "sensations-panel-title",
          "withFrame": "sensations-custom-frame"
        },
      }
    }
    else if (sender.currentPage == "Actions"){
      sender.css = {
        "question": {
          "withFrame": "actions-custom-frame custom-all-box"
        },
      }
    }
  }

  const addIIcon = ((sender, options) => {
   var title;
  });
  const handlePageChange = (sender, options) => {
    updateCSS(sender, options);
    handleSurveyState("pageNo", options.newCurrentPage.visibleIndex)
  }

  const valChangedUpdate = (sender, options) => {
        let questions = sender.currentPage.questions;

        for ( var i = 0; i < questions.length; i++){
            if (questions[i].isVisible && questions[i].isEmpty()){
                document.getElementById("next-bttn-Disease").disabled = true;
                return;
            } else {
                document.getElementById("next-bttn-Disease").disabled = false;
            }
        }
    }



  useEffect(() => {  
      console.log("TokenPayload.sub from FFT: "+tokenPayload?.sub)
      console.log("savedFFTData: "+ savedFFTData);

      const handleValueChanged = () => {
        console.log("currentPageNo",savedFFTData.fftSurvey.currentPageNo)
        const data = savedFFTData.fftSurvey.data;
        data.pageNo = savedFFTData.fftSurvey.currentPageNo;

        handleSurveyState("surveyData",data);
        handleSurveyState("currentPageNo", savedFFTData.fftSurvey.currentPageNo);
      };
      const handleComplete = (sender) => {
        console.log(JSON.stringify(sender.data, undefined, 3));

      };


      savedFFTData.fftSurvey.onUpdateQuestionCssClasses.add(function (_, options) {
        const classes = options.cssClasses;

        if (options.question.getType() === "radiogroup" && savedFFTData.fftSurvey.currentPage.name === "Primary Feelings") {
          classes.root += " question-root-pf";
          classes.panel += " Motorcade";
        }
        if (options.question.getType() === "checkbox" && savedFFTData.fftSurvey.currentPage.name === "Associated Feelings") {
          classes.root += " question-root-af";
        }
        if (savedFFTData.fftSurvey.currentPage.name === "Thoughts" || savedFFTData.fftSurvey.currentPage.name === "Actions") {
          classes.root += " question-root-ta";
        }
        if (options.question.getType() === "radiogroup" && savedFFTData.fftSurvey.currentPage.name === "Body Sensations") {
          classes.root += " question-root-rad-bs";
          // classes.itemChecked +=  " question-root-cb-bs-item-checked";
        }
        if (options.question.getType() === "checkbox" && savedFFTData.fftSurvey.currentPage.name === "Body Sensations") {
          classes.root += " question-root-cb-bs";
        }
        if (savedFFTData.fftSurvey.currentPage.name === "Body Sensations") {
          classes.root += " question-root-cb-bs";
          classes.header += " sensations-custom-header"
        }
      });

      updateCSS(savedFFTData.fftSurvey)

      savedFFTData.fftSurvey.onValueChanged.add(handleValueChanged);
      savedFFTData.fftSurvey.onCurrentPageChanged.add(handleValueChanged);
      savedFFTData.fftSurvey.onComplete.add(handleComplete);

      handleSurveyState("surveyInstance", savedFFTData.fftSurvey)

      return () => {
        savedFFTData.fftSurvey.onValueChanged.remove(handleValueChanged);
        savedFFTData.fftSurvey.onCurrentPageChanged.remove(handleValueChanged);
        savedFFTData.fftSurvey.onComplete.remove(handleComplete);
        // Saves survey data once user navigates out the page
        handleSurveyState("surveyData",savedFFTData.fftSurvey.data);
      };    
  }, []);

  useEffect(() => {
    if (savedFFTData.surveyInstance && savedFFTData.surveyData) {
      savedFFTData.surveyInstance.data = savedFFTData.surveyData;
      savedFFTData.surveyInstance.currentPageNo = savedFFTData.currentPageNo;

    }
  }, [savedFFTData.surveyInstance, savedFFTData.surveyData, savedFFTData.currentPageNo]);

    useEffect(() => {
      if (savedFFTData.surveyData && savedFFTData.surveyData.question1){

        const text_num = savedFFTData.surveyData.question1.replace(/[^0-9]/g,"")

        const AF_page_icon = savedFFTData.fftSurvey.getQuestionByName('question1').jsonObj.choices[text_num-1];
        const AF_page_icon2 = savedFFTData.fftSurvey.getQuestionByName('Associated Feelings Icon');
        AF_page_icon2.html = `<div class="AssociatedEmoticonBox">${AF_page_icon.text}</div>`;
        handleSurveyState("reRender",true);
      }


  }, [savedFFTData.currentPageNo, savedFFTData.reRender]);

  //for testing and debug purposes, remove when unnecessary
  useEffect(()=>{
    console.log("pageNo Updated:",savedFFTData.pageNo)
  },[savedFFTData.pageNo])

  useMemo(() => {
    console.log("Memo check")
    //Check if the user has already answered a survey today
    if(savedFFTData.latestAnswer && savedFFTData.latestAnswer.payload?.length > 0) {    
      console.log("Latest Answer check: ",savedFFTData.latestAnswer?.payload[0].survey)

      goodToNavigate.current=true;
      //Assuming that the response is sorted by most recent date of users response of the day first 
      //(ideally 1 entry really), set the summary screen and send them to it.
      const userAnswer = savedFFTData.latestAnswer.payload[0].survey
      setEmotionalData({
        "q1": userAnswer.q1,
        "q2": userAnswer.q2,
        "q3": userAnswer.q3,
        "location": {
          "zipcode": "20500",
          "latitude": "38.897676",
          "longitude": "-77.036529"
        },
        "q4": userAnswer.q4,
        "q5": userAnswer.q5
      });
      
    } else if(!savedFFTData.memoEventsAttached) {
        var converter = new Converter();
        savedFFTData.fftSurvey.onTextMarkdown.add(function(survey, options){
          //convert the markdown text to html
          var str = converter.makeHtml(options.text);
          //remove root paragraphs <p></p>
          str = str.substring(3);
          str = str.substring(0, str.length - 4);
          //set html
          options.html = str;
        });

        savedFFTData.fftSurvey.css = customCSS;
        savedFFTData.fftSurvey.goNextPageAutomatic = false;
        savedFFTData.fftSurvey.allowCompleteSurveyAutomatic = false;
        savedFFTData.fftSurvey.clearInvisibleValues = "none";
        savedFFTData.fftSurvey.showNavigationButtons = false;
        savedFFTData.fftSurvey.showCompletedPage = false;
        savedFFTData.fftSurvey.onCompleting.add(confirmComplete);
        savedFFTData.fftSurvey.onCurrentPageChanged.add(handlePageChange);
        savedFFTData.fftSurvey.onAfterRenderQuestion.add(addIIcon);
        savedFFTData.fftSurvey.onValidateQuestion.add(validateQ4AnswerCount);
        savedFFTData.fftSurvey.onValueChanged.add(valChangedUpdate);
        setSavedFFTData((oldData) => {
          return {
            ...oldData,
            memoEventsAttached: true
          }

        })
        console.log("Memo Attaching")

    }
  
  }, []);

  useEffect(()=> {
    if(goodToNavigate && emotionalData && isEqual(emotionalData, savedFFTData.latestAnswer?.payload?.[0].survey)) {
      console.log("emotionalData equal to latestAnswer, moving to /process...")
      goToSummary();
    }
  }, [emotionalData])

  useEffect(() => {
    if (savedFFTData.completeClicked){
      confirmPrompt.current.style.display = "block";
      savedFFTData.fftSurvey.currentPage = savedFFTData.fftSurvey.getPageByName("Actions");
      document.getElementById("survey").style.filter = "blur(2px)";
    }
  }, [savedFFTData.completeClicked])

  return (
      <div>
        <AssociatedF_def name={'Aliveness'} render={savedFFTData.surveyData}/>
        <PrimaryF_def render={[savedFFTData.surveyData]}/>
        <BodyS_def render={[savedFFTData.surveyData]}/>
        <div
            className="ff-container"
            style={{
              // backgroundColor:
              //     glossaryOpen || opacityDark ? "#B7B7B7" : currentQuestion === 6 && !glossaryOpen && !opacityDark ? "#ffffff" : "#F7F5F0",
              // opacity: reachedLimit ? 0.6 : 1,
              // height: isQuestionOne && isModalOpen ? "2165px" : null,
              // zIndex: 1,
            }}
        >
          {!savedFFTData.introPage &&
              <div className="progressHeader">


                <div className="progressBar-container">
                  <CustomProgressBar
                      progressBarKey={0}
                      currentPage={savedFFTData.pageNo}
                      pageTitles={savedFFTData.fftSurvey.pages.map((page) => page.jsonObj.name)}
                      isFFT={true}
                      survey={null}
                  />
                </div>
                <div className="coin-container">
                  <CustomCivCoin currentPage={savedFFTData.pageNo} isFFT={true} survey={null}/>
                </div>
              </div>

          }

          {savedFFTData.introPage ?
              <FftIntroPage/> :
              <Fragment>
                {savedFFTData.surveyInstance ? <Survey id="survey" model={savedFFTData.fftSurvey}/> : null}
              </Fragment>
          }
          <div className="surveyBttn-container">
            {!savedFFTData.introPage &&
                <button id="prev-bttn" className="surveyNavBttn" onClick={customPrevButtonClick}>
                  <img src={CancelArrow} alt="left facing arrow"/>Back
                </button>
            }
            <button id="next-bttn" className="surveyNavBttn"
                    onClick={customNextButtonClick}>
                      {savedFFTData.pageNo != 4 ? bttnTextToDisplay[0] : bttnTextToDisplay[1]}
                      <img src={SubmitArrow} alt="right facing arrow"/>
            </button>
          </div>
          <div className="submit-prompt-container" ref={confirmPrompt}
             style={savedFFTData.completeClicked ? {display: "block"} : {display: "none"}}>
          <p className="submit-prompt-text">
            Are you sure you want to proceed? You won't be able to edit your responses once you click submit.
          </p>
          <div className="submit-button-container">
            <button className="submit-cancel-button" onClick={(e) => {
              e.preventDefault();
              confirmPrompt.current.style.display = "none";
              savedFFTData.fftSurvey.currentPage = savedFFTData.fftSurvey.getPageByName("Actions");
              savedFFTData.fftSurvey.data = savedFFTData.surveyData;
              document.getElementById("survey").style.filter = "blur(0px)";
              handleSurveyState("completeClicked",false)
            }}>
              <img src={CancelArrow} alt="cancel" className="cancel-arrow"/>
              <p className="submit-cancel-text">Cancel</p>
            </button>
            <button className="submit-confirm-button" onClick={(e) => {
              checkConfirmComplete(e)
            }}>
              <p className="submit-confirm-text">Submit</p>
              <img src={SubmitArrow} alt="submit" className="submit-arrow" />
            </button>
          </div>
        </div>
          
        </div>

      </div>


  );
};

export default FeelingsFlowQuestion;