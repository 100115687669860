import { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet, useLocation} from "react-router-dom";
import InfoIcon from "../../../images/info.png";
import { WidgetContainer } from "../../PublicHealth/WidgetContainer";
import DetailFooter from "../../Shared/DetailFooter";
import { Ad } from "../../Shared/Ad";
import { Info } from "../../Shared/Info";
import { selectCommunityData, selectStatus } from "../../../store/communityHealthSlice";
import { getGradientColorFromValue } from "../../LearnDetail/DetailContent/getColorFromValue.js";
import { Slogan } from "../../Shared/Slogan";
import { API } from "aws-amplify";
import DetailHeader from "../../LearnDetail/DetailHeader";
import "./healthSubdomain.css";
import {DetailTitle} from "../../LearnDetail/DetailTitle";

// import BodySensationForm from "./BodySensationForm";
// import { useContext } from "react";
// import { QuestionContext } from "../../context/question.context";
export function HealthSubdomain(props) {
  // Will pass payload to emotionalHealth widget
  const [dropdownOpen, setDropdownOpen] = useState({
    footer: false,
    expand: false,
  });
  const location = useLocation();
  const { options, countyData, communityHealthInfo, diseaseInfo,
    countyLabel,
    stateData,
    stateLabel,
    countryData,
    countryLabel,
    comingSoonOptions,
    headerTitle,
    minuteCardOneHeader,
    minuteCardOneText,
    minuteCardTwoHeader,
    minuteCardTwoText,
    sayComponentText,
    saySectionLabel,
    tokenPayload,
    dropdownOpenState = {footer:false,expand:false}
  } = location.state || {};

  useEffect(()=>{
    setDropdownOpen(dropdownOpenState);
    console.log(options)
  },[])
  
    const [comingSoonList, setComingSoonList] = useState({
        Wellness: false,
        ChronicHealth: false,
        TBD: false,
    })

    const ComingSoonClick = (name) => {
        const NewList = {
        Wellness: false,
        ChronicHealth: false,
        TBD: false,
        }
        setComingSoonList({...NewList, [`${name}`]: true})
    }

  return (
    <div className="healthSubdomain">
      
      <DetailHeader parentPath={location.pathname} setDropdownOpen={setDropdownOpen} dropdownOpen={dropdownOpen} tokenPayload={tokenPayload} headerTitle= {"Community Health"}
        options={options}
        comingSoonOptions={comingSoonOptions}
        countyData
        countyLabel
        stateData
        stateLabel
        countryData
        countryLabel
        // disease: disease,
        // setDisease:setDisease,
        // page: page,
        // setPage:setPage,
        // showVerticals: showVerticals,
        // setShowVerticals: setShowVerticals,
        minuteCardOneHeader= {"My Guidelines"}
        minuteCardOneText={"Know what guidelines apply to you"}
        minuteCardTwoText={"Know guidelines and your personal risk of catching & spreading"}
        minuteCardTwoHeader= {"My Risk"}
        sayComponentText= {" Do you think the Covid-19 pandemic is over? Why or why not?"}
        saySectionLabel={"VIEW RISK BY"}
        variant={false}
      />
      <DetailTitle communityHealthInfo={communityHealthInfo} diseaseInfo={diseaseInfo}/>
      <div className="body">
          <Link style={{textDecoration: "none"}} state={{
                headerTitle: "Community Health",
                options: options.emotional,
                comingSoonOptions: comingSoonOptions.community,
                countyData,
                countyLabel,
                stateData,
                stateLabel,
                countryData,
                countryLabel,
                // disease: disease,
                // setDisease:setDisease,
                // page: page,
                // setPage:setPage,
                // showVerticals: showVerticals,
                // setShowVerticals: setShowVerticals,
                minuteCardOneHeader: "My Guidelines",
                minuteCardOneText: "Know what guidelines apply to you",
                minuteCardTwoText: "Know guidelines and your personal risk of catching & spreading",
                minuteCardTwoHeader: "My Risk",
                sayComponentText: " Do you think the Covid-19 pandemic is over? Why or why not?",
                saySectionLabel: "VIEW RISK BY",
                tokenPayload
              }}
              to={"/learnmore"}>
              <WidgetContainer name="Infectious Disease" link="" tokenPayload={tokenPayload}/>
          </Link>


        {/*</WidgetContainer>*/}
          <Link
              style={{textDecoration: "none"}}
              state={{
                  options: options.emotional,
                  comingSoonOptions: comingSoonOptions.emotional,
                  headerTitle: "Emotional Health",
                  minuteCardOneHeader: "Feelings Flow Tracker",
                  minuteCardOneText: "Identify, understand, and process your emotions",
                  minuteCardTwoHeader: "Boundary Builder",
                  minuteCardTwoText: "Understand your personal boundaries",
                  sayComponentText:
                      "In addition to Reading, Writing and Arithmetic, should schools teach Relationships (how we relate to ourselves, others and situations)?",
                  saySectionLabel: "VIEW ACTIVITY AS",
                  tokenPayload:tokenPayload,
              }}
              to={"/emotionalhealth"}
              className="learnmore"
          >
              <WidgetContainer name="Emotional Health" link="" tokenPayload={tokenPayload}/>

          </Link>
              <WidgetContainer name="Wellness" comingSoon={comingSoonList.Wellness} link="" onclick={()=>ComingSoonClick("Wellness")}>
              </WidgetContainer>

              <WidgetContainer name="Chronic Health" comingSoon={comingSoonList.ChronicHealth} link="" onclick={()=>ComingSoonClick("ChronicHealth")}>
              </WidgetContainer>
              <WidgetContainer name="Sexual & Reproductive Health" comingSoon={comingSoonList.TBD} link="" onclick={()=>ComingSoonClick("TBD")}>
              </WidgetContainer>
      </div>
      <DetailFooter setDropdownOpen={setDropdownOpen} dropdownOpen={dropdownOpen} />
    </div>
  );
}