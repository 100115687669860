export const Joy = "<svg width=\"69\" height=\"69\" viewBox=\"0 0 2359 2369\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" xml:space=\"preserve\" xmlns:serif=\"http://www.serif.com/\" style=\"fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:10;\">\n" +
    "    <g transform=\"matrix(1,0,0,1,-527.846,-647.836)\">\n" +
    "        <g transform=\"matrix(0.401111,0,0,0.401111,-209.618,-1251.27)\">\n" +
    "            <g transform=\"matrix(1.35256,0,0,1.45246,-2203.93,3841)\">\n" +
    "                <ellipse cx=\"5162.14\" cy=\"2648.17\" rx=\"2173.37\" ry=\"2032.94\" style=\"fill:rgb(240,227,240);\"/>\n" +
    "            </g>\n" +
    "            <g id=\"Joy.svg\" transform=\"matrix(114.813,0,0,114.813,4791.34,7690.16)\">\n" +
    "                <g transform=\"matrix(1,0,0,1,-17.5,-18)\">\n" +
    "                    <clipPath id=\"_clip1\">\n" +
    "                        <rect x=\"0\" y=\"0\" width=\"35\" height=\"36\"/>\n" +
    "                    </clipPath>\n" +
    "                    <g clip-path=\"url(#_clip1)\">\n" +
    "                        <path d=\"M17.839,34.622C27.043,34.622 34.505,27.161 34.505,17.956C34.505,8.751 27.043,1.289 17.839,1.289C8.634,1.289 1.172,8.751 1.172,17.956C1.172,27.161 8.634,34.622 17.839,34.622Z\" style=\"fill:white;fill-rule:nonzero;stroke:rgb(35,31,32);stroke-width:1px;\"/>\n" +
    "                        <path d=\"M28.274,11.571C28.274,11.571 27.414,8.031 23.689,7.554C23.689,7.554 20.567,7.165 19.051,10.407C19.051,10.407 23.439,7.189 28.274,11.571Z\" style=\"fill:rgb(35,31,32);fill-rule:nonzero;\"/>\n" +
    "                        <path d=\"M22.89,14.868C24.413,14.868 25.654,16.157 25.654,17.745C25.654,17.93 25.589,18.092 25.553,18.265C26.06,18.492 26.58,18.76 27.117,19.142C27.117,11.751 23.589,11.543 22.896,11.572C22.204,11.543 18.676,11.751 18.676,19.142C18.676,19.142 19.255,18.623 20.222,18.193C20.198,18.038 20.132,17.901 20.132,17.745C20.132,16.157 21.368,14.868 22.896,14.868L22.89,14.868Z\" style=\"fill:white;fill-rule:nonzero;stroke:rgb(35,31,32);stroke-width:0.5px;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;\"/>\n" +
    "                        <path d=\"M25.548,18.26C25.577,18.087 25.649,17.926 25.649,17.741C25.649,16.153 24.413,14.863 22.885,14.863C21.357,14.863 20.121,16.153 20.121,17.741C20.121,17.902 20.187,18.039 20.211,18.189C21.44,17.645 23.315,17.263 25.548,18.254L25.548,18.26Z\" style=\"fill:rgb(35,31,32);fill-rule:nonzero;stroke:rgb(35,31,32);stroke-width:0.5px;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;\"/>\n" +
    "                        <path d=\"M7,11.571C7,11.571 7.86,8.031 11.585,7.554C11.585,7.554 14.707,7.165 16.223,10.407C16.223,10.407 11.835,7.189 7,11.571Z\" style=\"fill:rgb(35,31,32);fill-rule:nonzero;\"/>\n" +
    "                        <path d=\"M12.391,14.868C10.868,14.868 9.627,16.157 9.627,17.745C9.627,17.93 9.692,18.092 9.728,18.265C9.221,18.492 8.701,18.76 8.164,19.142C8.164,11.751 11.692,11.543 12.385,11.572C13.077,11.543 16.605,11.751 16.605,19.142C16.605,19.142 16.026,18.623 15.059,18.193C15.083,18.038 15.149,17.901 15.149,17.745C15.149,16.157 13.913,14.868 12.385,14.868L12.391,14.868Z\" style=\"fill:white;fill-rule:nonzero;stroke:rgb(35,31,32);stroke-width:0.5px;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;\"/>\n" +
    "                        <path d=\"M9.73,18.26C9.701,18.087 9.629,17.926 9.629,17.741C9.629,16.153 10.865,14.863 12.393,14.863C13.921,14.863 15.157,16.153 15.157,17.741C15.157,17.902 15.091,18.039 15.067,18.189C13.838,17.645 11.963,17.263 9.73,18.254L9.73,18.26Z\" style=\"fill:rgb(35,31,32);fill-rule:nonzero;stroke:rgb(35,31,32);stroke-width:0.5px;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;\"/>\n" +
    "                        <path d=\"M12.436,27.493C13.952,28.496 15.761,29.105 17.731,29.105C19.94,29.105 21.945,28.347 23.551,27.123C21.545,25.881 17.916,25.875 17.916,25.875C14.424,25.863 12.615,27.338 12.43,27.493L12.436,27.493Z\" style=\"fill:rgb(240,31,41);fill-rule:nonzero;stroke:rgb(240,31,41);stroke-width:1px;stroke-linecap:round;\"/>\n" +
    "                        <path d=\"M8.268,21.707C8.668,24.322 10.202,26.561 12.351,27.982C13.867,28.985 15.676,29.593 17.646,29.593C19.855,29.593 21.86,28.835 23.466,27.611C25.335,26.185 26.654,24.113 27.024,21.719C22.977,23.426 16.04,25.104 8.262,21.707L8.268,21.707Z\" style=\"fill:none;fill-rule:nonzero;stroke:black;stroke-width:1px;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;\"/>\n" +
    "                    </g>\n" +
    "                </g>\n" +
    "                <g>\n" +
    "                </g>\n" +
    "            </g>\n" +
    "        </g>\n" +
    "    </g>\n" +
    "</svg>"