import React, { useRef } from 'react'
import GrayScaleIcon from '../../../images/gray_scale_i_icon.svg'
import ColoredIcon from '../../../images/colored_i_icon.svg'
function InfoIconComponent({superScript}) {
  const imageRef = useRef(null);
  const hover = () => {
    if (imageRef.current) {
      imageRef.current.setAttribute('src', ColoredIcon);
    }
  };

  const unhover = () => {
    if (imageRef.current) {
      imageRef.current.setAttribute('src', GrayScaleIcon);
    }
  };
  
  return (
    !superScript ? (  
    <div className="tipContainer" onMouseOver={hover} onMouseOut={unhover} >
        <img ref={imageRef} src={GrayScaleIcon} id= "iIconLogo" className="iIcon" alt='iIcon'></img>
        <span className="tipContainerText wide">
            Update your health profile when there are changes in your health conditions or habits, weight, or more infrequently identified sex, gender, or race/ethnicity. <br /><br />
            These factors can affect your risk of infectious disease and the guidelines appropriate for you. To make sure you're current, we'll ask you to update every six months.
        </span>
    </div>
    ) : (
      <div className="tipContainer" onMouseOver={hover()} onMouseOut={unhover} >
        <img ref={imageRef} src={GrayScaleIcon} id= "iIconLogo" className="superScriptIIcon" alt='iIcon'></img>
        <span className="tipContainerText wide">
            Testing to identify the presence of botulinum toxin includes blood serum, stool sample, gastric fluid, suspected food source, or wound samples.
        </span>
      </div>
    )
  )
}
export default InfoIconComponent