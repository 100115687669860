export const Fragile = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 1543 1550\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-2184.16 -3926.75)\">\n" +
    "        <ellipse\n" +
    "          cx=\"5162.14\"\n" +
    "          cy=\"2648.17\"\n" +
    "          fill=\"#C15023\"\n" +
    "          rx=\"2173.37\"\n" +
    "          ry=\"2032.94\"\n" +
    "          transform=\"matrix(.35486 0 0 .38107 1123.57 3692.3)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(2965.52 4726.41) scale(14.8922) translate(-34.5 -35)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H69V70H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.5 68.004c18.502 0 33.5-14.999 33.5-33.502S53.002 1 34.5 1C15.999 1 1 15.999 1 34.502s14.999 33.502 33.5 33.502z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M35.648 21.914s1.951-7.035 9.452-7.741c0 0 6.282-.574 9.105 6.018 0 0-8.566-6.736-18.557 1.723z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M47.351 42.375c4.599 0 8.327-5.137 8.327-11.475 0-6.337-3.728-11.474-8.327-11.474s-8.328 5.137-8.328 11.474c0 6.338 3.729 11.475 8.328 11.475z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M45.962 39.649c2.3 0 4.164-2.572 4.164-5.744 0-3.172-1.864-5.743-4.164-5.743-2.299 0-4.163 2.571-4.163 5.743s1.864 5.744 4.163 5.744z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M33.1 21.807s-1.951-7.036-9.452-7.742c0 0-6.282-.574-9.105 6.019 0 0 8.566-6.736 18.557 1.723z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M21.399 42.267c4.599 0 8.328-5.137 8.328-11.474 0-6.337-3.729-11.475-8.328-11.475-4.598 0-8.327 5.138-8.327 11.475s3.729 11.474 8.327 11.474z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M22.775 39.539c2.299 0 4.164-2.571 4.164-5.743s-1.865-5.743-4.164-5.743c-2.3 0-4.164 2.571-4.164 5.743s1.864 5.743 4.164 5.743z\"></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M23.877 55.967l23.546-4.283\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#00ADEB\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M25.647 4.039l-1.831 5.743H17.2l-2.656 5.504-5.648-1.232M67.127 34.37l-5.013-3.326 1.806-6.365-4.582-4.056 2.728-5.098\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"