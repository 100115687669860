export const Loving = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 1428 1434\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-5866.44 -397.464) matrix(.6052 0 0 .6052 5522.7 -2414.81)\">\n" +
    "        <ellipse\n" +
    "          cx=\"5162.14\"\n" +
    "          cy=\"2648.17\"\n" +
    "          fill=\"#B22C96\"\n" +
    "          rx=\"2173.37\"\n" +
    "          ry=\"2032.94\"\n" +
    "          transform=\"matrix(.54253 0 0 .5826 -1053.52 4288.4)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(1684.4 5840.6) scale(49.0769) translate(-19.5 -18)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H39V36H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"1\"\n" +
    "              d=\"M21.502 34.619c9.204 0 16.665-7.462 16.665-16.667 0-9.205-7.461-16.667-16.665-16.667-9.205 0-16.666 7.462-16.666 16.667 0 9.205 7.461 16.667 16.666 16.667z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M11.922 9.978s1.012-2.566 4.428-2.572c0 0 2.857 0 4.024 2.572 0 0-3.768-2.822-8.446 0h-.006zM31.276 9.978s-1.012-2.566-4.428-2.572c0 0-2.857 0-4.024 2.572 0 0 3.768-2.822 8.446 0h.006z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#FF0812\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"0.5\"\n" +
    "              d=\"M10.292 20.661c-1.589-2.744-4.648.357-4.648.357s-3.06-3.101-4.649-.357c-1.821 3.143 4.649 6.44 4.649 6.44s6.475-3.297 4.648-6.44z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"0.5\"\n" +
    "              d=\"M16.233 14.354c1.518 0 2.756 1.286 2.756 2.869 0 .185-.065.345-.101.518a8.814 8.814 0 011.559.875c0-7.369-3.517-7.577-4.208-7.548-.69-.029-4.208.179-4.208 7.548 0 0 .578-.518 1.542-.946-.024-.155-.089-.292-.089-.447 0-1.583 1.232-2.869 2.755-2.869h-.006z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"0.5\"\n" +
    "              d=\"M18.887 17.742c.03-.172.101-.333.101-.517 0-1.584-1.232-2.87-2.756-2.87-1.523 0-2.755 1.286-2.755 2.87 0 .16.065.297.089.446 1.226-.542 3.095-.923 5.321.065v.006z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"0.5\"\n" +
    "              d=\"M26.96 14.354c1.518 0 2.756 1.286 2.756 2.869 0 .185-.066.345-.101.518a8.885 8.885 0 011.559.875c0-7.369-3.518-7.577-4.208-7.548-.691-.029-4.208.179-4.208 7.548 0 0 .577-.518 1.541-.946-.023-.155-.089-.292-.089-.447 0-1.583 1.232-2.869 2.756-2.869h-.006z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"0.5\"\n" +
    "              d=\"M29.613 17.742c.03-.172.102-.333.102-.517 0-1.584-1.232-2.87-2.756-2.87-1.524 0-2.756 1.286-2.756 2.87 0 .16.066.297.089.446 1.227-.542 3.095-.923 5.321.065v.006z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M16.289 24.606s5.309 5.5 10.428 0H16.289z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"1\"\n" +
    "              d=\"M16.289 24.606s5.309 5.5 10.428 0\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"