export const Fear = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 2363 2363\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-3373.96 -3428.71)\">\n" +
    "        <ellipse\n" +
    "          cx=\"3430.13\"\n" +
    "          cy=\"3550.95\"\n" +
    "          fill=\"#44403D\"\n" +
    "          rx=\"1444.45\"\n" +
    "          ry=\"1384.27\"\n" +
    "          transform=\"matrix(.81768 0 0 .85323 1750.31 1580.03)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(4560.55 4628.8) scale(24.901) translate(-34.5 -35)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H69V70H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.5 68.004c18.502 0 33.5-14.999 33.5-33.502S53.002 1 34.5 1C15.999 1 1 15.999 1 34.502s14.999 33.502 33.5 33.502z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M18.875 16.925s.191-5.372 5.444-7.179c0 0 4.403-1.484 7.561 2.752 0 0-7.298-3.35-13.005 4.427zM49.049 17.332s.107-5.385-5.037-7.467c0 0-4.319-1.722-7.705 2.334 0 0 7.477-2.956 12.742 5.133z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M44.634 38.367c4.599 0 8.327-5.137 8.327-11.475 0-6.337-3.728-11.474-8.327-11.474s-8.327 5.137-8.327 11.474c0 6.338 3.728 11.475 8.327 11.475z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M43.246 35.639c2.299 0 4.163-2.571 4.163-5.743s-1.864-5.744-4.163-5.744c-2.3 0-4.164 2.572-4.164 5.744 0 3.172 1.864 5.743 4.164 5.743z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M23.696 38.367c4.599 0 8.327-5.137 8.327-11.475 0-6.337-3.728-11.474-8.327-11.474s-8.327 5.137-8.327 11.474c0 6.338 3.728 11.475 8.327 11.475z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M22.308 35.639c2.3 0 4.164-2.571 4.164-5.743s-1.864-5.744-4.164-5.744c-2.299 0-4.163 2.572-4.163 5.744 0 3.172 1.864 5.743 4.163 5.743z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.5 53.885c1.819 0 3.41 1.232 4.403 3.123.646-1.233 1.041-2.728 1.041-4.367 0-4.14-2.429-7.491-5.432-7.491-3.003 0-5.432 3.351-5.432 7.491 0 1.639.395 3.134 1.041 4.367.981-1.879 2.584-3.123 4.403-3.123H34.5z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.501 60.131c1.818 0 3.409-1.233 4.402-3.123-.981-1.879-2.584-3.123-4.402-3.123-1.819 0-3.41 1.232-4.403 3.123.981 1.878 2.584 3.123 4.403 3.123z\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"