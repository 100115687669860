export const Angry = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 2363 2363\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-6381.1 -366.464) translate(325.27 -85.597)\">\n" +
    "        <ellipse\n" +
    "          cx=\"3430.13\"\n" +
    "          cy=\"3550.95\"\n" +
    "          fill=\"#D33022\"\n" +
    "          rx=\"1444.45\"\n" +
    "          ry=\"1384.27\"\n" +
    "          transform=\"matrix(.81768 0 0 .85323 4432.18 -1396.62)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(7240.32 1651.97) scale(24.7202) translate(-34.5 -35)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H69V70H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.5 68.004c18.502 0 33.5-14.999 33.5-33.502S53.002 1 34.5 1C15.999 1 1 15.999 1 34.502s14.999 33.502 33.5 33.502z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M22.033 51.158h22.553M43.76 47.174l3.218 8.555\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M22.716 42.041c4.546 0 8.231-5.078 8.231-11.343 0-6.264-3.685-11.342-8.231-11.342-4.546 0-8.232 5.078-8.232 11.342 0 6.265 3.686 11.343 8.232 11.343z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M22.715 38.882c2.273 0 4.116-2.539 4.116-5.671 0-3.133-1.843-5.672-4.116-5.672-2.273 0-4.115 2.539-4.115 5.672 0 3.132 1.842 5.671 4.115 5.671zM55.318 16.113s-5.444-2.345-11.844.455c0 0-5.743 2.728-7.562 8.638 0 0 7.849-7.825 19.406-9.093zM13.908 16.113s5.444-2.345 11.845.455c0 0 5.743 2.728 7.561 8.638 0 0-7.848-7.825-19.406-9.093z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M47.075 42.041c4.546 0 8.232-5.078 8.232-11.343 0-6.264-3.686-11.342-8.232-11.342-4.546 0-8.231 5.078-8.231 11.342 0 6.265 3.685 11.343 8.231 11.343z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M47.075 38.882c2.273 0 4.115-2.539 4.115-5.671 0-3.133-1.842-5.672-4.115-5.672s-4.116 2.539-4.116 5.672c0 3.132 1.843 5.671 4.116 5.671z\"></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"