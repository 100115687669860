export const Doubt = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 1543 1550\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-429.412 -3986.67)\">\n" +
    "        <ellipse\n" +
    "          cx=\"5162.14\"\n" +
    "          cy=\"2648.17\"\n" +
    "          fill=\"#E57F30\"\n" +
    "          rx=\"2173.37\"\n" +
    "          ry=\"2032.94\"\n" +
    "          transform=\"matrix(.35486 0 0 .38107 -631.175 3752.22)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(1193.66 4772) scale(14.8922) translate(-34.5 -35)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H69V70H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.5 68.004c18.502 0 33.5-14.999 33.5-33.502S53.002 1 34.5 1C15.999 1 1 15.999 1 34.502s14.999 33.502 33.5 33.502z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M26.137 21.916a14.465 14.465 0 01-8.687 3.111c-.981 1.723-1.579 3.865-1.579 6.21 0 5.6 3.302 10.147 7.358 10.147 4.056 0 7.37-4.547 7.37-10.147 0-4.188-1.842-7.777-4.462-9.333v.012zM41.115 21.916a14.464 14.464 0 008.686 3.111c.981 1.723 1.579 3.865 1.579 6.21 0 5.6-3.302 10.147-7.358 10.147-4.067 0-7.37-4.547-7.37-10.147 0-4.188 1.843-7.777 4.463-9.333v.012z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M29.188 60.203c-1.28-2.931 1.292-6.892 5.755-8.854 4.463-1.95 9.117-1.16 10.409 1.771\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M29.188 60.203c-1.28-2.931 1.292-6.892 5.755-8.854 4.463-1.95 9.117-1.16 10.409 1.771\"\n" +
    "            ></path>\n" +
    "            <path d=\"M24.462 38.977c2.035 0 3.685-2.272 3.685-5.074s-1.65-5.073-3.685-5.073-3.685 2.271-3.685 5.073c0 2.802 1.65 5.074 3.685 5.074z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"0.15\"\n" +
    "              d=\"M13.418 23.89c5.204-9.142 14.74-7.347 14.74-7.347s-7.956 2.489-14.74 7.347z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M42.79 38.977c2.036 0 3.685-2.272 3.685-5.074s-1.649-5.073-3.685-5.073c-2.035 0-3.684 2.271-3.684 5.073 0 2.802 1.649 5.074 3.684 5.074z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeLinecap=\"round\"\n" +
    "              strokeLinejoin=\"round\"\n" +
    "              strokeMiterlimit=\"4\"\n" +
    "              strokeWidth=\"0.15\"\n" +
    "              d=\"M53.129 23.89c-5.205-9.142-14.74-7.347-14.74-7.347s7.956 2.489 14.74 7.347z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#F12C7A\"\n" +
    "              d=\"M56.778 45.524c5.061 0 9.165-1.233 9.165-2.752 0-1.52-4.104-2.752-9.165-2.752-5.062 0-9.165 1.232-9.165 2.752 0 1.519 4.103 2.752 9.165 2.752zM11.626 45.524c5.061 0 9.164-1.233 9.164-2.752 0-1.52-4.103-2.752-9.164-2.752-5.062 0-9.165 1.232-9.165 2.752 0 1.519 4.103 2.752 9.165 2.752z\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"