export const Numb = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 1504 1511\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-5646.03 -2217.48) matrix(.63772 0 0 .63772 1565.05 -2613.84)\">\n" +
    "        <ellipse\n" +
    "          cx=\"5162.14\"\n" +
    "          cy=\"2648.17\"\n" +
    "          fill=\"#F3ED58\"\n" +
    "          rx=\"2173.37\"\n" +
    "          ry=\"2032.94\"\n" +
    "          transform=\"matrix(.54253 0 0 .5826 4777.8 7217.44)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(7597.63 8745.41) scale(23.3653) translate(-34.5 -34.5)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H69V69H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.498 68c18.5 0 33.498-14.998 33.498-33.5C67.996 15.999 52.998 1 34.498 1S1 15.999 1 34.5C1 53.002 15.998 68 34.498 68z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M29.749 13.395s-1.232 7.19-8.626 8.65c0 0-6.185 1.196-9.654-5.085 0 0 9.2 5.839 18.28-3.565z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M23.241 41.379c4.07 0 7.369-4.542 7.369-10.145 0-5.604-3.299-10.146-7.369-10.146s-7.37 4.542-7.37 10.146c0 5.603 3.3 10.145 7.37 10.145z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M24.46 38.962c2.035 0 3.685-2.271 3.685-5.073 0-2.801-1.65-5.073-3.685-5.073s-3.685 2.272-3.685 5.073c0 2.802 1.65 5.073 3.685 5.073z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M36.998 13.395s1.232 7.19 8.626 8.65c0 0 6.185 1.196 9.654-5.085 0 0-9.2 5.839-18.28-3.565z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M44.895 41.379c4.07 0 7.369-4.542 7.369-10.145 0-5.604-3.299-10.146-7.369-10.146s-7.37 4.542-7.37 10.146c0 5.603 3.3 10.145 7.37 10.145z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M46.115 38.962c2.035 0 3.684-2.271 3.684-5.073 0-2.801-1.649-5.073-3.684-5.073-2.036 0-3.685 2.272-3.685 5.073 0 2.802 1.649 5.073 3.685 5.073z\"></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M22.57 53.93l23.544-4.283\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"