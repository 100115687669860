import { Block } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./process.styles.scss";
import Summary from "./Process/Summary";
import Thoughts from "./Process/Thoughts";
import BodySensations from "./Process/BodySensations";
import Actions from "./Process/Actions";
import Feelings from "./Process/Feelings";
import Transition from "./Process/Transition";
import CancelArrow from "../../../images/orange-left-arrow.svg"
import SubmitArrow from "../../../images/orange-right-arrow.svg"
import {FftIntroPage} from "../FeelingsFlowTrackerForms/FftIntroPage";
import {Reward} from "../../animations/Reward/Reward";


function ProcessScreen({ savedFFTData, emotionalData, setPage, value, onChange, setReload, communityHealthInfo, setNavPage, tokenPayload }) {

  const location = useLocation();
  const [page, setFormPage] = useState(1);
  const navigate = useNavigate()
  const handlePrev = () => {setFormPage(page - 1); scrollToTop();};

  const {summaryPage } = location.state || {}
  const options = {
    community: ["Location"],
    emotional: ["Today","This Week", "This Month", "Select Date/Range"],
  }

  const comingSoonOptions = {
    community: ["Disease", "Transmission Type", "Demographics", "Advanced"],
    emotional: ["Thoughts", "Feelings","Body Sensations", "Actions"],
  };
  const handleNext = () => {
    if (page === 5){
      console.log("latestAnswer before navigate to /emotionalhealth", savedFFTData.latestAnswer)
      navigate("/emotionalhealth", {state: {...communityHealthInfo, options: options,
      comingSoonOptions: comingSoonOptions, newNavPage: "See", tokenPayload:tokenPayload}})
    }else{
      setFormPage(page + 1)
      scrollToTop();
    }

  };

  const [animation, setAnimation] = useState(true)

  const scrollToTop = (top) => {
    window.scrollTo({
      top: top ? top : 300,
      behavior: "smooth", // Optional, smooth scrolling animation
    });
  };

  useEffect(() => {
    if(summaryPage) {
      setFormPage(summaryPage);
    }
    scrollToTop(220);
    console.log("Initial latestAnswer prop: ", savedFFTData.latestAnswer)
    const delayAnimation = setTimeout(() => setAnimation(false), 4500);
    console.log("DEV NOTE: If you would like to take another fft survey please go to the corresponding data table in AWS and delete your fft survey submissions for today's date. The app should then allow you to take another survey.")
    return () => clearTimeout(delayAnimation);
  }, []);


  return (
      <div className={"process-box-b"}>
        <div className="calendar-container">{/* <Calendar onChange={setDate} value={date}/> */}</div>

        {animation ?
            <Reward/> :
            <div className="process-container">
              {page === 1 ? (
                  // <Transition value={value} onChange={onChange} emotionalData={emotionalData} />
                  <FftIntroPage/>
              ) : page === 2 ? (
                  <Thoughts value={value} onChange={onChange} emotionalData={emotionalData}/>
              ) : page === 3 ? (
                  <Feelings value={value} onChange={onChange} emotionalData={emotionalData}/>
              ) : page === 4 ? (
                  <BodySensations value={value} onChange={onChange} emotionalData={emotionalData}/>
              ) : page === 5 ? (
                  <Actions value={value} onChange={onChange} emotionalData={emotionalData}/>
              // ) : page === 6 ? (
              //     <Summary options={options} value={value} onChange={onChange} emotionalData={emotionalData}/>
              )
                  : null}
              <div className="process-bttn-Container">
                <button id="prev-bttn" className="surveyNavBttn" style={{display: page === 1 ? "none" : "block"}}
                        onClick={() => handlePrev()}><img src={CancelArrow} alt="left facing arrow"/>Back
                </button>
                <button id="next-bttn" className="surveyNavBttn"
                        onClick={() => handleNext()}>{page === 5 ? "Finish" : "Next"}<img src={SubmitArrow}
                                                                                          alt="right facing arrow"/>
                </button>
              </div>
            </div>

        }

      </div>
  );
}

export default ProcessScreen;
