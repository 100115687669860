import React, { useRef, useState, useEffect} from "react";
import ChevronRight from "../../images/chevron-right.svg";
import CivToken from "../../images/cvl-token.svg";
import Geolocation from "./Geolocation"
import Groups from "./Groups"

const Dropdowns = ({ 
    confirmSettingRef,
    tokenPayload,
    registeredLocation,
    toggleDialog,
    activePrimaryLocation,
    activePrimaryLocationString,
    setActivePrimaryLocation,
    setActivePrimaryLocationString
}) => {

    const [expand, setExpand] = useState(
        {
            geolocation: false,
            groups: false,
        }
    );
    const [pageBook, setPageBook] = useState({
        geolocation: 1, //Possible parameters: home, primary, relevant, roaming
        groups: 1 //Possible parameters: home, groups1, groups2, groupReqs, groups3, affiliations1, affiliations2, affiliations3
    })


    const [buttonActivity, setButtonActivity] = useState({ //handles active/inactive stylings states of each form button across geoloation and groups tabs.
        newGroup: false,
        addMember: false,
        editPrimary: false,
        addRelevant: false,
    });

    const setPage = (section, num) => {
        if(pageBook[section] > 0) {
            setPageBook({
                ...pageBook,
                [section]: num
            })
        }
    }

    const DropdownHeader = ({ parent, title, parentPage, onClick }) => { 
        const returnClick = () => {
            setPage(parent, parentPage )
            if (onClick) onClick()
        }

       return (
        <ul className="headerNav">
            <li><button onClick={() => returnClick()}><p className="dropdownChevron">&lt;</p></button></li>
            <li><p>{title}</p></li>
            <li><button onClick={() => {closePage()}} className="dropdownClose"><p>-</p></button></li>
        </ul>
       )
    }

    // Displays comingSoon, same setup as for widgets
    const MenuOptionExpand = ({ name, text, comingSoon, token }) => {
        const soon = useRef();

        if(expand.hasOwnProperty(name)) {
            const newExpanded = {
                geolocation: false,
                groups: false,
            };
            return (
                <button className={`${name}Button`} onClick={() => 
                    setExpand(
                        {
                            ...newExpanded, 
                            [name]: true
                        })
                    }
                >                        
                <div className={`${name}Section`}>
                    <h2 className={`${name}Text`}>{text}</h2>
                    <RenderToken token={token}/>                            
                    <img src={ChevronRight} alt="chevron" className="chevronRight"/>
                </div>
                </button>
            );
        } else {
            return(
                <button 
                    onClick={(e) => {
                        e.preventDefault();
                        //display coming soon
                        return(<span className="coming-soon" ref={soon}>
                            Coming Soon!
                            </span>)
                        }}
                    onMouseOver={(e) => {
                        e.preventDefault();
                        if (comingSoon){
                            soon.current.style.display = "block";
                        }
                    }}
                    className={`${name}Button`}>
                        <div className={`${name}Section`}>
                            <h2 className={`${name}Text`}>{text}</h2>
                            <RenderToken token={token}/>
                            <img src={ChevronRight} alt="chevron" className="chevronRight"/>
                            {comingSoon && (
                            <span className="coming-soon" ref={soon}>
                                Coming Soon!
                            </span>
                            )}
                        </div>
                </button>
            )
        }
    }

    const RenderToken = ({token}) =>{
        if (token === true){
            return(<img src={CivToken} alt="token" className="civToken"/>)
        }
        else return null;
    }
    
    const closePage = () => {
        setExpand({
            geolocation: false,
            groups: false
        })
    }

    const handleButtonActivity = (formId, buttonState) => {
        const formCheck = document.getElementById(formId)
        if(formCheck.checkValidity()) {
            setButtonActivity((oldActivity)=> {
                return {
                    ...oldActivity,
                    [buttonState]: true,
                }
            })
        } else {
            setButtonActivity((oldActivity)=> {
                return {
                    ...oldActivity,
                    [buttonState]: false,
                }
            })
        }
    }
    
    const handleReset = (activity) => {
        setButtonActivity((oldActivity)=> {return{...oldActivity,[activity]: false}});
    }

    return (
        <>
            <MenuOptionExpand name="geolocation" text="Geolocation" comingSoon={false} token={false}/>
                <Geolocation
                    expand={expand}
                    pageBook={pageBook}
                    closePage={closePage}
                    setPage={setPage}
                    DropdownHeader={DropdownHeader}
                    confirmSettingRef={confirmSettingRef}
                    registeredLocation={registeredLocation}
                    toggleDialog={toggleDialog}
                    activePrimaryLocation={activePrimaryLocation}
                    activePrimaryLocationString={activePrimaryLocationString}
                    setActivePrimaryLocation={setActivePrimaryLocation}
                    setActivePrimaryLocationString={setActivePrimaryLocationString}
                    handleButtonActivity={handleButtonActivity}
                    handleReset={handleReset}
                    buttonActivity={buttonActivity}
                />
                <MenuOptionExpand name="groups" text="Groups & Affiliations" comingSoon={false} token={false}/>
                <Groups
                    expand={expand}
                    pageBook={pageBook}
                    closePage={closePage}
                    setPage={setPage}
                    DropdownHeader={DropdownHeader}
                    tokenPayload={tokenPayload}
                    handleButtonActivity={handleButtonActivity}
                    handleReset={handleReset}
                    buttonActivity={buttonActivity}
                />
                <MenuOptionExpand name="rewards" text="Rewards" comingSoon={true} token={true}/>
                {/* Add Rewards component here*/}
                <MenuOptionExpand name="archive" text="Archive" comingSoon={true} token={false}/>
                {/* Add Archive component here*/}
        </>
    );
};

export default Dropdowns;


