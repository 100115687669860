import React, { useState } from "react";
import { DetailSubHeader } from "./DetailSubHeader";
import { SayCategoryForm } from "./SayCategoryForm";
import { DetailSubsection } from "./DetailSubsection";
import filterIcon from '../../../images/filterIcon.svg';
import calenderIcon from '../../../images/calenderIcon.svg';
import searchIcon from '../../../images/searchIcon.svg';
import CloseBttn from "../../../images/close.svg";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-calendar/dist/Calendar.css';
import './ScanAndSay.css';
import dropdown from '../../../images/dropdown.svg';
import timer from '../../../images/timer.svg';
import civUnanswered from '../../../images/civ_unanswered.svg';
import civAnswered from '../../../images/civ_answered.svg';
import likeIcon from '../../../images/likeIcon.svg';
import shareIcon from '../../../images/shareIcon.svg';
import CancelArrow from "../../../images/orange-left-arrow.svg";
import SubmitArrow from "../../../images/orange-right-arrow.svg"
import { useLocation } from "react-router-dom";
function SayComponent({sayComponentText, tokenPayload}) {

    const [searchMode, setSearchMode] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [dateMode, setDateMode] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [categoryExpanded, setCategoryExpanded] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Live Questions');
    const [questionExpanded, setQuestionExpanded] = useState(false);
    const [question, setQuestion] = useState({});
    const [activeCategory, setActiveCategory] = useState('ME');
    const [activeCategoryContent, setActiveCategoryContent] = useState('');
    const [characterLimit] = useState(300);

    const [submitConfirmation, setSubmitConfirmation] = useState(false);

    const [showDropdown, setShowDropdown] = useState(false);
    const [showCommunityDropdown, setShowCommunityDropdown] = useState(false);

    const [selectedPrimarySortOption, setSelectedPrimarySortOption] = useState('all');
    const [selectedCommunitySortOption, setSelectedCommunitySortOption] = useState('newest');
    const [showSecondaryDropDown, setShowSecondaryDropDown] = useState(false);
    const [selectedSecondarySortOption, setSelectedSecondarySortOption] = useState('newest');

    const [readerPicks, setReaderPicks] = useState(false);
    const [selectedAnswerFilterOption, setSelectedAnswerFilterOption] = useState('all');

    const location = useLocation();
    const pathName = location.pathname;

    const handleContentChange = (event) => {
      const content = event.target.value;
      if (content.length <= characterLimit) {
        setActiveCategoryContent(content);
      }
    };
    
    const questions = {
            live : [
                {
                    qId: 0,
                    question: "Hi Do you think AI Chatbots are a solution for mental health, or is it better to work with live therapists?",
                    date: "1 October 2023",
                    isAnswered: true,
                    isTimed: false,
                },
                {
                    qId: 1,
                    question: "Do you think AI Chatbots are a solution for mental health, or is it better to work with live therapists?",
                    date: "31 October 2023",
                    isAnswered: false,
                    isTimed: true,
                    time: "72:24:59"
                },
                {
                    qId: 2,
                    question: "Do you think AI Chatbots are a solution for mental health, or is it better to work with live therapists?",
                    date: "31 October 2023",
                    isAnswered: true,
                    isTimed: false
                },
                {
                    qId: 3,
                    question: "Do you think AI Chatbots are a solution for mental health, or is it better to work with live therapists?",
                    date: "31 October 2023",
                    isAnswered: false,
                    isTimed: true,
                    time: "72:24:59"
                },
            ],
            archive : [
                {
                    qId: 0,
                    question: "Do you think AI Chatbots are a solution for mental health, or is it better to work with live therapists?",
                    date: "31 October 2023",
                    isAnswered: true,
                    isTimed: false
                },
                {
                    qId: 1,
                    question: "Do you think AI Chatbots are a solution for mental health, or is it better to work with live therapists?",
                    date: "31 October 2023",
                    isAnswered: false,
                    isTimed: true,
                    time: "72:24:599"
                },
                {
                    qId: 2,
                    question: "Do you think AI Chatbots are a solution for mental health, or is it better to work with live therapists?",
                    date: "31 October 2023",
                    isAnswered: true,
                    isTimed: false
                },
                {
                    qId: 3,
                    question: "Do you think AI Chatbots are a solution for mental health, or is it better to work with live therapists?",
                    date: "31 October 2023",
                    isAnswered: false,
                    isTimed: true,
                    time: "72:24:59"
                },
            ]
        };

    //shared and liked attributes should store the user's ID if they liked or shared (tokenPayload.sub). 
    const communityAnswers = [
        {
            aId: 0,
            qIdRelation: 0,
            username: "Username",
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in. Pellentesque ac augue quis purus ullamcorper feugiat tincidunt nec quam.",
            date: "11 October 2023",
            shared: [],
            liked: ["testID1","testID2", "testID3"],
        },
        {
            aId: 1,
            qIdRelation: 0,
            username: "Username",
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in. Pellentesque ac augue quis purus ullamcorper feugiat tincidunt nec quam.",
            date: "1 October 2023",
            shared: ["testID1"],
            liked: ["testID1","testID2"],
        },
        {
            aId: 2,
            qIdRelation: 1,
            username: "Username",
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in. Pellentesque ac augue quis purus ullamcorper feugiat tincidunt nec quam.",
            date: "31 October 2023",
            shared: ["testID1","testID2"],
            liked: ["testID1"],
        },
        {
            aId: 3,
            qIdRelation: 1,
            username: "Username",
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in. Pellentesque ac augue quis purus ullamcorper feugiat tincidunt nec quam.",
            date: "21 October 2023",
            shared: ["testID1","testID2", "testID3","testID4"],
            liked: ["testID1"],
        },
        {
            aId: 4,
            qIdRelation: 0,
            username: "Username",
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in. Pellentesque ac augue quis purus ullamcorper feugiat tincidunt nec quam.",
            date: "11 October 2023",
            shared: [],
            liked: ["testID1","testID2", "testID3","testID4"],
        },
        {
            aId: 5,
            qIdRelation: 0,
            username: "Username",
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in. Pellentesque ac augue quis purus ullamcorper feugiat tincidunt nec quam.",
            date: "1 October 2023",
            shared: ["testID1"],
            liked: ["testID1","testID2"],
        },
        {
            aId: 6,
            qIdRelation: 1,
            username: "Username",
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in. Pellentesque ac augue quis purus ullamcorper feugiat tincidunt nec quam.",
            date: "31 October 2023",
            shared: ["testID1","testID2"],
            liked: ["testID1"],
        },
        {
            aId: 7,
            qIdRelation: 1,
            username: "Username",
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum leo nibh, porttitor sagittis mauris congue in. Pellentesque ac augue quis purus ullamcorper feugiat tincidunt nec quam.",
            date: "21 October 2023",
            shared: ["testID1","testID2", "testID3", "testID4"],
            liked: [],
        },
    ];

    /*
    const liveToDatabase = () => {
        // TEMPORARY: HARDCODED TO WASHINGTON DC
        const zipcode = 20500;
        const latitude = 38.897676;
        const longitude = -77.036529;
        const userZipcode = JSON.stringify(zipcode);
        const userLatitude = JSON.stringify(latitude);
        const userLongitude = JSON.stringify(longitude);

        let payload = [];
        try{
            if(tokenPayload) {
                //Individually format and store each live question into the payload, then store it into the database.
                questions.live.forEach((qObj,index) => {
                    let isoDateString = new Date().toISOString();
                    payload[index] = {
                        body:
                        {
                            id: Date.now() + "-" + tokenPayload.sub,
                            userId: tokenPayload.sub,
                            activity: "sayLiveQuestion",
                            email: tokenPayload.email,
                            survey: {
                                qId: qObj.qId,
                                question: qObj.question,
                                date: qObj.date,
                                isAnswered: qObj.isAnswered,
                                isTimed: qObj.isTimed,
                                time: qObj?.time,
                                location: userZipcode,
                                latitude: userLatitude,
                                longitude: userLongitude
                            },
                            dateAdded: isoDateString
                        }
                    }

                    //The formatted object is then stored in the database.
                    API.post('https://r2jxsuls50.execute-api.us-east-1.amazonaws.com/test/say-survey', payload[index])
                        .then((response) => {
                            if (!response.error) {
                            console.log(response);
                            } else {
                            console.log(response);
                            }
                      });
                });
            }
        } catch(error) {
            console.log("Error currentSession: ", error);
        }
    }
        */

    const handleSearchClick = () => {
        setSearchMode(!searchMode);
        setSearchKeyword('');
    };
    const handleSearchInputChange = (event) => {
    setSearchKeyword(event.target.value);
    };
    const handleCalendarOpen = () => {
        setDateMode(true);
    };

    const handleCalendarClose = () => {
        if (!startDate || !endDate) {
            setDateMode(false);
        }
    };

    const toggleOptions = () => {
        setCategoryExpanded(!categoryExpanded);
    };

    const handleQuestionClicked = (question) => {
        setQuestion(question);
        setQuestionExpanded(true);
    };
    
    const handleCategoryClick = (category) => {
        setActiveCategory(category);
    };

    const handleSubmitClick = () => {
        setSubmitConfirmation(true);
    };

    const handleCancelSubmit = () => {
        setSubmitConfirmation(false);
    };

    const handleFinalSubmit = () => {
        console.log("Submitted content:", activeCategoryContent);
        setSubmitConfirmation(false);
    };
    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };
    const handleCommunityDropdownToggle = () => {
        setShowCommunityDropdown(!showCommunityDropdown);
    };

    const handlePrimarySortOptionClick = (option) => {
        setSelectedPrimarySortOption(option);
        setShowSecondaryDropDown(true);
    };
    const handleSecondarySortOptionClick = (option) => {
        setSelectedSecondarySortOption(option);
        setShowSecondaryDropDown(false);
        setShowDropdown(false);
    };

    const handleCommunitySortOptionClick = (option) => {
        setSelectedCommunitySortOption(option);
    }

    const highlightSearchKeyword = (text) => {
        if (searchMode && searchKeyword.trim() !== '') {
            const regex = new RegExp(`(${searchKeyword.trim()})`, 'gi');
            return text.replace(regex, (match) => `<span class="highlight">${match}</span>`);
        }
        return text;
    };
    const parseDate = (dateString) => {
            
        const months = {
          January: 0, February: 1, March: 2, April: 3, May: 4, June: 5,
          July: 6, August: 7, September: 8, October: 9, November: 10, December: 11
        };
      
        const [day, month, year] = dateString.match(/(\d+) (\w+) (\d+)/).slice(1);
        const monthIndex = months[month];
        return new Date(year, monthIndex, day);
      };

    const sortQuestionsSecondary = (a, b) => {
        switch (selectedSecondarySortOption) {
            case 'newest':
                return parseDate(b.date) - parseDate(a.date);
            case 'oldest':
                return parseDate(a.date) - parseDate(b.date);
            default:
                return 0;
        }
    };
    const filterQuestions = (question) => {

        if (searchMode && searchKeyword) {
            if (!question.question.toLowerCase().includes(searchKeyword.toLowerCase())) {
                return false;
            }
        }
        if (dateMode && dateRange[0] && dateRange[1]) {
            const startDate = dateRange[0];
            const endDate = dateRange[1];
            const questionDate = parseDate(question.date);
            if (questionDate < startDate || questionDate > endDate) {
                return false;
            }
        }
        if (selectedPrimarySortOption === 'answered' && !question.isAnswered) return false;
        if (selectedPrimarySortOption === 'unanswered' && question.isAnswered) return false;
        return true;
    };
    const sortedQuestions = selectedOption === "Live Questions" ? questions.live : questions.archive;

    const handleAnswerFilterOptions = (option) => {
        setSelectedAnswerFilterOption(option);
    }

    let likedSorted = [...communityAnswers].sort((a,b) => b.liked.length - a.liked.length);
    const filterAnswers = (answer) => {
        if (selectedAnswerFilterOption === 'readerPicks') {
            return answer.liked.length >= likedSorted[Math.round(likedSorted.length*.15)].liked.length; //15% top liked posts only
        } else {
            return true; //If ALL is selected, or there aren't enough answers to evaluate, just return all values unfiltered.
        }
    }

    const sortCommunityAnswers = (a, b) => {
        switch (selectedCommunitySortOption) {
            case 'newest':
                return parseDate(b.date) - parseDate(a.date);
            case 'oldest':
                return parseDate(a.date) - parseDate(b.date);
            case 'shared': 
                return b.shared.length - a.shared.length;
            case 'liked':
                return b.liked.length - a.liked.length;
            default:
                return 0;
        }
    };

    //Look for the specific answer, check if the ID of the user is included in the "liked" array, add or remove them from the list accordingly. 
    const handleLike = (answer, aIndex) => {
        try{ 
            //The Liked and Share feature can not be handled without backend persistant storage of the question/answers.
            /*
            if(answer.liked.includes(tokenPayload.sub)) { //if already liked, remove user ID
                communityAnswers[aIndex].liked.splice(aIndex, 1);
            } else {
                communityAnswers[aIndex].liked.push(tokenPayload.sub); 
            }
            */
        } catch(error) {
            console.log(error);
        }
    }

    //Look for the specific answer, check if the ID of the user is included in the "shared" array, add or remove them from the list accordingly. 
    const handleShare = (answer, aIndex) => {
        try{ 
            //The Liked and Share feature can not be handled without backend persistant storage of the question/answers.
            /*
            if(answer.shared.includes(tokenPayload.sub)) {
                communityAnswers[aIndex].shared.splice(aIndex, 1);
            } else {
                communityAnswers[aIndex].shared.push(tokenPayload.sub);
            }
            */
        } catch(error) {
            console.log(error);
        }
    }

    /*
    return sequence:
    - Unexpanded
        1. Filters
        2. Questions
    - Expanded
        1. Me/Community answers
        2. Navigation buttons
    */
    return (
        <div>
            {!questionExpanded && (
            <>
                <div className='sayHeader-bttnContainer'>
                    {searchMode ? (
                    <input
                        type='text'
                        value={searchKeyword}
                        onChange={handleSearchInputChange}
                        placeholder='Enter keywords...'
                    />) : 
                    null
                    }
                    <button onClick={handleSearchClick}><img id="saySearchBttn" src={searchMode ? CloseBttn : searchIcon} alt="search"/></button>
                    <button onClick={handleDropdownToggle}><img id="sayFilterBttn" src={filterIcon} alt="filter"/></button>
                    {showDropdown && (
                    <div className="filterDropdown">
                        <button  className={selectedPrimarySortOption === 'all' ? 'active' : ''}onClick={() => handlePrimarySortOptionClick('all')}>All</button>
                        <button  className={selectedPrimarySortOption === 'answered' ? 'active' : ''} onClick={() => handlePrimarySortOptionClick('answered')}>Answered <img className='civFilterCoin' src={civAnswered} alt="civ coin answered"/></button>
                        <button  className={selectedPrimarySortOption === 'unanswered' ? 'active' : ''}onClick={() => handlePrimarySortOptionClick('unanswered')}>Not Answered <img className='civFilterCoin' src={civUnanswered} alt="civ coin unanswered"/></button>
                    </div>
                    )}
                    {
                        showSecondaryDropDown && showDropdown && (
                            <div className="secondaryDropdown">
                                <button className={selectedSecondarySortOption === 'newest' ? 'active' : ''} onClick={() => handleSecondarySortOptionClick('newest')}>
                                    Newest first
                                </button>
                                <button className={selectedSecondarySortOption === 'oldest' ? 'active' : ''} onClick={() => handleSecondarySortOptionClick('oldest')}>
                                    Oldest first
                                </button>
                            </div>
                        )
                    }
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                        }}
                        onCalendarOpen={handleCalendarOpen}
                        onCalendarClose={handleCalendarClose}
                        isClearable={true}
                        customInput={
                            <button className={startDate && endDate? 'flex pr-5' : ''} id='dateRangeBttn'>
                            <img id="sayCalendarBttn" src={calenderIcon} alt="calendar"/>
                            <div className='dateRangeWrapper'>
                                {startDate ? (
                                <div>
                                    {startDate.getDate()} {startDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }) + ' - '}
                                </div>
                                ) : null}
                                {endDate ? (
                                <div>
                                    {endDate.getDate()} {endDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
                                </div>
                                ) : null}
                            </div>
                            </button>
                        }
                        />
                    </div>
                </div>
                <div>
                    <div className="sayCategory-container">
                        <div className={`categoryButton-container ${categoryExpanded ? 'expanded' : ''}` + (pathName === '/learnmore' ? ' diseaseSayCategoryColor' : ' emotionalSayCategoryColor')} onClick={toggleOptions}>
                            { !categoryExpanded ? (
                                <div className='currentSayCategory'>
                                    <div className={"currentSayCategoryButtonWrapper" + (pathName === '/learnmore' ? ' diseaseSayCategoryColorGradient' : ' emotionalSayCategoryColorGradient')}>
                                        <div className="currentSayCategoryButton">
                                            <h5>{selectedOption}</h5>
                                            
                                            <img className="rightDropDown" src={dropdown} alt="Expand Category"/>
                                        </div>
                                    </div>
                                </div>
                                ) : null}
                            <div className="options-container">
                                <div className="optionsWrapper">
                                    <div className="option" onClick={() => {setSelectedOption("Live Questions")}}>
                                        Live Questions
                                    </div>
                                    <hr className="sayCategoryDivider" />
                                    <div className="option" onClick={() => {setSelectedOption("Archive")}}>
                                        Archive
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> 
            )}
            {!questionExpanded && (
                 <div className="questionBoard">
                {sortedQuestions
                    .filter(filterQuestions)
                    .sort(sortQuestionsSecondary)
                    .map((question, index) => (
                        <div key={index} className="questionContainer" onClick={() => {handleQuestionClicked(question)}}>
                            <div className="sayQuestionIsAnsweredContainer"><img src={question.isAnswered ? civAnswered : civUnanswered} alt="Question" className="questionImage" /></div>
                            <h4 className="sayQuestionTitle" dangerouslySetInnerHTML={{ __html: highlightSearchKeyword(question.question)}} />
                            <div className="sayQuestionDateAndTimeContainer">
                                <p className="sayQuestionDate">{question.date}</p>
                                {question.isTimed ? (
                                    <div className="sayQuestionTimerContainer">
                                        <img src={timer} alt="timer"/>
                                        <p className="sayQuestionTime">{question.time}</p>
                                    </div>
                                ) : (null)}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {questionExpanded ? (
                <div>
                    <div className="questionExpandedInfo">
                        <h6 className="questionExpanded-Title">{question.question}</h6>
                        <div className="questionExpanded-DateAndTime">
                            <p>{question.date}</p>
                            {question.isTimed && <div className="questionExpanded-TimerContainer"><img src={timer} alt="timer"/><p>{question.time}</p></div>}
                        </div>
                    </div>
                    <div className="questionExpanded-CategoryWrapper">
                        <div className={`questionExpanded-CategoryContainer ${submitConfirmation ? 'blurred' : ''}`}>
                            <div
                                className={`questionExpanded-CategoryButton left ${
                                    activeCategory === 'ME' ? 'active' : ''
                                }`}
                                onClick={() => handleCategoryClick('ME')}
                            >
                                ME
                            </div>
                            <div
                                className={`questionExpanded-CategoryButton right ${
                                    activeCategory === 'COMMUNITY' ? 'active' : ''
                                }`}
                                onClick={() => handleCategoryClick('COMMUNITY')}
                            >
                                COMMUNITY
                            </div>
                        </div>
                        {activeCategory === 'ME' ? (
                            <div className="inputBoxContainerWrapper">
                                <div className={`inputBoxContainer ${submitConfirmation ? 'blurred' : ''}`}>
                                    <textarea
                                    className="questionExpanded-InputBox"
                                    value={activeCategoryContent}
                                    onChange={handleContentChange}
                                    disabled={submitConfirmation}
                                    />
                                    <div
                                    className="characterCount"
                                    >
                                        {activeCategoryContent.length}/{characterLimit}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {activeCategory === 'COMMUNITY' ? (
                            <div className="questionExpanded-Community">
                                <div className="questionExpanded-CommunityHeader">                                        
                                    <div className="questionExpanded-CommunityHeaderLeft">                                            
                                        <div>
                                            <button className={selectedAnswerFilterOption === 'civPicks' ? 'active' : ''} onClick={() => handleAnswerFilterOptions('civPicks')}><p>Civilience Picks<img src={civAnswered} alt="civ coin"/></p> </button>
                                        </div>
                                        {selectedAnswerFilterOption ==='civPicks' && <div className="civPicksSoon">Coming Soon!</div>}
                                        <div>
                                            <button className={selectedAnswerFilterOption === 'readerPicks' ? 'active' : ''} onClick={() => handleAnswerFilterOptions('readerPicks')}><p>Reader Picks<img src={civAnswered} alt="civ coin"/></p></button>
                                        </div>
                                        <div>
                                            <button className={selectedAnswerFilterOption === 'all' ? 'active' : ''} onClick={() => handleAnswerFilterOptions('all')}>All</button>
                                        </div>
                                    </div>                                    
                                    <div className="questionExpanded-CommunityHeaderRight">
                                        <div>
                                            <button onClick={handleCommunityDropdownToggle}><img src={filterIcon} alt="filter" /></button>
                                            {showCommunityDropdown && (
                                            <div className="communityFilterDropdown">
                                                <button className={selectedCommunitySortOption === 'newest' ? 'active' : ''}onClick={() => handleCommunitySortOptionClick('newest')}>Newest First</button>
                                                <button className={selectedCommunitySortOption === 'oldest' ? 'active' : ''}onClick={() => handleCommunitySortOptionClick('oldest')}>Oldest First</button>
                                                <button className={selectedCommunitySortOption === 'shared' ? 'active' : ''}onClick={() => handleCommunitySortOptionClick('shared')}>Most Shared</button>
                                                <button className={selectedCommunitySortOption === 'liked' ? 'active' : ''}onClick={() => handleCommunitySortOptionClick('liked')}>Most Liked</button>
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="questionExpanded-CommunityAnswerBoard">
                                    {communityAnswers
                                    .filter(filterAnswers)
                                    .sort(sortCommunityAnswers)
                                    .map((answer, index) => (
                                        <div key={index} className="communityAnswer">
                                            <div className="answerHeader">
                                                <p className="answerUsername">{answer.username}</p>
                                            </div>
                                            <div className="answerContent">
                                                <p>{answer.answer}</p>
                                            </div>
                                            <div className="answerFooter">
                                                <div className="answerActions">
                                                    <p><button onClick={handleLike(answer, index)}><img src={likeIcon} alt="like" className="answerActionIcon" /></button>{answer.liked.length}</p>
                                                    <p><button onClick={handleShare(answer, index)}><img src={shareIcon} alt="share" className="answerActionIcon" /></button>{answer.shared.length}</p>
                                                </div>
                                                <p className="answerDate">{answer.date}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ): null}
                    </div>
                </div>
                ): null}
                {questionExpanded ? (
                    <div className="sayNavigation">
                        <div className="sayNavigationButtons">
                            <button className="sayPrevBttn" onClick={() => {setQuestionExpanded(false); setQuestion({}); setActiveCategoryContent(""); setActiveCategory("ME")}}><img src={CancelArrow}
                                alt="left facing arrow"/>Back
                            </button>
                            {activeCategory === 'ME' ? (
                                <button className="saySubmitBttn" onClick={handleSubmitClick}>
                                    Submit<img src={SubmitArrow} alt="right facing arrow"/>
                                </button>
                            ): null}
                        </div>
                       {submitConfirmation && (
                        <div className="confirmationDialog">
                            <p>You may have your say only once per survey - so, make it count! <b>Submit only if you're ready.</b></p>
                            <div className="confirmationButtons">
                                <button className="sayConfirmationDialogBttn" onClick={handleCancelSubmit}> <img src={CancelArrow} alt="left facing arrow" />Cancel</button>
                                <button className="sayConfirmationDialogBttn" onClick={handleFinalSubmit}>Submit<img src={SubmitArrow} alt="right facing arrow"/></button>
                            </div>
                        </div>
                        )}
                    </div>
                ) : null}
                
        </div>
    );
}

export default SayComponent;
