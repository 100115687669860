import React from 'react';
import { Ad } from './Ad';
import LinktreeLogo from '../../images/linktree_logo.png';
import CommunityIcon from "../../images/communityIcon.svg";
import generalInformationIcon from "../../images/general_information_icon.png"
import { useState } from 'react';
import MenuIcon from '../../images/hamburgerIcon.svg'

function DetailFooter({setDropdownOpen, dropdownOpen}) {
    let newDate = new Date();
    let year = newDate.getFullYear()

    const [comingSoonList, setComingSoonList] = useState({
        privacy: false,
        disclaimers: false,
        legal: false,
        community: false       
    })

    const handleComingSoonLinks = (link) => {
        const newList = {
            privacy: false,
            disclaimers: false,
            legal: false,
            community: false
        }
        if(comingSoonList[link] === true) {
            setComingSoonList(newList);
        } else {
            setComingSoonList({...newList, [`${link}`]: true})
        }    
    }

    const handleDropdown = () => {
        const dropdownStatus = dropdownOpen.expand;
        setDropdownOpen({ footer: true, expand: !dropdownStatus});
    }
    
    return (
        <div>
            <Ad/>
            <div className="blue" id="bottom-blue">
                <footer>
                    <div className="contentContainer">
                        
                        <ul className={"footer_image footer_image_m"}>

                            <li><a href="https://linktr.ee/Civilience" target="_blank" rel="noreferrer">
                                <img src={LinktreeLogo} alt="Linktree Logo"/>
                            </a>
                            </li>
                            <li>
                                <a style={{color: "inherit", textDecoration: "none"}}
                                   href="https://linktr.ee/Civilience" target="_blank" rel="noreferrer">
                                    linktr.ee/civilience
                                </a>
                            </li>
                        </ul>
                        <ul className={"footer_image footer_image_l"}>
                        
                            <li><button onClick={() => handleComingSoonLinks('community')}>
                                <img src={CommunityIcon} alt="Community Logo"/>
                            </button></li>
                            <li className='communitySoon'>Our Community {comingSoonList.community && <p>Coming Soon!</p>}</li>
                        </ul>
                        <ul className={"footer_image footer_image_l"}>
                            <li><button className='menu_button' onClick={() => handleDropdown()}>
                                <img className='adjusted_icon' src={MenuIcon} alt="Main Menu Icon"/>
                            </button></li>
                            <li>Main Menu</li>
                        </ul>
                        <ul className={"footer_privacy"}>
                            <li id='privacy'><button onClick={() => handleComingSoonLinks('privacy')}>Privacy</button>{comingSoonList.privacy && <p>Coming Soon!</p>}</li>
                            <li id='disclaimers'><button onClick={() => handleComingSoonLinks('disclaimers')}>Disclaimers</button>{comingSoonList.disclaimers && <p>Coming Soon!</p>}</li>
                            <li id='legal'><button onClick={() => handleComingSoonLinks('legal')}>Legal</button>{comingSoonList.legal && <p>Coming Soon!</p>}</li>
                        </ul>
                    </div>
                    <small>&copy; {year} Civilience</small>
                </footer>
            </div>
        </div>

    );
}

export default DetailFooter;