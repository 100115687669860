import { Dropdown } from "./DetailContent/Dropdown"
import Logo from "../../images/civilience_triangle.png"
import styled from "styled-components";
import styles from "./index.css";
import { MdArrowForwardIos } from "react-icons/md";
import { GoHomeFill } from "react-icons/go";
import { IoHome } from "react-icons/io5";
import {useLocation, useNavigate} from "react-router-dom";
import { PiArrowFatLinesRightFill } from "react-icons/pi";
import {useEffect, useState} from "react";
import { TiHome } from "react-icons/ti";
import banner from "../../images/deep_orange_velvet_bg.jpg"

const TitleInfoBox = styled.div.attrs(props => ({
    className: props.className
}))`
  position: relative;
  width: 100%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
`;


const TitleInfoContextBox = styled.div.attrs(props => ({
    className: props.className
}))`
  display: flex;
  z-index: 1;
  background: white;
  border-radius: 10px;
  padding: 7px 48px;
    height: 3.4rem;
  color: #808080;
  margin-top: -13px;
  border: 1.5px solid #01173F ;
  justify-content: center;
  align-items: center;
  border-bottom: none;
  user-select: none;
`;


const TitleBackdropColor = styled.div.attrs(props => ({
    className: props.className
}))`
    position: absolute;
    width: 90%;
    z-index: 1;
    //height: 100%;
    height: ${(props) => props.BackdropHeight}; 
    margin-top: ${(props) => props.BackdropMTop};
    margin-left: -5px;
    //top: 0;
    border-radius: 10px;
    background-image: url(${banner});
    background-size: cover;
    background-repeat: no-repeat;
`;


const TitleInfoCurrentloc = styled.div.attrs(props => ({
    className: props.className
}))`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    gap: ${(props) => props.AllGap};
    padding-right: ${(props) => props.AllPRight};
    
    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: ${(props) => props.AllLiGap};  //extend
        width: ${(props) => props.AllLiWidth};// changeHSD

        svg {
            min-width: ${(props) => props.LiSvgMWidth}; //extend
            min-height: ${(props) => props.LiSvgMHeight}; //extend
            font-size: 22px;
            color: #808080;
        }
    }

    li:last-child {
        z-index: 3;
        color: ${(props) => props.LiLastColor}; //learnmore
    }

    button {
        background: none;
        color: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 0;
        line-height: 16px;
        cursor: pointer;
        outline: inherit;

        svg {
            min-height: 20px; 
            min-width: 20px; 
            font-size: ${(props) => props.BuSvgFsize}; //extend
        }

        span {
            font-weight: 550;
            font-size: ${(props) => props.BuSpanFsize}; //extend
        }

        h1 {
            font-size: ${(props) => props.BuH1Fsize};
            font-weight: 600;
            //margin-top: -7px;
            margin-top: ${(props) => props.BuH1MTop};
            margin-bottom: ${(props) => props.BuH1MBottom};
        }
    }
`;
export const DetailTitle = ({ communityHealthInfo, diseaseInfo}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [newStyle, setNewStyle] = useState({
        AllGap: "5px",
        AllLiGap: "-1px",
        AllPRight: "45px",
        AllLiWidth: "20%",
        LiSvgMWidth: "35px",
        LiSvgMHeight: "22px",
        LiLastColor: "#EA4743",
        BuSvgFsize: "23px",
        BuSpanFsize: "13.7px",
        BuH1Fsize: "21px",
        BuH1MTop: "1px",
        BuH1MBottom: "0px",
        BackdropHeight: "3.4rem",
        BackdropMTop: "11px"

    })

    const HomeLink = () => {
        navigate("/")
    }
    const HealthLink = () => {
        navigate("/healthsubdomain", {state: communityHealthInfo})
    }

    const SubHealthLink = () =>{
        navigate("/emotionalhealth", {state: communityHealthInfo})
    }

    const SubInfectiousLink = () =>{
        navigate("/learnmore", {state: communityHealthInfo})
        diseaseInfo.setDisease(null)
    }

    const SubInfSurveyLink = () => {
        navigate("/learnmore/survey", {state: communityHealthInfo})
        diseaseInfo.setDisease(null)
    }
    const LocationStyle = () =>{

        let style = newStyle

        if (location.pathname === "/healthsubdomain"){
            style = {...style,
                AllLiWidth: "20%",
                AllGap: "15px",
                AllLiGap: "3px",
                AllPRight: "15px",
                LiLastColor: "#EA4743",
                LiSvgMHeight: "20px",
                LiSvgMWidth: "20px",
                BuH1MTop: "1px",
                BuH1MBottom: "0px",
                BackdropHeight: "3.4rem",
                BackdropMTop: "11px"

            }
        }
        if (location.pathname === "/learnmore" || location.pathname === "/emotionalhealth" || location.pathname === "/learnmore/survey"){
            style = {...style,
                AllLiWidth: "100%",
                AllGap: "15px",
                AllLiGap: "10px",
                AllPRight: "0px",
                LiSvgMHeight: "23px",
                BuH1MTop: "-7px",
                BuH1MBottom: "0px",
                BackdropHeight: "3rem",
                BackdropMTop: "3px",
                LiSvgMWidth: "35px",
                BuSvgFsize: "23px",
                BuSpanFsize: "13.7px",
                BuH1Fsize: "21px"
            }
        }
        if (location.pathname === "/emotionalhealth"){
            style = {...style,
                LiLastColor: "#F76E2D",
            }
        }
        if (location.pathname === "/emotionalhealth/fft" || location.pathname === "/emotionalhealth/process"){
            style = {...style,
                AllLiWidth: "100%",
                AllGap: "15px",
                AllLiGap: "7px",
                AllPRight: "0px",
                LiSvgMWidth: "21px",
                LiSvgMHeight: "21px",
                BuSvgFsize: "20px",
                BuSpanFsize: "11.5px",
                BuH1Fsize: "18px"
            }
        }

        setNewStyle(style)
    }

    useEffect(() => {
        LocationStyle()
        console.log(communityHealthInfo)
    }, [location.pathname]);


  return (

      <section>

        <TitleInfoBox>
            <TitleBackdropColor BackdropHeight={newStyle.BackdropHeight} BackdropMTop={newStyle.BackdropMTop}/>
            <TitleInfoContextBox>
                <TitleInfoCurrentloc
                    AllGap = {newStyle.AllGap}
                    AllLiGap = {newStyle.AllLiGap}
                    AllPRight={newStyle.AllPRight}
                    AllLiWidth = {newStyle.AllLiWidth}
                    LiSvgMWidth = {newStyle.LiSvgMWidth}
                    LiSvgMHeight = {newStyle.LiSvgMHeight}
                    LiLastColor = {newStyle.LiLastColor}
                    BuSvgFsize = {newStyle.BuSvgFsize}
                    BuSpanFsize = {newStyle.BuSpanFsize}
                    BuH1Fsize = {newStyle.BuH1Fsize}
                    BuH1MTop = {newStyle.BuH1MTop}
                    BuH1MBottom = {newStyle.BuH1MBottom}
                >
                    <li>
                        <button onClick={HomeLink}><TiHome/></button>
                        <MdArrowForwardIos/>
                    </li>
                    <li>
                        <button onClick={HealthLink}><h1>HEALTH</h1></button>
                    </li>
                    
                    {location.pathname !== "/healthsubdomain" && (
                        <>
                            <li>
                                <MdArrowForwardIos/>
                                    {(location.pathname === "/emotionalhealth" ||
                                            location.pathname === "/emotionalhealth/fft" || location.pathname === "/emotionalhealth/process") && (
                                                <button onClick={SubHealthLink}>
                                                    <span>EMOTIONAL HEALTH</span>
                                                </button>
                                            )
                                        }
                                    {(location.pathname === "/learnmore" || location.pathname === "/learnmore/survey") && (
                                        <button onClick={SubInfectiousLink}>
                                            <span>INFECTIOUS DISEASE</span>
                                        </button>
                                    )}

   
                            </li>

                            {(location.pathname === "/learnmore/survey") && (
                                <>
                                <li>
                                    <MdArrowForwardIos/>
                                    <button onClick={SubInfSurveyLink}>
                                        <span>SCREEN</span>
                                    </button>
                                </li>
                                </>
                            )}
                        </>
                    )}
                    {(location.pathname === "/emotionalhealth/fft" || location.pathname === "/emotionalhealth/process") &&
                        <li>
                            <MdArrowForwardIos/>
                            <button><span>FEELINGS FLOW</span></button>
                        </li>
                    }

                </TitleInfoCurrentloc>
            </TitleInfoContextBox>
        </TitleInfoBox>

      </section>
  )
}