export const Shame = "<svg\n" +
    "      xmlns=\"http://www.w3.org/2000/svg\"\n" +
    "      fillRule=\"evenodd\"\n" +
    "      strokeMiterlimit=\"10\"\n" +
    "      clipRule=\"evenodd\"\n" +
    "      viewBox=\"0 0 2363 2363\"\n" +
    "    >\n" +
    "      <g transform=\"translate(-3411.94 -396.423) translate(-205.434 -205.434)\">\n" +
    "        <ellipse\n" +
    "          cx=\"2507.28\"\n" +
    "          cy=\"2173.37\"\n" +
    "          fill=\"#D94941\"\n" +
    "          rx=\"1150.21\"\n" +
    "          ry=\"929.533\"\n" +
    "          transform=\"matrix(1.02685 0 0 1.27064 2223.86 -978.611)\"\n" +
    "        ></ellipse>\n" +
    "        <g transform=\"translate(4808.77 1823.63) scale(23.8825) translate(-34.5 -36.5)\">\n" +
    "          <clipPath id=\"_clip1\">\n" +
    "            <path d=\"M0 0H69V73H0z\"></path>\n" +
    "          </clipPath>\n" +
    "          <g fillRule=\"nonzero\" clipPath=\"url(#_clip1)\">\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"2\"\n" +
    "              d=\"M34.5 68.004c18.502 0 33.5-14.999 33.5-33.502S53.002 1 34.5 1C15.999 1 1 15.999 1 34.502s14.999 33.502 33.5 33.502z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#231F20\"\n" +
    "              d=\"M32.298 12.904s-.909 5.301-6.365 6.378c0 0-4.558.885-7.118-3.745 0 0 6.783 4.307 13.483-2.633zM13.754 29.225s1.95-7.036 9.452-7.742c0 0 6.281-.574 9.104 6.019 0 0-8.566-6.737-18.556 1.723zM35.111 12.904s.91 5.301 6.365 6.378c0 0 4.559.885 7.119-3.745 0 0-6.784 4.307-13.484-2.633zM55.258 29.225s-1.95-7.036-9.452-7.742c0 0-6.281-.574-9.105 6.019 0 0 8.567-6.737 18.557 1.723z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M23.026 44.515c4.071 0 7.37-4.542 7.37-10.146 0-5.604-3.299-10.146-7.37-10.146-4.07 0-7.37 4.542-7.37 10.146 0 5.604 3.3 10.146 7.37 10.146z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M24.248 42.098c2.035 0 3.685-2.272 3.685-5.074s-1.65-5.073-3.685-5.073c-2.036 0-3.685 2.271-3.685 5.073 0 2.802 1.649 5.074 3.685 5.074z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              stroke=\"#000\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M45.974 44.515c4.07 0 7.37-4.542 7.37-10.146 0-5.604-3.3-10.146-7.37-10.146-4.071 0-7.371 4.542-7.371 10.146 0 5.604 3.3 10.146 7.371 10.146z\"\n" +
    "            ></path>\n" +
    "            <path d=\"M47.207 42.098c2.035 0 3.685-2.272 3.685-5.074s-1.65-5.073-3.685-5.073c-2.036 0-3.685 2.271-3.685 5.073 0 2.802 1.649 5.074 3.685 5.074z\"></path>\n" +
    "            <path\n" +
    "              fill=\"#00ADEB\"\n" +
    "              d=\"M60.188 25.35s-6.94 11.522 0 11.522c6.939 0 0-11.522 0-11.522z\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M14.818 65.216s7.993-14.909 9.943-11.535c0 0 8.686-11.714 12.132-6.916 3.445 4.798-3.446 13.916-3.446 13.916s2.811-1.496 2.5.67c-.311 2.166-13.424 10.517-13.424 10.517\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M14.818 65.216s7.993-14.909 9.943-11.535c0 0 8.686-11.714 12.132-6.916 3.445 4.798-3.446 13.916-3.446 13.916s2.811-1.496 2.5.67c-.311 2.166-13.424 10.517-13.424 10.517\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"#fff\"\n" +
    "              d=\"M54.193 65.216s-7.992-14.909-9.942-11.535c0 0-8.686-11.714-12.132-6.916-3.446 4.798 3.446 13.916 3.446 13.916s-2.812-1.496-2.501.67c.311 2.166 13.424 10.517 13.424 10.517\"\n" +
    "            ></path>\n" +
    "            <path\n" +
    "              fill=\"none\"\n" +
    "              stroke=\"#231F20\"\n" +
    "              strokeWidth=\"1.3\"\n" +
    "              d=\"M54.193 65.216s-7.992-14.909-9.942-11.535c0 0-8.686-11.714-12.132-6.916-3.446 4.798 3.446 13.916 3.446 13.916s-2.812-1.496-2.501.67c.311 2.166 13.424 10.517 13.424 10.517\"\n" +
    "            ></path>\n" +
    "          </g>\n" +
    "        </g>\n" +
    "      </g>\n" +
    "    </svg>"